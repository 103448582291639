<template>
  <div class="modal-wrap">
    <div class="forms-container">
      <div class="form">
        <div v-if="!hasAPI">
          <label for="lojaintegrada-apikey" class="text">{{ $t('integration.loja_integrada.title') }}</label>
          <div class="input-container mb-5">
            <input
              v-model="apiKey"
              id="lojaintegrada-apikey"
              class="input"
              type="text"
              :placeholder="$t('integration.loja_integrada.placeholder_api')"
            />
          </div>
          <div class="input-container">
            <input
              v-model="appKey"
              id="lojaintegrada-apikey"
              class="input"
              type="text"
              :placeholder="$t('integration.loja_integrada.placeholder_app')"
            />
            <button class="button is-primary ml-5" @click="saveCredentials()" :disabled="!apiKey || !appKey">
              <template>{{ $t('integration.loja_integrada.button') }}</template>
            </button>
          </div>
        </div>
        <div v-else>
          <div class="has-text-primary is-flex">
            <modatta-icon icon="info-square-light" class="mr-2"></modatta-icon> API credentils are defined.
          </div>
        </div>
        <hr />
        <section class="intro">
          <header>
            <p class="text">{{ $t('integration.loja_integrada.intro_title') }}</p>
            <span class="text">{{ $t('integration.loja_integrada.intro_description') }}</span>
          </header>
          <div class="video">
            <video width="400" controls>
              <source src="mov_bbb.mp4" type="video/mp4" />
              <source src="mov_bbb.ogg" type="video/ogg" />
            </video>
          </div>
          <footer>
            <p class="text">{{ $t('integration.loja_integrada.footer_title') }}</p>
            <a href="#" class="text" target="_BLANK">{{ $t('integration.loja_integrada.footer_link') }}</a>
          </footer>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['options'],
    components: { Multiselect },
    data() {
      return {
        apiKey: '',
        appKey: '',
        credential: '',
        saved: false,
      };
    },
    mounted() {
      this.getCredentials().then(() => {
        this.credential = this.$store.state.user.user.credentials;
        this.apiKey = this.splitCredential(this.credential)['chave_api'];
        this.appKey = this.splitCredential(this.credential)['aplicacao'];
      });
    },
    watch: {
      apiKey() {
        this.apiKey = this.apiKey.trim();
      },
      appKey() {
        this.appKey = this.appKey.trim();
      },
    },
    computed: {
      hasAPI() {
        return this.$store.state.user.user.credentials || (this.apiKey && this.appKey && this.saved);
      },
    },
    methods: {
      ...mapActions(['getCredentials', 'setCredentials']),
      ...mapMutations(['setOpenToast']),
      saveCredentials() {
        if (!this.apiKey || !this.appKey) {
          this.setOpenToast({
            title: 'It was not possible to set new credentials, try again.',
            message: '',
            isSuccess: true
          });
          return;
        }
        this.setCredentials({
          key: this.apiKey,
          app: this.appKey,
        })
          .then(() => {
            this.saved = true;
            this.setOpenToast({
              title: 'Credentials were set correctly.',
              message: '',
              isSuccess: true
            });
            this.getCredentials().then(() => {
              this.credential = this.$store.state.user.user.credentials;
              this.apiKey = this.splitCredential(this.credential)['chave_api'];
              this.appKey = this.splitCredential(this.credential)['aplicacao'];
            });
          })
          .catch((error) => {
            this.setOpenToast({
              title: 'It was not possible to set new credentials, try again.',
              message: '',
              isSuccess: false
            });
          });
      },
      splitCredential(str) {
        const keyValuePairs = str.split(/ /);
        const object = {};
        for (let i = 0; i < keyValuePairs.length; i += 2) {
          const key = keyValuePairs[i];
          const value = keyValuePairs[i + 1];
          object[key] = value;
        }
        return object;
      },
    },
  };
</script>

<styled lang="scss" scoped>
.modatta-side-modal {
  background-color: transparent !important;
}

.forms-container {
  padding: 30px 35px;

  .form {
    label {
      color: #0a1735;
      font-family: 'Silka Medium';
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 20px;
      display: block;
    }

    .input-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 8px;

      input,
      button {
        height: 50px;
      }

      input {
        border-radius: 8px;
        border: 1px solid #dee5f6;
        background: #f7f9fd;
        box-shadow: none;
        color: rgba(#0a1735, 0.5);
        font-family: 'Silka Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        padding: 0 20px;
      }
    }
  }

  hr {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .intro {
    header {
      display: block;
      border: 0;
      margin: 0;
      padding: 0;
      text-align: center;

      p {
        display: block;
        color: #0a1735;
        font-family: 'Silka Bold';
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;
        margin-bottom: 14px;
      }

      span {
        display: block;
        color: #0a1735;
        font-family: 'Silka Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
      }
    }

    .video {
      video {
        display: block;
        margin: 30px auto;
      }
    }

    footer {
      text-align: center;

      p {
        display: block;
        color: #0a1735;
        font-family: 'Silka Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
      }

      a {
        display: block;
        color: #2355c3;
        font-family: 'Silka Bold';
        font-size: 14px;
        font-weight: 700;
        line-height: 150%;
      }
    }
  }
}
</styled>
