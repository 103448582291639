<template>
  <div>
    <div class="container-categories">
      <b-field v-bind:label="$t('offers_step4.integration_data_points.form_category_label')">
        <div class="categories">
          <div
            :key="index"
            class="category"
            v-for="(category, index) in categoryList"
            @click="handlerCategory(category)"
            :class="{ 'is-selected': selectedCategory && selectedCategory.id === category.id }"
          >
            <modatta-icon :icon="category.icon" width="24"></modatta-icon>
            <div v-text="$t(`offers_step4.integration_data_points.form_category_values.${category.name}`)"></div>
          </div>
        </div>
      </b-field>
    </div>
    <div class="container-profile" v-if="isShowProfile">
      <b-field v-bind:label="$t('offers_step4.integration_data_points.form_profile_label')">
        <div class="modatta-country-select">
          <multiselect
            v-bind:placeholder="$t('offers_step4.integration_data_points.form_profile_label_placeholder')"
            no-options="Please select a category above"
            selectLabel="ENTER to select"
            v-model="selectedProfile"
            openDirection="bottom"
            :allow-empty="false"
            :showLabels="false"
            :options="profileList"
            track-by="name"
            label="name"
          >
            <span class="caret" slot="caret">
              <modatta-icon icon="arrow-down" width="10"></modatta-icon>
            </span>
          </multiselect>
        </div>
      </b-field>
    </div>
    <div class="container-data-points" v-if="isShowDataPoint">
      <b-field v-bind:label="$t('offers_step4.integration_data_points.form_data_points_label')">
        <div class="data-points-list">
          <div :key="index" class="data-points" v-for="(dataPoint, index) in dataPointList">
            <span>{{ index + 1 }}&nbsp;-&nbsp;</span>
            <strong class="data-points-profile-label">{{ dataPoint.profileName }}</strong>
            <span class="data-points-operator-label">{{ dataPoint.operatorName }}</span>
            <button @click="deleteDataPoint(dataPoint)">
              <modatta-icon icon="times" width="12"></modatta-icon>
            </button>
          </div>
        </div>
      </b-field>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import Multiselect from 'vue-multiselect';
  import Filters from '../../../../../helpers/filters/filter-methods.js';
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    name: 'ModattaNewOfferStepInteractionDataPoint',
    components: { Multiselect, ModattaIcon },
    props: [],
    data() {
      return {
        categoryList: [],
        selectedCategory: null,
        profileList: [],
        selectedProfile: null,
        isShowProfile: false,
        dataPointList: [],
        isShowDataPoint: false,
      };
    },
    mounted() {
      this.loadAlreadySavedDataPoints();
      this.loadCategoryList();
    },
    watch: {
      '$store.state.offer.profiles': function (newStateProfileList) {
        let profileList = newStateProfileList.map((p) => {
          return {
            id: p.id,
            name: this.$t(`labels.${p.id}`),
            oper_type: p.oper_type,
            value_type: p.value_type,
            order: p.order,
          };
        });
        profileList = Filters.excludeProfilesFromAdvancedFilters(profileList);
        profileList.sort(function (a, b) {
          return a.order - b.order;
        });
        this.profileList = profileList;
      },
      selectedCategory: function (newSelectedCategory) {
        this.resetToCategoryState();
        const portugalCountryId = 'GEO1';
        this.getProfileFieldsWithinFamily({ familyId: newSelectedCategory.id, countryId: portugalCountryId });
        this.isShowProfile = newSelectedCategory !== null;
        this.isShowDataPoint = newSelectedCategory !== null;
      },
      selectedProfile: function (newSelectedProfile) {
        if (newSelectedProfile) {
          const haveDataPoint = this.dataPointList.find((item) => item.profileId === newSelectedProfile.id);
          if (!haveDataPoint) {
            this.addDataPointList(newSelectedProfile.id, newSelectedProfile.name);
          }
        }
      },
      dataPointList: function (newDataPointList) {
        this.$emit('data-points-list', JSON.stringify(newDataPointList.map((dataPoint) => dataPoint.profileId)));
      },
    },
    methods: {
      ...mapActions(['getProfileFieldsWithinFamily']),
      handlerCategory(category) {
        category.isSelected = true;
        this.selectedCategory = { ...category };
      },
      resetToCategoryState() {
        this.selectedProfile = null;
      },
      deleteDataPoint(dataPointRemoved) {
        this.dataPointList = this.dataPointList.filter(
          (dataPoint) => dataPoint.profileId !== dataPointRemoved.profileId,
        );
      },
      addDataPointList: function (idDataPoint, nomeDataPoint) {
        this.dataPointList.push({
          profileId: idDataPoint,
          profileName: nomeDataPoint,
          operatorName: this.$t('labels.OPE37'),
        });
      },
      loadAlreadySavedDataPoints: function () {
        if (this.$parent.$parent.newOffer.action) {
          const savedDataPointList = JSON.parse(this.$parent.$parent.newOffer.action);
          savedDataPointList.map((idDataPoint) => {
            this.addDataPointList(idDataPoint, this.$t(`labels.${idDataPoint}`));
          });
        }
      },
      loadCategoryList: function () {
        this.categoryList = [
          { id: 'FAM1', name: 'general', icon: 'profile-outline' },
          { id: 'FAM2', name: 'occupation', icon: 'suitcase-outline' },
          { id: 'FAM3', name: 'ownership', icon: 'target' },
          { id: 'FAM4', name: 'other', icon: 'map-pin' },
        ];
        this.selectedCategory = this.categoryList[0];
      },
    },
  };
</script>

<style scoped lang="scss">
  .container-categories {
    display: flex;
  }

  .categories {
    display: flex;
    flex-direction: row;
  }

  .category {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    min-height: 100px;
    gap: 24px;
    height: auto;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 8px;
    background: #f7f9fd;
    transition: all 300ms ease;
    cursor: pointer;
    margin-right: 16px;
    margin-top: 8px;

    &:hover {
      border-color: #2355c3;
    }

    div {
      min-height: 38px;
      font-size: 12px;
      font-family: 'Silka SemiBold', sans-serif;
    }
  }

  .category.is-selected {
    border-color: #2355c3;
    background: #fff;
  }

  .container-profile {
    margin-top: 16px;
  }

  .container-data-points {
    margin-top: 16px;
  }

  .data-points-list {
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
  }

  .data-points {
    display: flex;
    align-items: center;
    margin: 4px;
    padding: 4px 12px;
    min-height: 38px;
    font-size: 13px;
    border-radius: 6px;
    background: #e7edf9;

    .data-points-profile-label {
      margin-right: 4px;
    }
    .data-points-operator-label {
      margin-right: 4px;
    }

    button {
      padding: 0;
      width: var(--icon-width);
      height: var(--icon-width);
      border: none;
      background: none;
      opacity: 0.5;
      transition: all 0.3s ease;
    }
  }
</style>
