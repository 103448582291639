<template>
  <div class="authentication">
    <div class="form-container reset-password" :class="{ success: success }">
      <div class="languages select">
        <b-dropdown append-to-body aria-role="menu" scrollable max-height="200" trap-focus>
          <template #trigger>
            <a class="navbar-item" role="button">
              <span>{{ selectedLanguageLabel }}</span>
              <b-icon icon="menu-down"></b-icon>
            </a>
          </template>
          <b-dropdown-item
            custom
            aria-role="listitem"
            v-for="(language, index) in languages"
            :active="selectedLanguageId === language.lang_id"
            :key="index"
          >
            <b-radio
              name="language"
              :id="language.lang_id"
              v-model="selectedLanguageId"
              :native-value="language.lang_id"
              @input="handleLanguageClick(language)"
            >
              {{ language.code }}
            </b-radio>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="clouds-left">
        <img src="/svg/decorations/reset-left-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="clouds-right">
        <img src="/svg/decorations/reset-right-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="tree">
        <img src="/svg/decorations/tree.svg" alt="Illustration representing a tree" />
      </div>
      <div class="illustrations">
        <div class="background">
          <img
            v-if="!success"
            src="/svg/decorations/reset-password-background.svg"
            alt="Illustration representing mountains, plants and sky"
          />
          <img
            v-else
            src="/svg/decorations/reset-password-success-background.svg"
            alt="Illustration representing mountains, plants and sky"
          />
        </div>
        <router-link v-if="!success" to="/" class="logo"></router-link>
        <div v-else class="paper-plane"></div>
      </div>
      <div v-if="!success" class="form">
        <h1>{{ $t('recover_password_request.title') }}</h1>
        <p>{{ $t('recover_password_request.description') }}</p>
        <form @submit.prevent="resetPassword()">
          <div
            class="input-wrapper"
            :class="{ 'has-error': $v.form.email.$error, 'is-valid': !$v.form.email.$invalid }"
          >
            <modatta-icon icon="email" width="23"></modatta-icon>
            <input
              v-model.trim="$v.form.email.$model"
              @input="handleInput()"
              type="email"
              placeholder="Email"
              autofocus
              required
            />
            <modatta-icon v-if="$v.form.email.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.email.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <p v-if="error != null" class="is-error">{{ error }}</p>
          <button class="button is-primary" type="submit" :disabled="$v.form.$invalid || isLoading">
            <template v-if="!isLoading">{{ $t('recover_password_request.button_label') }}</template>
            <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
          </button>
          <footer>
            <router-link to="/login">
              <modatta-icon icon="arrow-left"></modatta-icon>
              {{ $t('recover_password_request.back_button') }}
            </router-link>
          </footer>
        </form>
      </div>
      <div v-else class="form">
        <h1>{{ $t('recover_password_request_confirmation.title') }}</h1>
        <p>
          {{ $t('recover_password_request_confirmation.message_label_1') }} <span>{{ form.email }}</span
          >. {{ $t('recover_password_request_confirmation.message_label_2') }}
        </p>
        <router-link to="/login" class="button is-primary" type="submit">{{
          $t('recover_password_request_confirmation.button_label')
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { required, email } from 'vuelidate/lib/validators';
  import { sha256 } from 'js-sha256';
  import ModattaIcon from '../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    data() {
      return {
        isLoading: false,
        success: false,
        error: null,
        selectedLanguageLabel: null,
        selectedLanguageId: '',
        form: {
          email: '',
        },
        languages: [
          { name: this.$t('labels.LNG1'), lang_id: 'LNG1', i18n_code: 'pt', code: 'pt-pt' },
          { name: this.$t('labels.LNG5'), lang_id: 'LNG5', i18n_code: 'br', code: 'pt-br' },
          { name: this.$t('labels.LNG3'), lang_id: 'LNG3', i18n_code: 'en', code: 'en' },
        ],
      };
    },
    mounted() {
      this.browserLang = navigator.language;
      switch (this.browserLang) {
        case 'pt':
          this.selectedLanguageId = 'LNG1';
          break;
        case 'pt-BR':
          this.selectedLanguageId = 'LNG5';
          break;
        default:
          this.selectedLanguageId = 'LNG1';
          break;
      }
    },
    validations: {
      form: {
        email: {
          required,
          email,
        },
      },
    },
    methods: {
      ...mapActions(['sendRecoverPasswordLink']),
      handleInput() {
        if (this.error != null) this.error = null;
      },
      resetPassword() {
        this.isLoading = true;
        this.sendRecoverPasswordLink({ username: sha256(this.form.email) })
          .then(() => (this.success = true))
          .catch(() => {
            this.error = this.$t('recover_password_request.error_email');
            this.isLoading = false;
          });
      },
      handleLanguageClick(lang) {
        this.selectedLanguageId = lang.lang_id;
      },
    },
    watch: {
      selectedLanguageId() {
        let current = this.languages.find((o) => o.lang_id === this.selectedLanguageId);
        this.$i18n.locale = current.i18n_code;
        this.selectedLanguageLabel = current.code;
      },
    },
  };
</script>
