import headers from './headers';

export default {
  getSocialWorkPlataformConfigSdk: async () => {
    const URL = `${process.env.VUE_APP_API_URL}integrations/phyllo`;
    return await axios.get(URL, { headers });
  },
  connectWithSocialNetwork: async (socialIntegrationId, socialAccountId) => {
    const URL = `${process.env.VUE_APP_API_URL}integrations/phyllo/infos`;
    return await axios.post(
      URL,
      {
        id: socialIntegrationId,
        account_social_id: socialAccountId,
      },
      { headers },
    );
  },
  disconnectWithSocialNetwork: async (socialIntegrationId) => {
    const URL = `${process.env.VUE_APP_API_URL}integrations/phyllo/infos`;
    return await axios.post(
      URL,
      {
        id: socialIntegrationId,
        account_social_id: null,
      },
      { headers },
    );
  },
  getInstagramPostId: async (accountSocialId, postURL) => {
    return await axios.post(
      `${process.env.VUE_APP_API_URL}integrations/phyllo/posts`,
      {
        account_social_id: accountSocialId ?? null,
        url: postURL,
      },
      { headers },
    );
  },
};
