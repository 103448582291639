<script>
  import { Line } from 'vue-chartjs';
  export default {
    props: ['chartData', 'labels'],
    extends: Line,
    data() {
      return {
        chart: {
          data: {
            labels: '',
            datasets: [],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: { display: false },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: 'rgba(0, 0, 0, 0)',
                    zeroLineColor: 'transparent',
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: 'rgba(0, 0, 0, 0)',
                    // zeroLineColor: 'transparent'
                  },
                  ticks: {
                    // max: 25000,
                    // min: 0,
                    // stepSize: 5000,
                    // beginAtZero: true,
                    // // padding: 20,
                    // callback: function(value, index, values) {
                    //     return value / 1000 + "k";
                    // }
                  },
                },
              ],
            },
          },
        },
      };
    },
    mounted() {
      this.chart.data.labels = this.labels;
      this.chart.data.datasets = this.chartData;
      this.renderChart(this.chart.data, this.chart.options);
    },
  };
</script>
