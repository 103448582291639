<template>
  <div class="modatta-side-modal-select-default-banner">
    <div class="banners-wrapper">
      <p>{{ $t('offers_step3.select_generic') }}</p>
      <div class="banners">
        <div
          class="banner"
          @click="selectBanner(index)"
          :class="{ selected: banner === selectedBanner }"
          v-for="(banner, index) in 12"
          :key="index"
          :style="{ backgroundImage: `url('/img/generic-banners/default-banner-${index + 1}.jpg')` }"
        ></div>
      </div>
      <button v-if="selectedBanner != null" class="button is-primary" @click="saveBanner()">Save</button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedBanner: null,
      };
    },
    mounted() {},
    computed: {},
    methods: {
      selectBanner(index) {
        const url = `/img/generic-banners/default-banner-${index + 1}.jpg`;
        const blob = this.getBlob(url);
        blob.then((file) => {
          this.$emit('banner', { file, url });
        });
      },
      async getBlob(url) {
        return await fetch(url).then((r) => r.blob());
      },
    },
  };
</script>
