<template>
  <!-- Add .scroll-container to the parent where you are going to use this component -->
  <div class="hide-x-scroll">
    <div
      v-if="items"
      class="h-scroll-row"
      @mousedown="handleMouseDown"
      @mouseleave="handleMouseLeave"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove"
    >
      <div class="h-scroll-items" v-if="items && items.length > 0">
        <div class="item" v-for="(item, i) in items" :key="i">
          <div class="chip">
            <span class="icon">
              <modatta-icon v-if="item.icon" :icon="item.icon" width="1.125" :rem="true" :color="'red'"></modatta-icon>
            </span>
            <span class="text">{{ currency(item.value, item.currency) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty-state">
      {{ $t('budget.table_no_content') }}
    </div>
  </div>
</template>

<script>
  import Utilities from '../helpers/utilities.js';

  export default {
    name: 'ModattaChipScroll',
    props: ['items'],
    methods: {
      currency(value, currency) {
        return Utilities.currency(value, this.$t(`labels.${currency}`));
      },
      // Methods will allow user to grab and drag the scroll as if it were a slider
      // Listeners are being used in the component's html
      handleMouseDown(e) {
        const slider = e.currentTarget;
        this.isDown = true;
        slider.classList.add('active');
        this.startX = e.pageX - slider.offsetLeft;
        this.scrollLeft = slider.scrollLeft;
      },
      handleMouseLeave(e) {
        const slider = e.currentTarget;
        this.isDown = false;
        slider.classList.remove('active');
      },
      handleMouseUp(e) {
        const slider = e.currentTarget;
        this.isDown = false;
        slider.classList.remove('active');
      },
      handleMouseMove(e) {
        const slider = e.currentTarget;
        if (!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - this.startX) * 3; // scroll-fast
        slider.scrollLeft = this.scrollLeft - walk;
      },
    },
  };
</script>

<style scoped lang="scss">
  .empty-state {
    // TODO: Move this to an scss utilitarian file
    height: 100%;
    width: 100%;
    margin-left: 1.25em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
</style>
