export default function SetFilterDegreeValueId(id) {
  switch (id) {
    case 'VAL490':
      return 0;
    case 'VAL491':
      return 1;
    case 'VAL492':
      return 2;
    case 'VAL493':
      return 3;
    case 'VAL494':
      return 4;
    case 'VAL495':
      return 5;
    case 'VAL496':
      return 6;
    case 'VAL1031':
      return 7;
    case 'VAL1032':
      return 8;
    case 'VAL1033':
      return 9;
  }
}
