// Fixed incorrectly named function
export default function setFilterCityValueId(id) {
  switch (id) {
    case 'VAL5':
      return 'abrantes';
    case 'VAL6':
      return 'agualva-cacem';
    case 'VAL7':
      return 'águeda';
    case 'VAL8':
      return 'albergaria-a-velha';
    case 'VAL9':
      return 'albufeira';
    case 'VAL10':
      return 'alcacer_do_sal';
    case 'VAL11':
      return 'alcobaca';
    case 'VAL12':
      return 'alfena';
    case 'VAL13':
      return 'almada';
    case 'VAL14':
      return 'almeirim';
    case 'VAL15':
      return 'alverca_do_ribatejo';
    case 'VAL16':
      return 'amadora';
    case 'VAL17':
      return 'amarante';
    case 'VAL18':
      return 'amora';
    case 'VAL19':
      return 'anadia';
    case 'VAL20':
      return 'angra_do_heroismo';
    case 'VAL21':
      return 'aveiro';
    case 'VAL22':
      return 'barcelos';
    case 'VAL23':
      return 'barreiro';
    case 'VAL24':
      return 'beja';
    case 'VAL25':
      return 'borba';
    case 'VAL26':
      return 'braga';
    case 'VAL27':
      return 'braganca';
    case 'VAL28':
      return 'caldas_da_rainha';
    case 'VAL29':
      return 'camara_de_lobos';
    case 'VAL30':
      return 'canico';
    case 'VAL31':
      return 'cantanhede';
    case 'VAL32':
      return 'cartaxo';
    case 'VAL33':
      return 'castelo_branco';
    case 'VAL34':
      return 'chaves';
    case 'VAL35':
      return 'coimbra';
    case 'VAL36':
      return 'costa_da_caparica';
    case 'VAL37':
      return 'covilha';
    case 'VAL38':
      return 'elvas';
    case 'VAL39':
      return 'entroncamento';
    case 'VAL40':
      return 'ermesinde';
    case 'VAL41':
      return 'esmoriz';
    case 'VAL42':
      return 'espinho';
    case 'VAL43':
      return 'esposende';
    case 'VAL44':
      return 'estarreja';
    case 'VAL45':
      return 'estremoz';
    case 'VAL46':
      return 'évora';
    case 'VAL47':
      return 'fafe';
    case 'VAL48':
      return 'faro';
    case 'VAL49':
      return 'fatima';
    case 'VAL50':
      return 'felgueiras';
    case 'VAL51':
      return 'fiaes';
    case 'VAL52':
      return 'figueira_da_foz';
    case 'VAL53':
      return 'freamunde';
    case 'VAL54':
      return 'funchal';
    case 'VAL55':
      return 'fundao';
    case 'VAL56':
      return 'gafanha_da_nazare';
    case 'VAL57':
      return 'gandra';
    case 'VAL58':
      return 'gondomar';
    case 'VAL59':
      return 'gouveia';
    case 'VAL60':
      return 'guarda';
    case 'VAL61':
      return 'guimaraes';
    case 'VAL62':
      return 'horta';
    case 'VAL63':
      return 'ilhavo';
    case 'VAL64':
      return 'lagoa';
    case 'VAL65':
      return 'lagoa';
    case 'VAL66':
      return 'lagos';
    case 'VAL67':
      return 'lamego';
    case 'VAL68':
      return 'leiria';
    case 'VAL69':
      return 'lisboa';
    case 'VAL70':
      return 'lixa';
    case 'VAL71':
      return 'lordelo';
    case 'VAL72':
      return 'loule';
    case 'VAL73':
      return 'loures';
    case 'VAL74':
      return 'lourosa';
    case 'VAL75':
      return 'macedo_de_cavaleiros';
    case 'VAL76':
      return 'machico';
    case 'VAL77':
      return 'maia';
    case 'VAL78':
      return 'mangualde';
    case 'VAL79':
      return 'marco_de_canaveses';
    case 'VAL80':
      return 'marinha_grande';
    case 'VAL81':
      return 'matosinhos';
    case 'VAL82':
      return 'mealhada';
    case 'VAL83':
      return 'meda';
    case 'VAL84':
      return 'miranda_do_douro / miranda_de_l_douro';
    case 'VAL85':
      return 'mirandela';
    case 'VAL86':
      return 'montemor-o-novo';
    case 'VAL87':
      return 'montijo';
    case 'VAL88':
      return 'moura';
    case 'VAL89':
      return 'odivelas';
    case 'VAL90':
      return 'olhao_da_restauracao';
    case 'VAL91':
      return 'oliveira_de_azemeis';
    case 'VAL92':
      return 'oliveira_do_bairro';
    case 'VAL93':
      return 'oliveira_do_hospital';
    case 'VAL94':
      return 'ourem';
    case 'VAL95':
      return 'ovar';
    case 'VAL96':
      return 'pacos_de_ferreira';
    case 'VAL97':
      return 'paredes';
    case 'VAL98':
      return 'penafiel';
    case 'VAL99':
      return 'peniche';
    case 'VAL100':
      return 'peso_da_regua';
    case 'VAL101':
      return 'pinhel';
    case 'VAL102':
      return 'pombal';
    case 'VAL103':
      return 'ponta_delgada';
    case 'VAL104':
      return 'ponte_de_sor';
    case 'VAL105':
      return 'portalegre';
    case 'VAL106':
      return 'portimao';
    case 'VAL107':
      return 'porto';
    case 'VAL108':
      return 'povoa_de_santa_iria';
    case 'VAL109':
      return 'povoa_de_varzim';
    case 'VAL110':
      return 'praia_da_vitoria';
    case 'VAL111':
      return 'quarteira';
    case 'VAL112':
      return 'queluz';
    case 'VAL113':
      return 'rebordosa';
    case 'VAL114':
      return 'reguengos_de_monsaraz';
    case 'VAL115':
      return 'ribeira_grande';
    case 'VAL116':
      return 'rio_maior';
    case 'VAL117':
      return 'rio_tinto';
    case 'VAL118':
      return 'sabugal';
    case 'VAL119':
      return 'sacavem';
    case 'VAL120':
      return 'samora_correia';
    case 'VAL121':
      return 'santa_comba_dao';
    case 'VAL122':
      return 'santa_cruz';
    case 'VAL123':
      return 'santa_maria_da_feira';
    case 'VAL124':
      return 'santana';
    case 'VAL125':
      return 'santarem';
    case 'VAL126':
      return 'santiago_do_cacem';
    case 'VAL127':
      return 'santo_tirso';
    case 'VAL128':
      return 'sao_joao_da_madeira';
    case 'VAL129':
      return 'sao_mamede_de_infesta';
    case 'VAL130':
      return 'sao_pedro_do_sul';
    case 'VAL131':
      return 'seia';
    case 'VAL132':
      return 'seixal';
    case 'VAL133':
      return 'senhora_da_hora';
    case 'VAL134':
      return 'serpa';
    case 'VAL135':
      return 'setubal';
    case 'VAL136':
      return 'silves';
    case 'VAL137':
      return 'sines';
    case 'VAL138':
      return 'tarouca';
    case 'VAL139':
      return 'tavira';
    case 'VAL140':
      return 'tomar';
    case 'VAL141':
      return 'tondela';
    case 'VAL142':
      return 'torres_novas';
    case 'VAL143':
      return 'torres_vedras';
    case 'VAL144':
      return 'trancoso';
    case 'VAL145':
      return 'trofa';
    case 'VAL146':
      return 'valbom';
    case 'VAL147':
      return 'vale_de_cambra';
    case 'VAL148':
      return 'valenca';
    case 'VAL149':
      return 'valongo';
    case 'VAL150':
      return 'valpacos';
    case 'VAL151':
      return 'vendas_novas';
    case 'VAL152':
      return 'viana_do_castelo';
    case 'VAL153':
      return 'vila_baleira';
    case 'VAL154':
      return 'vila_do_conde';
    case 'VAL155':
      return 'vila_franca_de_xira';
    case 'VAL156':
      return 'vila_nova_de_famalicao';
    case 'VAL157':
      return 'vila_nova_de_foz_coa';
    case 'VAL158':
      return 'vila_nova_de_gaia';
    case 'VAL159':
      return 'vila_nova_de_santo_andre';
    case 'VAL160':
      return 'vila_real';
    case 'VAL161':
      return 'vila_real_de_santo_antonio';
    case 'VAL162':
      return 'viseu';
    case 'VAL163':
      return 'vizela';
  }
}
