export default function SetFilterGenderValueId(id) {
  switch (id) {
    case 'VAL563':
      return 'female';
    case 'VAL564':
      return 'male';
    case 'VAL983':
      return 'non_binary';
    case 'VAL1015':
      return 'transgender';
    case 'VAL1016':
      return 'queer';
    case 'VAL1017':
      return 'other';
  }
}
