export default function SetValidationTypeIcon(id) {
  switch (id) {
    case 'ITV1':
    case 'ITV7':
    case 'ITV8':
    case 'ITV9':
    case 'ITV10':
    case 'ITV11':
    case 'ITV15':
      return 'gear';
    case 'ITV2':
      return 'globe';
    case 'ITV3':
      return 'fingerprint';
    case 'ITV4':
      return 'post-back';
    case 'ITV5':
      return 'qr-code';
    case 'ITV6':
      return 'tag-manager';
    case 'ITV12':
      return 'post-back';
    case 'ITV13':
    case 'ITV14':
      return 'loja-integrada';
  }
}
