<script>
  import { mapActions } from 'vuex';

  export default {
    props: ['source', 'id', 'isPoll'],
    mounted() {
      switch (this.source) {
        case 'dashboard':
          this.getDashboardAnalytics();
          break;
        case 'brand':
          this.getBrandAnalytics();
          break;
        case 'offer':
          this.getOfferAnalytics();
          break;
      }
    },
    methods: {
      ...mapActions([
        'getTotalInvested',
        'getActiveOffers',
        'getInteractions',
        'getTotalDelivered',
        'getAcceptedDecisions',
        'getDeclinedDecisions',
        'getAccumulatedCost',
        'getOfferObjective',
        'getOfferDelivered',
        'getDecisionsData',
        'getOfferAcceptedData',
        'getOfferDeclinedData',
        'getOfferAnswers',
      ]),
      getDashboardAnalytics() {
        let acceptedData = this.$store.state.analytic.acceptedData;
        let declinedData = this.$store.state.analytic.declinedData;
        let delivered = this.$store.state.analytic.totalDelivered;
        let accepted = this.$store.state.analytic.totalAccepted;
        let declined = this.$store.state.analytic.totalDeclined;

        // 0. Populate Data if already in Store
        if (delivered != null && accepted != null && declined != null) {
          // 1. Populate Accepted Data if already in Store

          this.getNPS(accepted, acceptedData);
          this.$set(this.$parent.metricCards[2].detail, 'value', `${Math.round((accepted / delivered) * 100) || 0}%`);
          // 2. Populate Declined Data if already in Store
          this.$set(this.$parent.charts[1], 'center', `${Math.round((declined / delivered) * 100) || 0}%`);
          this.$set(this.$parent.metricCards[3].detail, 'value', `${Math.round((declined / delivered) * 100) || 0}%`);
          // 3. Show Charts Data
          this.$parent.acceptedDataLoaded = true;
          this.$parent.declinedDataLoaded = true;
        }

        // 1. Call APIs
        this.getTotalInvested();
        this.getActiveOffers();
        this.getInteractions();
        this.getTotalDelivered().then(() => {
          // After getting delivered, get accepted and declined. We need to wait to calculate %.
          delivered = this.$store.state.analytic.totalDelivered;
          this.getAcceptedDecisions().then(() => {
            accepted = this.$store.state.analytic.totalAccepted;
            acceptedData = this.$store.state.analytic.acceptedData;

            // 0. Set parent variables to API values | Had to use $set because of Vue reactivity issues
            this.getNPS(accepted, acceptedData);
            this.$set(this.$parent.charts[0], 'data', acceptedData.length === 0 ? [0, 0, 0] : acceptedData);
            this.$set(this.$parent.metricCards[2].detail, 'value', `${Math.round((accepted / delivered) * 100) || 0}%`);

            this.$parent.acceptedDataLoaded = true;
          });
          this.getDeclinedDecisions().then(() => {
            declined = this.$store.state.analytic.totalDeclined;
            declinedData = this.$store.state.analytic.declinedData;

            // 0. Set parent variables to API values | Had to use $set because of Vue reactivity issues
            this.$set(this.$parent.charts[1], 'center', `${Math.round((declined / delivered) * 100) || 0}%`);
            this.$set(this.$parent.charts[1], 'data', declinedData.length === 0 ? [0, 0, 0, 0] : declinedData);
            this.$set(this.$parent.metricCards[3].detail, 'value', `${Math.round((declined / delivered) * 100) || 0}%`);

            this.$parent.declinedDataLoaded = true;
          });
        });
      },
      getBrandAnalytics() {
        let notEvaluatedData = this.$store.state.analytic.brandNotEvaluatedData;
        let acceptedData = this.$store.state.analytic.brandAcceptedData;
        let declinedData = this.$store.state.analytic.brandDeclinedData;
        let delivered = this.$store.state.analytic.brandTotalDelivered;
        let accepted = this.$store.state.analytic.brandTotalAccepted;
        let declined = this.$store.state.analytic.brandTotalDeclined;

        this.getTotalInvested(this.id);
        this.getActiveOffers(this.id);
        this.getTotalDelivered(this.id).then(() => {
          // After getting delivered, get accepted and declined. We need to wait to calculate %.
          delivered = this.$store.state.analytic.brandTotalDelivered;
          this.getAcceptedDecisions(this.id).then(() => {
            accepted = this.$store.state.analytic.brandTotalAccepted;
            acceptedData = this.$store.state.analytic.brandAcceptedData;
            notEvaluatedData = this.$store.state.analytic.brandNotEvaluatedData;

            // 0. Set parent variables to API values | Had to use $set because of Vue reactivity issues
            this.getNPS(accepted, acceptedData);
            this.$set(this.$parent.charts[0], 'data', acceptedData.length === 0 ? [0, 0, 0] : acceptedData);
            this.$set(this.$parent.metricCards[2].detail, 'value', `${Math.round((accepted / delivered) * 100) || 0}%`);

            this.$parent.acceptedDataLoaded = true;
          });
          this.getDeclinedDecisions(this.id).then(() => {
            declined = this.$store.state.analytic.brandTotalDeclined;
            declinedData = this.$store.state.analytic.brandDeclinedData;

            // 0. Set parent variables to API values | Had to use $set because of Vue reactivity issues
            this.$set(this.$parent.charts[1], 'center', `${Math.round((declined / delivered) * 100) || 0}%`);
            this.$set(this.$parent.charts[1], 'data', declinedData.length === 0 ? [0, 0, 0, 0] : declinedData);
            this.$set(this.$parent.metricCards[3].detail, 'value', `${Math.round((declined / delivered) * 100) || 0}%`);

            this.$parent.declinedDataLoaded = true;
          });
        });
      },
      getOfferAnalytics() {
        let acceptedData = this.$store.state.offer.acceptedData;
        let declinedData = this.$store.state.offer.declinedData;
        let delivered = this.$store.state.offer.totalDelivered;
        let accepted = this.$store.state.offer.totalAccepted;
        let declined = this.$store.state.offer.totalDeclined;

        this.getAccumulatedCost(this.id);
        this.getOfferObjective(this.id).then(() => {
          this.getOfferDelivered(this.id).then(() => {
            delivered = this.$store.state.offer.totalDelivered;
            this.getOfferDeclinedData(this.id).then(() => {
              declined = this.$store.state.offer.totalDeclined;
              declinedData = this.$store.state.offer.declinedData;

              // 0. Set parent variables to API values | Had to use $set because of Vue reactivity issues
              this.$set(this.$parent.charts[1], 'center', `${Math.round((declined / delivered) * 100) || 0}%`);
              this.$set(this.$parent.charts[1], 'data', declinedData.length === 0 ? [0, 0, 0, 0] : declinedData);
              this.$set(
                this.$parent.metricCards[3].detail,
                'value',
                `${Math.round((declined / delivered) * 100) || 0}%`,
              );

              this.$parent.declinedDataLoaded = true;
            });
            this.getOfferAcceptedData(this.id).then(() => {
              accepted = this.$store.state.offer.totalAccepted;
              acceptedData = this.$store.state.offer.acceptedData;
              // 0. Set parent variables to API values | Had to use $set because of Vue reactivity issues
              this.getNPS(accepted, acceptedData);
              this.$set(this.$parent.charts[0], 'data', acceptedData.length === 0 ? [0, 0, 0] : acceptedData);
              this.$set(
                this.$parent.metricCards[2].detail,
                'value',
                `${Math.round((accepted / delivered) * 100) || 0}%`,
              );

              this.$parent.acceptedDataLoaded = true;
            });

            this.getOfferAnswers(this.id);
          });
        });
      },
      /*
        |--------------------------------------------------------------------------
        | HELPERS
        |--------------------------------------------------------------------------
        */

      // accepted: int - the number of users that accepted this offer
      // data: array - number of users that rated the offer as
      // [0] -> 9-10; [1] -> 7-8; [2] -> 0-6 //
      getNPS(total, data) {
        // var total não utilizada!
        // TODO avaliar todas as chamadas para a fnc getNPS() - para corrigir o valor da var ´total´ enviado
        let sum = data[0] + data[2];
        if (data == null) return '';
        let promoters = (data[0] / sum) * 100;
        let detractors = (data[2] / sum) * 100;
        this.$parent.nps = Math.round(promoters - detractors) || 0;
      },
    },
  };
</script>
