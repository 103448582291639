<template>
  <div class="modal-wrap full">
    <div class="forms full">
      <div class="form circle modal-container form-container">
        <!-- <b-dropdown aria-role="menu" scrollable max-height="200" trap-focus>
					<template #trigger>
						<a class="navbar-item" role="button">
							<span>LABEL</span>
							<b-icon icon="menu-down"></b-icon>
						</a>
					</template>
					<b-dropdown-item custom aria-role="listitem">
            ABC
					</b-dropdown-item>
					<b-dropdown-item custom aria-role="listitem">
            ABC
					</b-dropdown-item>
					<b-dropdown-item custom aria-role="listitem">
            ABC
					</b-dropdown-item>
					<b-dropdown-item custom aria-role="listitem">
            ABC
					</b-dropdown-item>
					<b-dropdown-item custom aria-role="listitem">
            ABC
					</b-dropdown-item>
				</b-dropdown> -->

        <b-field :label="'LABEL'">
          <div class="modatta-country-select">
            <multiselect
              placeholder="Choose a question"
              selectLabel="ENTER to select"
              openDirection="bottom"
              :options="['1', '2', '3', '4']"
              :allow-empty="false"
              :showLabels="false"
            >
              <span class="caret" slot="caret">
                <modatta-icon icon="arrow-down" width="10"></modatta-icon>
              </span>
            </multiselect>
          </div>
        </b-field>
      </div>
      <div class="form followers"></div>
      <div class="form target">
        <b-field :label="'LABEL'">
          <div class="modatta-country-select">
            <multiselect
              placeholder="Choose a question"
              selectLabel="ENTER to select"
              openDirection="bottom"
              :options="['1', '2', '3', '4']"
              :allow-empty="false"
              :showLabels="false"
            >
              <span class="caret" slot="caret">
                <modatta-icon icon="arrow-down" width="10"></modatta-icon>
              </span>
            </multiselect>
          </div>
        </b-field>
      </div>
      <div class="form amount"></div>
      <div class="form total">
        <b-field :label="'LABEL'">
          <div class="modatta-country-select">
            <multiselect
              placeholder="Choose a question"
              selectLabel="ENTER to select"
              openDirection="bottom"
              :options="['1', '2', '3', '4']"
              :allow-empty="false"
              :showLabels="false"
            >
              <span class="caret" slot="caret">
                <modatta-icon icon="arrow-down" width="10"></modatta-icon>
              </span>
            </multiselect>
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>
<script>
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['options'],
    components: { Multiselect },
    data() {
      return {};
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {},
  };
</script>
