<template>
  <div class="modatta-new-offer-preview">
    <modatta-new-offer-preview :offer="options.offer" :brand-id="options.brandId"></modatta-new-offer-preview>
  </div>
</template>

<script>
  export default {
    props: ['options'],
  };
</script>
