<template>
  <div class="authentication">
    <div class="form-container reset-password recover-password" :class="{ success: success }">
      <div class="clouds-left">
        <img src="/svg/decorations/reset-left-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="clouds-right">
        <img src="/svg/decorations/reset-right-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="tree">
        <img src="/svg/decorations/tree.svg" alt="Illustration representing a tree" />
      </div>
      <div class="illustrations">
        <div class="background">
          <img
            v-if="!success"
            src="/svg/decorations/reset-password-background.svg"
            alt="Illustration representing mountains, plants and sky"
          />
          <img v-else src="/svg/decorations/new-password-success-background.svg" alt="Illustration a green checkmark" />
        </div>
        <router-link v-if="!success" to="/" class="logo"></router-link>
      </div>
      <div v-if="!success" class="form">
        <h1>{{ $t('recover-password.title') }}</h1>
        <p>{{ $t('recover-password.description') }}</p>
        <form>
          <div
            class="input-wrapper"
            :class="{ 'has-error': $v.form.password.$error, 'is-valid': !$v.form.password.$invalid }"
          >
            <modatta-icon icon="lock" width="23"></modatta-icon>
            <input
              v-model.trim="$v.form.password.$model"
              type="password"
              :placeholder="$t('recover-password.password_input')"
              required
              autocomplete="false"
            />
            <modatta-icon v-if="$v.form.password.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.password.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <div
            class="input-wrapper"
            :class="{ 'has-error': $v.form.passwordConfirm.$error, 'is-valid': !$v.form.passwordConfirm.$invalid }"
          >
            <modatta-icon icon="lock" width="23"></modatta-icon>
            <input
              v-model.trim="$v.form.passwordConfirm.$model"
              type="password"
              :placeholder="$t('recover-password.password_confirmation_input')"
              required
            />
            <modatta-icon
              v-if="$v.form.passwordConfirm.$error"
              icon="error-exclamation-point"
              width="20"
            ></modatta-icon>
            <modatta-icon v-if="!$v.form.passwordConfirm.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <button class="button is-primary" :disabled="$v.form.$invalid || isLoading" @click="sendNewPassword()">
            <template v-if="!isLoading">{{ $t('recover-password.button_label') }}</template>
            <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
          </button>
          <footer>
            <router-link to="/login">
              <modatta-icon icon="arrow-left"></modatta-icon>
              {{ $t('recover-password.back_button') }}
            </router-link>
          </footer>
        </form>
      </div>
      <div v-else class="form">
        <h1>{{ $t('recover_password_success.title') }}</h1>
        <p>{{ $t('recover_password_success.description') }}</p>
        <router-link to="/dashboard" class="button is-primary" type="submit">{{
          $t('recover_password_success.button_label')
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { required, sameAs, minLength } from 'vuelidate/lib/validators';
  import { sha256 } from 'js-sha256';
  import ModattaIcon from '../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    data() {
      return {
        isLoading: false,
        success: false,
        form: {
          password: null,
          passwordConfirm: null,
        },
        languages: [
          { name: this.$t('labels.LNG1'), lang_id: 'LNG1', i18n_code: 'pt', code: 'pt-pt' },
          { name: this.$t('labels.LNG5'), lang_id: 'LNG5', i18n_code: 'br', code: 'pt-br' },
          { name: this.$t('labels.LNG3'), lang_id: 'LNG3', i18n_code: 'en', code: 'en' },
        ],
        selectedLanguageId: '',
        code: null,
      };
    },
    mounted() {
      this.code = this.getCodeFromUrl();
      this.browserLang = navigator.language;
      switch (this.browserLang) {
        case 'pt':
          this.selectedLanguageId = 'LNG1';
          break;
        case 'pt-BR':
          this.selectedLanguageId = 'LNG5';
          break;
        default:
          this.selectedLanguageId = 'LNG1';
          break;
      }
    },
    validations: {
      form: {
        password: {
          required,
          minLength: minLength(6),
        },
        passwordConfirm: {
          required,
          sameAsPassword: sameAs('password'),
        },
      },
    },
    watch: {
      selectedLanguageId() {
        let current = this.languages.find((o) => o.lang_id === this.selectedLanguageId);
        this.$i18n.locale = current.i18n_code;
        this.selectedLanguageLabel = current.code;
      },
    },
    methods: {
      ...mapActions(['setForgotPassword']),
      getCodeFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('code');
      },

      sendNewPassword() {
        this.isLoading = true;
        this.setForgotPassword({ code: this.code, password: sha256(this.form.password) })
          .then(() => (this.success = true))
          .catch(() => {
            this.isLoading = false;
          });
      },
    },
  };
</script>
