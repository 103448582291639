<template>
  <section class="portal">
    <modatta-sidenav page="brands"></modatta-sidenav>
    <main>
      <div class="brands components-wrapper">
        <modatta-navbar v-bind:title="$t('brands.title')" :has-icon="{ name: 'brands' }"></modatta-navbar>
        <div class="components">
          <div class="component main-table-header">
            <div class="search">
              <div class="filter">
                <b-dropdown aria-role="list" v-if="filters.options.length > 0">
                  <template #trigger>
                    <button class="button is-dropdown">
                      {{ $t('brands.' + filters.selected.name) }}
                    </button>
                  </template>
                  <b-dropdown-item
                    aria-role="listitem"
                    v-for="(f, i) in filters.options"
                    :key="i"
                    @click="filterList(f)"
                    >{{ $t('brands.' + f.name) }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="input-wrapper">
                <b-field>
                  <b-input v-model="filters.query" v-bind:placeholder="$t('offers.search')" type="search"></b-input>
                </b-field>
                <modatta-icon icon="magnifier"></modatta-icon>
              </div>
            </div>
            <b-button
              type="is-primary"
              @click="openBrandSideModal('brand', $t('brands_edit.title'), { type: 'create' })"
              >{{ $t('brands.button_label') }}</b-button
            >
          </div>
          <div v-if="isLoading" class="loading component main-table">
            <div class="is-loading loading-row" v-for="(row, index) in 7" :key="index"></div>
          </div>
          <div v-else class="component main-table">
            <b-table
              :data="list"
              :sticky-header="true"
              checkbox-position="left"
              :selected.sync="rows.selected"
              :checked-rows.sync="rows.checked"
            >
              <b-table-column field="logo" v-bind:label="$t('brands.table_col_1')" v-slot="props">
                <div class="logo">
                  <div class="logo-wrapper" :style="`background-image: url(${props.row.logo_url})`"></div>
                </div>
              </b-table-column>
              <b-table-column field="brand" v-bind:label="$t('brands.table_col_2')" v-slot="props">
                <div class="brand">
                  <div>
                    <span class="is-xs">{{ shortenBrandId(props.row.id) }}</span
                    ><br />
                    {{ props.row.name }}
                  </div>
                </div>
              </b-table-column>
              <b-table-column field="company" v-bind:label="$t('brands.table_col_3')" v-slot="props">
                <div class="company">
                  <div>{{ props.row.company_name }}</div>
                </div>
              </b-table-column>
              <b-table-column field="nps" v-bind:label="$t('brands.table_col_4')" v-slot="props">
                <div class="nps">{{ Math.round(props.row.nps_avg * 100) || 0 }}</div>
              </b-table-column>
              <b-table-column field="status" v-bind:label="$t('brands.table_col_5')" v-slot="props">
                <div class="status">
                  <div
                    class="tag"
                    :class="{
                      'is-yellow': props.row.status_id === 'BST1',
                      'is-green': props.row.status_id === 'BST2',
                      'is-red': props.row.status_id === 'BST3',
                      'is-gray': props.row.status_id === 'BST4',
                    }"
                  >
                    {{ $t('brands.' + getHumanReadableStatus(props.row.status_id)) }}
                  </div>
                </div>
              </b-table-column>
              <b-table-column field="actions" label="" width="40" v-slot="props">
                <div class="actions">
                  <b-dropdown aria-role="list" class="user-info" position="is-bottom-left" @click.native.stop="">
                    <template #trigger>
                      <div class="button p-0">
                        <modatta-icon icon="vertical-dots"></modatta-icon>
                      </div>
                    </template>
                    <b-dropdown-item
                      @click="handleAction(action, props.row.id)"
                      aria-role="listitem"
                      v-for="(action, index) in rows.options"
                      :key="index"
                      :class="{ hide: props.row.status_id !== 'BST2' && action.action === 'archive' }"
                    >
                      <modatta-icon :icon="action.icon"></modatta-icon>
                      {{ $t('offers.' + action.name) }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-table-column>
              <template #empty>
                <div class="modatta-empty-screen">
                  <div class="empty-screen-card">
                    <div
                      class="illustration"
                      :style="{ 'background-image': 'url(/svg/decorations/empty-screen-brands.svg)' }"
                    ></div>
                    <div class="card-info">
                      <p>{{ $t('offer_insufficient_balance.description') }}</p>
                      <button
                        class="button is-primary"
                        @click="openBrandSideModal('brand', $t('brands_edit.title'), { type: 'create' })"
                      >
                        {{ $t('brands_edit.title') }}
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </b-table>
            <b-loading :is-full-page="true" v-model="isActionLoading" :can-cancel="false">
              <div class="global-loading">
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </b-loading>
          </div>
        </div>

        <modatta-side-modal
          v-if="openSideModal"
          :content="selectedSideModal.name"
          :title="selectedSideModal.title"
          :options="selectedSideModal.options"
        ></modatta-side-modal>
      </div>
    </main>
  </section>
</template>

<script>
  import Fuse from 'fuse.js';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import BrandTypesPriority from '../../helpers/brand-type-priority.js';
  import status from '../../helpers/set-brand-status.js';
  import Utilities from '../../helpers/utilities.js';
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import ModattaIcon from '../../components/ModattaIcon.vue';
  import ModattaSideModal from '../../components/ModattaSideModal.vue';

  export default {
    components: {
      ModattaSidenav,
      ModattaNavbar,
      ModattaIcon,
      ModattaSideModal,
    },
    data() {
      return {
        isLoading: false,
        isActionLoading: false,
        filters: {
          query: '',
          options: [],
          selected: {},
        },
        rows: {
          options: [],
          selected: {},
          checked: [],
        },
        brands: [],
      };
    },
    mounted() {
      this.isLoading = true;
      this.filters.options = status.getBrandStatus();
      this.filters.selected = status.getBrandStatusDefault();
      this.setRowOptions();
      this.getBrands().then(() => {
        this.brands = this.$store.state.brand.brands;
        this.brands.sort((a, b) => b.name.localeCompare(a.name));
        this.isLoading = false;
      });
    },
    watch: {
      rows: {
        handler(row) {
          if (row.selected && Object.keys(row.selected).length === 0) {
            return;
          }
          if (row.selected.status_id === 'BST2') {
            // this.$router.replace({ path: `/brands/${row.selected.id}` });
            return;
          }
          this.handleAction({ action: 'details' }, row.selected.id);
        },
        deep: true,
      },
    },
    computed: {
      ...mapState(['openSideModal', 'brand']),
      list() {
        return this.updateList({ filter: this.filters.selected.id !== '', search: this.filters.query });
      },
    },
    methods: {
      ...mapActions(['getBrands', 'createBrand', 'archiveBrand', 'deleteBrand']),
      ...mapMutations(['setOpenSideModal', 'setOpenToast']),
      updateList(options) {
        let list = this.brands;
        list = options.filter ? list.filter((f) => f.status_id === this.filters.selected.id) : list;
        list = this.filters.query
          ? new Fuse(list, {
              threshold: 0.25,
              keys: ['name', 'company_name'],
            })
              .search(options.search)
              .map((i) => i.item)
          : list;
        list = this.orderList(list);
        return list;
      },
      filterList(f) {
        this.filters.query = '';
        this.filters.selected = f;
        this.brands = this.orderList(this.brands);
      },
      orderList(list) {
        list.sort((a, b) => {
          return BrandTypesPriority().indexOf(a.status_id) > BrandTypesPriority().indexOf(b.status_id) ? 1 : -1;
        });

        list = groupBy(list, 'status_id');

        function flatten(arr) {
          return arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
          }, []);
        }
        function groupBy(arr, property) {
          return arr.reduce((acc, cur) => {
            acc[cur[property]] = [...(acc[cur[property]] || []), cur];
            return acc;
          }, {});
        }
        return flatten(
          Object.keys(list).map(function (key) {
            return list[key].sort(function (a, b) {
              return a.name.localeCompare(b.name);
            });
          }),
        );
      },

      setRowOptions() {
        this.rows.options = [
          { name: 'table_menu_1', icon: 'eye', action: 'details' },
          { name: 'table_menu_3', icon: 'archive', action: 'archive' },
          { name: 'table_menu_4', icon: 'delete', action: 'delete' },
        ];
      },

      handleAction(action, brandId) {
        switch (action.action) {
          case 'details':
            true || this.brands.find((b) => b.id === brandId).status_id === 'BST4'
              ? this.openBrandSideModal('brand', 'Brand', { type: 'edit', id: brandId, disabled: true })
              : this.openBrandSideModal('brand', 'Edit Brand', { type: 'edit', id: brandId });
            break;
          case 'duplicate':
            this.isActionLoading = true;
            let id = brandId;
            let brand = this.$store.state.brand.brands.find((b) => b.id === id);
            const body = this.getPayloadBody(brand);
            const blob = this.getBlob(brand.logo_url);
            blob.then((response) => {
              body.set('brand_logo', response);
              this.createBrand(body).then((response) => {
                this.$store.state.brand.brands.unshift(response.data.result);
                this.isActionLoading = false;
              });
            });
            break;
          case 'archive':
            this.$set(
              this.brands.find((b) => b.id === brandId),
              'status',
              'archived',
            );
            this.archiveBrand({ status_id: 'BST4', id: brandId });
            break;
          case 'delete':
            this.isActionLoading = true;
            this.deleteBrand({ id: brandId })
              .then(() => {
                const index = this.brands.findIndex((b) => b.id === brandId);
                if (index > -1) {
                  this.brands.splice(index, 1);
                }
                this.isActionLoading = false;
              })
              .catch(() => {
                this.setOpenToast({
                  title: "Couldn't delete this brand",
                  isSuccess: false
                });
              }).finally(() => {
                this.isActionLoading = false;
              });

            this.isActionLoading = false;
            break;
        }
      },

      openBrandSideModal(name, title, options) {
        this.selectedSideModal = { name, title, options };
        this.setOpenSideModal(true);
        this.selected = null; // To keep listening to selected clicks
      },
      getPayloadBody(brand) {
        const body = new FormData();
        body.append('name', brand.name);
        body.append('company_nif', brand.company_nif);
        body.append('brand_logo', brand.brand_url);
        body.append('company_url', brand.company_url);
        body.append('company_name', brand.company_name);
        body.append('company_privacy_url', brand.company_privacy_url);
        body.append('activate', brand.status_id === 'BST1' ? false : true);
        return body;
      },
      async getBlob(url) {
        return await fetch(url).then((r) => r.blob());
      },
      shortenBrandId(id) {
        return 'ID: ' + `${Utilities.trimStringCenter(id, 3, '...')}`;
      },
      getHumanReadableStatus(status) {
        switch (status) {
          case 'BST1':
            return 'menu_filter_2';
          case 'BST2':
            return 'menu_filter_3';
          case 'BST3':
            return 'menu_filter_4';
          case 'BST4':
            return 'menu_filter_5';
        }
      },
    },
  };
</script>
