<template>
  <div class="modatta-side-modal-cashback-overview">
    <div class="forms" :class="{ expanded: form.methods.model == 'pix' }">
      <div class="shadow-area">
        <div class="form balance">
          <div class="modatta-info-box modal-container flex-all-center">
            <div class="">
              <p>{{ setAmount(form.balance.amount, 'CUR1', 2) }}</p>
              <span>
                {{ form.balance.label }}
              </span>
            </div>
          </div>
        </div>
        <!-- Segment: Choose Action - Modatta balance / PIX -->
        <div class="form method">
          <div class="modatta-segment-control modal-container">
            <div class="row">
              <button
                class="button grow"
                @click="form.methods.model = 'balance'"
                :active="form.methods.model === 'balance'"
                :class="{ active: form.methods.selected === 'balance' }"
                :disabled="!form.methods.options.balance.available"
              >
                {{ form.methods.options.balance.label }}
              </button>
              <button
                class="button grow"
                @click="form.methods.model = 'pix'"
                :active="form.methods.model === 'pix'"
                :class="{ active: form.methods.model === 'pix' }"
                :disabled="!form.methods.options.pix.available"
              >
                {{ form.methods.options.pix.label }}
              </button>
            </div>
          </div>
        </div>
        <!-- end for: Segment: Choose Action - Modatta balance / PIX -->
        <div class="form pix" v-if="form.methods.model == 'pix'">
          <div class="modatta-input modal-container">
            <div class="row">
              <div class="grow">
                <div class="input-wrapper" :class="{ 'has-error': !form.pix.valid }">
                  <input
                    class="input"
                    type="text"
                    v-model="form.pix.model"
                    v-bind:placeholder="form.pix.options.key.label"
                    :disabled="!form.pix.options.key.available"
                  />
                  <modatta-icon icon="error-exclamation-point" width="20" v-if="!form.pix.valid"></modatta-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end for: Segment: Choose Action - Modatta balance / PIX -->
        <div class="form amount">
          <div class="modatta-input modal-container">
            <label class="label">{{ form.amount.options.text.label }}</label>
            <div class="row">
              <div class="grow">
                <div class="input-wrapper" :class="{ 'has-error': !form.amount.valid }">
                  <input
                    class="input"
                    type="number"
                    v-model="form.amount.model"
                    v-bind:placeholder="form.amount.options.text.placeholder"
                    :disabled="!form.amount.options.text.available"
                  />
                  <modatta-icon icon="error-exclamation-point" width="20" v-if="!form.amount.valid"></modatta-icon>
                </div>
              </div>
              <div>
                <button
                  class="button is-primary"
                  :disabled="!form.amount.options.button.available || !form.amount.valid"
                >
                  <template>{{ form.amount.options.button.label }}</template>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Table Vouchers Management -->
      <div class="scroll">
        <div class="wrap">
          <div class="modal-table vouchers">
            <div class="modatta-input modal-container">
              <div class="row header">
                <p class="table-title">{{ tables.vouchers.header.title }}</p>
                <button
                  class="action"
                  @click="tables.vouchers.header.options.action"
                  v-if="tables.vouchers.header.options.available"
                >
                  {{ tables.vouchers.header.options.label }}
                </button>
              </div>
              <div class="row">
                <table class="vouchers">
                  <thead>
                    <tr>
                      <th v-for="(label, index) in tables.vouchers.labels" :key="index">
                        {{ $t(`tables.vouchers.labels.` + label) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in tables.vouchers.data" :key="index">
                      <td>{{ row.name }}</td>
                      <td>
                        <strong class="chip">{{ row.code }}</strong>
                      </td>
                      <td>{{ setAmount(row.amount, 'CUR1', 0) }}</td>
                      <td>
                        <span class="status" :class="row.status.toLowerCase()">{{
                          $t('tables.vouchers.status.' + row.status)
                        }}</span>
                      </td>
                      <td>{{ setDate(row.expiration) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- end for: vouchers management-->
          <hr />
          <!-- Table Withdraws -->
          <div class="modal-table withdraws">
            <div class="modatta-input modal-container">
              <div class="row">
                <p class="table-title">{{ tables.withdraws.header.title }}</p>
                <button class="action" @click="tables.withdraws.header.options.action">
                  {{ tables.withdraws.header.options.label }}
                </button>
              </div>
              <div class="row">
                <table class="withdraws">
                  <thead>
                    <tr>
                      <th v-for="(label, index) in tables.withdraws.labels" :key="index">
                        {{ $t(`tables.widthdraws.labels.` + label) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in tables.withdraws.data" :key="index">
                      <td>{{ setDate(row.date) }}</td>
                      <td>{{ $t('tables.widthdraws.model.' + row.model.toLowerCase()) }}</td>
                      <td>
                        <span class="status" :class="row.status.toLowerCase()">{{
                          $t('tables.widthdraws.status.' + row.status)
                        }}</span>
                      </td>
                      <td>{{ setAmount(row.amount, 'CUR1') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- end for: Withdraws -->
        </div>
      </div>
    </div>

    <div class="modatta-side-modal-expanded" :class="{ open: subModal.isOpen }">
      <div class="modal-wrapper">
        <header>
          <button class="close button" @click="closeSubModals()">
            <modatta-icon icon="arrow-left-primary"></modatta-icon>
          </button>
          <div class="modal-title">{{ subModal.title }}</div>
        </header>

        <div class="modal-table vouchers" v-if="subModal.vouchers.isOpen">
          <div class="modatta-input modal-container">
            <table class="vouchers">
              <thead>
                <tr>
                  <th v-for="(label, index) in tables.vouchers.labels" :key="index">
                    {{ $t(`tables.vouchers.labels.` + label) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in tables.vouchers.data" :key="index">
                  <td>{{ row.name }}</td>
                  <td>
                    <strong class="chip">{{ row.code }}</strong>
                  </td>
                  <td>{{ setAmount(row.amount, 'CUR1', 0) }}</td>
                  <td>
                    <span class="status" :class="row.status.toLowerCase()">{{
                      $t('tables.vouchers.status.' + row.status)
                    }}</span>
                  </td>
                  <td>{{ setDate(row.expiration) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-table withdraws" v-if="subModal.withdraws.isOpen">
          <div class="modatta-input modal-container">
            <table class="withdraws">
              <thead>
                <tr>
                  <th v-for="(label, index) in tables.withdraws.labels" :key="index">
                    {{ $t(`tables.widthdraws.labels.` + label) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in tables.withdraws.data" :key="index">
                  <td>{{ setDate(row.date) }}</td>
                  <td>{{ $t('tables.widthdraws.model.' + row.model.toLowerCase()) }}</td>
                  <td>
                    <span class="status" :class="row.status.toLowerCase()">{{
                      $t('tables.widthdraws.status.' + row.status)
                    }}</span>
                  </td>
                  <td>{{ setAmount(row.amount, 'CUR1') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { DateTime } from 'luxon';
  import Utilities from '../helpers/utilities.js';

  export default {
    props: ['options'],
    data() {
      return {
        subModal: {
          title: '',
          isOpen: false,
          vouchers: {
            name: '',
            title: 'Vouchers Management',
            options: {},
            available: true,
            isOpen: false,
          },
          withdraws: {
            name: '',
            title: 'Withdraws Management',
            options: {},
            available: true,
            isOpen: false,
          },
        },

        form: {
          /**
           * Input for Balance
           */

          balance: {
            amount: 10,
            currency: 'CUR1',
            label: this.$t('forms.cashback.balance.label'),
          },

          /**
           * Input for
           */

          methods: {
            model: 'pix',
            options: {
              // Used by Segment - PIX Withdraw
              pix: {
                label: this.$t('forms.cashback.methods.labels.pix'),
                available: true,
              },
              // Used by Segment - Convert into modatta balance
              balance: {
                label: this.$t('forms.cashback.methods.labels.balance'),
                available: true,
              },
            },
          },

          /**
           * Input for PIX
           */

          pix: {
            model: '',
            valid: true,
            errors: [],
            warnings: [],
            options: {
              key: {
                label: this.$t('forms.cashback.pix.placeholder'),
                available: true,
              },
            },
          },

          /**
           * Input for amount
           */

          amount: {
            model: '',
            valid: true,
            errors: [],
            warnings: [],
            options: {
              text: {
                placeholder: this.$t('forms.cashback.amount.placeholder'),
                label: this.$t('forms.cashback.amount.labels.text'),
                available: true,
              },
              button: {
                label: this.$t('forms.cashback.amount.labels.button'),
                available: true,
              },
            },
          },
        },
        tables: {
          /**
           * Table for Vouchers
           */

          vouchers: {
            header: {
              title: this.$t('modals.cashback.tables.vouchers.title'),
              options: {
                label: this.$t('tables.actions.seeall'),
                available: true,
                action: () => this.openVouchersModal(),
              },
            },
            labels: ['name', 'code', 'amount', 'status', 'expiration'],
            data: [
              {
                name: 'Company X',
                code: 'XADXFUG9',
                amount: 7,
                status: 'Active',
                expiration: '24-02-2022',
              },
              {
                name: 'Company Y',
                code: 'YBDYGUH8',
                amount: 6,
                status: 'Pending',
                expiration: '28-03-2023',
              },
              {
                name: 'Company Z',
                code: 'ZCDZMHI5',
                amount: 9,
                status: 'Expired',
                expiration: '31-12-2021',
              },
              {
                name: 'Company A',
                code: 'ADEFJKL4',
                amount: 5,
                status: 'Active',
                expiration: '15-06-2024',
              },
            ],
          },

          /**
           * Table for Withdraws
           */

          withdraws: {
            header: {
              title: this.$t('modals.cashback.tables.widthdraws.title'),
              options: {
                label: this.$t('tables.actions.seeall'),
                available: true,
                action: () => this.openWithdrawsModal(),
              },
            },
            labels: ['date', 'model', 'status', 'amount'],
            data: [
              {
                date: '24-02-2022',
                model: 'PIX withdraw',
                status: 'Active',
                amount: 6.5,
              },
              {
                date: '24-02-2022',
                model: 'PIX withdraw',
                status: 'Active',
                amount: 6.5,
              },
              {
                date: '24-02-2022',
                model: 'PIX withdraw',
                status: 'Active',
                amount: 6.5,
              },
              {
                date: '24-02-2022',
                model: 'PIX withdraw',
                status: 'Active',
                amount: 6.5,
              },
            ],
          },
        },
      };
    },
    mounted() {},
    watch: {},
    computed: {
      ...mapState(['user', 'modal']),
    },
    methods: {
      ...mapMutations(['setOpenSideModal']),
      setMethod(method) {
        switch (method) {
          case 'pix':
          case 'balance':
            this.form.methods.selected = method;
            break;
          default:
            throw new Error('Wrong parameter for setMethod() @ ModattaSideModalCashback.vue');
        }
      },

      closeSubModals() {
        this.subModal.vouchers.isOpen = false;
        this.subModal.withdraws.isOpen = false;
        this.subModal.title = '';
        this.subModal.isOpen = false;
      },

      openVouchersModal() {
        this.subModal.vouchers.isOpen = true;
        this.subModal.title = this.subModal.vouchers.title;
        this.subModal.isOpen = this.subModal.vouchers.isOpen;
      },

      openWithdrawsModal() {
        this.subModal.withdraws.isOpen = true;
        this.subModal.title = this.subModal.withdraws.title;
        this.subModal.isOpen = this.subModal.withdraws.isOpen;
      },

      /**
       * Modifiers
       */

      setTranslation(value) {
        return this.$t(`labels.${value}`);
      },

      setAmount(value, currency, decimal = 2) {
        if (value && !isNaN(value)) {
          return this.$t(`labels.${currency}`) + Utilities.numberWithCommas(Utilities.toDecimalPlaces(value, decimal));
        }
      },

      setPercentage(value, decimal = 0) {
        if (value && !isNaN(value)) {
          return Utilities.numberWithCommas(Utilities.toDecimalPlaces(value * 100, decimal)) + ' %';
        }
      },

      setDate(date) {
        return DateTime.fromFormat(date, 'dd-MM-yyyy').toLocaleString(DateTime.DATE_SHORT);
      },

      setUpperCase(value) {
        return value.toString().toUpperCase();
      },
    },
  };
</script>
