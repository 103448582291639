<template>
  <div class="validation-option postback">
    <div class="field">
      <label class="label">{{ $t('offers_step4.box_section_3_val_5_subtitle_2') }}</label>
    </div>
    <div class="configuration-steps">
      <div class="step" v-for="(step, index) in configSteps" :key="index">{{ step }}</div>
    </div>
    <div class="test">
      <div v-if="isValidated" class="start">
        <modatta-icon icon="green-checkmark"></modatta-icon>
        <span>Your Post back configurations is correct</span>
      </div>
      <template v-else>
        <div>
          <div v-if="url != null && isValid" class="testing-url">
            <template v-if="!hasModId(url) && url">
              {{ `${url}${hasModId(url) ? '&' : '?'}modid={modid}` }}
            </template>
            <template v-else>{{ `${url}` }}</template>
          </div>
          <button
            class="button is-secondary"
            style="margin-top: 24px"
            :disabled="!url || !isValid || isLoading"
            @click="testPostback(true)"
          >
            <span>{{ $t('offers_step4.test_pb') }}</span>
            <modatta-icon v-if="isLoading" icon="loading" class="rotating"></modatta-icon>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import Utilities from '../../../../../helpers/utilities.js';
  import { sha256 } from 'js-sha256';
  import Bugsnag from '@bugsnag/js';
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['offerId', 'url', 'isValid'],
    data() {
      return {
        formattedURL: '',
        testing: false,
        isLoading: false,
        isValidated: false,
        instructionsURL: this.$t('offers_step4.box_section_3_val_5_modatta_code'),
        configSteps: [
          this.$t('offers_step4.box_section_3_val_5_step_1'),
          this.$t('offers_step4.box_section_3_val_5_step_2'),
          this.$t('offers_step4.box_section_3_val_5_step_3'),
          this.$t('offers_step4.box_section_3_val_5_step_4'),
          //this.$t('offers_step4.box_section_3_val_5_step_5'),
        ],
      };
    },
    mounted() {
      if (this.url) {
        this.setModid();
        this.testPostback(false);
      }
    },
    watch: {
      url() {
        // When user changes URL;
        this.setModid();
      },
    },
    computed: {
      validatedPostback() {
        return this.$store.state.offer.validatePostback;
      },
      hash() {
        return 'pb_' + sha256(`${this.$store.state.user.user.id}-${this.offerId}`);
      },
    },

    methods: {
      ...mapActions(['validatePostback']),

      validURL(str) {
        return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(str);
      },
      hasModId(url) {
        return url ? url.includes('modid={modid}') : false;
      },

      setModid() {
        if ((this.url?.trim()?.length || 0) > 0 && this.url.includes('modid={modid}')) {
          this.formattedURL = Utilities.replaceModid(this.url, this.hash);
        } else {
          this.formattedURL = Utilities.addModid(this.url);
        }
      },

      testPostback(isFromButton) {
        this.isLoading = true;
        const body = JSON.stringify({
          offer_id: this.offerId,
          modid: this.hash,
        });
        this.validatePostback(body).then(() => {
          if (this.validatedPostback) this.isValidated = true;
          else {
            if (isFromButton) {
              try {
                // https:// e um action valido de acordo com o regex deeplink no entanto
                // retorna erro no window.open (https://?modid={modid}))
                this.formattedURL = Utilities.replaceModid(this.formattedURL, this.hash);
                window.open(this.formattedURL, '_blank');
              } catch (error) {
                if (Bugsnag.isStarted()) {
                  Bugsnag.notify(error);
                }
              }
            }
          }
          this.isLoading = false;
        });
      },
    },
  };
</script>

<style>
  .mask {
    line-height: 20px;
  }
</style>
