function setStatus() {
  return [
    { id: 'OPE20', name: 'accepted' },
    { id: 'OPE21', name: 'validated' },
    { id: 'OPE31', name: 'expired' },
    { id: 'OPE25', name: 'completed' },
    { id: 'OPE26', name: 'declined' },
    { id: 'OPE45', name: 'not_completed' },
  ];
}
function setCompletedSubStatus() {
  return [
    { id: 'OPE25', name: 'completed_all' },
    { id: 'OPE24', name: 'detractors' },
    { id: 'OPE23', name: 'passive' },
    { id: 'OPE22', name: 'promoters' },
  ];
}

function setDeclinedSubStatus() {
  return [
    { id: 'OPE26', name: 'declined_all' },
    { id: 'OPE27', name: 'declined_too_low' },
    { id: 'OPE28', name: 'declined_too_brand' },
    { id: 'OPE29', name: 'declined_type' },
    { id: 'OPE30', name: 'declined_product' },
  ];
}

export default {
  setStatus: () => setStatus(),
  setDeclinedSubStatus: () => setDeclinedSubStatus(),
  setCompletedSubStatus: () => setCompletedSubStatus(),
};
