function SetBrandStatus(id) {
  switch (id) {
    case 'BST1':
      return 'menu_filter_2';
    case 'BST2':
      return 'menu_filter_3';
    case 'BST3':
      return 'menu_filter_4';
    case 'BST4':
      return 'menu_filter_5';
  }
}

function getBrandStatus() {
  return [
    { name: 'menu_filter_1', id: '' }, // All
    { name: 'menu_filter_3', id: 'BST2' }, // Active
    { name: 'menu_filter_5', id: 'BST4' }, // Archived
    //{name: 'menu_filter_4', id: 'BST3'},
    //{name: 'menu_filter_2', id: 'BST1'},
  ];
}
function getBrandStatusDefault() {
  return { name: 'menu_filter_3', id: 'BST2' }; // Active
}

export default {
  SetBrandStatus: (id) => SetBrandStatus(id),
  getBrandStatus: () => getBrandStatus(),
  getBrandStatusDefault: () => getBrandStatusDefault(),
};
