<template>
  <div class="modatta-settings security">
    <header>
      <h2>{{ $t('settings_password.section_1_title') }}</h2>
      <modatta-icon icon="unlock" width="20" height="24"></modatta-icon>
    </header>
    <form @submit.prevent="updatePassword()">
      <section class="change-password">
        <div class="input-wrapper field" :class="{ 'has-error': $v.form.password.$error }">
          <label class="label">{{ $t('settings_password.section_1_new_label') }}</label>
          <input type="password" v-model="$v.form.password.$model" placeholder="" />
        </div>
        <div class="input-wrapper field" :class="{ 'has-error': $v.form.passwordConfirm.$error }">
          <label class="label">{{ $t('settings_password.section_1_confirmation_label') }}</label>
          <input type="password" v-model="$v.form.passwordConfirm.$model" placeholder="" />
        </div>
      </section>
      <button class="button is-primary" type="submit" :disabled="$v.form.$invalid || $parent.isLoading">
        <template>{{ $t('settings_password.button_change_label') }}</template>
        <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
      </button>
    </form>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import { required, sameAs, minLength } from 'vuelidate/lib/validators';
  import { sha256 } from 'js-sha256';

  export default {
    data() {
      return {
        form: { password: null, passwordConfirm: null },
      };
    },
    mounted() {},
    validations: {
      form: {
        password: {
          required,
          minLength: minLength(6),
        },
        passwordConfirm: {
          required,
          sameAsPassword: sameAs('password'),
        },
      },
    },
    methods: {
      ...mapActions(['setNewPassword']),
      ...mapMutations(['setOpenToast']),
      updatePassword() {
        this.$parent.isLoading = true;
        this.setNewPassword({ password: sha256(this.form.password) })
          .then(() => {
            this.setOpenToast({
              title: 'Password changed sucessfully',
              isSuccess: true
            });
            this.form.password = null;
            this.form.passwordConfirm = null;
            this.$parent.isLoading = false;
            this.$v.$reset();
          })
          .catch((error) => {
            this.setOpenToast({
              title: 'Password changed error',
              isSuccess: false
            });
            this.$parent.isLoading = false;
          });
      },
    },
  };
</script>
