<template>
  <div class="modatta-snap-tabs" ref="tabGroup">
    <!-- Tab Header -->
    <div class="snap-tabs-header">
      <!-- Tab Navigation -->
      <div class="snap-tabs-nav" ref="tabNav">
        <span class="nav-indicator" :style="{ left: indicatorOffset, width: indicatorWidth }"></span>
        <div v-for="tab in tabs">
          <a
            :class="{ active: tab.isActive }"
            :key="tab.id"
            ref="tabNavItems"
            :href="`#${tab.id}`"
            :active="tab.isActive"
            @click="
              (e) => {
                activateTab(tab);
                handleNavigation(e);
              }
            "
            >{{ $t('brands-dashboard.' + tab.id) }}</a
          >
        </div>
      </div>
      <!-- No actions -->
    </div>
    <!-- Tab Content -->
    <div class="snap-tabs-body" v-if="!isLoading">
      <div class="snap-tabs section-body" ref="tabSection">
        <div class="snap-tab" v-for="tab in tabs" :key="tab.id" :class="`${tab.id}-table`">
          <!-- Tab: Brands (table) -->
          <template v-if="tab.id === 'brands'" id="brands-tab">
            <modatta-table :data="tab.data" :show-header="false" :is-offer="tab.isOffer"></modatta-table>
          </template>
          <!-- Tab: Missions (table) -->
          <template v-if="tab.id === 'offers'" id="offers-tab">
            <modatta-table
              :data="tab.data"
              :show-header="false"
              :is-offer="tab.isOffer"
              v-if="tab.data.length > 0"
            ></modatta-table>
            <div class="empty-state" v-else>
              {{ $t('budget.table_no_content') }}
            </div>
          </template>
          <!-- Tab: Offer Type -->
          <template v-if="tab.id === 'offer-type'" id="offer-type-tab" class="tab-body offet-type-table">
            <div v-for="(offer, index) in tab.data" :key="index" class="offer-type">
              <div class="icon-wrapper">
                <modatta-icon :icon="offer.icon" width="1" height="1" :rem="true"></modatta-icon>
              </div>
              <div class="progressbar-container">
                <div class="type">{{ $t('labels.' + offer.type) }}</div>
                <div class="modatta-progress-bar">
                  <div
                    class="progressbar"
                    :style="{
                      width: `${(offer.completed / offer.total) * 100 || 0}%`,
                      left: `${(offer.rejected / offer.total) * 100 || 0}%`,
                    }"
                    :class="{ 'left-side-squared': ((offer.rejected / offer.total) * 100 || 0) !== 0 }"
                  ></div>
                  <div
                    class="progressbar rejected"
                    :style="{ width: `${(offer.rejected / offer.total) * 100 || 0}%` }"
                    :class="{ 'right-side-squared': ((offer.completed / offer.total) * 100 || 0) !== 0 }"
                  ></div>
                </div>
              </div>
              <div class="stats">
                <span class="rejected">{{ getRejectedPercentage(offer) }}%</span>
                <span class="completed">{{ getCompletedPercentage(offer) }}%</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="snap-tabs-body" style="padding: 1.25rem" v-else>
      <b-skeleton :animated="true" height="30px"></b-skeleton>
      <b-skeleton :animated="true" height="30px"></b-skeleton>
      <b-skeleton :animated="true" height="30px"></b-skeleton>
      <b-skeleton :animated="true" height="30px"></b-skeleton>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { DateTime } from 'luxon';
  import '../../../helpers/scroll-timeline.js';
  import Utilities from '../../../helpers/utilities.js';
  import SetOfferStatus from '../../../helpers/set-offer-status.js';
  import SetInteractionType from '../../../helpers/set-interaction-type.js';
  import SetInteractionTypeIcon from '../../../helpers/set-interaction-type-icon.js';

  export default {
    props: ['id'],
    data() {
      return {
        filterOptions: [
          { isSelected: true, text: 'Active' },
          { isSelected: true, text: 'In Review' },
          { isSelected: true, text: 'Achive' },
          { isSelected: true, text: '', isSeparator: true },
          { isSelected: true, text: 'Read Text Message' },
          { isSelected: true, text: 'Watch Video' },
          { isSelected: true, text: 'Visit Website' },
          { isSelected: true, text: 'Execute Task Online' },
          { isSelected: true, text: 'Execute Task Offline' },
          { isSelected: true, text: 'Answer Poll' },
          { isSelected: true, text: 'Answer Survey' },
          { isSelected: true, text: 'Share Data' },
        ],
        // isSelectAllFilterOptions: false,
        tabs: [
          { id: 'offers', name: 'Offers', isActive: true, data: [] },
          { id: 'offer-type', name: 'Offer Type', isActive: false, data: [] },
        ],

        isLoading: true,
        indicatorOffset: 0,
        indicatorWidth: 0,
      };
    },
    mounted() {
      this.setupSnapTabs();

      // Call API
      this.getBrandsAndOffers(this.id).then(() => {
        this.tabs[0].data = this.offers;
        this.tabs[1].data = this.offersType;
        this.isLoading = false;
      });
    },
    computed: {
      offers() {
        let offers = [];
        this.$store.state.analytic.brandOffers.forEach((b) => {
          b.offers.forEach((o) => {
            let offer = {};
            offer.type = { type: 'icon', icon: SetInteractionTypeIcon(SetInteractionType(o.interaction_type_id)) };
            // offer.id = `ID: ${o.id}`;
            offer.name = o.name;
            offer.date = this.setDate(o.offline_on);
            offer.state = {
              type: 'badge',
              status: `offers_status.${SetOfferStatus(o.status_id)}`,
              isClickable: { type: 'offers' },
              id: o.id,
            };
            // (SetOfferStatus(o.status_id) === "menu_filter_7") ? archivedOffers.push(offer) : notArchivedOffers.push(offer);
            offers.push(offer);
          });
        });
        return offers;
      },
      offersType() {
        let offersType = [];
        this.$store.state.analytic.brandOffers.forEach((b) => {
          b.offers.forEach((o) => {
            let offer = {};
            offer.icon = SetInteractionTypeIcon(SetInteractionType(o.interaction_type_id));
            offer.type = SetInteractionType(o.interaction_type_id);
            offer.total = o.count_delivered;
            offer.rejected = o.count_declined;
            offer.completed = o.count_completed;
            offersType.push(offer);
          });
        });
        const aggregate = Utilities.aggregate(offersType, ['icon', 'type'], ['total', 'rejected', 'completed']);
        return aggregate;
        // return offersType;
      },

      isSelectAllFilterOptions() {
        return this.filterOptions.every((o) => o.isSelected === true);
      },
      activeTab() {
        return this.tabs.find((t) => t.isActive);
      },
      isDataLoaded() {
        return this.tabs[0].data.length > 0 && this.tabs[1].data.length;
      },
    },
    methods: {
      ...mapActions(['getBrandsAndOffers']),

      selectAll(e) {
        e.preventDefault();
        e.stopPropagation();

        this.isSelectAllFilterOptions
          ? this.filterOptions.forEach((o) => (o.isSelected = false))
          : this.filterOptions.forEach((o) => (o.isSelected = true));
      },

      download() {},

      setDate(date) {
        // Ref: https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html
        if (date === null) return '';
        const newFormat = Object.assign(DateTime.DATE_MED, DateTime.TIME_SIMPLE);
        return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').toLocaleString(newFormat);
      },

      getRejectedPercentage(offer) {
        const result = Utilities.toDecimalPlaces((offer.rejected / offer.total) * 100, 0) || 0;
        return isFinite(result) ? result : 0;
      },
      getCompletedPercentage(offer) {
        const result = Utilities.toDecimalPlaces((offer.completed / offer.total) * 100, 0) || 0;
        return isFinite(result) ? result : 0;
      },

      /*********************************
    | SETUP TABS
    **********************************/

      setupSnapTabs() {
        const activeTabId = location.hash ? this.tabs.findIndex((t) => t.id === location.hash.replace('#', '')) : 0;
        this.moveIndicator(this.$refs.tabNavItems[activeTabId]);
        this.activateTab(this.tabs[activeTabId]);
      },

      moveIndicator(to) {
        this.indicatorWidth = `${to.offsetWidth}px`;
        this.indicatorOffset = `${to.offsetLeft}px`;
      },

      changeTab(tabId) {
        this.$refs.tabSection.scrollLeft = tabId * this.$refs.tabSection.offsetWidth;
      },

      activateTab(tab) {
        this.tabs.forEach((t) => (t.isActive = false));
        tab.isActive = true;
      },

      findActiveTab(f) {
        return this.tabs.findIndex((t) => t.id === f);
      },

      handleNavigation(e) {
        this.moveIndicator(e.currentTarget);
        const activeTabId = this.findActiveTab(e.currentTarget.hash.replace('#', ''));
        this.activateTab(this.tabs[activeTabId]);
        this.changeTab(activeTabId);
      },
    },
  };
</script>

<style scoped lang="scss">
  .empty-state {
    // TODO: Move this to an scss utilitarian file
    position: absolute;
    left: 0;
    top: 4.12rem;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
