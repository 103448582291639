<template>
  <div class="step-details">
    <div class="details">
      <h2>{{ $t('offers_step1.section_1_title') }}</h2>
      <p>{{ $t('offers_step1.section_1_subtitle') }}</p>
      <div
        v-if="
          !$parent.isLoading &&
          $parent.newOffer.interaction_type_id === null &&
          this.$store?.state?.newOfferType?.code === null
        "
        class="interaction no-offer"
      >
        <h3>{{ $t('offers_step1.select_offer_type') }}</h3>
        <button class="button is-primary" @click="selectOption('campaign', 'Create New Offer')">+ New Offer</button>
      </div>
      <div v-else class="brands-wrapper">
        <div class="brands">
          <template v-if="!$parent.isLoading">
            <div
              class="brand"
              :class="{ 'is-selected': brand.id === $parent.newOffer.brand_id }"
              v-for="brand in activeBrands"
              :key="brand.id"
              @click="selectBrand(brand)"
            >
              <div class="logo-wrapper">
                <div class="logo" :style="`background-image: url(${brand.logo_url})`"></div>
              </div>
              <div class="name">{{ brand.name }}</div>
            </div>
          </template>
          <div v-else class="is-loading brand" v-for="index in 8" :key="index"></div>
          <div
            class="brand new-brand"
            @click="selectOption('brand', $t('brands_edit.title'), { type: 'create', disabled: false })"
          >
            <modatta-icon icon="upload"></modatta-icon>
            <p class="name">{{ $t('offers_step1.button_new_brand_label') }}</p>
          </div>
        </div>
      </div>
      <button
        class="button is-primary"
        style="height: 52px; margin-top: 12px"
        :disabled="$parent.newOffer.brand_id == null || $parent.isLoading"
        @click="
          $parent.handleNextClick(1, {
            brand_id: $parent.newOffer.brand_id,
          })
        "
      >
        <template>{{ $t('offers_step2.button_label') }}</template>
        <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
      </button>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import ModattaIcon from '../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    data() {
      return {
        isLoading: false,
        brands: this.$store.state.brand.brands,
        selectedOption: {},
      };
    },
    mounted() {
      this.isLoading = true;
      this.getBrands().then(() => {
        this.isLoading = false;
        this.brands = this.$store.state.brand.brands.filter((b) => b.status_id === 'BST2');
      });
    },
    watch: {
      brands(newVal) {
        this.brands = newVal;
      },
    },
    computed: {
      ...mapState(['openSideModal', 'newOfferType']),
      activeBrands() {
        return this.$store.state.brand.brands.filter((b) => b.status_id === 'BST2');
      },
      archivedBrands() {
        return this.$store.state.brand.brands.filter((b) => b.status_id === 'BST4');
      },
    },
    methods: {
      ...mapActions(['getBrands']),
      ...mapMutations(['setOpenSideModal']),
      selectBrand(brand) {
        this.$parent.selectedBrand = brand;
        this.$parent.newOffer.brand_id = brand.id;
      },
      selectOption(name, title, options) {
        this.$parent.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
      },
    },
  };
</script>
