<template>
  <div class="step-details">
    <div class="details">
      <h2>{{ $t('offers_step3.section_1_title') }}</h2>
      <p>{{ $t('offers_step3.section_1_subtitle') }}</p>
      <div class="offer-details">
        <div class="offer-name">
          <div class="field">
            <label class="label">{{ $t('offers_step3.offer_name') }}</label>
            <div class="input-wrapper">
              <!-- Maxlength c/ limite de 255 - c/ 256 char quebra -->
              <input type="text" v-model="$parent.newOffer.name" maxlength="255" />
            </div>
          </div>
        </div>
        <div class="offer-description-and-image">
          <div class="offer-description">
            <div class="field">
              <label class="label">
                {{ $t('offers_step3.box_section_1_title') }}
                <b-tooltip
                  v-bind:label="$t('offers_step3.box_section_1_tooltip')"
                  type="is-dark"
                  position="is-top"
                  size="is-small"
                  multilined
                >
                  <modatta-icon icon="info-square"></modatta-icon>
                </b-tooltip>
              </label>
              <div class="input-wrapper">
                <!-- Maxlength c/ limite ? - s/ limite para mandar para API -->
                <textarea type="text" v-model="$parent.newOffer.description" />
              </div>
            </div>
          </div>
          <modatta-upload-offer-banner
            :banner="$parent.newOffer.banner"
            :banner-url="$parent.newOffer.banner_url"
            @banner="(file) => (this.$parent.newOffer.banner = file)"
            @banner-url="(url) => (this.$parent.newOffer.banner_url = url)"
          ></modatta-upload-offer-banner>
        </div>
        <div v-if="interactionType !== 'INT6' && interactionType !== 'INT7'" class="offer-steps">
          <div class="field">
            <label class="label">
              {{ $t('offers_step3.box_section_3_title') }}
              <b-tooltip
                v-bind:label="$t('offers_step3.box_section_3_tooltip')"
                type="is-dark"
                position="is-top"
                size="is-small"
                multilined
              >
                <modatta-icon icon="info-square"></modatta-icon>
              </b-tooltip>
            </label>
          </div>

          <div class="steps">
            <div class="step" v-for="(step, index) in $parent.newOffer.steps" :key="index">
              <div class="input-wrapper">
                <!-- Maxlength c/ limite de 255 - c/ 256 char quebra -->
                <input
                  type="text"
                  v-model="step.description"
                  maxlength="255"
                  :class="{
                    'empty-field':
                      step.description.replace(/\s/g, '') === '' &&
                      canDisableNexStep &&
                      $parent.newOffer.interaction_type_id !== 'INT6' &&
                      $parent.newOffer.interaction_type_id !== 'INT7',
                  }"
                />
              </div>
              <div class="button-wrapper">
                <button
                  class="button add-media is-secondary"
                  :class="{
                    'last-item': index + 1 === $parent.newOffer.steps.length,
                  }"
                  @click="selectOption('step-media', !step?.media_type_id ? 'Adicionar Mídia' : 'Editar Mídia', index)"
                  tabindex="-1"
                >
                  <span v-if="!step?.media_type_id">Adicionar Midia</span>
                  <span v-else>Editar Midia</span>
                </button>
                <button
                  v-if="$parent.newOffer.steps.length > 1"
                  class="button delete-step"
                  :class="{
                    'last-item': index + 1 === $parent.newOffer.steps.length,
                  }"
                  @click="deleteStep(index)"
                  tabindex="-1"
                >
                  <modatta-icon icon="delete"></modatta-icon>
                </button>
                <modatta-icon
                  class="icon-type"
                  v-if="['MTY3', 'MTY4'].includes(step.media_type_id)"
                  icon="movie-step"
                ></modatta-icon>
                <modatta-icon class="icon-type" v-if="step.media_type_id === 'MTY2'" icon="photo-step"></modatta-icon>
                <button
                  v-if="index === $parent.newOffer.steps.length - 1"
                  class="button add-new-step"
                  @click="addNewStep()"
                >
                  <modatta-icon icon="plus"></modatta-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button class="button is-secondary" @click="$parent.currentStep--">
            {{ $t('offers_step2.back_button_label') }}
          </button>
          <button
            class="button is-primary"
            :disabled="!$parent.isOfferStepCompleted || disableNextStep() || $parent.isLoading"
            @click="handleNextClick()"
          >
            <template>{{ $t('offers_step3.button_label') }}</template>
            <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import SetInteractionType from '../../../../helpers/set-interaction-type.js';
  import ModattaIcon from '../../../../components/ModattaIcon.vue';
  import ModattaUploadOfferBanner from './components/ModattaUploadOfferBanner.vue';

  export default {
    components: { ModattaIcon, ModattaUploadOfferBanner },
    data() {
      return {
        selectedOption: {},
        isOfferNameEditable: false,
        isSelectBannerOpen: false,
        canDisableNexStep: false,
      };
    },
    props: {
      emitStepMedia: {
        type: Function,
        default: () => {},
      },
    },
    mounted() {
      if (this.$parent.newOffer.type_label_id == 'OFT28') {
        if (typeof this.$parent.action === 'object') {
          this.$parent.newOffer.action = JSON.stringify(this.$parent.newOffer.action);
        }
      }

      if (this.$parent.newOffer.steps.length === 0) {
        this.$parent.newOffer.steps = [{ description: '', index: 0 }];
      }
    },
    computed: {
      ...mapState(['openSideModal']),
      interactionType() {
        return SetInteractionType(this.$parent.newOffer.interaction_type_id);
      },
    },
    methods: {
      ...mapMutations(['setOpenSideModal']),
      selectOption(name, title, index) {
        this.$parent.selectedOption = { name, title, index };
        this.setOpenSideModal(true);
      },
      handleNextClick() {
        if (
          this.$parent.newOffer.interaction_type_id !== 'INT6' &&
          this.$parent.newOffer.interaction_type_id !== 'INT7' &&
          this.$parent.newOffer.steps.filter((s) => s.description.replace(/\s/g, '') !== '').length <
            this.$parent.newOffer.steps.length
        ) {
          this.canDisableNexStep = true;
          return;
        } else {
          this.$parent.newOffer.steps = this.$parent.newOffer.steps.filter(
            (s) => s.description.replace(/\s/g, '') !== '',
          );
        }

        if (this.$parent.newOffer.validations_codes.length > 0) {
          if (
            this.$parent.newOffer.validations_codes[0].code == '' ||
            this.$parent.newOffer.validations_codes[0].code == null
          ) {
            this.$parent.newOffer.validations_codes = [];
          }
        }

        this.$parent.newOffer.steps.forEach((step, i) => (step.index = i));
        this.$parent.handleNextClick(3, {
          name: this.$parent.newOffer.name,
          description: this.$parent.newOffer.description,
          banner_url: this.$parent.newOffer.banner_url,
          steps: this.$parent.newOffer.steps,
        });
      },
      editOfferName(id) {
        const inputField = document.getElementById(id);
        setTimeout(() => inputField.focus(), 10);
        this.isOfferNameEditable = true;
      },
      saveOfferName() {
        this.isOfferNameEditable = false;
      },
      addNewStep() {
        if (this.$parent.newOffer.steps.length === 10) {
          return;
        }
        this.$parent.newOffer.steps.push({
          description: '',
          index: this.$parent.newOffer.steps.length - 1,
        });
      },
      deleteStep(i) {
        if (this.$parent.newOffer.steps.length === 1) {
          return;
        }
        this.$parent.newOffer.steps.splice(i, 1);
      },
      setFieldError() {},
      disableNextStep() {
        if (
          this.$parent.newOffer.interaction_type_id !== 'INT6' &&
          this.$parent.newOffer.interaction_type_id !== 'INT7' &&
          this.canDisableNexStep &&
          this.$parent.newOffer.steps.filter((s) => s.description.replace(/\s/g, '') !== '').length <
            this.$parent.newOffer.steps.length
        ) {
          return true;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .empty-field {
    border-color: #f16341;
  }
</style>
./components/ModattaUploadOfferBanner.vue/index.js
