<template>
  <b-field class="modatta-radio-input" :class="{ 'spacing-sm': spacing === 'sm' }">
    <b-radio-button
      v-model="value"
      type="is-selected"
      :native-value="option"
      @input="[(otherValue = ''), newValue($event)]"
      v-for="(option, index) in options"
      :key="index"
    >
      <span>{{ option }}</span>
    </b-radio-button>
    <div v-if="hasOther === true" class="modatta-radio-input-editable input-wrapper">
      <input
        :min="min"
        :step="step"
        type="number"
        placeholder="Other"
        v-model="otherValue"
        @focus="resetValue($event)"
        @input="handleInput($event)"
        class="has-validation"
        :class="{ 'is-selected': otherValue != '', 'is-error': isError }"
      />
    </div>
  </b-field>
</template>

<style>
  .spacing-sm .control {
    flex-grow: 1;
  }
  .spacing-sm .button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
</style>
<script>
  export default {
    props: ['options', 'min', 'max', 'step', 'hasOther', 'suggestedValue', 'acceptsOnlyInteger', 'spacing', 'hasError'],
    data() {
      return {
        value: '',
        otherValue: '',
        isError: false,
      };
    },
    mounted() {
      this.setOtherValue();
      this.isError = this.hasError;
      if (this.suggestedValue != null) {
        this.value = this.suggestedValue;
      }
    },
    watch: {
      suggestedValue(newVal) {
        this.value = newVal;
      },
      hasError: {
        handler: function (val) {
          this.isError = val;
        },
        deep: true,
      },
    },
    methods: {
      // Method to populate input if suggested value !=
      // to pre-populated options (e.g. 0.10, 0.15, 0.50)
      setOtherValue() {
        const isSuggestedValueInsideOptions = this.options.find((o) => o === this.suggestedValue);
        if (isSuggestedValueInsideOptions == null) {
          this.otherValue = this.suggestedValue;
        }
      },

      handleInput(e) {
        this.value = '';
        if (this.acceptsOnlyInteger) {
          this.otherValue = Math.round(e.target.value);
          this.newValue(Math.round(e.target.value));
        } else {
          this.newValue(e.target.value);
        }
      },
      resetValue(e) {
        this.value = '';
        this.newValue(`${e.target.value}`);
      },
      newValue(e) {
        this.$emit('radio-value', e);
      },
    },
  };
</script>
