<template>
  <section class="profile-snap-tabs" ref="tabGroup">
    <nav>
      <a
        :key="tab.id"
        v-for="tab in tabs"
        :href="`#${tab.id}`"
        class="navigation-item"
        @click="selectedTab = tab.id"
        :class="{ 'is-selected': tab.id === selectedTab }"
        >{{ $t('settings.' + tab.name) }}</a
      >
    </nav>
    <section class="snap-tab-body">
      <modatta-settings-profile v-if="selectedTab === 'profile'"></modatta-settings-profile>
      <modatta-settings-security v-else-if="selectedTab === 'security'"></modatta-settings-security>
      <modatta-settings-billing v-else-if="selectedTab === 'billing'"></modatta-settings-billing>
      <modatta-settings-other v-else-if="selectedTab === 'other'"></modatta-settings-other>
    </section>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
  import ModattaSettingsProfile from '../components/ModattaSettingsProfile.vue';
  import ModattaSettingsSecurity from '../components/ModattaSettingsSecurity.vue';
  import ModattaSettingsBilling from '../components/ModattaSettingsBilling.vue';
  import ModattaSettingsOther from '../components/ModattaSettingsOther.vue';

  export default {
    components: { ModattaSettingsProfile, ModattaSettingsSecurity, ModattaSettingsBilling, ModattaSettingsOther },
    data() {
      return {
        tabs: [
          { id: 'profile', name: 'menu_profile' },
          { id: 'security', name: 'menu_security' },
          { id: 'billing', name: 'menu_billing' },
          { id: 'other', name: 'menu_settings' },
        ],
        selectedTab: 'profile',
        isLoading: false,
      };
    },
    mounted() {
      const urlHash = window.location.href.split('#')[1];
      if (urlHash != null) {
        let tab = this.tabs.find((t) => t.id === urlHash);
        this.selectedTab = tab.id;
      }
    },
    watch: {
      $route(to) {
        this.selectedTab = to.hash.replace('#', '');
      },
    },
    methods: {
      ...mapActions(['updateUser']),
      ...mapMutations(['setOpenToast']),
      changeUser(body) {
        this.isLoading = true;
        this.updateUser(body).then(() => {
          this.setOpenToast({
            title: 'Saved sucessfully',
            isSuccess: true
          });
          this.isLoading = false;
        });
      },
    },
  };
</script>
