export default function SetFilterCountryValueId(id) {
  switch (id) {
    case 'VAL241':
      return 'AD';
    case 'VAL242':
      return 'AE';
    case 'VAL243':
      return 'AF';
    case 'VAL244':
      return 'AG';
    case 'VAL245':
      return 'AI';
    case 'VAL246':
      return 'AL';
    case 'VAL247':
      return 'AM';
    case 'VAL248':
      return 'AO';
    case 'VAL249':
      return 'AQ';
    case 'VAL250':
      return 'AR';
    case 'VAL251':
      return 'AS';
    case 'VAL252':
      return 'AT';
    case 'VAL253':
      return 'AU';
    case 'VAL254':
      return 'AW';
    case 'VAL255':
      return 'AX';
    case 'VAL256':
      return 'AZ';
    case 'VAL257':
      return 'BA';
    case 'VAL258':
      return 'BB';
    case 'VAL259':
      return 'BD';
    case 'VAL260':
      return 'BE';
    case 'VAL261':
      return 'BF';
    case 'VAL262':
      return 'BG';
    case 'VAL263':
      return 'BH';
    case 'VAL264':
      return 'BI';
    case 'VAL265':
      return 'BJ';
    case 'VAL266':
      return 'BL';
    case 'VAL267':
      return 'BM';
    case 'VAL268':
      return 'BN';
    case 'VAL269':
      return 'BO';
    case 'VAL270':
      return 'BQ';
    case 'VAL271':
      return 'BR';
    case 'VAL272':
      return 'BS';
    case 'VAL273':
      return 'BT';
    case 'VAL274':
      return 'BV';
    case 'VAL275':
      return 'BW';
    case 'VAL276':
      return 'BY';
    case 'VAL277':
      return 'BZ';
    case 'VAL278':
      return 'CA';
    case 'VAL279':
      return 'CC';
    case 'VAL280':
      return 'CD';
    case 'VAL281':
      return 'CF';
    case 'VAL282':
      return 'CG';
    case 'VAL283':
      return 'CH';
    case 'VAL284':
      return 'CI';
    case 'VAL285':
      return 'CK';
    case 'VAL286':
      return 'CL';
    case 'VAL287':
      return 'CM';
    case 'VAL288':
      return 'CN';
    case 'VAL289':
      return 'CO';
    case 'VAL290':
      return 'CR';
    case 'VAL291':
      return 'CU';
    case 'VAL292':
      return 'CV';
    case 'VAL293':
      return 'CW';
    case 'VAL294':
      return 'CX';
    case 'VAL295':
      return 'CY';
    case 'VAL296':
      return 'CZ';
    case 'VAL297':
      return 'DE';
    case 'VAL298':
      return 'DJ';
    case 'VAL299':
      return 'DK';
    case 'VAL300':
      return 'DM';
    case 'VAL301':
      return 'DO';
    case 'VAL302':
      return 'DZ';
    case 'VAL303':
      return 'EC';
    case 'VAL304':
      return 'EE';
    case 'VAL305':
      return 'EG';
    case 'VAL306':
      return 'EH';
    case 'VAL307':
      return 'ER';
    case 'VAL308':
      return 'ES';
    case 'VAL309':
      return 'ET';
    case 'VAL310':
      return 'FI';
    case 'VAL311':
      return 'FJ';
    case 'VAL312':
      return 'FK';
    case 'VAL313':
      return 'FM';
    case 'VAL314':
      return 'FO';
    case 'VAL315':
      return 'FR';
    case 'VAL316':
      return 'GA';
    case 'VAL317':
      return 'GB';
    case 'VAL318':
      return 'GD';
    case 'VAL319':
      return 'GE';
    case 'VAL320':
      return 'GF';
    case 'VAL321':
      return 'GG';
    case 'VAL322':
      return 'GH';
    case 'VAL323':
      return 'GI';
    case 'VAL324':
      return 'GL';
    case 'VAL325':
      return 'GM';
    case 'VAL326':
      return 'GN';
    case 'VAL327':
      return 'GP';
    case 'VAL328':
      return 'GQ';
    case 'VAL329':
      return 'GR';
    case 'VAL330':
      return 'GS';
    case 'VAL331':
      return 'GT';
    case 'VAL332':
      return 'GU';
    case 'VAL333':
      return 'GW';
    case 'VAL334':
      return 'GY';
    case 'VAL335':
      return 'HK';
    case 'VAL336':
      return 'HM';
    case 'VAL337':
      return 'HN';
    case 'VAL338':
      return 'HR';
    case 'VAL339':
      return 'HT';
    case 'VAL340':
      return 'HU';
    case 'VAL341':
      return 'ID';
    case 'VAL342':
      return 'IE';
    case 'VAL343':
      return 'IL';
    case 'VAL344':
      return 'IM';
    case 'VAL345':
      return 'IN';
    case 'VAL346':
      return 'IO';
    case 'VAL347':
      return 'IQ';
    case 'VAL348':
      return 'IR';
    case 'VAL349':
      return 'IS';
    case 'VAL350':
      return 'IT';
    case 'VAL351':
      return 'JE';
    case 'VAL352':
      return 'JM';
    case 'VAL353':
      return 'JO';
    case 'VAL354':
      return 'JP';
    case 'VAL355':
      return 'KE';
    case 'VAL356':
      return 'KG';
    case 'VAL357':
      return 'KH';
    case 'VAL358':
      return 'KI';
    case 'VAL359':
      return 'KM';
    case 'VAL360':
      return 'KN';
    case 'VAL361':
      return 'KP';
    case 'VAL362':
      return 'KR';
    case 'VAL363':
      return 'KW';
    case 'VAL364':
      return 'KY';
    case 'VAL365':
      return 'KZ';
    case 'VAL366':
      return 'LA';
    case 'VAL367':
      return 'LB';
    case 'VAL368':
      return 'LC';
    case 'VAL369':
      return 'LI';
    case 'VAL370':
      return 'LK';
    case 'VAL371':
      return 'LR';
    case 'VAL372':
      return 'LS';
    case 'VAL373':
      return 'LT';
    case 'VAL374':
      return 'LU';
    case 'VAL375':
      return 'LV';
    case 'VAL376':
      return 'LY';
    case 'VAL377':
      return 'MA';
    case 'VAL378':
      return 'MC';
    case 'VAL379':
      return 'MD';
    case 'VAL380':
      return 'ME';
    case 'VAL381':
      return 'MF';
    case 'VAL382':
      return 'MG';
    case 'VAL383':
      return 'MH';
    case 'VAL384':
      return 'MK';
    case 'VAL385':
      return 'ML';
    case 'VAL386':
      return 'MM';
    case 'VAL387':
      return 'MN';
    case 'VAL388':
      return 'MO';
    case 'VAL389':
      return 'MP';
    case 'VAL390':
      return 'MQ';
    case 'VAL391':
      return 'MR';
    case 'VAL392':
      return 'MS';
    case 'VAL393':
      return 'MT';
    case 'VAL394':
      return 'MU';
    case 'VAL395':
      return 'MV';
    case 'VAL396':
      return 'MW';
    case 'VAL397':
      return 'MX';
    case 'VAL398':
      return 'MY';
    case 'VAL399':
      return 'MZ';
    case 'VAL400':
      return 'NA';
    case 'VAL401':
      return 'NC';
    case 'VAL402':
      return 'NE';
    case 'VAL403':
      return 'NF';
    case 'VAL404':
      return 'NG';
    case 'VAL405':
      return 'NI';
    case 'VAL406':
      return 'NL';
    case 'VAL407':
      return 'NO';
    case 'VAL408':
      return 'NP';
    case 'VAL409':
      return 'NR';
    case 'VAL410':
      return 'NU';
    case 'VAL411':
      return 'NZ';
    case 'VAL412':
      return 'OM';
    case 'VAL413':
      return 'PA';
    case 'VAL414':
      return 'PE';
    case 'VAL415':
      return 'PF';
    case 'VAL416':
      return 'PG';
    case 'VAL417':
      return 'PH';
    case 'VAL418':
      return 'PK';
    case 'VAL419':
      return 'PL';
    case 'VAL420':
      return 'PM';
    case 'VAL421':
      return 'PN';
    case 'VAL422':
      return 'PR';
    case 'VAL423':
      return 'PS';
    case 'VAL424':
      return 'PT';
    case 'VAL425':
      return 'PW';
    case 'VAL426':
      return 'PY';
    case 'VAL427':
      return 'QA';
    case 'VAL428':
      return 'RE';
    case 'VAL429':
      return 'RO';
    case 'VAL430':
      return 'RS';
    case 'VAL431':
      return 'RU';
    case 'VAL432':
      return 'RW';
    case 'VAL433':
      return 'SA';
    case 'VAL434':
      return 'SB';
    case 'VAL435':
      return 'SC';
    case 'VAL436':
      return 'SD';
    case 'VAL437':
      return 'SE';
    case 'VAL438':
      return 'SG';
    case 'VAL439':
      return 'SH';
    case 'VAL440':
      return 'SI';
    case 'VAL441':
      return 'SJ';
    case 'VAL442':
      return 'SK';
    case 'VAL443':
      return 'SL';
    case 'VAL444':
      return 'SM';
    case 'VAL445':
      return 'SN';
    case 'VAL446':
      return 'SO';
    case 'VAL447':
      return 'SR';
    case 'VAL448':
      return 'SS';
    case 'VAL449':
      return 'ST';
    case 'VAL450':
      return 'SV';
    case 'VAL451':
      return 'SX';
    case 'VAL452':
      return 'SY';
    case 'VAL453':
      return 'SZ';
    case 'VAL454':
      return 'TC';
    case 'VAL455':
      return 'TD';
    case 'VAL456':
      return 'TF';
    case 'VAL457':
      return 'TG';
    case 'VAL458':
      return 'TH';
    case 'VAL459':
      return 'TJ';
    case 'VAL460':
      return 'TK';
    case 'VAL461':
      return 'TL';
    case 'VAL462':
      return 'TM';
    case 'VAL463':
      return 'TN';
    case 'VAL464':
      return 'TO';
    case 'VAL465':
      return 'TR';
    case 'VAL466':
      return 'TT';
    case 'VAL467':
      return 'TV';
    case 'VAL468':
      return 'TW';
    case 'VAL469':
      return 'TZ';
    case 'VAL470':
      return 'UA';
    case 'VAL471':
      return 'UG';
    case 'VAL472':
      return 'UM';
    case 'VAL473':
      return 'US';
    case 'VAL474':
      return 'UY';
    case 'VAL475':
      return 'UZ';
    case 'VAL476':
      return 'VA';
    case 'VAL477':
      return 'VC';
    case 'VAL478':
      return 'VE';
    case 'VAL479':
      return 'VG';
    case 'VAL480':
      return 'VI';
    case 'VAL481':
      return 'VN';
    case 'VAL482':
      return 'VU';
    case 'VAL483':
      return 'WF';
    case 'VAL484':
      return 'WS';
    case 'VAL485':
      return 'YE';
    case 'VAL486':
      return 'YT';
    case 'VAL487':
      return 'ZA';
    case 'VAL488':
      return 'ZM';
    case 'VAL489':
      return 'ZW';
  }
}
