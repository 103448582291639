<template>
  <div class="modatta-metric-card" :class="{ horizontal: layout === 'horizontal', 'is-disabled': isDisabled }">
    <div v-if="media.type === 'icon'" class="media icon" :style="styleMedia()"></div>
    <div v-else class="modatta-pie-chart objective">
      <!-- && total > 0 && completed > 0 ajuda o PieChart a ser correctamente colocado, porém só quando os dados são > 0 -->
      <modatta-pie-chart
        v-if="isChartLoaded && total > 0 && completed > 0"
        :chart-data="objectiveChart.data"
        :labels="objectiveChart.labels"
        :colors="objectiveChart.colors"
        :cutout="75"
      ></modatta-pie-chart>
      <div class="chart-center-content">
        <span>{{ objectiveChart.center }}</span>
      </div>
    </div>
    <div class="content">
      <div class="title text-ellipsis" :class="{ 'is-loading': title == null }">{{ title }}</div>
      <div class="body">{{ body }}</div>
    </div>
    <div
      v-if="detail"
      class="detail"
      :class="{ 'is-red': detail.isRed }"
      :style="{ 'background-color': detail.background, color: detail.percentageColor }"
    >
      <b-tooltip
        v-if="detail.tooltip != null"
        :label="detail.tooltip"
        type="is-dark"
        :position="tooltipPosition ? tooltipPosition : 'is-top'"
        size="is-small"
        multilined
      >
        <modatta-icon icon="info-square-light"></modatta-icon>
      </b-tooltip>
      <template v-else>{{ detail.value }}</template>
    </div>
  </div>
</template>
<script>
  import tinycolor from 'tinycolor2';

  export default {
    props: ['tooltipPosition', 'layout', 'media', 'title', 'body', 'detail', 'isLoading', 'options', 'isDisabled'],
    data() {
      return {
        total: '',
        completed: '',
        isChartLoaded: false,
      };
    },
    mounted() {},
    watch: {
      title(newVal) {
        // Considerar parseInt para excluir probabilidades de estar a passar string
        this.total = newVal;
        this.isChartLoaded = true;
      },
      options: {
        deep: true,
        handler(newVal) {
          this.completed = newVal.completed;
          this.isChartLoaded = true;
        },
      },
    },
    computed: {
      objectiveChart() {
        const centerValue = Math.round((this.completed / this.total) * 100 * 10) / 10;
        this.isChartLoaded = true;
        return {
          center: isFinite(centerValue) ? `${centerValue}%` : '0%',
          labels: ['Delivered', 'Completed'],
          data: [this.total - this.completed, this.completed],
          colors: ['#EBF0FA', '#2ED5BA'],
        };
      },
    },
    methods: {
      styleMedia() {
        const color = tinycolor(this.media.color);
        color.setAlpha(0.2);
        const shadow = this.media.hasShadow ? `0px 6px 8px -1px ${color.toRgbString()}` : '';
        return {
          boxShadow: shadow,
          backgroundColor: this.media.color,
          backgroundImage: `url(/svg/metrics/${this.media.source}.svg)`,
        };
      },
    },
  };
</script>
