<template>
  <div class="modal-wrapper">
    <header>
      <button class="close button" @click="close">
        <modatta-icon icon="arrow-left-primary"></modatta-icon>
      </button>
      <div class="modal-title">
        {{ $t('offers_step2_profile_filter_advanced.title') }}
      </div>
    </header>
    <div class="advanced-filters">
      <div class="filter">
        <b-field v-bind:label="$t('offers_step2_profile_filter_advanced.category')">
          <div class="categories">
            <div
              :key="index"
              class="category"
              v-for="(category, index) in categoryList"
              @click="handlerCategory(category)"
              :class="{ 'is-selected': selectedCategory && selectedCategory.id === category.id }"
            >
              <modatta-icon :icon="category.icon" width="24"></modatta-icon>
              <div v-text="$t('offers_step2_profile_filter_advanced.' + category.name)"></div>
            </div>
          </div>
        </b-field>
      </div>

      <div class="filter" v-if="isShowProfile">
        <b-field v-bind:label="$t('offers_step2_profile_filter_advanced.profile')">
          <div class="modatta-country-select">
            <multiselect
              v-bind:placeholder="$t('offers_step2_profile_filter_advanced.choose_profile')"
              no-options="Please select a category above"
              selectLabel="ENTER to select"
              v-model="selectedProfile"
              openDirection="bottom"
              :allow-empty="false"
              :showLabels="false"
              :options="profileList"
              track-by="name"
              label="name"
            >
              <span class="caret" slot="caret">
                <modatta-icon icon="arrow-down" width="10"></modatta-icon>
              </span>
            </multiselect>
          </div>
        </b-field>
      </div>

      <div class="filter" v-if="isShowOperator">
        <b-field v-bind:label="$t('offers_step2_profile_filter_advanced.operator')">
          <modatta-radio-input
            :options="operatorList.map((operator) => operator.name)"
            :has-other="false"
            @radio-value="(radio) => (this.selectedOperator = radio)"
            :suggested-value="selectedOperator"
          ></modatta-radio-input>
        </b-field>
      </div>

      <div class="filter" v-if="isShowValue">
        <b-field v-bind:label="$t('offers_step2_profile_filter_advanced.value')">
          <div v-if="valueList.length > 0" class="modatta-country-select">
            <multiselect
              v-bind:placeholder="$t('offers_step2_profile_filter_advanced.choose_value')"
              selectLabel="ENTER to select"
              v-model="selectedValue"
              openDirection="bottom"
              :allow-empty="false"
              :showLabels="false"
              :options="valueList"
              track-by="name"
              label="name"
            >
              <span class="caret" slot="caret">
                <modatta-icon icon="arrow-down" width="10"></modatta-icon>
              </span>
            </multiselect>
          </div>
          <div v-if="valueList.length === 0" class="input-wrapper">
            <input
              v-model="selectedValue"
              type="text"
              :placeholder="$t('offers_step2_profile_filter_advanced.value')"
            />
          </div>
        </b-field>
      </div>

      <button class="button is-primary" :disabled="!isEnabledToSave" @click="salveFilter()">
        {{ $t('offers_step2_profile_filter.button_label') }}
      </button>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import Multiselect from 'vue-multiselect';
  import Filters from '../helpers/filters/filter-methods.js';

  export default {
    components: { Multiselect },
    data() {
      return {
        categoryList: [],
        selectedCategory: null,
        profileList: [],
        selectedProfile: null,
        isShowProfile: false,
        operatorList: [],
        selectedOperator: null,
        isShowOperator: false,
        valueList: [],
        selectedValue: null,
        isShowValue: false,
      };
    },
    mounted() {
      this.resetToInitialState();
      this.categoryList = [
        { id: 'FAM1', name: 'general', icon: 'profile-outline' },
        { id: 'FAM2', name: 'occupation', icon: 'suitcase-outline' },
        { id: 'FAM3', name: 'ownership', icon: 'target' },
        { id: 'FAM4', name: 'other', icon: 'map-pin' },
      ];
    },
    watch: {
      '$store.state.offer.profiles': function (newStateProfileList) {
        let profileList = newStateProfileList.map((p) => {
          return {
            id: p.id,
            name: this.$t(`labels.${p.id}`),
            oper_type: p.oper_type,
            value_type: p.value_type,
            order: p.order,
          };
        });
        profileList = Filters.excludeProfilesFromAdvancedFilters(profileList);
        profileList.sort(function (a, b) {
          return a.order - b.order;
        });
        this.profileList = profileList;
      },
      '$store.state.offer.operators': function (newStateOperatorList) {
        this.operatorList = newStateOperatorList.map((operator) => {
          return { id: operator.id, name: this.$t(`labels.${operator.id}`) };
        });
      },
      '$store.state.offer.values': function (newStateValuesList) {
        const profileCityListId = 'PRO1854';
        let valueList = newStateValuesList.map((value) => {
          return {
            id: value.id,
            value: value.value,
            order: value.order,
            name: this.selectedProfile.id === profileCityListId ? value.value : this.$t(`labels.${value.id}`),
          };
        });
        valueList.sort(function (a, b) {
          return a.order - b.order;
        });
        this.valueList = valueList;
      },
      selectedCategory: function (newSelectedCategory) {
        this.resetToCategoryState();
        this.isShowProfile = newSelectedCategory !== null;
      },
      selectedProfile: function (newSelectedProfile) {
        this.resetToProfileState();
        if (newSelectedProfile !== null) {
          this.getOperatorOptions(newSelectedProfile.oper_type);
          this.getValueOptions(newSelectedProfile.value_type);
          this.isShowOperator = true;
        } else {
          this.isShowOperator = false;
        }
      },
      selectedOperator: function (newSelectedOperator) {
        this.resetToOperatorState();
        if (this.selectedProfile && newSelectedOperator) {
          const operatorId = this.getOperatorId(newSelectedOperator);
          if (this.operatorHasValue(operatorId)) {
            this.isShowValue = true;
          } else {
            this.isShowValue = false;
          }
        } else {
          this.isShowValue = false;
        }
      },
    },
    computed: {
      isEnabledToSave() {
        let isComplete = this.selectedCategory !== null && this.selectedOperator !== null;
        if (this.isShowValue) {
          isComplete = this.selectedValue !== null;
        }
        return isComplete;
      },
    },
    methods: {
      ...mapActions(['getProfileFieldsWithinFamily', 'getOperatorOptions', 'getValueOptions']),
      handlerCategory(category) {
        const portugalCountryId = 'GEO1';
        this.getProfileFieldsWithinFamily({ familyId: category.id, countryId: portugalCountryId });
        category.isSelected = true;
        this.selectedCategory = { ...category };
      },
      operatorHasValue(operatorId) {
        const operadorNotValueIdList = ['OPE1', 'OPE4', 'OPE9', 'OPE15', 'OPE34', 'OPE35', 'OPE36', 'OPE37', 'OPE50'];
        return !operadorNotValueIdList.includes(operatorId);
      },
      salveFilter() {
        let newFilter = {
          profile_id: this.selectedProfile.id,
          profile: this.selectedProfile.name,
          operator_id: this.getOperatorId(this.selectedOperator),
          operator: this.selectedOperator,
          value_id: null,
          value: '0',
        };
        if (this.operatorHasValue(newFilter.operator_id)) {
          newFilter.value = this.valueList.length === 0 ? this.selectedValue : this.selectedValue.value;
          const profileCityListId = 'PRO1854';
          if (this.selectedProfile.id === profileCityListId) {
            newFilter.value_id = this.selectedValue.id;
          } else {
            newFilter.value_id = this.$te(`labels.` + this.selectedValue.id) ? this.selectedValue.id : null;
          }
        }

        Filters.upsert(newFilter, this.$parent.newOptions, this.$parent.filters);

        this.close();
      },
      getOperatorId(operatorName) {
        if (operatorName) {
          return this.operatorList.filter((operator) => operator.name === operatorName)[0].id;
        }
      },
      resetToInitialState() {
        this.selectedCategory = null;
        this.selectedProfile = null;
        this.selectedOperator = null;
        this.selectedValue = null;
      },
      resetToCategoryState() {
        this.selectedProfile = null;
        this.selectedOperator = null;
        this.selectedValue = null;
      },
      resetToProfileState() {
        this.selectedOperator = null;
        this.selectedValue = null;
      },
      resetToOperatorState() {
        this.selectedValue = null;
      },
      close() {
        this.resetToInitialState();
        this.$parent.isAdvancedOpen = false;
      },
    },
  };
</script>
