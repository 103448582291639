<template>
  <div id="app">
    <modatta-toast />
    <router-view />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
  import ModattaToast from './components/ModattaToast.vue';

  export default {
    components: { ModattaToast },
    watch: {
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Modatta';
        },
      },
    },
  };
</script>
