<template>
  <div class="status-message">
    <div class="illustration-wrapper" style="background-image: url('/svg/decorations/status-success.svg')"></div>
    <h2>{{ $t('offers.success.title') }}</h2>
    <h3>{{ $t('offers.success.subtitle') }}</h3>
    <p>{{ $t('offers.success.description') }}</p>
    <button class="button is-primary" @click="goToOffer()">{{ $t('offers.success.action') }}</button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        browserLang: '',
      };
    },
    mounted() {},
    methods: {
      goToOffer() {
        window.location = '/offers';
      },
    },
  };
</script>
