const tiList = {
  GEO1: 'Europe/Lisbon',
  GEO2: 'Europe/Madrid',
  GEO3: 'Europe/London',
  GEO32: 'America/Sao_Paulo',
};

const tz2i18n = (tz) => {
  const revList = Object.entries(tiList).reduce((p, [k, v]) => ({ ...p, [v]: k }), {});
  return revList[tz];
};

const i18n2tz = (i18n) => {
  return tiList[i18n];
};

export { tz2i18n, i18n2tz };
