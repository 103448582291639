import headers from './headers';

// READ
async function getOffers() {
  const URL = `${process.env.VUE_APP_API_URL}offers`;
  return await axios.get(URL, { headers });
}
async function getDetailedOffers() {
  const URL = `${process.env.VUE_APP_API_URL}offers/accounts/details`;
  return await axios.get(URL, { headers });
}
async function getOffer(id) {
  const URL = `${process.env.VUE_APP_API_URL}offers/${id}/detail`;
  return await axios.get(URL, { headers });
}
// CREATE
async function upsertOffer(data) {
  const URL = `${process.env.VUE_APP_API_URL}offers${data.id != null ? `/${data.id}` : ''}`;
  return await axios.post(URL, data.body, { headers });
}

async function upsertOfferFormData(data) {
  const URL = `${process.env.VUE_APP_API_URL}offers${`/${data.id}`}`;
  return await axios.post(URL, data.payload, {
    headers: { ...headers, 'Content-Type': 'multipart/form-data' },
  });
}

// VALIDATE
async function validatePostback(data) {
  const URL = `${process.env.VUE_APP_API_URL}offers/validations/postback`;
  return await axios.post(URL, data, { headers });
}
// UPDATE
async function updateBannerFile(data) {
  const URL = `${process.env.VUE_APP_API_URL}offers/${data.id}/banner`;
  return await axios.post(URL, data.body, {
    headers: { ...headers, 'Content-Type': 'multipart/form-data' },
  });
}
async function updateOfferStatus(data) {
  const URL = `${process.env.VUE_APP_API_URL}offers/${data.id}/setstatus`;
  return await axios.put(URL, data.data, { headers });
}
async function updateOffer(data) {
  const URL = `${process.env.VUE_APP_API_URL}offers/${data.id}/change`;
  return await axios.put(URL, data.data, { headers });
}
// DELETE
async function deleteOffer(data) {
  const URL = `${process.env.VUE_APP_API_URL}offers/${data.id}`;
  return await axios.delete(URL, data, { headers });
}

// NEW OFFER
async function getCities() {
  const URL = `${process.env.VUE_APP_API_URL}offers/filters/values?value_type=type_city`;
  return await axios.get(URL, { headers });
}
async function getStates() {
  const URL = `${process.env.VUE_APP_API_URL}offers/filters/values?value_type=type_state`;
  return await axios.get(URL, { headers });
}
async function getInterests() {
  const URL = `${process.env.VUE_APP_API_URL}offers/filters/insights`;
  return await axios.get(URL, { headers });
}
async function getProfileFieldsWithinFamily(data) {
  const URL = `${process.env.VUE_APP_API_URL}offers/filters/profiles?family_id=${data.familyId}`;
  return await axios.get(URL, { headers });
}
async function getOperatorOptions(type) {
  const URL = `${process.env.VUE_APP_API_URL}offers/filters/operators?oper_type=${type}`;
  return await axios.get(URL, { headers });
}
async function getValueOptions(type) {
  const URL = `${process.env.VUE_APP_API_URL}offers/filters/values?value_type=${type}`;
  return await axios.get(URL, { headers });
}

// DASHBOARD
async function getAccumulatedCost(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/invested?offer=${id}`;
  return await axios.get(URL, { headers });
}
async function getOfferObjective(id) {
  const URL = `${process.env.VUE_APP_API_URL}offers/${id}/detail`;
  return await axios.get(URL, { headers });
}
async function getOfferDelivered(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/delivered?offer=${id}`;
  return await axios.get(URL, { headers });
}
async function getDeliveredDecisionsData(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/delivered/list?offer=${id}&format=Y-m-d`;
  return await axios.get(URL, { headers });
}
async function getDecisionsData(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/decisions/list?offer=${id}`;
  return await axios.get(URL, { headers });
}
async function getOfferAcceptedData(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/decisions/completed?offer=${id}`;
  return await axios.get(URL, { headers });
}
async function getOfferDeclinedData(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/decisions/declined?offer=${id}`;
  return await axios.get(URL, { headers });
}
async function getOfferAnswers(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/answers?offer=${id}`;
  return await axios.get(URL, { headers });
}

export default {
  getOffers: () => getOffers(),
  getOffer: (id) => getOffer(id),
  upsertOffer: (data) => upsertOffer(data),
  upsertOfferFormData: (data) => upsertOfferFormData(data),
  deleteOffer: (data) => deleteOffer(data),
  updateOffer: (data) => updateOffer(data),
  getDetailedOffers: () => getDetailedOffers(),
  updateBannerFile: (data) => updateBannerFile(data),
  updateOfferStatus: (data) => updateOfferStatus(data),

  // NEW OFFER
  getCities: (id) => getCities(id),
  getStates: (id) => getStates(id),
  getInterests: () => getInterests(),
  getValueOptions: (data) => getValueOptions(data),
  validatePostback: (data) => validatePostback(data),
  getOperatorOptions: (type) => getOperatorOptions(type),
  getProfileFieldsWithinFamily: (data) => getProfileFieldsWithinFamily(data),

  // DASHBOARD
  getOfferAnswers: (id) => getOfferAnswers(id),
  getDecisionsData: (id) => getDecisionsData(id),
  getOfferObjective: (id) => getOfferObjective(id),
  getOfferDelivered: (id) => getOfferDelivered(id),
  getAccumulatedCost: (id) => getAccumulatedCost(id),
  getOfferAcceptedData: (id) => getOfferAcceptedData(id),
  getOfferDeclinedData: (id) => getOfferDeclinedData(id),
  getDeliveredDecisionsData: (id) => getDeliveredDecisionsData(id),
};
