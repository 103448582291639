<template>
  <div class="modatta-side-modal-scroll-middle-area modatta-side-modal-create-audience">
    <div class="sidenav-container">
      <div v-if="offers.length > 0" class="basic-filters">
        <div class="filter">
          <b-field label="Offer">
            <div class="modatta-country-select">
              <multiselect
                :custom-label="offerNameWithId"
                v-bind:placeholder="$t('offers_step2_offer_filter.choose_offer')"
                selectLabel="ENTER to select"
                openDirection="bottom"
                v-model="selectedOffer"
                :allow-empty="false"
                :showLabels="false"
                :options="offers"
                track-by="name"
                label="name"
              >
                <span class="caret" slot="caret">
                  <modatta-icon icon="arrow-down" width="10"></modatta-icon>
                </span>
              </multiselect>
            </div>
          </b-field>
        </div>
        <div class="filter">
          <b-field v-bind:label="$t('offers_step2_offer_filter.status')">
            <modatta-object-radio-input
              :options="status"
              prop="name"
              :has-to-translate="true"
              @radio-value="(radio) => (selectedStatus = radio)"
              :suggested-value="selectedStatus"
            ></modatta-object-radio-input>
          </b-field>
        </div>
        <div v-if="selectedStatus.id === 'OPE25'" class="filter">
          <b-field label="Sub status">
            <modatta-object-radio-input
              :options="completedSubStatus"
              prop="name"
              :has-to-translate="true"
              @radio-value="(radio) => (selectedCompletedSubStatus = radio)"
              :suggested-value="selectedCompletedSubStatus"
            ></modatta-object-radio-input>
          </b-field>
        </div>
        <div v-else-if="selectedStatus.id === 'OPE26'" class="filter">
          <b-field label="Sub status">
            <modatta-object-radio-input
              :options="declinedSubStatus"
              prop="name"
              :has-to-translate="true"
              @radio-value="(radio) => (selectedDeclinedSubStatus = radio)"
              :suggested-value="selectedDeclinedSubStatus"
            ></modatta-object-radio-input>
          </b-field>
        </div>
        <button
          class="button is-info"
          @click="addFilter()"
          :disabled="!allFieldsCompleted"
          style="margin-top: 0; min-width: 100px; height: 42px"
        >
          {{ $t('offers_step2.button_add_filter') }}
        </button>
      </div>
      <div v-else class="filter">
        <b-field v-bind:label="$t('offers_step2.nothing_here')">
          <div class="empty-state">{{ $t('offers_step2_offer_filter.no_poll') }}</div>
        </b-field>
      </div>
      <div v-if="filters.length > 0" class="selected-filters">
        <div class="filters-wrapper">
          <div class="filter-item" v-for="(filter, ind) in filters" :key="ind">
            <div class="concatenation">
              {{ $t(`labels.${filter.operator_id}`) }} {{ $t('filters.details.offer_id') }}:
              {{ shortenOfferId(filter.profile_id) }}
            </div>
            <button class="button" @click="deleteFilter(ind)">
              <modatta-icon icon="times" width="12"></modatta-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button
        class="button is-primary"
        type="Submit"
        :disabled="filters.length < 1 || isLoading"
        @click="createFilters()"
      >
        <template v-if="!isLoading">{{ $t('offers_step2.create_filter') }}</template>
        <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
      </button>
      <button class="button is-text" @click="$parent.closeSideModal()" :disabled="isLoading">
        <template v-if="!isLoading">{{ $t('offers_step2.cancel_filter') }}</template>
        <template v-else>{{ $t('offers_step2.loading_filter') }}</template>
      </button>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import setStatusByOffer from '../helpers/filters/set-status-filter-by-offer';
  import Multiselect from 'vue-multiselect';
  import Utilities from '../helpers/utilities.js';

  export default {
    props: ['options'],
    components: { Multiselect },
    data() {
      return {
        isLoading: false,

        newOptions: [...this.options.filters],

        selectedOffer: null,

        status: setStatusByOffer.setStatus(),
        selectedStatus: { id: null },

        completedSubStatus: setStatusByOffer.setCompletedSubStatus(),
        selectedCompletedSubStatus: { id: null },

        declinedSubStatus: setStatusByOffer.setDeclinedSubStatus(),
        selectedDeclinedSubStatus: { id: null },
        filter: {
          format: {},
        },
      };
    },
    computed: {
      filters() {
        return this.newOptions;
      },
      allFieldsCompleted() {
        if (this.selectedStatus.id === 'OPE25') {
          return this.selectedOffer != null && this.selectedOffer != '' && this.selectedCompletedSubStatus.id != null;
        } else if (this.selectedStatus.id === 'OPE26') {
          return this.selectedOffer != null && this.selectedOffer != '' && this.selectedDeclinedSubStatus.id != null;
        } else if (this.selectedStatus.id != null) {
          return this.selectedOffer != null && this.selectedOffer != '';
        } else {
          return false;
        }
      },
      offers() {
        return this.$store.state.offer.offers.filter((o) => o.status_id != 'OST1');
      },
    },
    methods: {
      ...mapMutations(['upsertProfileNewOfferFilters']),

      offerNameWithId({ name, id }) {
        return `ID ${Utilities.trimStringCenter(id, 3, '...')}: ${name}`;
      },
      createFilters() {
        const payload = { filters: this.filters, id: 'offers', targetId: this.options.targetGroupId };
        this.upsertProfileNewOfferFilters(payload);
        this.$parent.closeSideModal();
      },

      addFilter() {
        let filter = this.newOptions.find((f) => f.profile_id === this.selectedOffer.id);
        if (filter) {
          filter.operator = this.addOperatorId();
          filter.value = true;
          return;
        }
        this.newOptions.push({
          profile: this.selectedOffer.name,
          profile_id: this.selectedOffer.id,
          operator: this.addOperatorLabel(),
          operator_id: this.addOperatorId(),
          value: true,
          value_id: true,
          category: 'offers',
        });

        this.resetFilters();
      },
      addOperatorLabel() {
        if (this.selectedStatus.id === 'OPE25') {
          return this.$t(`offers_step2_offer_filter.${this.selectedCompletedSubStatus.name}`);
        } else if (this.selectedStatus.id === 'OPE26') {
          return this.$t(`offers_step2_offer_filter.${this.selectedDeclinedSubStatus.name}`);
        } else {
          return this.$t(`offers_step2_offer_filter.${this.selectedStatus.name}`);
        }
      },
      addOperatorId() {
        if (this.selectedStatus.id === 'OPE25') {
          return this.selectedCompletedSubStatus.id;
        } else if (this.selectedStatus.id === 'OPE26') {
          return this.selectedDeclinedSubStatus.id;
        } else {
          return this.selectedStatus.id;
        }
      },
      deleteFilter(i) {
        this.newOptions.splice(i, 1);
      },
      resetFilters() {
        this.selectedOffer = null;
        this.selectedOperator = null;
        this.selectedStatus = { id: null };
        this.selectedDeclinedSubStatus = { id: null };
        this.selectedCompletedSubStatus = { id: null };
      },
      // FIX: Move to Utilities
      shortenOfferId(offerid) {
        return `${Utilities.trimStringCenter(offerid, 3, '...')}`;
      },
    },
  };
</script>
