<template>
  <div class="pix-payment">
    <p class="text">{{ $t('forms.payment.pix.title') }}</p>
    <div class="input-wrapper amount">
      <input
        max="9"
        class="input"
        type="number"
        v-model="amount"
        @blur="handleBlur"
        :placeholder="$t('forms.payment.pix.amount.placeholder')"
      />
    </div>
    <modatta-radio-input
      :spacing="'sm'"
      class="amounts"
      :options="amounts"
      :has-other="false"
      @radio-value="(radio) => (this.amount = radio)"
    ></modatta-radio-input>
    <hr />
    <button class="button is-primary" @click="handleClick()" :disabled="!amount || isNaN(amount) || amount > 999999999">
      <template>{{ $t('forms.payment.pix.generate') }}</template>
    </button>

    <div class="qr" v-if="amount && pixCode">
      <p>{{ $t('forms.payment.pix.scan') }}</p>
      <img :src="qrCode" />
      <span>{{ $t('forms.payment.pix.copy') }}</span>
      <div>
        <textarea readonly :value="pixCode" @click="copy"></textarea>
        <button class="code button is-secondary mx-auto" @click="copy">{{ $t('forms.payment.pix.copybtn') }}</button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  .pix-payment {
    textarea {
      width: 300px;
      height: 100px;
      overflow: hidden;
      border: 0;
      resize: none !important;
    }

    img {
      width: 222px;
      height: 222px;
    }

    .mx-auto {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .text {
      margin-bottom: 23px;
      text-align: left;
      font-family: 'Silka Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #0a1735;
    }

    .input-wrapper.amount {
      margin-bottom: 30px;
    }

    .input.amounts {
      margin-bottom: 30px;
    }

    modatta-radio-input {
      ::v-deep {
        .button {
          padding-left: 0.5rem !important;
          padding-right: 0.5rem !important;
        }
      }
    }

    .code {
      position: relative;
      padding-right: 40px;
      margin-top: 18px;

      &::before {
        position: absolute;
        right: 13px;
        top: 50%;
        content: '';
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background-image: url('/svg/Copy.svg');
        background-size: 24px;
        background-position: 50%;
        background-repeat: no-repeat;
      }
    }

    .qr {
      padding-top: 25px;
      padding-bottom: 25px;
      text-align: center;

      p {
        font-family: 'Silka Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: #0a1735;

        strong {
          font-family: 'Silka Bold';
        }
      }

      span {
        font-family: 'Silka Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #0a1735;
        padding-bottom: 15px;
        display: block;
      }
    }
  }
</style>
<script>
  import api from '../api/budget_api';
  import { Pix, Img } from '../helpers/pix';

  export default {
    props: ['options'],
    data() {
      return {
        payment: {
          transactionId: null,
          amount: null,
          message: this.$store.state.user.user.email,
          name: this.$store.state.user.user.email.substring(0, 24),
        },
        amount: '',
        suggestedAmounts: [2500, 5000, 10000, 25000, 50000, 100000],
        pixCode: null,
        qrCode: null,
      };
    },
    watch: {
      pixCode(newPix) {
        Img(newPix).then((data) => (this.qrCode = data));
      },
    },
    computed: {
      amounts() {
        //'R$' + amount
        return this.suggestedAmounts.map((amount) => amount);
      },
    },
    mounted() {},
    methods: {
      copy() {
        document.querySelector('textarea').select();
        document.execCommand('copy');
      },
      handleClick() {
        if (this.amount && !isNaN(this.amount) && this.amount <= 999999999) {
          this.payment.amount = this.amount;
          this.genCode();
        }
      },
      handleBlur() {
        if (isNaN(this.amount)) {
          this.amount = '';
        } else {
          let val = this.amount;
          val = this.formatCurrencyAmount(val);
          this.amount = `${val}`;
        }
      },
      formatCurrencyAmount(amount) {
        return amount;
      },
      async genCode() {
        try {
          const res = await api.getPixTransaction({ amount: this.payment.amount });
          this.payment.transactionId = res.data.transactionId;
          this.pixCode = Pix(this.payment);
        } catch (error) {
          console.log('genCode -> error ->', error);
        }
      },
    },
  };
</script>
