var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tabGroup",staticClass:"modatta-snap-tabs"},[_c('div',{staticClass:"snap-tabs-header"},[_c('div',{ref:"tabNav",staticClass:"snap-tabs-nav"},[_c('span',{staticClass:"nav-indicator",style:({ left: _vm.indicatorOffset, width: _vm.indicatorWidth })}),_vm._l((_vm.tabs),function(tab){return _c('div',[_c('a',{key:tab.id,ref:"tabNavItems",refInFor:true,class:{ active: tab.isActive },attrs:{"href":`#${tab.id}`,"active":tab.isActive},on:{"click":(e) => {
              _vm.activateTab(tab);
              _vm.handleNavigation(e);
            }}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.' + tab.name))+" ")])])})],2),(_vm.activeTab.id === 'brands')?_c('button',{staticClass:"button is-secondary snap-tabs-action",on:{"click":function($event){_vm.$parent.selectOption('brand', _vm.$t('brands_edit.title'), { type: 'create', disabled: false })}}},[_vm._v(" "+_vm._s(_vm.$t('brands.button_label'))+" ")]):_c('button',{staticClass:"button is-secondary snap-tabs-action",on:{"click":function($event){_vm.$parent.selectOption('campaign', _vm.$t('offers_step0.title'))}}},[_vm._v(" "+_vm._s(_vm.$t('offers.button_label'))+" ")])]),(_vm.isDataLoaded)?_c('div',{staticClass:"snap-tabs-body"},[_c('div',{ref:"tabSection",staticClass:"snap-tabs section-body"},_vm._l(([_vm.activeTab]),function(tab){return _c('div',{key:tab.id,staticClass:"snap-tab",class:`${tab.id}-table`},[(tab.id === 'brands')?[_c('modatta-table',{attrs:{"data":tab.data,"show-header":false,"is-offer":tab.isOffer}})]:_vm._e(),(tab.id === 'offers')?[_c('modatta-table',{attrs:{"data":tab.data,"show-header":false,"is-offer":tab.isOffer}})]:_vm._e(),(tab.id === 'offer-type')?_vm._l((tab.data),function(offer,index){return _c('div',{key:index,staticClass:"offer-type"},[_c('div',{staticClass:"icon-wrapper"},[_c('modatta-icon',{attrs:{"icon":offer.icon,"width":"1","height":"1","rem":true}})],1),_c('div',{staticClass:"progressbar-container"},[_c('div',{staticClass:"type"},[_vm._v(_vm._s(_vm.$t('labels.' + offer.type)))]),_c('div',{staticClass:"modatta-progress-bar"},[_c('div',{staticClass:"progressbar",class:{ 'left-side-squared': ((offer.rejected / offer.total) * 100 || 0) !== 0 },style:({
                    width: `${(offer.completed / offer.total) * 100 || 0}%`,
                    left: `${(offer.rejected / offer.total) * 100 || 0}%`,
                  })}),_c('div',{staticClass:"progressbar rejected",class:{ 'right-side-squared': ((offer.completed / offer.total) * 100 || 0) !== 0 },style:({ width: `${(offer.rejected / offer.total) * 100 || 0}%` })})])]),_c('div',{staticClass:"stats"},[_c('span',{staticClass:"rejected"},[_vm._v(_vm._s(_vm.getRejectedPercentage(offer))+"%")]),_c('span',{staticClass:"completed"},[_vm._v(_vm._s(_vm.getCompletedPercentage(offer))+"%")])])])}):_vm._e()],2)}),0)]):_c('div',{staticClass:"snap-tabs-body",staticStyle:{"padding":"1.25rem"}},[_c('b-skeleton',{attrs:{"animated":true,"height":"30px"}}),_c('b-skeleton',{attrs:{"animated":true,"height":"30px"}}),_c('b-skeleton',{attrs:{"animated":true,"height":"30px"}}),_c('b-skeleton',{attrs:{"animated":true,"height":"30px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }