import headers from './headers';

async function getBrands() {
  const URL = `${process.env.VUE_APP_API_URL}brands`;
  return await axios.get(URL, { headers });
}
async function getBrand(id) {
  const URL = `${process.env.VUE_APP_API_URL}brands/${id}`;
  return await axios.get(URL, { headers });
}
async function createBrand(data) {
  const URL = `${process.env.VUE_APP_API_URL}brands`;
  return await axios.post(URL, data, {
    headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
  });
}
async function updateBrand(data) {
  const URL = `${process.env.VUE_APP_API_URL}brands/${data.id}`;
  return await axios.post(URL, data.body, {
    headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
  });
}
async function archiveBrand(data) {
  const URL = `${process.env.VUE_APP_API_URL}brands/${data.id}/setstatus`;
  return await axios.post(URL, data, { headers });
}
async function deleteBrand(data) {
  const URL = `${process.env.VUE_APP_API_URL}brands/${data.id}`;
  return await axios.delete(URL, data, { headers });
}

export default {
  getBrands: () => getBrands(),
  getBrand: (id) => getBrand(id),
  createBrand: (data) => createBrand(data),
  updateBrand: (data) => updateBrand(data),
  deleteBrand: (data) => deleteBrand(data),
  archiveBrand: (data) => archiveBrand(data),
};
