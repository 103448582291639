<template>
  <div class="authentication">
    <div class="form-container reset-password success">
      <div class="clouds-left">
        <img src="/svg/decorations/reset-left-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="clouds-right">
        <img src="/svg/decorations/reset-right-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="tree">
        <img src="/svg/decorations/tree.svg" alt="Illustration representing a tree" />
      </div>
      <div class="illustrations">
        <div class="background">
          <img
            src="/svg/decorations/reset-password-success-background.svg"
            alt="Illustration representing mountains, plants and sky"
          />
        </div>
        <div class="paper-plane"></div>
      </div>
      <div class="form">
        <!-- Bugs BR: i18n for registration success page-->
        <h1>{{ $t('signup.success.title') }}</h1>
        <p>{{ $t('signup.success.description') }}</p>
        <router-link to="/login" class="button is-primary" type="submit">OK</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        browserLang: '',
      };
    },
    mounted() {
      this.browserLang = navigator.language;
      switch (this.browserLang) {
        case 'pt':
          this.selectedLanguageId = 'LNG1';
          break;
        case 'pt-BR':
          this.selectedLanguageId = 'LNG5';
          break;
        default:
          this.selectedLanguageId = 'LNG3';
          break;
      }
    },
  };
</script>
