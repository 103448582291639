import i18n from '../../i18n.js';
const short = require('short-uuid');

/**
 *
 * # Explanation of Data Structure for Filters:
 * [{},{}, ... {}] => Group > options[0-3] (categories) > filters[0-999]
 *
 * The following data structure represents a hierarchical relationship between:
 * target groups, groups, filters, and categories. These levels are as follows:
 *
 * - Target Groups - This is an object that has information about multiple group objects.
 * It contains an ID, name, isOpen, and options array with group objects inside it.
 * e.g. {id: ..., name: ..., isOpen: ..., options: [ ... (groups) ... ]};
 * i.e. One given target group object will contain as many groups as possible
 *
 * - Group- Groups are objects that have an ID, name, icon, and filters array.
 * The filter array contains 4 categories Profile, Interest, Offers, and Question.
 * e.g. each: { id: '<ID>', name: <NAME>, icon: <ICON>, filters: [] },
 *
 * - Categories - Categories are Profile, Interest, Offers, and Questions.
 * Each of these categories has an array of filters under them that need to be applied.
 * e.g. options: [ { ..., filters:[] }, { ..., filters:[] }, { ..., filters:[] }, { ..., filters:[] } ]
 *
 * - Filters - Filters are an array of objects that consist of
 * profile_id, operator_id, value, value_id, category, and group.
 * All these six properties define the filter you want to apply.
 *
 * Each target group can have many groups under it, each containing four categories with
 * many filters under each category. By using this data structure, we can easily manage
 * the data and use it for filtering or searching purposes.
 *
 */

let profilesWithMultipleFilters = ['PRO7'];

let excludedProfilesFromAdvancedFilters = [];

/**
 *  function upsert() checks the existence of the given profile in the profile array and
 *  either pushes it or updates it accordingly.
 */

function upsert(data, r, filters = r) {
  const index = filters.findIndex(
    (f) => f.profile_id === data.profile_id && !profilesWithMultipleFilters.includes(data.profile_id),
  );
  if (index !== -1) {
    Object.assign(filters[index], data);
  } else {
    r.push(data);
  }
  return filters[index];
}

/**
 *
 * Helper functions
 *
 */

// Consider shortening fn name: exclProfFrAdvFilters(...)
function excludeProfilesFromAdvancedFilters(arr, key = 'id', values = excludedProfilesFromAdvancedFilters) {
  return removeItemByKeys(arr, key, values);
}

/**
 * will iterate through the arr and keep only those objects that pass the provided function.
 * As an end result, an new array is returned which contains all objects except for the ones with the match.
 * # e.g.:
 * const users = [ { name: "Alice", age: 25, }, { name: "Bob", age: 30, }, { name: "Charlie", age: 35, }, ];
 */

// const updatedUsers = removeItemByKey(users, "name", "Bob");
function removeItemByKey(arr, key, value) {
  return arr.filter((obj) => obj[key] !== value);
}

// const newUsers = removeItemByKeys(users, 'age', [30, 25]);
function removeItemByKeys(arr, key, values) {
  return arr.filter((obj) => !values.includes(obj[key]));
}

/**
 *
 * Helper functions
 *
 */

/**
 * takes a number age as an input and returns the birth year of an individual corresponding to that age.
 * # e.g.
 * const age = 30;
 * const birthYear = getBirthYear(age);
 * console.log('Birth Year:', birthYear); // logs "Birth Year: 1991"
 */

function getBirthYear(age) {
  const currentYear = new Date().getFullYear();
  const birthYear = currentYear - age;
  return birthYear;
}

/**
 * takes a birth year as input and calculates the age based on the current year.
 * # e.g.:
 * const birthYear = 1990;
 * const age = calculateAge(birthYear);
 * console.log(age); // Output: 31
 */

function getAge(birthYear) {
  const currentYear = new Date().getFullYear();
  return currentYear - birthYear;
}

// Building the structure and feeding with the groups
function setTargetGroups(tg) {
  let g = [];
  if (tg.length > 0) {
    g = buildTargetGroups(tg);
  } else {
    g = [filterGroups(short.generate())];
  }
  return g;
}

// Building the structure for each group (within target groups)
function filterGroups(name = null) {
  const [profileId, interestId, offerId, questionId, locationId, recommendationId] = [
    'profile',
    'interests',
    'offers',
    'question',
    'location',
    'recommendation',
  ];
  const [profileName, interestName, offerName, questionName, locationName, recommendationName] = [
    i18n.t('offers_step2.table_section_1_title'),
    i18n.t('offers_step2.table_section_2_title'),
    i18n.t('offers_step2.table_section_3_title'),
    i18n.t('offers_step2.table_section_4_title'),
  ];
  // i18n.t('offers_step2.table_section_5_title'),
  // i18n.t('offers_step2.table_section_6_title')];
  const [profileIcon, interestIcon, offerIcon, questionIcon, locationIcon, recommendationIcon] = [
    'profile-outline',
    'target',
    'gift',
    'help-circle',
    'help-circle',
    'help-circle',
  ];
  return {
    id: name,
    name: i18n.t('offers_step2.table_subtitle'),
    isOpen: true,
    options: [
      groupObj(profileId, profileName, profileIcon), // Profile
      // groupObj(interestId, interestName, interestIcon), // Interest
      groupObj(offerId, offerName, offerIcon), // By Offer
      groupObj(questionId, questionName, questionIcon), // By Question
      // groupObj(locationId, locationName, locationIcon), // By Location
      // groupObj(recommendationId, recommendationName, recommendationIcon) // By Recommendation
    ],
  };
}

function buildTargetGroups(tg) {
  let returnedTargetGroups = [];
  tg.forEach((group) => {
    let groupObj = filterGroups(null);
    group.forEach((filter) => {
      groupObj.id = filter.group;
      let gOptions = filtersByCatName(filter.category, groupObj);
      if (gOptions) {
        pushFilterToCategory(gOptions, filter);
      }
    });
    returnedTargetGroups.push(groupObj);
  });
  return returnedTargetGroups;
}

// Getting the filter category position by it's name, (within each group array) for each Category
function filtersByCatName(name, g) {
  switch (name) {
    // TODO: AT THE MOMENT WE ACTIVATE INTERESTS DONT FORGET TO CHANGE the INDEX FOR: g.options[0]
    case 'profile':
      return g.options[0].filters;
    case 'offers':
      return g.options[1].filters;
    case 'question':
      return g.options[2].filters;
    default:
      return null;
  }
}

function pushFilterToCategory(array, filter) {
  let ope = i18n.t('labels.' + filter.operator_id);
  array.push({
    group: filter.group,
    value: filter.value,
    value_id: filter.value_id,
    category: filter.category,
    profile: filter.profile,
    profile_id: filter.profile_id,
    operator: ope,
    operator_id: filter.operator_id,
  });
}

function groupObj(id, name, icon, filters = []) {
  return { id: id, name: name, icon: icon, filters: [] };
}

export default {
  setTargetGroups: (tg) => setTargetGroups(tg),
  upsert: (data, r) => upsert(data, r),
  getBirthYear: (age) => getBirthYear(age),
  getAge: (birthyear) => getAge(birthyear),
  removeItemByKey: (arr, key, value) => removeItemByKey(arr, key, value),
  excludeProfilesFromAdvancedFilters: (arr) => excludeProfilesFromAdvancedFilters(arr),
};
