import i18n from '../i18n';
import api from '../api/user_api';
import SetUserLanguage from '../helpers/set-user-language';

const state = {
  token: null,
  user: null,
  userIntegration: null,
};

//TODO: Remover dos lugares que estão usando.
const getters = {};

const actions = {
  // Before Auth
  async isEmailAvailable(_, data) {
    return await api.isEmailAvailable(data);
  },

  sendRecoverPasswordLink(_, data) {
    return api.sendRecoverPasswordLink(data);
  },

  async setNewPassword(_, data) {
    return await api.setNewPassword(data);
  },

  async setForgotPassword(_, data) {
    return await api.setForgotPassword(data);
  },

  // CRUD
  async createUser(_, data) {
    return await api.createUser(data);
  },

  async authenticateUser(_, data) {
    return await api.authenticateUser(data);
  },

  async updateUser({ commit }, data) {
    return await api.updateUser(data).then((response) => commit('updateUser', response.data.result));
  },

  async attemptAuthentication({ commit, state }, token) {
    if (token) {
      commit('setToken', token);
    }
    if (!state.token) return;

    return await api
      .attemptAuthentication()
      .then((response) => commit('setUser', response.data.result))
      .catch(() => {
        commit('setToken', null);
        commit('setUser', null);
        window.location.href = '/';
      });
  },

  async getBalance({ commit }) {
    return await api
      .getBalance()
      .then((response) => commit('setBalance', response.data))
      .catch((e) => console.log('getBalance -> error -> ', e));
  },

  async getCredentials({ commit }) {
    return await api
      .getCredentials()
      .then((response) => commit('setCredentials', response.data))
      .catch((e) => console.log('getCredentials -> error -> ', e));
  },

  async setCredentials(_, data) {
    return await api.setCredentials(data);
  },

  signOut({ commit }) {
    return api.signOut().then(() => {
      commit('setToken', null);
      commit('setUser', null);
    });
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setUser(state, user) {
    if (user === null) {
      state.user = null;
      return;
    }
    user.balance = { value: '', currency: '' };
    state.user = user;
    i18n.locale = SetUserLanguage(user.lang);
  },
  setBalance(state, balance) {
    state.user.balance = balance.result;
  },
  setCredentials(state, credentials) {
    state.user.credentials = credentials.result;
  },
  updateUser(state, user) {
    user.balance = state.user.balance;
    state.user = user;
  },
  setConfigIntegration(state, integration) {
    state.userIntegration = integration;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
