<template>
  <div class="scroll-helper">
    <div class="modatta-dashboard-funnel component">
      <modatta-metric-card
        :title="card.title"
        :media="card.media"
        :layout="card.layout"
        :detail="card.detail"
        :ref="`card-${index}`"
        :is-disabled="card.isDisabled"
        v-for="(card, index) in items"
        :key="index"
        :tooltip-label="`${$t('dashboard.' + card.tooltip)}`"
        :tooltip-position="'is-bottom'"
        :body="$te('dashboard.' + card.body) ? $t('dashboard.' + card.body) : card.body"
      ></modatta-metric-card>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    props: ['id', 'type', 'delivered'],
    data() {
      return {
        data: null,
        items: [
          {
            tooltip: 'delivered_tooltip',
            body: 'delivered_counter_label',
            title: this.delivered,
            media: { type: 'icon', source: 'send', color: '#7494EA', hasShadow: true },
          },
          {
            tooltip: 'accepted_tooltip',
            layout: 'horizontal',
            detail: { percentageColor: '#19D2B4', background: '#DDF8F4', value: '' },
            body: 'accepted_counter_label',
            title: null,
            media: { type: 'icon', source: 'thumbs-up', color: '#19D2B4', hasShadow: true },
          },
          {
            tooltip: 'declined_tooltip',
            layout: 'horizontal',
            detail: { percentageColor: '#748EC3', background: '#D9E1F2', value: '' },
            body: 'declined_counter_label',
            title: null,
            isDisabled: true,
            media: { type: 'icon', source: 'close', color: '#BBC8E2', hasShadow: true },
          },
          {
            tooltip: 'validated_tooltip',
            layout: 'horizontal',
            detail: { percentageColor: '#19D2B4', background: '#DDF8F4', value: '' },
            body: 'validated_counter_label',
            title: null,
            media: { type: 'icon', source: 'check', color: '#19D2B4', hasShadow: true },
          },
          {
            tooltip: 'expired_tooltip',
            layout: 'horizontal',
            detail: { percentageColor: '#748EC3', background: '#D9E1F2', value: '' },
            body: 'expired_counter_label',
            title: null,
            isDisabled: true,
            media: { type: 'icon', source: 'hourglass', color: '#BBC8E2', hasShadow: true },
          },
          {
            tooltip: 'evaluated_tooltip',
            detail: { percentageColor: '#19D2B4', background: '#DDF8F4', value: '' },
            body: 'evaluated_counter_label',
            title: null,
            media: { type: 'icon', source: 'star', color: '#19D2B4', hasShadow: true },
          },
        ],
      };
    },
    mounted() {
      // NPS: Mounted: Mounted
      this.setFunnelData();
    },
    computed: {
      ...mapState(['funnelData', 'brandFunnelData', 'offerFunnelData']),
    },
    watch: {
      delivered(newVal) {
        this.$set(this.items[0], 'title', newVal);
        for (let index = 0; index < this.items.length; index++) {
          if (index === 0) continue;
          this.setPercentage(this.items[index].title, index);
        }
      },
    },
    methods: {
      ...mapActions(['getFunnelData']),

      async setFunnelData() {
        const payload = { id: this.id, type: this.type };
        const result = await this.getFunnelData(payload);
        this.data = result.data;
        this.setItemsValues(result.data);
      },
      setItemsValues(data) {
        Object.keys(data).forEach((key) => {
          // NPS 1) Formats data with this.$set() => data.accepted/declined/etc.
          switch (key) {
            case 'accepted':
              this.$set(this.items[1], 'title', data.accepted);
              this.setPercentage(data.accepted, 1);
              break;
            case 'declined':
              this.$set(this.items[2], 'title', data.declined);
              this.setPercentage(data.declined, 2);
              break;
            case 'validated':
              this.$set(this.items[3], 'title', data.validated);
              this.setPercentage(data.validated, 3);
              break;
            case 'expired':
              this.$set(this.items[4], 'title', data.expired);
              this.setPercentage(data.expired, 4);
              break;
            case 'evaluated':
              this.$set(this.items[5], 'title', data.evaluated);
              this.setPercentage(data.evaluated, 5);
              break;
          }
        });
      },
      setPercentage(value, i) {
        if (this.delivered == null) return;
        const detailValue = Math.round((value / this.delivered) * 100) || 0;
        this.$set(this.items[i].detail, 'value', isFinite(detailValue) ? `${detailValue}%` : '0%');
      },
    },
  };
</script>

<style lang="scss">
  .modatta-dashboard-funnel.component {
    @media screen and (max-width: 967px) {
      gap: 1rem !important;
      display: flex !important;
      flex-direction: column;
    }
  }

  .modatta-dashboard-funnel .modatta-metric-card {
    @media screen and (max-width: 967px) {
      flex-direction: row !important;
      align-items: center !important;
      justify-content: flex-start !important;
    }
  }

  .content {
    @media screen and (max-width: 967px) {
      margin-left: 1rem !important;
    }
  }
</style>
