<template>
  <div class="modatta-side-modal-new-brand">
    <form @submit.prevent="callAPI()">
      <b-field v-bind:label="$t('brands_edit.brand_name_title')">
        <div
          class="input-wrapper"
          :class="{
            'has-error': $v.form.name.$error,
            'is-valid': !$v.form.name.$invalid && !isDisabled,
          }"
        >
          <input maxlength="255" id="brand-name" v-model="$v.form.name.$model" type="text" :disabled="isDisabled" />
          <modatta-icon v-if="$v.form.name.$error" icon="error-exclamation-point" width="20"></modatta-icon>
          <modatta-icon v-if="!$v.form.name.$invalid && !isDisabled" icon="checkmark-green" width="18"></modatta-icon>
        </div>
      </b-field>
      <modatta-upload-logo-file
        :logo-url="options.type === 'edit' ? form.logo : null"
        :disabled="isDisabled"
      ></modatta-upload-logo-file>
      <hr />
      <b-field v-bind:label="$t('brands_edit.company_name_title')">
        <div
          class="input-wrapper"
          :class="{
            'has-error': $v.form.company.$error,
            'is-valid': !$v.form.company.$invalid && !isDisabled,
          }"
        >
          <input maxlength="255" v-model="$v.form.company.$model" type="text" :disabled="isDisabled" />
          <modatta-icon v-if="$v.form.company.$error" icon="error-exclamation-point" width="20"></modatta-icon>
          <modatta-icon
            v-if="!$v.form.company.$invalid && !isDisabled"
            icon="checkmark-green"
            width="18"
          ></modatta-icon>
        </div>
      </b-field>
      <!-- <b-field v-bind:label="$t('brands_edit.company_vat_title')">
                <div class="input-wrapper" :class="{'has-error': $v.form.vat.$error, }">
                    <input v-model="$v.form.vat.$model" type="text" :disabled="isDisabled"/>
                    <modatta-icon v-if="$v.form.vat.$error" icon="error-exclamation-point" width="20"></modatta-icon>
                    <!-<modatta-icon v-if="!$v.form.vat.$invalid && !isDisabled" icon="checkmark-green" width="18"></modatta-icon>->
                </div>
            </b-field> -->
      <div class="field">
        <label class="label">
          {{ $t('brands_edit.company_web_title') }}
          <b-tooltip
            v-bind:label="$t('brands_edit.company_web_tooltip')"
            type="is-dark"
            position="is-top"
            size="is-small"
            multilined
          >
            <modatta-icon icon="info-square"></modatta-icon>
          </b-tooltip>
        </label>
        <div
          class="input-wrapper"
          :class="{
            'has-error': $v.form.website.$error,
            'is-valid': !$v.form.website.$invalid && !isDisabled,
          }"
        >
          <input maxlength="255" type="text" v-model="$v.form.website.$model" :disabled="isDisabled" />
          <modatta-icon v-if="$v.form.website.$error" icon="error-exclamation-point" width="20"></modatta-icon>
          <modatta-icon
            v-if="!$v.form.website.$invalid && !isDisabled"
            icon="checkmark-green"
            width="18"
          ></modatta-icon>
        </div>
        <p class="error-text" v-if="$v.form.website.$error">
          {{ $t('brands_edit.url_error') }}
        </p>
      </div>
      <div class="field">
        <label class="label">
          {{ $t('brands_edit.company_privacy_title') }}
          <b-tooltip
            v-bind:label="$t('brands_edit.company_privacy_tooltip')"
            type="is-dark"
            position="is-top"
            size="is-small"
            multilined
          >
            <modatta-icon icon="info-square"></modatta-icon>
          </b-tooltip>
        </label>
        <div
          class="input-wrapper"
          :class="{
            'has-error': $v.form.privacy.$error,
            'is-valid': !$v.form.privacy.$invalid && !isDisabled,
          }"
        >
          <input maxlength="255" type="text" v-model="$v.form.privacy.$model" :disabled="isDisabled" />

          <modatta-icon v-if="$v.form.privacy.$error" icon="error-exclamation-point" width="20"></modatta-icon>
          <modatta-icon
            v-if="!$v.form.privacy.$invalid && !isDisabled"
            icon="checkmark-green"
            width="18"
          ></modatta-icon>
        </div>
        <p class="error-text" v-if="$v.form.privacy.$error">
          {{ $t('brands_edit.url_error') }}
        </p>
      </div>
      <div v-if="!isDisabled" class="buttons">
        <button
          class="button is-primary"
          type="Submit"
          :disabled="isLoading.hasLoading || $v.form.$invalid || form.logo == null"
        >
          <template v-if="!isLoading.save">{{ $t('brands_edit.button_save_label') }}</template>
          <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
        </button>
        <!-- <button class="button is-secondary" @click="saveAsDraft()" :disabled="isLoading.hasLoading || form.name == ''">
                    <template v-if="!isLoading.draft">Save as draft</template>
                    <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
                </button> -->
      </div>
    </form>

    <div class="modatta-side-modal-select-logo" :class="{ open: isSelectLogoOpen }">
      <div class="modal-wrapper">
        <header>
          <button class="close button" @click="isSelectLogoOpen = false">
            <modatta-icon icon="arrow-left-primary"></modatta-icon>
          </button>
          <div class="modal-title">{{ $t('brands_edit_logo.title') }}</div>
        </header>
        <div class="logos-wrapper">
          <p>{{ $t('brands_edit_logo.description') }}</p>
          <div class="logos">
            <div
              class="logo"
              @click="selectLogo(logo)"
              :class="{ selected: logo === selectedLogo }"
              v-for="(logo, index) in logos"
              :key="index"
              :style="{
                backgroundImage: `url('/svg/generic-logos/${logo.icon}.svg')`,
              }"
            ></div>
          </div>
          <button v-if="selectedLogo != null" class="button is-primary" @click="saveLogo()">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { required, url, minLength, maxLength } from 'vuelidate/lib/validators';
  import lodash from 'lodash';

  export default {
    props: ['options'],
    data() {
      return {
        isSelectLogoOpen: false,
        isLoading: { hasLoading: false, save: false, draft: false },
        form: {
          vat: '',
          name: '',
          logo: null,
          company: '',
          website: '',
          privacy: '',
        },

        selectedLogo: null,
        logos: [
          { icon: '3174' },
          { icon: '3150' },
          { icon: '3151' },
          { icon: '3152' },
          { icon: '3159' },
          { icon: '3161' },
          { icon: '3162' },
          { icon: '3163' },
          { icon: '3164' },
          { icon: '3165' },
          { icon: '3166' },
          { icon: '3167' },
          { icon: '3168' },
          { icon: '3169' },
          { icon: '3170' },
          { icon: '3172' },
          { icon: '3173' },
          { icon: '3174' },
          { icon: '3180' },
          { icon: '3181' },
        ],
      };
    },
    mounted() {
      if (this.options.type === 'edit') {
        const brand = this.getBrandById(this.options.id);
        this.form.name = brand.name;
        this.form.logo = brand.logo_url;
        this.selectedLogo = brand.logo_url;
        this.form.company = brand.company_name;
        this.form.vat = brand.company_nif;
        this.form.website = brand.company_url;
        this.form.privacy = brand.company_privacy_url;
      } else {
        setTimeout(() => document.getElementById('brand-name').focus(), 100);
      }
    },
    computed: {
      ...mapGetters(['getBrandById']),
      isDisabled() {
        return this.options.disabled;
      },
    },
    validations: {
      form: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        company: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        vat: {
          //required,
          minLength: minLength(8),
          maxLength: maxLength(14),
        },
        website: {
          url,
          required,
          maxLength: maxLength(255),
        },
        privacy: {
          url,
          required,
          maxLength: maxLength(255),
        },
      },
    },
    methods: {
      ...mapActions(['createBrand', 'updateBrand']),
      ...mapMutations(['setOpenToast']),
      callAPI(isDraft = false) {
        this.isLoading = { hasLoading: true, save: true };
        if (this.options.type === 'create') {
          let body = this.getPayloadBody(isDraft);
          const shouldConvertToBlob = lodash.has(this.form.logo, 'icon');
          if (!shouldConvertToBlob) {
            this.addBrand(body, isDraft);
          } else {
            const blob = this.getBlob(`/svg/generic-logos/${this.form.logo.icon}.svg`);
            blob.then((response) => {
              body.set('brand_logo', response);
              this.addBrand(body, isDraft);
            });
          }
        } else if (this.options.type === 'edit') {
          let body = this.getPayloadBody(isDraft);
          body.append('id', this.options.id);
          const shouldConvertToBlob = lodash.has(this.form.logo, 'icon');
          if (!shouldConvertToBlob) {
            const payload = { body, id: this.options.id };
            this.changeBrand(payload);
          } else {
            const blob = this.getBlob(`/svg/generic-logos/${this.form.logo.icon}.svg`);
            blob.then((response) => {
              body.set('brand_logo', response);
              const payload = { body, id: this.options.id };
              this.changeBrand(payload);
            });
          }
        }
      },
      addBrand(body, isDraft) {
        this.createBrand(body)
          .then((response) => {
            let newBrand = response.data.result;
            let buffer = [];
            buffer.unshift(newBrand);
            // buffer.unshift(buffer.splice(buffer.findIndex(e => e.id == "ZZ"), 1)[0])

            newBrand.status_id = isDraft ? 'BST1' : 'BST2';
            this.$store.state.analytic.brands.unshift(newBrand);
            this.addUniqueBrand(newBrand, this.$store.state.brand.brands);
            this.addUniqueBrand(newBrand, this.$parent.$parent.brands);

            this.$parent.closeSideModal();
          })
          .catch(() => {
            this.removeLoading();
          });
        // Permite fecho da modal nos paineis.
        this.$parent.closeSideModal();
      },

      addUniqueBrand(add, brands) {
        let index = brands.findIndex((brand) => brand.id === add.id);
        if (index > -1) {
          brands.splice(index, 1);
        }
        brands.unshift(add);
      },
      changeBrand(payload) {
        this.updateBrand(payload)
          .then((response) => {
            const updatedBrand = response.data.result;
            let currentBrandIndex = this.$store.state.brand.brands.findIndex((b) => b.id === updatedBrand.id);
            this.$store.state.brand.brands.splice(currentBrandIndex, 1, updatedBrand);
            this.$parent.closeSideModal();
            this.setOpenToast({
              title: 'Updated sucessfully',
              isSuccess: true
            });
          })
          .catch(() => {
            this.setOpenToast({
              title: `Couldn't update this brand because it's active`,
              isSuccess: false
            });
          }).finally(() => {
            this.removeLoading();
          });
      },
      saveAsDraft() {
        this.isLoading = { hasLoading: true, draft: true };
        this.callAPI(true);
      },
      selectLogo(logo) {
        this.form.logo = logo;
        this.selectedLogo = logo;
        this.isSelectLogoOpen = false;
      },

      /*********************************
        | HELPERS
        **********************************/
      getPayloadBody(isDraft) {
        const body = new FormData();
        body.append('activate', !isDraft);
        body.append('name', this.form.name);
        body.append('company_nif', this.form.vat);
        body.append('brand_logo', this.form.logo);
        body.append('company_url', this.form.website);
        body.append('company_name', this.form.company);
        body.append('company_privacy_url', this.form.privacy);
        return body;
      },
      async getBlob(url) {
        return await fetch(url).then((r) => r.blob());
      },
      removeLoading() {
        this.isLoading = { hasLoading: false, save: false, draft: false };
      },
    },
  };
</script>
