<template>
  <div class="global-loading">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModattaGlobalLoading',
  };
</script>
