<template>
  <header>
    <p>{{ title }}</p>
    <div class="end">
      <button v-if="stepId !== null" class="button is-text" @click="handleEditStep()">
        <span>Edit</span>
        <modatta-icon icon="edit"></modatta-icon>
      </button>
      <div class="icon-wrapper">
        <modatta-icon v-if="isStepCompleted" icon="green-checkbox"></modatta-icon>
        <modatta-icon v-else icon="alert"></modatta-icon>
      </div>
    </div>
  </header>
</template>

<script>
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['title', 'offer', 'isStepCompleted', 'stepId'],
    methods: {
      handleEditStep() {
        this.$emit('current-step', this.stepId);
      },
    },
  };
</script>
