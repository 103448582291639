var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-details"},[_c('div',{staticClass:"details"},[_c('h2',[_vm._v(_vm._s(_vm.$t('offers_step1.section_1_title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('offers_step1.section_1_subtitle')))]),(
        !_vm.$parent.isLoading &&
        _vm.$parent.newOffer.interaction_type_id === null &&
        this.$store?.state?.newOfferType?.code === null
      )?_c('div',{staticClass:"interaction no-offer"},[_c('h3',[_vm._v(_vm._s(_vm.$t('offers_step1.select_offer_type')))]),_c('button',{staticClass:"button is-primary",on:{"click":function($event){return _vm.selectOption('campaign', 'Create New Offer')}}},[_vm._v("+ New Offer")])]):_c('div',{staticClass:"brands-wrapper"},[_c('div',{staticClass:"brands"},[(!_vm.$parent.isLoading)?_vm._l((_vm.activeBrands),function(brand){return _c('div',{key:brand.id,staticClass:"brand",class:{ 'is-selected': brand.id === _vm.$parent.newOffer.brand_id },on:{"click":function($event){return _vm.selectBrand(brand)}}},[_c('div',{staticClass:"logo-wrapper"},[_c('div',{staticClass:"logo",style:(`background-image: url(${brand.logo_url})`)})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(brand.name))])])}):_vm._l((8),function(index){return _c('div',{key:index,staticClass:"is-loading brand"})}),_c('div',{staticClass:"brand new-brand",on:{"click":function($event){_vm.selectOption('brand', _vm.$t('brands_edit.title'), { type: 'create', disabled: false })}}},[_c('modatta-icon',{attrs:{"icon":"upload"}}),_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('offers_step1.button_new_brand_label')))])],1)],2)]),_c('button',{staticClass:"button is-primary",staticStyle:{"height":"52px","margin-top":"12px"},attrs:{"disabled":_vm.$parent.newOffer.brand_id == null || _vm.$parent.isLoading},on:{"click":function($event){return _vm.$parent.handleNextClick(1, {
          brand_id: _vm.$parent.newOffer.brand_id,
        })}}},[[_vm._v(_vm._s(_vm.$t('offers_step2.button_label')))],(_vm.$parent.isLoading)?_c('modatta-icon',{staticClass:"rotating",attrs:{"icon":"loading"}}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }