<template>
  <b-field class="modatta-radio-input">
    <b-radio-button
      v-model="value"
      type="is-selected"
      :native-value="option"
      @input="[(otherValue = ''), newValue($event)]"
      v-for="(option, index) in options"
      :key="index"
    >
      <span v-if="hasToTranslate">{{ $t(`offers_step2_offer_filter.${option[prop]}`) }}</span>
      <span v-else>{{ option[prop] }}</span>
    </b-radio-button>
  </b-field>
</template>

<script>
  export default {
    props: ['options', 'prop', 'suggestedValue', 'hasToTranslate'],
    data() {
      return {
        value: {},
      };
    },
    mounted() {
      if (this.suggestedValue != null) {
        this.value = this.suggestedValue;
      }
    },
    watch: {
      suggestedValue(newVal) {
        this.value = newVal;
      },
    },
    methods: {
      handleInput(e) {
        this.value = {};
        if (e.target.value < 1) {
          e.target.value = 1;
          this.newValue(1);
        } else {
          this.newValue(e.target.value);
        }
      },
      resetValue(e) {
        this.value = {};
        this.newValue(e.target.value);
      },
      newValue(e) {
        this.$emit('radio-value', e);
      },
    },
  };
</script>
