<template>
  <div>
    <div class="forms">
      <div class="form location">
        <div class="modatta-input modal-container form-container amount-input">
          <p class="form-label">{{ forms.location.label }}</p>
          <div class="row">
            <div class="grow">
              <div class="input-wrapper">
                <input class="input" type="text" :placeholder="'Birmingham'" v-model="forms.location.model" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form radius">
        <div class="modatta-input modal-container form-container amount-input">
          <p class="form-label">{{ forms.radius.label }}</p>
          <div class="row">
            <div class="grow">
              <div
                class="input-wrapper append-km"
                :class="['len-' + forms.radius.length, !forms.radius.valid ? 'error' : '']"
                v-bind:data-unit="forms.radius.unit"
              >
                <input
                  class="input"
                  type="number"
                  :placeholder="'5km'"
                  min="0"
                  max="100"
                  v-model="forms.radius.model"
                />
                <div class="message" :class="forms.radius.message" v-if="!forms.radius.valid">
                  <span>{{ forms.radius.message }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-container modal-container">
        <div class="form map">Mapa</div>
      </div>
      <div class="form-container modal-container">
        <div class="form tags">
          <div class="form-tag" v-if="forms.location.model && forms.location.model.length > 0">
            <strong>{{ forms.location.label }}</strong>
            <span>{{ forms.location.model }}</span>
            <button class="button">
              <modatta-icon icon="times" width="12"></modatta-icon>
            </button>
          </div>
          <div class="form-tag append-km" v-if="forms.radius.model && forms.radius.model > 0">
            <strong>{{ forms.radius.label }}</strong>
            <span>{{ forms.radius.model }} {{ forms.radius.unit }}</span>
            <button class="button">
              <modatta-icon icon="times" width="12"></modatta-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Multiselect from 'vue-multiselect';

  export default {
    props: ['options'],
    components: { Multiselect },
    data() {
      return {
        forms: {
          location: {
            label: 'Location',
            model: '',
          },
          radius: {
            label: 'Radius',
            model: '',
            length: 0,
            unit: 'km',
            valid: true,
            message: 'You should keep the radius between 0 and 100 km.',
          },
        },
      };
    },
    mounted() {},
    watch: {
      'forms.radius.model': {
        handler: function (val) {
          this.forms.radius.length = String(val).length;
          if (this.forms.radius.model > 100 || this.forms.radius.model < 0) {
            this.forms.radius.valid = false;
          } else {
            this.forms.radius.valid = true;
          }
        },
        deep: true,
      },
    },
    computed: {},
    methods: {},
  };
</script>
