<template>
  <section class="portal">
    <modatta-sidenav page="panels"></modatta-sidenav>
    <main>
      <div class="settings components-wrapper">
        <modatta-navbar :title="$t('page_panels.title')" :hasIcon="{ name: 'panels' }"></modatta-navbar>
        <p v-if="isAlertData" style="margin-left: 12px; color: brown">Não existe dados para o ambiente de <b>QA</b>!</p>
        <div class="components">
          <div class="component">
            <iframe
              :title="$t('page_panels.title')"
              width="100%"
              height="100%"
              :src="url"
              border="0"
              allowfullscreen
              sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            ></iframe>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
  import { mapState } from 'vuex';
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';

  export default {
    components: { ModattaSidenav, ModattaNavbar },
    data() {
      return {
        url: null,
        isAlertData: process.env.VUE_APP_ENVIRONMENT !== 'production',
      };
    },
    mounted() {
      const params = { 'ds20.b2bid': this.user.user?.id };
      const baseURL = `https://lookerstudio.google.com/embed/reporting/41d7c966-01cc-491f-96a1-912d9ed0e7ab/page/p_f7i0iixejd?params=${JSON.stringify(params)}`;
      this.url = encodeURI(baseURL);
    },
    computed: {
      ...mapState(['user']),
    },
  };
</script>
