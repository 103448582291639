export default function SetInteractionTypeIcon(id) {
  switch (id) {
    case 'INT1':
      return 'message';
    case 'INT2':
      return 'video-camera';
    case 'INT3':
      return 'globe';
    case 'INT4':
      return 'wifi';
    case 'INT5':
      return 'wifi-off';
    case 'INT6':
      return 'poll';
    case 'INT7':
      return 'list';
    case 'INT8':
    case 'INT9':
    case 'INT10':
    case 'INT11':
      return 'activity';
    case 'INT12':
    case 'INT13':
    case 'INT14':
    case 'INT15':
    case 'INT16':
    case 'INT17':
      return 'hand-one-finger';
    case 'INT18':
      return 'list';
    case 'INT19':
      return 'icon-instagram-blue';
  }
}
