<template>
  <div class="modatta-snap-tabs" ref="tabGroup">
    <!-- Tab Header -->
    <div class="snap-tabs-header">
      <!-- Tab Navigation -->
      <div class="snap-tabs-nav" ref="tabNav">
        <span class="nav-indicator" :style="{ left: indicatorOffset, width: indicatorWidth }"></span>
        <!-- Tabs -->
        <div v-for="tab in tabs">
          <a
            :class="{ active: tab.isActive }"
            :key="tab.id"
            ref="tabNavItems"
            :href="`#${tab.id}`"
            :active="tab.isActive"
            @click="
              (e) => {
                activateTab(tab);
                handleNavigation(e);
              }
            "
          >
            {{ $t('dashboard.' + tab.name) }}
          </a>
        </div>
      </div>

      <!-- Tab Actions: New Brand -->
      <button
        v-if="activeTab.id === 'brands'"
        class="button is-secondary snap-tabs-action"
        @click="$parent.selectOption('brand', $t('brands_edit.title'), { type: 'create', disabled: false })"
      >
        {{ $t('brands.button_label') }}
      </button>

      <!-- Tab Actions: New Offer -->
      <button
        v-else
        class="button is-secondary snap-tabs-action"
        @click="$parent.selectOption('campaign', $t('offers_step0.title'))"
      >
        {{ $t('offers.button_label') }}
      </button>
    </div>
    <!-- Tab Content -->
    <div class="snap-tabs-body" v-if="isDataLoaded">
      <div class="snap-tabs section-body" ref="tabSection">
        <!-- TODO: Remover v-for -->
        <div class="snap-tab" v-for="tab in [activeTab]" :key="tab.id" :class="`${tab.id}-table`">
          <!-- Tab: Brands (table) -->
          <template v-if="tab.id === 'brands'" id="brands-tab">
            <modatta-table :data="tab.data" :show-header="false" :is-offer="tab.isOffer"></modatta-table>
          </template>
          <!-- Tab: Offers (table) -->
          <template v-if="tab.id === 'offers'" id="offers-tab">
            <modatta-table :data="tab.data" :show-header="false" :is-offer="tab.isOffer"></modatta-table>
          </template>
          <!-- Tab: Offer Type -->
          <template v-if="tab.id === 'offer-type'" id="offer-type-tab" class="tab-body offet-type-table">
            <div v-for="(offer, index) in tab.data" :key="index" class="offer-type">
              <div class="icon-wrapper">
                <modatta-icon :icon="offer.icon" width="1" height="1" :rem="true"></modatta-icon>
              </div>
              <div class="progressbar-container">
                <div class="type">{{ $t('labels.' + offer.type) }}</div>
                <div class="modatta-progress-bar">
                  <div
                    class="progressbar"
                    :style="{
                      width: `${(offer.completed / offer.total) * 100 || 0}%`,
                      left: `${(offer.rejected / offer.total) * 100 || 0}%`,
                    }"
                    :class="{ 'left-side-squared': ((offer.rejected / offer.total) * 100 || 0) !== 0 }"
                  ></div>
                  <div
                    class="progressbar rejected"
                    :style="{ width: `${(offer.rejected / offer.total) * 100 || 0}%` }"
                    :class="{ 'right-side-squared': ((offer.completed / offer.total) * 100 || 0) !== 0 }"
                  ></div>
                </div>
              </div>
              <div class="stats">
                <span class="rejected">{{ getRejectedPercentage(offer) }}%</span>
                <span class="completed">{{ getCompletedPercentage(offer) }}%</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="snap-tabs-body" style="padding: 1.25rem" v-else>
      <b-skeleton :animated="true" height="30px"></b-skeleton>
      <b-skeleton :animated="true" height="30px"></b-skeleton>
      <b-skeleton :animated="true" height="30px"></b-skeleton>
      <b-skeleton :animated="true" height="30px"></b-skeleton>
    </div>
    <!-- Modal: New Brand, New Offer -->
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { mapState, mapMutations } from 'vuex';
  import { DateTime } from 'luxon';
  import '../../../helpers/scroll-timeline.js';
  import Utilities from '../../../helpers/utilities.js';
  import SetOfferStatus from '../../../helpers/set-offer-status.js';
  import SetInteractionType from '../../../helpers/set-interaction-type.js';
  import SetInteractionTypeIcon from '../../../helpers/set-interaction-type-icon.js';

  export default {
    data() {
      return {
        tabs: [
          { id: 'brands', name: 'table_menu_brands', isOffer: false, isActive: false, data: [] },
          { id: 'offers', name: 'table_menu_offers', isOffer: true, isActive: false, data: [] },
          { id: 'offer-type', name: 'table_menu_offer_type', isOffer: false, isActive: false, data: [] },
        ],
        indicatorOffset: 0,
        indicatorWidth: 0,
      };
    },
    mounted() {
      this.setupSnapTabs();
      this.getBrandsAndOffers().then(() => {
        this.tabs[0].data = this.brands;
        this.tabs[1].data = this.offers;
        this.tabs[2].data = this.offersType;
        if (this.offersType.length === 0) this.offersType.push({ type: this.$t('dashboard.mission_types_empty') });
      });
    },
    watch: {
      brands(newVal) {
        this.tabs[0].data = newVal;
      },
    },
    computed: {
      ...mapState(['openSideModal']),
      brands() {
        const brands = [];
        this.$store.state.analytic.brands.forEach((b) => {
          brands.push({
            type: { type: 'image', image: b.logo_url },
            name: b.name,
            state: { type: 'badge', status: b.status_id, isClickable: { type: 'brands' }, id: b.id }
          });
        });
        return brands.reverse();
      },
      offers() {
        let offers = [],
          archivedOffers = [],
          notArchivedOffers = [];
        this.$store.state.analytic.brands.forEach((b) => {
          b.offers.forEach((o) => {
            let offer = {};
            offer.type = { type: 'icon', icon: SetInteractionTypeIcon(SetInteractionType(o.interaction_type_id)) };
            offer.name = o.name;
            offer.date = this.setDate(o.offline_on);
            offer.state = {
              type: 'badge',
              status: SetOfferStatus(o.status_id),
              isClickable: { type: 'offers' },
              id: o.id,
            };
            SetOfferStatus(o.status_id) === 'menu_filter_7'
              ? archivedOffers.push(offer)
              : notArchivedOffers.push(offer);
          });
        });
        offers = [...notArchivedOffers, ...archivedOffers];
        return offers;
      },
      offersType() {
        let offersType = [];
        this.$store.state.analytic.brands.forEach((b) => {
          b.offers.forEach((o) => {
            if (o.status_id === 'OST1') {
              return;
            }
            let offer = {};
            offer.icon = SetInteractionTypeIcon(SetInteractionType(o.interaction_type_id));
            offer.type = SetInteractionType(o.interaction_type_id);
            offer.total = o.count_delivered;
            offer.rejected = o.count_declined;
            offer.completed = o.count_completed;
            offersType.push(offer);
          });
        });

        const aggregate = Utilities.aggregate(offersType, ['icon', 'type'], ['total', 'rejected', 'completed']);
        return aggregate;
      },

      activeTab() {
        let active = this.tabs.find((t) => t.isActive);
        return active ? active : this.tabs[0];
      },
      isDataLoaded() {
        return this.tabs[0].data.length > 0 && this.tabs[1].data.length && this.tabs[2].data.length;
      },
    },
    methods: {
      ...mapMutations(['setOpenSideModal']),
      ...mapActions(['getBrandsAndOffers']),
      groupBy(collection, property) {
        let i = 0,
          val,
          index,
          values = [],
          result = [];
        for (; i < collection.length; i++) {
          val = collection[i][property];
          index = values.indexOf(val);
          if (index > -1) result[index].push(collection[i]);
          else {
            values.push(val);
            result.push([collection[i]]);
          }
        }
        return result;
      },
      setDate(date) {
        if (date == null) {
          return '-';
        }
        const newFormat = Object.assign(DateTime.DATE_MED, DateTime.TIME_SIMPLE);
        return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss').toLocaleString(newFormat);
      },
      getRejectedPercentage(offer) {
        const result = Utilities.toDecimalPlaces((offer.rejected / offer.total) * 100, 0) || 0;
        return isFinite(result) ? result : 0;
      },
      getCompletedPercentage(offer) {
        const result = Utilities.toDecimalPlaces((offer.completed / offer.total) * 100, 0) || 0;
        return isFinite(result) ? result : 0;
      },
      activateTab(tab) {
        this.tabs.forEach((t) => (t.isActive = false));
        tab.isActive = true;
        this.tabs[tab.id].isActive = true;
      },
      activateTabById(tabId) {
        this.tabs.forEach((t) => (t.isActive = false));
        this.tabs[tabId].isActive = true;
      },
      setActiveTab(tabbtn) {
        this.$refs.tabNav.querySelector(':scope a[active]').removeAttribute('active');
        tabbtn.setAttribute('active', '');
        tabbtn.scrollIntoView();
      },
      setupSnapTabs() {
        const activeTabId = location.hash ? this.tabs.findIndex((t) => t.id === location.hash.replace('#', '')) : 0;
        this.activateTab(this.tabs[activeTabId]);
        this.moveIndicator(this.$refs.tabNavItems[activeTabId]);
      },
      moveIndicator(to) {
        this.indicatorWidth = `${to.offsetWidth}px`;
        this.indicatorOffset = `${to.offsetLeft}px`;
      },
      changeTab(tabId) {
        this.$refs.tabSection.scrollLeft = tabId * this.$refs.tabSection.offsetWidth;
      },
      activateTab(tab) {
        this.tabs.forEach((t) => (t.isActive = false));
        tab.isActive = true;
      },
      findActiveTab(f) {
        return this.tabs.findIndex((t) => t.id === f);
      },
      handleNavigation(e) {
        this.moveIndicator(e.currentTarget);
        const activeTabId = this.findActiveTab(e.currentTarget.hash.replace('#', ''));
        this.activateTab(this.tabs[activeTabId]);
        this.changeTab(activeTabId);
      },
      selectOption(name, title, options) {
        this.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
        this.selected = null; // To keep listening to selected clicks
      },
    },
  };
</script>

<style lang="scss" scoped></style>
