export default function SetFilterYearValueId(id) {
  switch (id) {
    case '1900':
      return 'VAL857';
    case '1901':
      return 'VAL858';
    case '1902':
      return 'VAL859';
    case '1903':
      return 'VAL860';
    case '1904':
      return 'VAL861';
    case '1905':
      return 'VAL862';
    case '1906':
      return 'VAL863';
    case '1907':
      return 'VAL864';
    case '1908':
      return 'VAL865';
    case '1909':
      return 'VAL866';
    case '1910':
      return 'VAL867';
    case '1911':
      return 'VAL868';
    case '1912':
      return 'VAL869';
    case '1913':
      return 'VAL870';
    case '1914':
      return 'VAL871';
    case '1915':
      return 'VAL872';
    case '1916':
      return 'VAL873';
    case '1917':
      return 'VAL874';
    case '1918':
      return 'VAL875';
    case '1919':
      return 'VAL876';
    case '1920':
      return 'VAL877';
    case '1921':
      return 'VAL878';
    case '1922':
      return 'VAL879';
    case '1923':
      return 'VAL880';
    case '1924':
      return 'VAL881';
    case '1925':
      return 'VAL882';
    case '1926':
      return 'VAL883';
    case '1927':
      return 'VAL884';
    case '1928':
      return 'VAL885';
    case '1929':
      return 'VAL886';
    case '1930':
      return 'VAL887';
    case '1931':
      return 'VAL888';
    case '1932':
      return 'VAL889';
    case '1933':
      return 'VAL890';
    case '1934':
      return 'VAL891';
    case '1935':
      return 'VAL892';
    case '1936':
      return 'VAL893';
    case '1937':
      return 'VAL894';
    case '1938':
      return 'VAL895';
    case '1939':
      return 'VAL896';
    case '1940':
      return 'VAL897';
    case '1941':
      return 'VAL898';
    case '1942':
      return 'VAL899';
    case '1943':
      return 'VAL900';
    case '1944':
      return 'VAL901';
    case '1945':
      return 'VAL902';
    case '1946':
      return 'VAL903';
    case '1947':
      return 'VAL904';
    case '1948':
      return 'VAL905';
    case '1949':
      return 'VAL906';
    case '1950':
      return 'VAL907';
    case '1951':
      return 'VAL908';
    case '1952':
      return 'VAL909';
    case '1953':
      return 'VAL910';
    case '1954':
      return 'VAL911';
    case '1955':
      return 'VAL912';
    case '1956':
      return 'VAL913';
    case '1957':
      return 'VAL914';
    case '1958':
      return 'VAL915';
    case '1959':
      return 'VAL916';
    case '1960':
      return 'VAL917';
    case '1961':
      return 'VAL918';
    case '1962':
      return 'VAL919';
    case '1963':
      return 'VAL920';
    case '1964':
      return 'VAL921';
    case '1965':
      return 'VAL922';
    case '1966':
      return 'VAL923';
    case '1967':
      return 'VAL924';
    case '1968':
      return 'VAL925';
    case '1969':
      return 'VAL926';
    case '1970':
      return 'VAL927';
    case '1971':
      return 'VAL928';
    case '1972':
      return 'VAL929';
    case '1973':
      return 'VAL930';
    case '1974':
      return 'VAL931';
    case '1975':
      return 'VAL932';
    case '1976':
      return 'VAL933';
    case '1977':
      return 'VAL934';
    case '1978':
      return 'VAL935';
    case '1979':
      return 'VAL936';
    case '1980':
      return 'VAL937';
    case '1981':
      return 'VAL938';
    case '1982':
      return 'VAL939';
    case '1983':
      return 'VAL940';
    case '1984':
      return 'VAL941';
    case '1985':
      return 'VAL942';
    case '1986':
      return 'VAL943';
    case '1987':
      return 'VAL944';
    case '1988':
      return 'VAL945';
    case '1989':
      return 'VAL946';
    case '1990':
      return 'VAL947';
    case '1991':
      return 'VAL948';
    case '1992':
      return 'VAL949';
    case '1993':
      return 'VAL950';
    case '1994':
      return 'VAL951';
    case '1995':
      return 'VAL952';
    case '1996':
      return 'VAL953';
    case '1997':
      return 'VAL954';
    case '1998':
      return 'VAL955';
    case '1999':
      return 'VAL956';
    case '2000':
      return 'VAL957';
    case '2001':
      return 'VAL958';
    case '2002':
      return 'VAL959';
    case '2003':
      return 'VAL960';
    case '2004':
      return 'VAL961';
    case '2005':
      return 'VAL962';
    case '2006':
      return 'VAL963';
    case '2007':
      return 'VAL964';
    case '2008':
      return 'VAL965';
    case '2009':
      return 'VAL966';
    case '2010':
      return 'VAL967';
    case '2011':
      return 'VAL968';
    case '2012':
      return 'VAL969';
    case '2013':
      return 'VAL970';
    case '2014':
      return 'VAL971';
    case '2015':
      return 'VAL972';
    case '2016':
      return 'VAL973';
    case '2017':
      return 'VAL974';
    case '2018':
      return 'VAL975';
    case '2019':
      return 'VAL976';
    case '2020':
      return 'VAL981';
    case '2021':
      return 'VAL982';
  }
}
