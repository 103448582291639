var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"portal"},[_c('modatta-sidenav',{attrs:{"page":"campaigns"}}),_c('main',[_c('div',{staticClass:"campaigns components-wrapper new-offer"},[(_vm.openSideModal)?_c('modatta-side-modal',{attrs:{"content":_vm.selectedOption.name,"title":_vm.selectedOption.title,"options":_vm.selectedOption.options,"handleUpdateMediaStep":_vm.handleUpdateMediaStep},on:{"banner":(file) => (this.newOffer.banner = file),"banner-url":(url) => (this.newOffer.banner_url = url)}}):_vm._e(),_c('modatta-navbar',{attrs:{"title":_vm.$t('offers_step0.title'),"offer-type":_vm.offerType,"has-icon":{ name: 'campaigns' }}}),_c('div',{staticClass:"steps-wrapper modatta-scroller-x"},[_c('div',{staticClass:"steps"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"step",class:{
              current: _vm.currentStep === index + 1,
              completed: step.isCompleted,
              disabled: _vm.offerId == null,
            }},[_c('span',[_vm._v(_vm._s(step.name))]),(step.isCompleted)?_c('span',{staticClass:"badge"},[_c('svg',{attrs:{"width":"16","height":"16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"8","cy":"8","r":"8","fill":"#2355C3"}}),_c('path',{attrs:{"d":"M11.334 5.5L6.75 10.083 4.667 8","stroke":"#fff","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e()])}),0)]),_c('div',{staticClass:"new-offer-main-content"},[(_vm.currentStep === 1)?_c('modatta-step-brand'):(_vm.currentStep === 2)?_c('modatta-step-audience'):(_vm.currentStep === 3)?_c('modatta-step-offer'):(_vm.currentStep === 4)?_c('modatta-step-interaction'):(_vm.currentStep === 5)?_c('modatta-step-budget'):(_vm.currentStep === 6)?_c('modatta-step-summary',{attrs:{"status":_vm.status}}):_vm._e(),(_vm.currentStep < 2)?_c('button',{staticClass:"button is-secondary preview-offer-btn",on:{"click":function($event){_vm.selectOption('campaign', _vm.$t('offers_step0.edit_offer_type'))}}},[_vm._v(" "+_vm._s(_vm.$t('offers_step0.edit_offer_type'))+" ")]):_vm._e(),(_vm.currentStep !== 6 && _vm.currentStep > 1)?_c('button',{staticClass:"button is-secondary preview-offer-btn",on:{"click":function($event){_vm.selectOption('new-offer-preview', _vm.$t('offers_step0.preview_button'), {
              brandId: _vm.newOffer.brand_id,
              offer: _vm.newOffer,
            })}}},[_vm._v(" "+_vm._s(_vm.$t('offers_step0.preview_button'))+" ")]):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }