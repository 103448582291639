<template>
  <div class="field field-container">
    <div class="field field-container">
      <div class="columns p-0" v-if="title.length > 0 || deeplink">
        <div class="column py-0" v-if="title.length > 0">
          <label style="line-height: 28px" class="label">{{ title }}</label>
        </div>
        <div class="column py-0 is-narrow" v-if="deeplink">
          <b-switch size="is-small" :left-label="true" class="mr-0 ml-auto" v-model="isDeeplink">
            {{ $t('offers_step4.deeplink_label') }}
          </b-switch>
        </div>
      </div>
      <div class="input-wrapper" :class="{ 'has-error': isInputInvalid, 'is-valid': isInputValid }">
        <input type="text" :placeholder="placeholder" v-model="url" @input="handleInput" />
        <modatta-icon v-if="isInputInvalid" icon="error-exclamation-point" width="20"></modatta-icon>
        <modatta-icon v-if="isInputValid" icon="checkmark-green" width="18"></modatta-icon>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'deeplink', 'onInput', 'isInvalid', 'isValid', 'placeholder', 'suggestedValue'],
    data() {
      return {
        isDeeplink: false,
        url: '',
        cleanURL: '',
      };
    },
    mounted() {
      if (this.suggestedValue != null) {
        if (this.suggestedValue != null) {
          this.url = this.suggestedValue;
          this.url = this.url.replace('?modid={modid}', '');
          this.url = this.url.replace('&modid={modid}', '');
        }
      }
    },
    watch: {
      isDeeplink() {
        this.update();
      },
      url() {
        this.update();
      },
    },
    methods: {
      handleInput() {
        this.update();
      },
      regularRegex() {
        return /^(http){1}(s)?(\:\/\/){1}(.*?)\/?\.+\w+.*$/gm;
      },
      deeplinkRegex() {
        return /^(\w)+(\:\/\/){1}\w*.*$/gm;
      },
      update() {
        this.$emit('url', this.url);
        this.$emit('is-valid', this.isInputValid);
        this.$emit('is-invalid', this.isInputInvalid);
        if (this.onInput) {
          this.onInput();
        }
      },
    },
    computed: {
      regexURL() {
        return this.isDeeplink === false ? this.regularRegex().test(this.url) : this.deeplinkRegex().test(this.url);
      },
      isInputValid() {
        return this.regexURL && this.url.length > 0 && this.isValid;
      },
      isInputInvalid() {
        return (!this.regexURL && this.url.length > 0) || this.isInvalid;
      },
    },
  };
</script>
