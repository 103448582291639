<template>
  <div class="offer-image">
    <div class="upload-banner-section">
      <modatta-upload-image
        v-if="banner == null"
        name="banner"
        size="750x750px"
        v-bind:label="$t('offers_step3.box_section_2_title')"
        :show-preview="true"
        source="new-offer-step"
        @new-banner-image="(banner) => (this.newBanner = banner)"
        v-bind:tooltip="{ text: this.$t('offers_step3.box_section_2_tooltip') }"
      ></modatta-upload-image>
      <div v-else class="field">
        <label class="label">
          {{ $t('offers_step3.box_section_2_title') }}
          <b-tooltip
            v-bind:label="$t('offers_step3.box_section_2_tooltip')"
            type="is-dark"
            position="is-top"
            size="is-small"
            multilined
          >
            <modatta-icon icon="info-square"></modatta-icon>
          </b-tooltip>
        </label>
        <div class="preview-image">
          <div class="image-wrapper">
            <img v-if="bannerUrl != null" :src="bannerUrl" alt="New offer banner" />
            <img v-else id="preview-image" alt="Banner da missãos" />
          </div>
          <button class="button is-subtle" @click="removeBanner()">{{ $t('offers_step3.remove_banner') }}</button>
        </div>
      </div>
    </div>
    <div v-if="banner == null" class="default-banner-section">
      <p>{{ $t('offers_step3.no_banner') }}</p>
      <button
        class="button is-subtle"
        @click.prevent="$parent.selectOption('new-offer-default-banner', $t('offers_step3.select_banner'))"
      >
        {{ $t('offers_step3.select_banner') }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['banner', 'bannerUrl'],
    data() {
      return {
        newBanner: null,
      };
    },
    mounted() {},
    watch: {
      banner() {
        if (this.banner) {
          if (this.banner.size >= 1000000) {
            this.removeBanner();
          }
        }
      },
      newBanner(newVal) {
        this.$emit('banner', newVal);
      },
    },
    computed: {},
    methods: {
      removeBanner() {
        this.$emit('banner', null);
        this.$emit('banner-url', null);
      },
      formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
        const f = sizes[i];

        return { size, f };
      },
    },
  };
</script>
