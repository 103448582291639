export default {
  state: {
    title: '',
    message: '',
    isOpen: false,
    isSuccess: false,
  },
  actions: {},
  mutations: {
    setOpenToast(state, payload) {
      state.title = payload.title;
      state.message = payload.message;
      state.isOpen = true;
      state.isSuccess = payload.isSuccess;
    },
    setCloseToast(state) {
      state.isOpen = false;
    },
  },
};
