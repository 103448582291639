<template>
  <div class="validation-option">
    <div class="field">
      <label class="label">{{ $t('offers_step4.box_section_3_val_3_subtitle_2') }}</label>
    </div>
    <b-upload v-model="csvFile" @input="uploadFile()" accept=".csv">
      <div class="button is-subtle">
        <modatta-icon icon="upload-arrow"></modatta-icon>
        <span>{{ $t('offers_step4.box_section_3_val_3_upload_button') }}</span>
      </div>
    </b-upload>
    <b-skeleton v-if="fileLoading" height="380"></b-skeleton>
    <div v-if="!fileLoading && codes.length > 0" class="uploaded-codes">
      <div class="field">
        <label class="label">{{ $t('offers_step4.box_section_3_val_3_subtitle_3') }}</label>
      </div>
      <p class="codes-list" v-if="divideCodes[1].length > 25" :class="{ 'collapsed-codes': collapsed }">
        <span v-for="(code, i) in divideCodes[0]" :key="'first-' + i">
          <span>{{ code.code }}</span>
          <span class="text-muted">•</span>
        </span>
        <span v-if="!collapsed" v-for="(code, i) in divideCodes[1]" :key="'second-' + i">
          <span>{{ code.code }}</span>
          <span class="text-muted">•</span>
        </span>
        <button @click="toggleCollapsed()" class="toggle-collapsed-codes">
          <svg v-if="collapsed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>chevron-down</title>
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>chevron-up</title>
            <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
          </svg>
        </button>
      </p>
      <p class="codes-list" v-else>
        <span v-for="(code, i) in codes" :key="i">
          <span>{{ code.code }}</span>
          <span class="text-muted">•</span>
        </span>
      </p>
    </div>

    <template v-if="codes.length > 0">
      <div v-if="codes.length > 0 && codes[0].code.length > 0">
        <br />
        <b-notification role="alert">
          <strong v-if="codes.length > 0" class="text-success">{{
            $tc('alerts.codes.added', codes.length, {
              added: codes.length,
            })
          }}</strong>
          <ul class="text-primary">
            <li v-if="empty > 0">
              <small>{{ $tc('alerts.codes.empty', empty, { empty: empty }) }}</small>
            </li>
            <li v-if="duplicates > 0">
              <small>{{
                $tc('alerts.codes.duplicates', duplicates, {
                  duplicates: duplicates,
                })
              }}</small>
            </li>
          </ul>
        </b-notification>
      </div>
    </template>
  </div>
</template>

<script>
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['validationCodes'],
    data() {
      return {
        codes: [],
        csvFile: null,
        allowEmpty: false,
        allowDuplicates: false,
        duplicates: 0,
        empty: 0,
        collapsed: true,
        fileLoading: false,
      };
    },
    mounted() {
      if (this.validationCodes != null) {
        this.codes = this.validationCodes;
      }
    },
    computed: {
      divideCodes() {
        let first = [];
        let second = [];
        first = this.codes.slice(0, 25);
        second = this.codes.slice(25);
        this.collapsed = true;
        this.fileLoading = false;
        return [first, second];
      },
    },
    methods: {
      upload() {},

      toggleCollapsed() {
        this.collapsed = !this.collapsed;
      },

      removeDuplicates() {
        if (this.allowDuplicates) return;
        const filtered = this.codes.filter((v, i, a) => a.findIndex((v2) => v2.code === v.code) === i);
        return { data: filtered, diff: this.codes.length - filtered.length };
      },
      removeEmpty() {
        if (this.allowEmpty) return;
        const filtered = this.codes.filter((obj) => obj.code.trim().length > 0);
        return { data: filtered, diff: this.codes.length - filtered.length };
      },

      uploadFile() {
        this.fileLoading = true;
        this.codes = [];

        setTimeout(() => {
          const reader = new FileReader();
          reader.readAsBinaryString(this.csvFile);
          reader.onload = () => {
            const codesArray = reader.result.split('\n');
            codesArray.forEach((code) => {
              let newCode = code.replace('\r', '').toString();
              if (newCode !== null && newCode !== '') {
                this.codes.push({ type: 'unique', code: newCode });
              }
            });

            const empty = this.removeEmpty();

            if (empty.diff > 0) {
              this.empty = empty.diff;
              this.codes = empty.data;
              this.$emit('new-codes', this.codes);
            }

            const duplicates = this.removeDuplicates();

            if (duplicates.diff > 0) {
              this.duplicates = duplicates.diff;
              this.codes = duplicates.data;
              this.$emit('new-codes', this.codes);
            }

            if (duplicates && empty) {
              this.fileLoading = false;
            }
          };
        }, 100);

        this.$emit('new-codes', this.codes);
        this.$emit('csvFile', this.csvFile);
      },
    },
  };
</script>

<style scoped lang="scss">
  .b-skeleton-item {
    margin-top: 64px;
    opacity: 0.35;
  }

  ul {
    list-style: disc;
    margin: 0 20px;
    padding: initial;
  }

  .notification {
    margin: 0;
    background-color: #e9eef9;

    strong {
      color: #2355c3;
      font-size: 150%;
    }
  }

  .text-muted {
    color: #6c757d !important;
    opacity: 0.2;
    font-size: 1rem;
    line-height: 0;
    display: inline-block;
    margin: 0 0.5rem;
  }

  .codes-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > span {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  .collapsed-codes {
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 60px;
      content: '';
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 2%,
        rgba(255, 255, 255, 1) 98%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 2%,
        rgba(255, 255, 255, 1) 98%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 2%,
        rgba(255, 255, 255, 1) 98%,
        rgba(255, 255, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    }

    .toggle-collapsed-codes {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      border: none;
      position: absolute;
      z-index: 10;
      bottom: 0;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 20px;
      font-size: 1.5rem;
      font-weight: 900;
      text-transform: lowercase;

      svg {
        height: 50px;
        stroke: #2355c3;
        opacity: 0.5;
      }

      &:hover,
      &:focus {
        svg {
          opacity: 1;
        }
      }
    }
  }

  .codes-list:not(.collapsed-codes) {
    position: relative;

    .toggle-collapsed-codes {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      border: none;
      width: 100%;
      margin-top: 20px;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      bottom: 0;

      svg {
        height: 50px;
        stroke: #2355c3;
      }
    }
  }
</style>
