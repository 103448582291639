import api from '../api/offer_api';

// Initial state
const state = {
  offers: [],
  detailedOffers: [],

  // NEW OFFER
  values: [],
  cities: [],
  states: [],
  profiles: [],
  operators: [],
  interests: [],
  validatePostback: null,

  // Dashboard
  totalGenerated: null,
  accumulatedCost: null,

  objective: null,
  achieved: null,

  totalDelivered: null,
  totalAccepted: null,
  totalDeclined: null,

  notEvaluatedData: null,
  acceptedData: null, // if !poll or !survey
  answersData: null, // if poll or survey

  declinedData: null,
  deliveredDecisionsData: null,
};

const getters = {
  getOfferById: (state) => (id) => {
    return state.offers.find((offer) => offer.id === id);
  },
};

const actions = {
  // READ
  async getOffers({ commit }) {
    return await api.getOffers().then((response) => {
      commit('setOffers', response.data);
    });
  },
  async getDetailedOffers({ commit }) {
    return await api.getDetailedOffers().then((response) => commit('setDetailedOffers', response.data));
  },
  async getOffer(_, id) {
    return await api.getOffer(id);
  },

  // NEW OFFER
  async getCities({ commit }, id) {
    return await api.getCities(id).then((response) => commit('setCities', response.data));
  },
  async getStates({ commit }, id) {
    return await api.getStates(id).then((response) => commit('setStates', response.data));
  },
  async getInterests({ commit }) {
    return await api.getInterests().then((response) => commit('setInterests', response.data));
  },
  async getProfileFieldsWithinFamily({ commit }, data) {
    return await api.getProfileFieldsWithinFamily(data).then((response) => commit('setProfiles', response.data));
  },
  async getOperatorOptions({ commit }, data) {
    return await api.getOperatorOptions(data).then((response) => commit('setOperators', response.data));
  },
  async getValueOptions({ commit }, data) {
    return await api.getValueOptions(data).then((response) => commit('setValues', response.data));
  },

  // CREATE
  async upsertOffer(_, data) {
    return await api.upsertOffer(data);
  },

  async upsertOfferFormData(_, data) {
    return await api.upsertOfferFormData(data);
  },

  // VALIDATE
  async validatePostback({ commit }, data) {
    return await api.validatePostback(data).then((response) => commit('setValidationPostback', response.data));
  },

  // UPDATE
  async updateBannerFile({ commit }, data) {
    return await api.updateBannerFile(data);
    //.then((response) => commit('setOffers', response.data));
  },
  async updateOfferStatus({ commit }, data) {
    return await api.updateOfferStatus(data).then((response) => commit('setOfferStatus', data));
  },
  async updateOffer({ commit }, data) {
    return await api.updateOffer(data).then((response) => console.log(response));
  },

  // DELETE
  async deleteOffer({ commit }, data) {
    return await api.deleteOffer(data);
    // .then(() => commit('removeOffer', data.id));
  },

  // DASHBOARD
  async getAccumulatedCost({ commit }, id) {
    return await api.getAccumulatedCost(id).then((r) => commit('setAccumulatedCost', r.data));
  },
  async getOfferObjective({ commit }, id) {
    return await api.getOfferObjective(id).then((r) => commit('setOfferObjective', r.data.result));
  },
  async getOfferDelivered({ commit }, id) {
    return await api.getOfferDelivered(id).then((r) => commit('setOfferDelivered', r.data.result));
  },
  async getDeliveredDecisionsData({ commit }, id) {
    return await api.getDeliveredDecisionsData(id).then((r) => commit('setDeliveredDecisionsData', r.data.result));
  },
  async getDecisionsData({ commit }, id) {
    return await api.getDecisionsData(id);
    // .then(r => commit('setDecisionsData', r.data.result));
  },
  async getOfferAcceptedData({ commit }, id) {
    return await api.getOfferAcceptedData(id).then((r) => {
      commit('setOfferAchieved', r.data.result);
      commit('setOfferAccepted', r.data.result);
      commit('setOfferAcceptedData', r.data.result);
    });
  },
  async getOfferDeclinedData({ commit }, id) {
    return await api.getOfferDeclinedData(id).then((r) => {
      commit('setOfferDeclined', r.data.result);
      commit('setOfferDeclinedData', r.data.result);
    });
  },
  async getOfferAnswers({ commit }, id) {
    return await api.getOfferAnswers(id).then((r) => commit('setOfferAnswers', r.data));
  },
};

const mutations = {
  setOffers(state, payload) {
    state.offers = payload.result.reverse();
  },
  setDetailedOffers(state, payload) {
    state.detailedOffers = payload.result;
  },
  setOfferStatus(state, payload) {
    let offer = state.offers.find((o) => o.id == payload.id);
    offer.status_id = payload.data.status_id;
  },
  removeOffer(state, id) {
    const index = state.offers.findIndex((o) => o.id === id);
    state.offers.splice(index, 1);
  },
  setValidationPostback(state, payload) {
    state.validatePostback = payload.result.validation_status;
  },

  // NEW OFFER
  setCities(state, payload) {
    state.cities = payload.result;
  },

  setStates(state, payload) {
    state.states = payload.result;
  },

  setInterests(state, payload) {
    state.interests = payload.result;
  },
  setProfiles(state, payload) {
    state.profiles = payload.result;
  },
  setOperators(state, payload) {
    state.operators = payload.result;
  },
  setValues(state, payload) {
    state.values = payload.result;
  },

  // DASHBOARD
  setAccumulatedCost(state, payload) {
    state.totalGenerated = payload.business;
    state.accumulatedCost = payload.invested;
  },
  setOfferObjective(state, payload) {
    state.objective = payload.max_accepts;
  },
  setOfferAchieved(state, payload) {
    state.achieved = payload.total;
  },
  setOfferDelivered(state, payload) {
    state.totalDelivered = payload;
  },
  setOfferAccepted(state, payload) {
    state.totalAccepted = payload.total;
  },
  setOfferDeclined(state, payload) {
    state.totalDeclined = payload.total;
  },
  setOfferAcceptedData(state, payload) {
    state.acceptedData = Object.values(payload.statistic).reverse();
    state.notEvaluatedData = payload.not_evaluated;
  },
  setOfferDeclinedData(state, payload) {
    state.declinedData = Object.values(payload.statistic).reverse();
  },
  setOfferAnswers(state, payload) {
    state.answersData = payload;
  },

  setDeliveredDecisionsData(state, payload) {
    state.deliveredDecisionsData = payload;
  },
  // setDecisionsData(state, payload) {state.decisionsData = payload},
};

export default {
  state,
  getters,
  actions,
  mutations,
};
