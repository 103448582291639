<template>
  <div class="modatta-settings billing">
    <section class="billing-information">
      <header>
        <h2>{{ $t('settings_billing.section_1_title') }}</h2>
        <modatta-icon icon="billing" width="17" height="20"></modatta-icon>
      </header>
      <!-- <div class="modatta-segment-control">
                <button class="button" @click="form.billing_entity = 'personal'" :active="form.billing_entity === 'personal'">{{ $t( 'settings_billing.section_1_personal_switch_label' ) }}</button>
                <button class="button" @click="form.billing_entity = 'company'" :active="form.billing_entity === 'company'">{{ $t( 'settings_billing.section_1_company_switch_label' ) }}</button>
            </div> -->
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">{{ $t('settings_profile.section_2_vat_label') }}</label>
            <div class="input-wrapper" :class="{ 'has-error': $v.form.company_vat.$error }">
              <input
                type="text"
                v-model="$v.form.company_vat.$model"
                v-bind:placeholder="$t('settings_profile.section_2_vat_input')"
              />
              <modatta-icon v-if="$v.form.company_vat.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            </div>
          </div>
        </div>
        <!-- <div class="column">
                    <div class="field">
                        <label class="label">{{ $t( 'settings_profile.section_1_vat_label' ) }}</label>
                        <div class="input-wrapper" :class="{'has-error': $v.form.personal_vat.$error}">
                            <input v-model="$v.form.personal_vat.$model" type="text" v-bind:placeholder="$t('settings_profile.section_1_vat_input')" />
                            <modatta-icon v-if="$v.form.personal_vat.$error" icon="error-exclamation-point" width="20"></modatta-icon>
                        </div>
                    </div>
                </div> -->
      </div>
      <div class="input-wrapper field">
        <label class="label">{{ $t('settings_billing.section_1_address_label') }}</label>
        <input type="text" v-model="form.billing_address" />
      </div>
      <div class="columns">
        <div class="column">
          <div class="input-wrapper field">
            <label class="label">{{ $t('settings_billing.section_1_zipcode_label') }}</label>
            <input type="text" v-model="form.billing_postalcode" placeholder="" />
          </div>
        </div>
        <div class="column is-three-fifths">
          <div class="field">
            <label class="label">{{ $t('settings_billing.section_1_country_label') }}</label>
            <div class="modatta-country-select">
              <multiselect
                disabled
                v-bind:placeholder="$t('settings_billing.choose_country')"
                selectLabel="ENTER to select"
                v-model="selectedCountry"
                openDirection="bottom"
                :options="countries"
                :allow-empty="false"
                :showLabels="false"
                track-by="name"
                label="name"
              >
                <span class="caret" slot="caret">
                  <modatta-icon icon="arrow-down" width="10"></modatta-icon>
                </span>
                <template slot="singleLabel" slot-scope="props">
                  <img :src="getFlag(props.option.code)" />
                  {{ props.option.name }}
                </template>
                <template slot="option" slot-scope="props">
                  <img :src="getFlag(props.option.code)" />
                  {{ props.option.name }}
                </template>
              </multiselect>
            </div>
            <span v-if="denyGEO(['GEO32'])">
              {{ $t('settings_billing.current_vat') }} {{ this.user.user.vat }}
              <b-tooltip
                v-bind:label="$t('settings_billing.current_vat_tooltip')"
                type="is-dark"
                position="is-right"
                size="is-small"
                multilined
              >
                <modatta-icon icon="info-square"></modatta-icon>
              </b-tooltip>
            </span>
          </div>
        </div>
      </div>
      <button class="button is-primary" :disabled="$parent.isLoading" @click="updateUser()">
        <template>{{ $t('settings_billing.button_save_label') }}</template>
        <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
      </button>
    </section>
    <!-- <div class="billing-table">
            <header>
                <h2>{{ $t( 'settings_billing.section_2_title' ) }}</h2>
                <modatta-icon icon="document" width="20" height="22"></modatta-icon>
            </header>
            <modatta-table :data="billingData" :stripped="true" :equal-width="true" :side-padding="20"></modatta-table>
        <div v-if="!isLoadingBilling" class="balance-table">
            <modatta-table :data="billingData" :stripped="true" :equal-width="true" side-padding="20"></modatta-table>
        </div>
        <div v-else class="loading">
            <div class="is-loading loading-row" v-for="(row, index) in 4" :key="index"></div>
        </div>
    </div> -->
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { required, url, email, numeric, minLength, maxLength } from 'vuelidate/lib/validators';
  import Multiselect from 'vue-multiselect';
  import { findFlagUrlByIso2Code } from 'country-flags-svg';
  import { DateTime } from 'luxon';
  export default {
    components: { Multiselect },
    data() {
      return {
        form: {},
        selectedCountry: {},
        countries: [
          { name: this.$t('labels.GEO1'), code: 'PT', country_id: 'GEO1' },
          // {name: this.$t('labels.GEO2'), code: 'ES', country_id: 'GEO2'},
          { name: this.$t('labels.GEO32'), code: 'BR', country_id: 'GEO32' },
        ],
        billingData: [],
        isLoadingBilling: false,
      };
    },
    mounted() {
      this.form.billing_entity = 'company';
      this.form = {
        company_vat: this.user.user.company_vat,
        personal_vat: this.user.user.personal_vat,
        billing_entity: this.user.user.billing_entity,
        billing_address: this.user.user.billing_address,
        billing_postalcode: this.user.user.billing_postalcode,
        billing_country: this.user.user.billing_country,
      };

      if (this.user.user.billing_country) {
        this.selectedCountry = this.countries.find((c) => c.country_id == this.user.user.billing_country);
      } else {
        this.selectedCountry = this.countries.find((c) => c.country_id == this.user.user.country_id);
      }

      if (this.$store.state.budget.billing.length === 0) {
        this.isLoadingBilling = true;
        this.getBilling().then(() => {
          this.$store.state.budget.billing.forEach((b) => {
            // Add value to the correct format
            b.date = this.setDate(b.created_at);
            b.invoice = { type: 'button', icon: 'download', url: b.invoice_url };
            delete b.id;
            delete b.value;
            delete b.created_at;
            delete b.invoice_url;
          });
          this.billingData = this.$store.state.budget.billing;
          this.checkIfBillingDataIsEmpty();
          this.isLoadingBilling = false;
        });
      }
      this.checkIfBillingDataIsEmpty();
    },
    // Validar formato CPF/CNPJ
    validations: {
      form: {
        // email: {
        //     required,
        //     // email
        // },
        company_vat: {
          minLength: minLength(8),
          maxLength: maxLength(14),
        },
        personal_vat: {
          minLength: minLength(8),
          maxLength: maxLength(14),
        },
      },
    },
    computed: {
      ...mapState(['user']),
    },
    methods: {
      ...mapActions(['getMovements', 'getBilling']),
      updateUser() {
        this.form.billing_country = this.selectedCountry.country_id;
        const body = JSON.stringify(this.form);
        this.$parent.changeUser(body);
      },
      checkIfBillingDataIsEmpty() {
        let msg = 'settings_billing.table_no_history';
        if (this.billingData.length === 0) {
          this.billingData.push({ type: this.$t(msg) });
          // this.billingData.push({date: '31-12-2019', type: 'No Billing history yet.', invoice: {type: 'button', icon: 'download', url: 'https://zecoda.nyc3.digitaloceanspaces.com/assets/media/meta-home.png'}})
        }
      },
      getFlag(code) {
        if (code == null) return null;
        return findFlagUrlByIso2Code(code);
      },
      setDate(date) {
        // const newFormat = Object.assign(DateTime.DATE_MED, DateTime.TIME_SIMPLE);
        return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
      },
      denyGEO(countries) {
        return countries.includes(this.user.user.country_id) ? false : true;
      },
    },
  };
</script>
