<template>
  <div class="validation-option postback">
    <div class="field">
      <label class="label">{{ $t('offers_step4.box_section_3_val_4_subtitle_2') }}</label>
    </div>
    <div class="configuration-steps">
      <div class="step" v-for="(step, index) in configSteps" :key="index">
        {{ step }}
      </div>
    </div>
    <div class="field">
      <label class="label">{{ $t('offers_step4.copy_snippet') }} 1</label>
    </div>
    <div class="instructions">
      <button class="button is-subtle" @click="copyCodeSnippet('all_pages')">
        <modatta-icon icon="duplicate"></modatta-icon>
        <span>{{ $t('offers_step4.copy') }}</span>
      </button>
      <pre class="language-js">
        <code>&lt;script&gt;</code>
        <code v-html="firstPageSnippet"></code>
        <code>&lt;/script&gt;</code>
      </pre>
    </div>
    <div class="field">
      <label class="label">{{ $t('offers_step4.copy_snippet') }} 2</label>
    </div>
    <div class="instructions">
      <button class="button is-subtle" @click="copyCodeSnippet('final_page')">
        <modatta-icon icon="duplicate"></modatta-icon>
        <span>{{ $t('offers_step4.copy') }}</span>
      </button>
      <pre class="language-js">
        <code>&lt;script&gt;</code>
        <code v-html="finalPageSnippet"></code>
        <code>&lt;/script&gt;</code>
      </pre>
    </div>
    <div class="test">
      <div v-if="isValidated" class="start">
        <modatta-icon icon="green-checkmark"></modatta-icon>
        <span>{{ $t('offers_step4.gtm_validated') }}</span>
      </div>
      <template v-else>
        <div>
          <div v-if="url != null && isValid" class="testing-url">
            <template v-if="!hasModId(url)">
              {{ `${url}${hasModId(url) ? '&' : '?'}modid={modid}` }}
            </template>
            <template v-else>{{ `${url}` }}</template>
          </div>
          <button
            class="button is-secondary"
            style="margin-top: 24px; width: auto"
            :disabled="!url || !isValid || isLoading"
            @click="testPostback(true)"
          >
            <span>{{ $t('offers_step4.test_gtm') }}</span>
            <modatta-icon v-if="isLoading" icon="loading" class="rotating"></modatta-icon>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
  import copy from 'copy-to-clipboard';
  import Utilities from '../../../../../helpers/utilities.js';
  import { sha256 } from 'js-sha256';
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['offerId', 'url', 'isValid'],
    data() {
      return {
        formattedURL: '',
        isLoading: false,
        isValidated: false,
        instructionsURL: this.$t('offers_step4.box_section_3_val_5_modatta_code'),
        configSteps: [
          this.$t('offers_step4.box_section_3_val_4_step_1'),
          this.$t('offers_step4.box_section_3_val_4_step_2'),
          this.$t('offers_step4.box_section_3_val_4_step_3'),
          this.$t('offers_step4.box_section_3_val_4_step_4'),
          this.$t('offers_step4.box_section_3_val_4_step_5'),
        ],
        firstPageSnippet: `
        var params = new URLSearchParams(window.location.search);
        if (params.has('modid')) {
            window.localStorage.setItem('modid', params.get('modid'))
        }`,

        finalPageSnippet: `
    var l = function () {
        var params = new URLSearchParams(window.location.search);
        if (params.has('modid')) {
            window.localStorage.setItem('modid', params.get('modid'))
        }
        return params.get('modid');
    }

    var c = function () {
        var api = "${process.env.VUE_APP_PB_URL}external/gtm";
        var sk = window.localStorage.getItem('modid') || null;

        if (!sk) { sk = l(); }

        if (sk) {
            fetch(api.concat('/', sk))
                .then(function (result) { return result.text() })
                .then(function (data) { console.log(data) })
                .catch(function (err) { thow("Fetch Error :-S", err) })
        }
        else {
            console.log("Undefined 'modid' var.");
        }
    }
    c();`,
      };
    },
    mounted() {
      if (this.url) {
        this.setModid();
        this.testPostback(false);
      }
    },
    watch: {
      url() {
        // When user changes URL;
        this.setModid();
      },
    },
    computed: {
      validatedPostback() {
        return this.$store.state.offer.validatePostback;
      },
      hash() {
        return 'gtm_' + sha256(`${this.$store.state.user.user.id}-${this.offerId}`);
      },
    },
    methods: {
      ...mapActions(['validatePostback']),
      ...mapMutations(['setOpenToast']),
      hasModId(url) {
        return (this.url?.trim()?.length || 0) > 0 ? url.includes('modid={modid}') : false;
      },
      validURL(str) {
        return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g.test(str);
      },
      setModid() {
        if (this.url) {
          if ((this.url?.trim()?.length || 0) > 0 && this.url.includes('modid={modid}')) {
            this.formattedURL = Utilities.replaceModid(this.url, this.hash);
          } else {
            this.formattedURL = Utilities.addModid(this.url);
          }
        }
      },

      testPostback(isFromButton) {
        this.isLoading = true;
        const body = JSON.stringify({
          offer_id: this.offerId,
          modid: this.hash,
        });
        // if (isFromButton) {window.open(`${this.url}${(this.hasOtherQueries)?'&':'?'}modid=${this.hash}`, '_blank');}
        this.validatePostback(body).then(() => {
          if (this.validatedPostback) this.isValidated = true;
          else {
            if (isFromButton) {
              try {
                this.formattedURL = Utilities.replaceModid(this.formattedURL, this.hash);
                window.open(this.formattedURL, '_blank');
              } catch (e) {}
            }
          }
          this.isLoading = false;
        });
      },
      copyCodeSnippet(page) {
        if (page == 'all_pages') {
          copy('<script>' + this.firstPageSnippet + '\n<\/script>');
          this.setOpenToast({
            title: 'Copied to clipboard',
            isSuccess: true
          });
        } else {
          copy('<script>' + this.finalPageSnippet + '\n<\/script>');
          this.setOpenToast({
            title: 'Copied to clipboard',
            isSuccess: true
          });
        }
      },
    },
  };
</script>
