import store from '../store';
import axios from 'axios';

store.subscribe((mutation) => {
  // If we are setting our token, set our axios headers and store it
  switch (mutation.type) {
    case 'setToken':
      if (mutation.payload) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
        localStorage.setItem('token', mutation.payload);
      } else {
        axios.defaults.headers.common['Authorization'] = null;
        localStorage.removeItem('token');
      }
      break;

    default:
      break;
  }
});

store.subscribeAction({
  error: (action, state, error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
    }
  },
});
