// const intTypeCosts = {
// GEO1: [
// {
// intList: ["INT1", "INT3", "INT6", "INT7"],
// options: ['0.10', '0.15', '0.25', '0.35', '0.50', '0.75', '1.00'],
// },
// ]
// }

// PT
const GEO1 = (id) => {
  switch (id) {
    // Read Text Message
    case 'INT1':
    case 'INT3':
      return ['0.10', '0.15', '0.25', '0.35', '0.50', '0.75', '1.00'];
    // Watch a Video
    case 'INT2':
      return ['0.20', '0.40', '0.60', '0.80', '1.00', '1.50', '2.00'];
    // Execute a Task Online
    case 'INT4':
      return ['1.00', '1.50', '2.00', '2.50', '5.00', '7.50', '10.00'];
    // Execute a Task Offline
    case 'INT5':
    case 'INT8':
      return ['2.00', '4.00', '6.00', '8.00', '10.00', '15.00', '20.00'];
    //Answer survey
    case 'INT6':
    case 'INT7':
      return ['0.50', '1.00', '2.00', '3.00', '5.00', '10.00', '20.00'];
    //Social Interactions
    case 'INT12':
    case 'INT13':
    case 'INT14':
      return ['0.25', '0.50', '0.75', '1.00', '1.25', '1.50', '2.00'];
    // Other
    default:
      return ['0.25', '0.50', '1.00', '2.00', '3.00', '5.00', '10.00'];
  }
};

// BR
const GEO32 = (id) => {
  switch (id) {
    // Read Text Message
    case 'INT1':
    case 'INT3':
      return ['0.5', '0.75', '1.00', '2.00', '3.00', '4.00', '5.00'];
    // Watch a Video
    case 'INT2':
      return ['1.00', '1.5', '2.00', '4.00', '6.00', '8.00', '10.00'];
    // Execute a Task Online
    case 'INT4':
      return ['5.00', '7.50', '10.00', '15.00', '25.00', '35.00', '50.00'];
    // Execute a Task Offline
    case 'INT5':
    case 'INT8':
      return ['10.00', '20.00', '30.00', '40.00', '50.00', '75.00', '100.00'];
    //Answer survey
    case 'INT6':
    case 'INT7':
      return ['2.50', '5.00', '10.00', '15.00', '25.00', '50.00', '100.00'];
    //Social Interactions
    case 'INT12':
    case 'INT13':
    case 'INT14':
      return ['1.00', '2.00', '3.00', '4.00', '5.00', '7.50', '10.00'];
    // Other
    default:
      return ['1.00', '2.50', '5.00', '10.00', '15.00', '25.00', '50.00'];
  }
};

export default {
  GEO1,
  GEO32,
};
