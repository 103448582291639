<template>
  <div class="main-p-frame">
    <div class="drawer-frame" :class="{ open: isGetStartedDrawer }" @click="isGetStartedDrawer = false">
      <div class="inner">
        <div class="getstarted-db">
          <div class="modatta-consent-inline">
            <div class="contain-phone">
              <header>
                <div class="drawer-title">{{ $t('offers_steps_preview_consent.title') }}</div>
              </header>
              <div class="content">
                <div class="sections">
                  <section>
                    <div>
                      <label>{{ $t('offers_steps_preview_consent.section_1_title_1') }}</label>
                      <div>{{ interactionValidation }}</div>
                    </div>
                    <div>
                      <label>{{ $t('offers_steps_preview_consent.section_1_title_2') }}</label>
                      <div>{{ offer.country_id ? $t('labels.' + offer.country_id) : '...' }}</div>
                    </div>
                    <div>
                      <label>{{ $t('offers_steps_preview_consent.section_1_title_3') }}</label>
                      <div v-text="'N/A'"></div>
                    </div>
                  </section>
                  <!-- TODO: Validar -->
                  <section v-if="tags">
                    <label>{{ $t('offers_steps_preview_consent.section_2_title_1') }}</label>
                    <div class="tags-wrapper">
                      <div
                        v-for="(tag, index) in tags"
                        :key="index"
                        :class="{
                          tag: tag[0] !== 'No data will be used',
                          'tag-category': tag.category,
                          [tag.category]: tag.category,
                          hide: tag.profile_id === 'PRO7' && tag.operator_id == 'OPE7',
                        }"
                      >
                        <span v-if="tag.category == 'profile' && tag.profile_id !== 'PRO7'">
                          {{ $t(`labels.` + tag.profile_id) }}
                        </span>
                        <span v-if="tag.category === 'question'" class="question">{{
                          $t(`offers_step2.table_section_4_title`)
                        }}</span>
                        <span v-if="tag.category === 'offers'" class="offers">{{
                          $t(`offers_step2.table_section_3_title`)
                        }}</span>
                        <span v-if="tag.category == 'interests'" class="interests">{{
                          $t(`labels.` + tag.profile_id)
                        }}</span>
                        <span v-if="tag == 'No data will be used'">
                          {{ $t('offers_steps_preview_consent.no_data') }}
                        </span>
                        <span
                          v-if="tag.category == 'profile' && tag.profile_id === 'PRO7' && tag.operator_id == 'OPE8'"
                        >
                          {{ $t(`labels.` + tag.profile_id) }}
                        </span>
                        <span v-else></span>
                      </div>
                    </div>
                  </section>
                  <section>
                    <label>{{ $t('offers_steps_preview_consent.section_3_title_1') }}</label>
                    <div class="tags-wrapper">
                      <div class="tag">
                        {{ $t('offers_steps_preview_consent.no_data_share') }}
                      </div>
                    </div>
                  </section>
                  <section>
                    <label>{{ $t('offers_steps_preview_consent.section_4_title_1') }}</label>
                    <div class="tags-wrapper">
                      <div class="tag">{{ $t('offers_steps_preview_consent.no_data') }}</div>
                    </div>
                  </section>
                </div>

                <button class="closebtn" @click="isGetStartedDrawer = false">
                  {{ $t('offers_steps_preview_main.close_label') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="drawer-frame" :class="{ open: isPreviewConsentDrawer }" @click="isPreviewConsentDrawer = false">
      <div class="inner" v-if="isPreviewConsentDrawer">
        <div class="preview-dh">
          <p>{{ $t('offers_steps_preview_main.section_2_tc') }}</p>
        </div>
        <div class="preview-db">
          <p>{{ $t('offers_steps_preview_main.conditions') }}</p>
          <br />
          <button class="closebtn" @click="isGetStartedDrawer = false">
            {{ $t('offers_steps_preview_main.close_label') }}
          </button>
        </div>
      </div>
    </div>

    <div id="phone-frame" class="phone-frame" :class="{ 'is-consent-drawer-open': isConsentDrawerOpen }">
      <div class="component">
        <div class="offer-header contain-phone">
          <div class="component-wrap">
            <div class="background">
              <img v-bind:src="bannerUrl" alt="" />
            </div>
            <div class="card">
              <div class="navigation-wrap contain-phone">
                <div class="grid">
                  <div class="row">
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col"></div>
                  </div>
                </div>
              </div>
              <div class="avatar-wrap contain-phone">
                <div class="grid">
                  <div class="row">
                    <div class="col">
                      <div
                        class="avatar"
                        style="
                          width: 58px;
                          height: 58px;
                          border-radius: 58px;
                          overflow: hidden;
                          border: 1px solid #dee5f6;
                          background-color: #ffffff;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <!-- <img src="../../../assets/components/offer-header/avatar.png" alt=""> -->
                        <div
                          class="logo"
                          :style="{
                            'background-image':
                              brand != null ? `url(${brand.logo_url})` : 'linear-gradient(to right, #E7EDF9, #F7F9FD)',
                          }"
                        ></div>
                      </div>
                    </div>
                    <div class="col"></div>
                  </div>
                </div>
              </div>
              <div class="headline-wrap contain-phone">
                <div>
                  <p class="title" v-text="brand != null ? brand.name : '...'"></p>
                </div>
                <div>
                  <p class="description" v-text="offer != null ? offer.name : '...'"></p>
                </div>
              </div>
              <div class="details-wrap">
                <div class="chip">
                  <img :src="'/svg/offer_types/' + offer.type_label_id + '.svg'" alt="" v-if="offer.type_label_id" />
                  <span
                    >{{ $t('offers_steps_preview_main.banner_text_1') }} <strong v-text="offerDescription"></strong>
                    {{ $t('offers_steps_preview_main.banner_text_2') }}
                    {{ $t('labels.' + interactionType) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="numbers contain-phone">
          <div class="component-wrap">
            <div class="left">
              <span class="left-title">
                <button class="info-outline-btn" id="hover-trigger-time"></button>
                {{ $t('offers_steps_preview_main.section_1_subtitle_1') }}
              </span>
              <div class="timer" v-if="offer.validation_max_hours && offer.validation_max_hours > 0">
                <div class="days">
                  <span class="number-p" v-text="timer.days"></span>
                  <!-- Traduzir -->
                  <span
                    class="label"
                    v-text="
                      offer.interaction_type_id === 'INT5'
                        ? 'Day'
                        : this.$t('offers_steps_preview_main.section_1_subtitle_1_days')
                    "
                  ></span>
                </div>
                <div class="hours">
                  <!-- Traduzir -->
                  <span class="number-p" v-text="timer.hours"></span>
                  <span class="label">{{ $t('offers_steps_preview_main.section_1_subtitle_1_hours') }}</span>
                </div>
                <div class="minutes">
                  <span class="number-p" v-text="timer.minutes"></span>
                  <span class="label">{{ $t('offers_steps_preview_main.section_1_subtitle_1_mins') }}</span>
                </div>
                <div class="seconds">
                  <span class="number-p" v-text="timer.seconds"></span>
                  <span class="label">{{ $t('offers_steps_preview_main.section_1_subtitle_1_secs') }}</span>
                </div>
              </div>
              <div class="timer" v-else>
                <div class="days">
                  <span class="number-p" v-text="offer.interaction_type_id != 'INT5' ? 0 : 1"></span>
                  <!-- TODO: Traduzir -->
                  <span
                    class="label"
                    v-text="
                      offer.interaction_type_id === 'INT5'
                        ? 'Day'
                        : this.$t('offers_steps_preview_main.section_1_subtitle_1_days')
                    "
                  ></span>
                </div>
                <div class="hours">
                  <span class="number-p" v-text="setValidationDeadline()"></span>
                  <span class="label">{{ $t('offers_steps_preview_main.section_1_subtitle_1_hours') }}</span>
                </div>
                <div class="minutes">
                  <span class="number-p">0</span>
                  <span class="label">{{ $t('offers_steps_preview_main.section_1_subtitle_1_mins') }}</span>
                </div>
                <div class="seconds">
                  <span class="number-p">0</span>
                  <span class="label">{{ $t('offers_steps_preview_main.section_1_subtitle_1_secs') }}</span>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="available">
                <span class="right-title">
                  <button class="info-outline-btn" id="hover-trigger-time"></button>
                  {{ $t('offers_steps_preview_main.section_1_subtitle_2') }}</span
                >
                <div class="amount">
                  <span class="number-p" v-text="offer.max_accepts == null ? '...' : offer.max_accepts"></span>
                  <span class="label">{{ $t('offers_steps_preview_main.section_1_subtitle_2') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="about contain-phone">
          <div class="component-wrap">
            <!-- TODO: Traduzir -->
            <p v-text="offer == null ? 'Your offer description' : offer.description"></p>
            <div class="links">
              <a :href="brand != null ? brand.company_url : '#'" target="_blank" class="website">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_6957_22452)">
                    <path
                      d="M7.31283 13.6049C10.5553 13.6049 13.1838 10.9764 13.1838 7.73398C13.1838 4.49155 10.5553 1.86304 7.31283 1.86304C4.0704 1.86304 1.44189 4.49155 1.44189 7.73398C1.44189 10.9764 4.0704 13.6049 7.31283 13.6049Z"
                      stroke="#19D2B4"
                      stroke-width="0.986318"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.44189 8.20361H13.1838"
                      stroke="#19D2B4"
                      stroke-width="0.986318"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.31249 1.86304C8.78097 3.47071 9.61551 5.55705 9.66086 7.73398C9.61551 9.9109 8.78097 11.9972 7.31249 13.6049C5.844 11.9972 5.00946 9.9109 4.96411 7.73398C5.00946 5.55705 5.844 3.47071 7.31249 1.86304V1.86304Z"
                      stroke="#19D2B4"
                      stroke-width="0.986318"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6957_22452">
                      <rect width="14.0903" height="14.0903" fill="white" transform="translate(0.267578 0.454102)" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{{ $t('offers_steps_preview_main.website_label') }}</span>
              </a>
              <a :href="brand != null ? brand.company_privacy_url : '#'" target="_blank" class="policy">
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_6957_22446)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.2397 4.44838C14.5491 4.55168 14.7559 4.82984 14.7559 5.14244V9.71528C14.7559 10.9934 14.2688 12.2134 13.4086 13.1586C12.976 13.6346 12.4287 14.0052 11.8475 14.305L9.32847 15.6027L6.80522 14.3043C6.22326 14.0046 5.67528 13.6346 5.24199 13.1579C4.38109 12.2127 3.89258 10.992 3.89258 9.71258V5.14244C3.89258 4.82984 4.09931 4.55168 4.4087 4.44838L9.06864 2.88674C9.2343 2.83137 9.41484 2.83137 9.5798 2.88674L14.2397 4.44838Z"
                      stroke="#19D2B4"
                      stroke-width="0.986318"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.48364 9.13055L8.82314 10.4086L11.5829 7.77686"
                      stroke="#19D2B4"
                      stroke-width="0.986318"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6957_22446">
                      <rect width="16.9916" height="16.2038" fill="white" transform="translate(0.884033 0.731689)" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{{ $t('offers_steps_preview_main.privacy_label') }}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="steps contain-phone" v-if="!isOfferPollOrSurvey">
          <div class="component-wrap">
            <span class="title">{{ $t('offers_steps_preview_main.section_2_title') }}</span>
            <div class="list" v-if="offer">
              <div class="item" v-for="(step, index) in offer.steps" :key="index">
                <div class="step">
                  <span>{{ ++index }}</span>
                </div>
                <div class="description">
                  <span>{{ step.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-link contain-phone">
          <div class="component-wrap">
            <!-- {{Advertance and Conditions}} -->
            <a href="#" @click="isPreviewConsentDrawer = true"> {{ $t('offers_steps_preview_main.section_2_tc') }}</a>
          </div>
        </div>

        <div class="footer-button contain-phone">
          <div class="component-wrap">
            <button @click="isGetStartedDrawer = true">{{ $t('offers_steps_preview_main.button_label') }}</button>
          </div>
        </div>
        <br />

        <template v-if="isConsentDrawerOpen">
          <div class="modatta-consent-drawer" :class="{ open: setOpenConsentAnimated, close: !setOpenConsentAnimated }">
            <div class="content-wrapper">
              <header>
                <div class="drawer-title">{{ $t('offers_steps_preview_consent.title') }}</div>
                <button class="button close" @click="closeConsentDrawer()">
                  <modatta-icon icon="times" width="16" height="16"></modatta-icon>
                </button>
              </header>
              <div class="content">
                <div class="sections">
                  <section>
                    <div>
                      <label>{{ $t('offers_steps_preview_consent.section_1_title_1') }}</label>
                      <div>{{ interactionValidation }}</div>
                    </div>
                    <div>
                      <label>{{ $t('offers_steps_preview_consent.section_1_title_2') }}</label>
                      <div>{{ $t('labels.' + offer.country_id) }}</div>
                    </div>
                    <div>
                      <label>{{ $t('offers_steps_preview_consent.section_1_title_3') }}</label>
                      <div v-text="offer.online_on != null ? setDate(offer.online_on) : '...'"></div>
                    </div>
                  </section>
                  <section>
                    <label>{{ $t('offers_steps_preview_consent.section_2_title_1') }}</label>
                    <div class="tags-wrapper">
                      <div v-if="tags">
                        <label>{{ $t('offers_steps_preview_consent.section_2_title_1') }}</label>
                        <div class="tags-wrapper">
                          <div
                            v-for="(tag, index) in tags"
                            :key="index"
                            :class="{
                              tag: tag[0] !== 'No data will be used',
                              'tag-category': tag.category,
                              [tag.category]: tag.category,
                              hide: tag.profile_id === 'PRO7' && tag.operator_id == 'OPE7',
                            }"
                          >
                            <span v-if="tag.category == 'profile' && tag.profile_id !== 'PRO7'">
                              {{ $t(`labels.` + tag.profile_id) }}
                            </span>
                            <span v-if="tag.category === 'question'" class="question">{{
                              $t(`offers_step2.table_section_4_title`)
                            }}</span>
                            <span v-if="tag.category === 'offers'" class="offers">{{
                              $t(`offers_step2.table_section_3_title`)
                            }}</span>
                            <span v-if="tag.category == 'interests'" class="interests">{{
                              $t(`labels.` + tag.profile_id)
                            }}</span>
                            <span v-if="tag == 'No data will be used'">
                              {{ $t('offers_steps_preview_consent.no_data') }}
                            </span>
                            <span
                              v-if="tag.category == 'profile' && tag.profile_id === 'PRO7' && tag.operator_id == 'OPE8'"
                            >
                              {{ $t(`labels.` + tag.profile_id) }}
                            </span>
                            <span v-else></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section>
                    <label>{{ $t('offers_steps_preview_consent.section_3_title_1') }}</label>
                    <div class="tags-wrapper">
                      <div class="tag">
                        {{ $t('offers_details.section_5_no_data2') }}
                      </div>
                    </div>
                  </section>
                  <section>
                    <label>{{ $t('offers_steps_preview_consent.section_4_title_1') }}</label>
                    <div class="tags-wrapper">
                      <div class="tag">{{ $t('offers_steps_preview_consent.no_data') }}</div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon';
  import { mapState, mapActions } from 'vuex';
  import lodash from 'lodash';
  import SetValidationType from '../../../../../helpers/set-validation-type.js';
  import SetInteractionType from '../../../../../helpers/set-interaction-type.js';
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['offer', 'brandId'],
    data() {
      return {
        brand: null,
        isPreviewConsentDrawer: false,
        isGetStartedDrawer: false,
        isConsentDrawerOpen: false,
        setOpenConsentAnimated: false,
        timer: {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        },
      };
    },
    mounted() {
      this.getBrand(this.brandId).then((r) => (this.brand = r.data.result[0]));
      if (this.offer.validation_max_hours) {
        var timer = this.splitHours(this.offer.validation_max_hours);
        this.timer.days = timer.days;
        this.timer.hours = timer.hours;
        this.timer.minutes = timer.minutes;
        this.timer.seconds = timer.seconds;
      }
    },
    computed: {
      ...mapState(['newOfferType']),
      offerDescription() {
        return this.offer.unit_tcost != null
          ? this.$t(`labels.${this.offer.currency_id}`) +
              `${Math.round((this.offer.unit_tcost * 0.4 + Number.EPSILON) * 100) / 100}`
          : '...';
      },

      isOfferPollOrSurvey() {
        return this.offer.type_label_id === 'OFT18' || this.offer.type_label_id === 'OFT19';
      },
      interactionType() {
        return SetInteractionType(this.offer.interaction_type_id);
      },
      interactionValidation() {
        if (this.offer.interaction_validation_id == null) return '...';
        return SetValidationType(this.offer.interaction_validation_id);
      },
      tags() {
        const tags = this.offer.grouped_filters.flat();

        // Remove duplicates of tags that have not the following categories:
        // Interests or Profile
        // For Interests and Profile we ouput the translated profile by profile id
        // For the rest we will output the category of segmentation since their profile id might not be self-explantory

        const filteredTags = tags.reduce((accumulator, currentValue) => {
          if (
            currentValue['category'] === 'profile' ||
            currentValue['category'] === 'interests' ||
            !accumulator.find((item) => item['category'] === currentValue['category'])
          ) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, []);

        const unique = lodash.uniqBy(filteredTags, 'profile_id');

        if (unique.length == 0) return ['No data will be used'];
        // return tags.map(f => this.$t((`labels.${f.[NEW_KEY]}`));

        return unique;
      },
      bannerUrl() {
        if (this.offer.banner_url == null && this.offer.banner != null) {
          return URL.createObjectURL(this.offer.banner);
        }
        return this.offer.banner_url;
      },
    },
    methods: {
      ...mapActions(['getBrand']),
      splitHours(totalHours) {
        const secondsPerMinute = 60;
        const secondsPerHour = 60 * secondsPerMinute;
        const days = Math.floor(totalHours / 24);
        const hours = Math.floor(totalHours % 24);
        const minutes = Math.floor(((totalHours % 1) * secondsPerHour) / secondsPerMinute);
        const seconds = Math.floor(((totalHours % 1) * secondsPerHour) % secondsPerMinute);

        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      },
      openConsentDrawer() {
        this.isConsentDrawerOpen = true;
        setTimeout(() => (this.setOpenConsentAnimated = true), 10);
      },
      closeConsentDrawer() {
        this.setOpenConsentAnimated = false;
        setTimeout(() => (this.isConsentDrawerOpen = false), 300);
      },

      setValidationDeadline() {
        switch (this.offer.interaction_type_id) {
          case 'INT4':
            return 4;
          case 'INT5':
            return 0;
          default:
            return 1;
        }
      },

      setDate(date) {
        if (date === null) return '';
        let formatted = DateTime.fromSQL(date).toFormat('dd/MM/yyyy');
        return formatted;
      },
    },
  };
</script>

<style scoped>
  .contain-phone {
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }

  .contain-phone > .inner {
    max-width: 100%;
  }

  .contain-phone--only-left,
  .contain-phone--only-right {
    padding-right: 25px;
    padding-left: 25px;
    z-index: 10;
    width: 100%;
  }

  .offer-header {
    padding: 0;
  }

  .offer-header .component-wrap {
    height: 328px;
    background-color: #f4f5f6;
    position: relative;
  }

  .offer-header .component-wrap .contain-phone {
    padding: 0 20px;
  }

  .offer-header .component-wrap .row,
  .offer-header .component-wrap .grid,
  .offer-header .component-wrap .col {
    margin: 0;
    padding: 0;
  }

  .offer-header .component-wrap .card {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 25px 0;
    height: 100%;
    background-color: transparent;
  }

  .offer-header .component-wrap .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  .offer-header .component-wrap .background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .offer-header .component-wrap .background::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.85) 70.89%);
    opacity: 0.9;
    content: '';
  }

  .offer-header .component-wrap .navigation-wrap {
    flex: 1 1 auto;
    padding-bottom: 23px;
  }

  .offer-header .component-wrap .navigation-wrap button {
    font-size: 24px;
    width: 24px;
    height: 24px;
    background-color: transparent;
  }

  .offer-header .component-wrap .avatar-wrap,
  .offer-header .component-wrap .headline-wrap,
  .offer-header .component-wrap .details-wrap {
    flex: 0 1 auto;
  }

  .offer-header .component-wrap .avatar-wrap {
    padding-bottom: 20px;
  }

  .offer-header .component-wrap .avatar-wrap img,
  .offer-header .component-wrap .avatar-wrap .logo {
    width: 58px;
    height: 58px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
  }

  .offer-header .component-wrap .headline-wrap p {
    margin: 0;
    text-overflow: ellipsis;
  }

  .offer-header .component-wrap .headline-wrap p.title {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  .offer-header .component-wrap .headline-wrap .description {
  }

  .offer-header .component-wrap .headline-wrap .title {
    font-family: 'Silka Semibold';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 14px;
  }

  .offer-header .component-wrap .headline-wrap .description {
    font-family: 'Silka Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: #ffffff;
    max-height: 52px;
    overflow: hidden;
    display: inline;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    /* Important for long words! */
  }

  .offer-header .component-wrap .details-wrap {
    padding-top: 20px;
    padding-bottom: 3px;
  }

  .offer-header .component-wrap .details-wrap .chip {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 15px;
    background: rgba(25, 210, 180, 0.3);

    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);

    border-radius: 11px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 15px 0 20px;
    margin: 0;
    width: 328px;
    min-height: 38px;
    max-width: 100%;
    font-family: 'Silka Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.111765px;
    color: #ffffff;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .offer-header .component-wrap .details-wrap .chip strong {
    font-weight: 700;
    color: #19d2b4;
  }

  .numbers {
    background-color: #f3f4f5;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .numbers .component-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
    gap: 50px;
    position: relative;
  }

  .numbers .component-wrap .number-p {
    font-family: 'Silka Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #101010;
    border-radius: 4px;
    background: #ffffff;
  }

  .numbers .component-wrap .label {
    font-family: 'Silka Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 140%;
    color: rgba(16, 16, 16, 0.7);
  }

  .numbers .component-wrap .left-title,
  .numbers .component-wrap .right-title {
    font-family: 'Silka Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(16, 16, 16, 0.7);
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .numbers .component-wrap .left-title > div {
    z-index: 10;
  }

  .numbers .component-wrap .left {
    text-align: center;
    position: relative;
  }

  .numbers .component-wrap .left .timer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 14px;
  }

  .numbers .component-wrap .left .timer > div,
  .numbers .component-wrap .right .available > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
  }

  .numbers .component-wrap .left .timer > div .number-p {
    width: 34px;
    height: 28px;
    line-height: 28px;
  }

  .numbers .component-wrap .left .timer > div::after {
    content: '\003A';
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
    height: 28px;
    line-height: 28px;
    transform: translateX(100%);
  }

  .numbers .component-wrap .left .timer > div:last-child::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 2px;
    height: 22px;
    line-height: 28px;
    background-color: rgba(0, 0, 0, 0.1);
    transform: translateX(25px) translateY(2px);
  }

  .numbers .component-wrap .right {
    flex-grow: 1;
  }

  .numbers .component-wrap .right .amount .number-p {
    width: 95px;
    height: 28px;
    display: block;
    line-height: 28px;
    margin: 0 auto;
    text-align: center;
  }

  .about .component-wrap {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .about p {
    font-family: 'Silka Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.2px;
    color: #101010;
    overflow-wrap: break-word;
  }

  .about .links {
    margin-top: 20px;
  }

  .about a {
    font-family: 'Silka Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 10px;
    color: #19d2b4;
    height: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 6px;
  }

  .about a:first-child {
    margin-right: 22px;
  }

  .steps .component-wrap {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .steps .title {
    font-family: 'Silka Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #101010;
    margin-bottom: 20px;
    display: block;
  }

  .steps .list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .steps .list .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
  }

  .steps .list .item .step {
    width: 21px;
  }

  .steps .list .item .step span {
    display: inline-flex;
    width: 21px;
    height: 21px;
    border-radius: 21px;
    justify-content: center;
    align-items: center;
    font-family: 'Silka Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #585858;
    border: 1.2px solid #585858;
  }

  .steps .list .item .description {
    font-family: 'Silka Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: #585858;
    min-height: 22px;
    line-height: 22px;
    max-width: calc(100% - 37px);
    overflow-wrap: break-word;
  }

  .footer-link a {
    font-family: 'Silka Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #19d2b4;
    text-align: center;
    display: block;
    text-decoration: none;
  }

  .footer-button {
    margin-top: 20px;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
    filter: drop-shadow(0px -4px 8px rgba(0, 0, 0, 0.05));
    padding-top: 21px;
    padding-bottom: 32px;
  }

  .footer-button .component-wrap {
    display: block;
    width: 100%;
  }

  .footer-button .component-wrap button {
    width: 100%;
    height: 100%;
    min-height: 56px;
    background: #19d2b4;
    mix-blend-mode: normal;
    border-radius: 12px;
    font-family: 'Silka Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #101010;
    box-shadow: none;
    outline: none;
    border: 0;
  }
</style>
