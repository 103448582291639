import headers from './headers';

async function attemptAuthentication() {
  return await axios.get(`${process.env.VUE_APP_API_URL}auth/me`, { headers });
}

async function createUser(data) {
  return await axios.put(`${process.env.VUE_APP_API_URL}auth/register`, data, { headers });
}

async function updateUser(data) {
  return await axios.post(`${process.env.VUE_APP_API_URL}auth/update`, data, { headers });
}

function authenticateUser(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}auth/login`, data, { headers });
}

async function signOut() {
  return await axios.get(`${process.env.VUE_APP_API_URL}auth/logout`, { headers });
}

async function getBalance() {
  return await axios.get(`${process.env.VUE_APP_API_URL}balance`, { headers });
}

async function isEmailAvailable(data) {
  return await axios.post(`${process.env.VUE_APP_API_URL}auth/available`, data, { headers });
}

async function sendRecoverPasswordLink(data) {
  return await axios.post(`${process.env.VUE_APP_API_URL}auth/recoverlink`, data, { headers });
}

async function setNewPassword(data) {
  return await axios.post(`${process.env.VUE_APP_API_URL}auth/password`, data, { headers });
}

async function setForgotPassword(data) {
  return await axios.post(`${process.env.VUE_APP_API_URL}auth/reset`, data, { headers });
}

async function getCredentials() {
  return await axios.get(`${process.env.VUE_APP_API_URL}integrations/lojaintegrada/authkey`, { headers });
}

async function setCredentials(data) {
  return await axios.post(`${process.env.VUE_APP_API_URL}integrations/lojaintegrada/authkey`, data, { headers });
}

export default {
  signOut: () => signOut(),
  getBalance: () => getBalance(),
  createUser: (data) => createUser(data),
  updateUser: (data) => updateUser(data),
  setNewPassword: (data) => setNewPassword(data),
  setForgotPassword: (data) => setForgotPassword(data),
  authenticateUser,
  isEmailAvailable: (data) => isEmailAvailable(data),
  attemptAuthentication: () => attemptAuthentication(),
  sendRecoverPasswordLink: (data) => sendRecoverPasswordLink(data),
  getCredentials: () => getCredentials(),
  setCredentials: (data) => setCredentials(data),
};
