import api from '../api/budget_api';

const state = {
  billing: [],
  movements: [],
  balanceChart: null,
  committedBalance: {},
};

const actions = {
  async getMovements({ commit }) {
    return await api.getMovements().then((response) => commit('setMovements', response.data));
  },
  async getBilling({ commit }) {
    return await api.getBilling().then((response) => commit('setBilling', response.data));
  },
  async getCommittedBalance({ commit }, data) {
    return await api.getCommittedBalance(data).then((response) => {
      const payload = { availableBalance: data, committedBalance: response.data.result };
      commit('setCommittedBalance', payload);
    });
  },
};

const mutations = {
  setMovements(state, payload) {
    state.movements = payload.result;
  },
  setBilling(state, payload) {
    state.billing = payload.result;
  },
  setCommittedBalance(state, payload) {
    state.committedBalance = payload.committedBalance;
    const totalBalance = payload.committedBalance.value * 2;
    const percentageAvailableBalance = Math.round((payload.availableBalance.value / totalBalance) * 100);
    const percentageLeftoverBalance = Math.round(100 - percentageAvailableBalance);
    state.balanceChart = {
      labels: [],
      data: [percentageAvailableBalance, percentageLeftoverBalance],
      colors:
        payload.availableBalance.value - payload.committedBalance.value < 0
          ? ['#A60202', '#EBF0FA']
          : ['#00C9C9', '#EBF0FA'],
    };
  },
};

export default {
  state,
  actions,
  mutations,
};
