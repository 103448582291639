<template>
  <div
    class="modatta-icon"
    :style="{ width: `${width}${rem ? 'rem' : 'px'}`, height: `${height}${rem ? 'rem' : 'px'}` }"
    :modatta-icon="icon"
  >
    <img
      :style="{ width: `${width}${rem ? 'rem' : 'px'}`, height: `${height}${rem ? 'rem' : 'px'}` }"
      :src="`/svg/icons/${icon}.svg`"
      :alt="`icon ${icon} Modatta`"
    />
  </div>
</template>

<script>
  export default {
    props: ['icon', 'width', 'height', 'color', 'rem'],
    data() {
      return {
        abcde: 'hello',
      };
    },
  };
</script>

<style>
  svg {
    max-width: 100%;
    max-height: 100%;
    fill: transparent !important;
  }
</style>
