<template>
  <div class="modatta-offer-decisions-timeline sub-component chart">
    <div class="header">
      <div class="chart-legend">
        <div class="data-point" v-for="(point, index) in linearChart.datasets" :key="index">
          <div class="color" :style="{ background: point.borderColor }"></div>
          <div
            class="point-label"
            :class="{ 'is-loading': !decisionsDataLoaded }"
            v-text="decisionsDataLoaded ? point.label : ''"
          ></div>
        </div>
      </div>
    </div>
    <div class="modatta-line-chart">
      <modatta-line-chart
        v-if="decisionsDataLoaded"
        :chart-data="linearChart.datasets"
        :labels="linearChart.labels"
      ></modatta-line-chart>
      <div v-else class="loading-skeleton"></div>
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon';
  import { mapActions } from 'vuex';

  export default {
    props: ['id', 'offerName'],
    data() {
      return {
        linearChart: {},
        decisionsDataLoaded: false,
        decisionsRawData: '',
        row: {
          name: '',
        },
      };
    },
    watch: {
      offerName: {
        handler: function (name) {
          this.row.name = name;
        },
        deep: true,
      },
    },
    mounted() {
      this.getDeliveredDecisionsData(this.id).then(() => {
        const labels = [],
          deliveredData = [],
          completedData = [],
          declinedData = [];

        this.$store.state.offer.deliveredDecisionsData.forEach((result) => {
          const dates = Object.keys(result.table);
          dates.forEach((date) => {
            deliveredData.push(result.table[date].delivered == null ? 0 : result.table[date].delivered);
            completedData.push(result.table[date].completed == null ? 0 : result.table[date].completed);
            declinedData.push(result.table[date].declined == null ? 0 : result.table[date].declined);

            let locale = '';
            switch (this.$i18n.locale) {
              case 'en':
                locale = 'en-GB';
                break;
              case 'pt':
                locale = 'pt-PT';
                break;
              case 'br':
                locale = 'pt-BR';
                break;
              default:
                locale = 'en-GB';
                break;
            }

            labels.push(DateTime.fromISO(date).setLocale(locale).toFormat('MMM dd').toUpperCase());
          });
        });
        this.linearChart.labels = labels;
        this.linearChart.datasets = [
          {
            label: this.$t('offers_dashboard.timeline_delivered'),
            data: deliveredData,
            fill: true,
            backgroundColor: 'rgba(116, 148, 234, 0.15)',
            borderColor: '#7494EA',
            pointBackgroundColor: '#7494EA',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBorderColor: 'rgba(255,255,255,1)',
            pointBorderWidth: 3,
            pointHoverBorderWidth: 3,
            pointRadius: 3,
            pointHoverRadius: 8,
          },
          {
            label: this.$t('offers_dashboard.timeline_completed'),
            data: completedData,
            fill: true,
            backgroundColor: 'rgba(25, 210, 180, 0.15)',
            borderColor: '#19D2B4',
            pointBackgroundColor: '#19D2B4',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBorderColor: 'rgba(255,255,255,1)',
            pointBorderWidth: 3,
            pointHoverBorderWidth: 3,
            pointRadius: 3,
            pointHoverRadius: 8,
          },
          {
            label: this.$t('offers_dashboard.timeline_declined'),
            data: declinedData,
            fill: true,
            backgroundColor: 'rgba(239, 135, 110, 0.15)',
            borderColor: '#F48266',
            pointBackgroundColor: '#F48266',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBorderColor: 'rgba(255,255,255,1)',
            pointBorderWidth: 3,
            pointHoverBorderWidth: 3,
            pointRadius: 3,
            pointHoverRadius: 8,
          },
        ];
        this.decisionsDataLoaded = true;
      });
    },
    methods: {
      ...mapActions(['getDeliveredDecisionsData', 'getDecisionsData']),
      async buildData() {
        const r = await this.getDecisionsData(this.id);
        const rawData = [];
        const decisions = r.data.result;
        const currency = {
          CUR1: '€',
          CUR2: 'R$',
        };

        decisions.forEach((decision) => {
          const row = {
            offer_id: this.id,
            name: this.row.name,
            date: decision.created_at,
            status: decision.status_id,
            value: decision.unit_tcost.value,
            currency: currency[decision.unit_tcost.currency],
          };

          rawData.push(row);
        });

        let isReady = this.row.name.length > 0;
        this.decisionsRawData = rawData;
        this.$emit('decisions-raw-data', this.decisionsRawData);
        this.$emit('data-is-ready', isReady);
      },
    },
  };
</script>

<style>
  .offer .component.data .sub-component.chart .modatta-line-chart canvas {
    height: 260px !important;
  }

  @media (max-width: 1450px) {
    .offer .component.data .sub-component.chart .modatta-line-chart canvas {
      height: 230px !important;
    }
  }
</style>
