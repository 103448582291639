<template>
  <div class="modatta-side-modal-filter-offers">
    <b-field :label="filter.title" v-for="filter in filters" :key="filter.id">
      <div class="modatta-country-select">
        <multiselect
          selectLabel="ENTER to select"
          :placeholder="filter.placeholder"
          v-model="filter.selected"
          :options="filter.options"
          :close-on-select="false"
          openDirection="bottom"
          :allow-empty="true"
          :showLabels="false"
          :multiple="true"
        >
          <span class="caret" slot="caret">
            <modatta-icon icon="arrow-down" width="10"></modatta-icon>
          </span>
        </multiselect>
      </div>
    </b-field>
    <b-field label="Filter by date">
      <div class="modatta-datepicker">
        <b-datepicker range v-model="filterDates" placeholder="Select dates"></b-datepicker>
        <modatta-icon icon="calendar"></modatta-icon>
      </div>
    </b-field>

    <button class="button is-primary" @click="applyFilters()">Search</button>
  </div>
</template>

<script>
  import Fuse from 'fuse.js';
  import { mapMutations } from 'vuex';
  import Multiselect from 'vue-multiselect';
  import SetInteractionType from '../helpers/set-interaction-type.js';

  export default {
    components: { Multiselect },
    props: ['options'],
    data() {
      return {
        offers: this.options.offers,

        filters: [],
        filterDates: [],
      };
    },
    mounted() {
      this.filters.push({
        id: 'brand',
        title: 'Filter by brand',
        placeholder: 'Select brands',
        selected: [],
        options: this.brandsOptions,
      }),
        this.filters.push({
          id: 'type',
          title: 'Filter by type',
          placeholder: 'Select offer types',
          selected: [],
          options: this.offerTypesOptionsName,
        });
    },
    computed: {
      brandsOptions() {
        const brands = this.options.offers.filter((o) => o.brand != null).map((offer) => offer.brand.name);
        return [...new Set(brands)]; // https://stackoverflow.com/a/33121880
      },
      offerInteractionTypeIds() {
        const ids = this.options.offers.filter((o) => o.interaction_type_id != null).map((o) => o.interaction_type_id);
        return [...new Set(ids)];
      },
      offerTypesOptionsName() {
        const offerTypes = this.options.offers
          .filter((o) => o.interaction_type_id != null)
          .map((o) => o.interaction_type_id);
        return [...new Set(offerTypes)].map((type) => SetInteractionType(type));
      },
      offerTypesOptions() {
        const offerTypes = [];
        for (let index = 0; index < this.offerInteractionTypeIds.length; index++) {
          const id = this.offerInteractionTypeIds[index];
          const name = this.offerTypesOptionsName[index];
          offerTypes.push({ name, id });
        }
        return offerTypes;
      },
    },
    methods: {
      ...mapMutations(['setOpenSideModal', 'setNewOfferType']),

      applyFilters() {
        const fuse = new Fuse(this.offers, {
          threshold: 0.25,
          useExtendedSearch: true,
          keys: ['brand.name', 'interaction_type_id'], // the thing is: to be able to search the origin offers we need to have its id instead of the name string
        });
        const query = this.filters[0].selected.join(' ') + ' ' + this.filters[1].selected.join(' ');
      },
    },
  };
</script>
