<template>
  <div class="modatta-side-modal-offer-step-media">
    <div class="step-media-wrapper">
      <h2>{{ $t('offers_step3.step_media_type') }}</h2>
      <div class="steps-media">
        <div class="step-media" :class="{ 'step-selected': ['MTY3', 'MTY4'].includes(type) }" @click="setType('MTY3')">
          <div>
            <modatta-icon :icon="'movie'" height="20"></modatta-icon>
          </div>
          <div class="type">Video</div>
        </div>
        <div class="step-media" :class="{ 'step-selected': type === 'MTY2' }" @click="setType('MTY2')">
          <div>
            <modatta-icon :icon="'image'" height="20"></modatta-icon>
          </div>
          <div class="type">Imagens</div>
        </div>
      </div>
      <div v-if="['MTY3', 'MTY4'].includes(type)">
        <div class="field">
          <label class="label">
            {{ $t('offers_step3.step_media_video_upload_label') }}
            <b-tooltip
              v-bind:label="$t('offers_step4.box_section_1_video_tooltip')"
              type="is-dark"
              position="is-right"
              size="is-small"
              multilined
            >
              <modatta-icon icon="info-square"></modatta-icon>
            </b-tooltip>
          </label>
          <div class="help-text">{{ $t('offers_step3.step_media_video_sub_label') }}</div>
        </div>
        <div class="input-wrapper">
          <b-upload v-if="!videoURL" v-model="videoFile" @input="handleVideoURL" drag-drop expanded accept="video/*">
            <section class="section">
              <div class="content has-text-centered">
                <modatta-icon icon="upload-arrow" width="32" style="display: contents"></modatta-icon>
                <p>{{ $t('offers_step3.step_media_video_upload_placehold') }}</p>
              </div>
            </section>
          </b-upload>
          <div v-if="videoURL">
            <video controls preload="metadata" :src="videoURL"></video>
          </div>
        </div>
      </div>
      <div v-if="type === 'MTY2'">
        <div class="field upload-file">
          <label class="label">
            Imagem
            <b-tooltip
              v-bind:label="$t('offers_step3.box_section_2_tooltip')"
              type="is-dark"
              position="is-top"
              size="is-small"
              multilined
            >
              <modatta-icon icon="info-square"></modatta-icon>
            </b-tooltip>
          </label>
          <div class="help-text" v-if="!warning">
            {{ $t('offers_step3.box_section_2_subtitle') }}
            {{ $t('offers_step3.box_section_2_subtitle_2') }} 1:1
          </div>
          <div class="help-text text-warning" v-else>
            {{ $t('offers_step3.box_section_2_error') }}
          </div>
          <b-upload
            v-if="!showImage"
            v-model="uploadedImage"
            @input="uploadImage()"
            drag-drop
            expanded
            accept="image/png,image/jpg,image/jpeg"
          >
            <section class="section">
              <div class="content has-text-centered">
                <modatta-icon icon="upload-arrow" width="32" style="display: contents"></modatta-icon>
                <p>{{ $t('offers_step3.box_section_2_upload_subtitle') }}</p>
              </div>
            </section>
          </b-upload>
          <div v-if="showImage" class="preview-image">
            <div class="image-wrapper">
              <img alt="Missão preview" :src="imageURL" />
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button class="button is-primary" @click="handleMedia" style="margin-right: 16px">
          {{ this.step?.media_type_id ? 'Editar Midia' : 'Adicionar Midia' }}
        </button>
        <button class="button is-secondary" @click="handleRemoveMedia">
          <template>Remover media</template>
          <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
        </button>
      </div>
      <p class="error-message">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    props: {
      handleUpdateMediaStep: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        videoFile: null,
        videoURL: null,
        type: 'MTY3',
        typeVideo: this.$t('offers_step4.video_selection_landscape'),
        uploadedImage: null,
        imageURL: null,
        showImage: false,
        warning: false,
        step: {},
        errorMessage: '',
      };
    },
    mounted() {
      this.step = this.$parent.$parent.newOffer.steps[this.$parent.$parent.selectedOption.index];
      if (this.step?.media_type_id) {
        this.type = this.step.media_type_id;
        if (['MTY3', 'MTY4'].includes(this.type)) {
          this.videoURL = this.step.media_source;
          this.typeVideo =
            this.type === 'MTY3'
              ? this.$t('offers_step4.video_selection_landscape')
              : this.$t('offers_step4.video_selection_portrait');
        } else {
          this.showImage = true;
          setTimeout(() => {
            this.imageURL = this.step.file ? URL.createObjectURL(this.step.file) : this.step.media_source;
          }, 100);
        }
      }
      if (this.uploadedImage !== null) {
        this.uploadImage();
      }
    },
    methods: {
      ...mapMutations(['setOpenSideModal', 'setNewOfferType']),
      setType(type) {
        this.type = type;
        this.showImage = false;
      },
      handleMedia() {
        if (['MTY3', 'MTY4'].includes(this.type) && this.videoURL === null) {
          this.errorMessage = 'Você deve fazer um upload de um vídeo.';
          return;
        }

        if (this.type === 'MTY2' && this.imageURL === null) {
          this.errorMessage = 'Você deve fazer um upload de uma imagem.';
          return;
        }

        if (['MTY3', 'MTY4'].includes(this.type)) {
          this.handleUpdateMediaStep({
            media_type_id: 'MTY4', //TODO: Vamos remover esse propriedade pois não precisamos mais saber qual a orientação do video.
            file: this.videoFile,
            media_source: this.videoURL,
          });
        } else {
          this.handleUpdateMediaStep({
            media_type_id: this.type,
            file: this.uploadedImage,
          });
        }
        this.closeSideModal();
      },
      handleRemoveMedia() {
        this.handleUpdateMediaStep(null);
        this.closeSideModal();
      },
      handleVideoURL() {
        this.videoURL = URL.createObjectURL(this.videoFile);
      },
      uploadImage() {
        setTimeout(() => {
          if (this.uploadedImage) {
            if (this.uploadedImage.size < 1000000) {
              this.imageURL = URL.createObjectURL(this.uploadedImage);
            }
          }
        }, 100);

        if (this.uploadedImage && this.uploadedImage.size < 1000000) {
          this.warning = false;
          this.showImage = true;
        } else {
          this.warning = true;
        }
      },
      closeSideModal() {
        setTimeout(() => this.setOpenSideModal(false), 300); // Animation close time
      },
    },
  };
</script>
