<template>
  <div class="field field-container">
    <div class="field field-container">
      <div class="columns p-0">
        <div class="column py-0">
          <span class="label">{{ this.$t('offers_step4.integration_comment_instagram.label_input_url') }}</span>
        </div>
      </div>
      <div class="input-wrapper" :class="{ 'has-error': !isInputValid, 'is-valid': isInputValid }">
        <input type="text" v-model="url" />
        <modatta-icon v-if="!isInputValid" icon="error-exclamation-point" width="20"></modatta-icon>
        <modatta-icon v-if="isInputValid" icon="checkmark-green" width="18"></modatta-icon>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        url: '',
      };
    },
    mounted() {
      if (this.$parent.$parent.newOffer.action) {
        const savedPostUrl = JSON.parse(this.$parent.$parent.newOffer.action);
        this.url = savedPostUrl.url;
      }
    },
    watch: {
      url() {
        if (this.isInputValid) {
          this.$emit('instagram-post-url', JSON.stringify({
            url: this.url,
            post_id: null
          }));
        } else {
          this.$emit('instagram-post-url', null);
        }
      },
    },
    computed: {
      isInputValid() {
        return this.url.length > 0 && /^(http){1}(s)?(\:\/\/){1}(.*?)\/?\.+\w+.*$/gm.test(this.url);
      }
    },
  };
</script>
