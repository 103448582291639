<template>
  <div class="modatta-side-modal-new-offer">
    <div class="offer-wrapper">
      <h2>{{ $t('offers_step0.section_1_title') }}</h2>
      <div class="offers">
        <div class="offer" v-for="(offer, index) in offers" :key="index" @click="openNewCampaign(offer)">
          <div v-if="offer.subtasks != null" class="badge" v-text="offer.subtasks.length"></div>
          <div class="icon-wrapper">
            <modatta-icon :icon="offer.icon" height="20"></modatta-icon>
          </div>
          <div class="type">{{ $t(`labels.${offer.type}`) }}</div>
        </div>
      </div>
    </div>

    <div class="modatta-side-modal-select-logo" :class="{ open: isSelectSubOfferOpen }">
      <div class="modal-wrapper">
        <header>
          <button class="close button" @click="isSelectSubOfferOpen = false">
            <modatta-icon icon="arrow-left-primary"></modatta-icon>
          </button>
          <div class="modal-title">{{ $t(`labels.${selectedInteractionType.title}`) }}</div>
        </header>
        <div class="offers-wrapper">
          <h2>{{ $t('offers_step0.section_1_title') }}</h2>
          <div class="offers">
            <div
              class="offer"
              v-for="(offer, i) in selectedInteractionType.offers"
              :key="i"
              :class="{ 'is-disabled': offer.isDisabled }"
              @click="openNewCampaign(offer)"
            >
              <div class="icon-wrapper">
                <modatta-icon :icon="offer.icon" height="20"></modatta-icon>
              </div>
              <div class="type">{{ $t('labels.' + offer.type) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        selectedInteractionType: { title: '', offers: [] },
        isSelectSubOfferOpen: false,
        offers: [],
      };
    },
    mounted() {
      const offersTypeInteraction = [
        { id: 'INT1', code: 'OFT1', icon: 'message', type: 'INT1', adminOnly: false },
        { id: 'INT2', code: 'OFT2', icon: 'video-camera', type: 'INT2', adminOnly: false },
        { id: 'INT3', code: 'OFT3', icon: 'globe', type: 'INT3', adminOnly: false },
        {
          id: 'INT19', code: '', icon: 'icon-share-blue', type: 'INT19', adminOnly: false, subtasks: [
            { id: 'INT19', code: 'OFT31', icon: 'icon-instagram-blue', type: 'OFT31', adminOnly: false },
          ]
        },
        {
          id: 'INT4',
          icon: 'wifi',
          type: 'INT4',
          adminOnly: false,
          subtasks: [
            { id: 'INT4', code: 'OFT4', icon: 'monitor', type: 'OFT4', adminOnly: false },
            { id: 'INT4', code: 'OFT5', icon: 'smartphone', type: 'OFT5', adminOnly: false },
            { id: 'INT4', code: 'OFT6', icon: 'smartphone', type: 'OFT6', adminOnly: false },
            { id: 'INT4', code: 'OFT7', icon: 'profile-circle-md', type: 'OFT7', adminOnly: false },
            { id: 'INT4', code: 'OFT8', icon: 'document-outline-md', type: 'OFT8', adminOnly: false },
            { id: 'INT4', code: 'OFT9', icon: 'shopping-cart', type: 'OFT9', adminOnly: false },
            { id: 'INT4', code: 'OFT10', icon: 'file-text', type: 'OFT10', adminOnly: false },
            { id: 'INT4', code: 'OFT25', icon: 'sales-contact', type: 'OFT25', adminOnly: false },
          ],
        },
        {
          id: 'INT5',
          code: '',
          icon: 'wifi-off',
          type: 'INT5',
          adminOnly: false,
          subtasks: [
            { id: 'INT5', code: 'OFT11', icon: 'wifi-off', type: 'OFT11', adminOnly: false },
            { id: 'INT5', code: 'OFT12', icon: 'location', type: 'OFT12', adminOnly: false },
            { id: 'INT5', code: 'OFT13', icon: 'location-pin', type: 'OFT13', adminOnly: false },
            { id: 'INT5', code: 'OFT14', icon: 'store-front', type: 'OFT14', adminOnly: false },
            { id: 'INT5', code: 'OFT15', icon: 'knife', type: 'OFT15', adminOnly: false },
            { id: 'INT5', code: 'OFT16', icon: 'cocktail', type: 'OFT16', adminOnly: false },
            { id: 'INT5', code: 'OFT17', icon: 'cake', type: 'OFT17', adminOnly: false },
          ],
        },
        { id: 'INT7', code: 'OFT19', icon: 'list', type: 'INT7', adminOnly: false },
        { id: 'INT10', code: 'OFT22', icon: 'datapoints', type: 'INT10', adminOnly: true },
      ];
      this.offers = offersTypeInteraction.filter((offerType) => this.loggedInUserIsAdmin() || !offerType.adminOnly);
    },
    methods: {
      ...mapMutations(['setOpenSideModal', 'setNewOfferType', 'setOpenToast']),
      openNewCampaign(offer) {
        if (offer.type === 'INT19') {
          const showErrorMessageIntegrationSocial = () => {
            this.setOpenToast({
              title: this.$t('integration.toast_mission_error.title'),
              isSuccess: false
            });
          }
          if (this.$store.state.user.userIntegration === null) {
            return showErrorMessageIntegrationSocial();
          } else {
            const accountSocialStatus = this.$store.state.user.userIntegration?.social_config.map((social) => {
              // TODO: levar para uma lugar melhor.
              const plataformInstagram = '9bb8913b-ddd9-430b-a66a-d74d846e6c66';
              if (social.work_platform_social_id === plataformInstagram) {
                return social.account_social_status;
              }
            })
            if (accountSocialStatus[0] !== 'CONNECTED') {
              return showErrorMessageIntegrationSocial();
            }
          }
        }
        if (offer.subtasks) {
          this.selectedInteractionType.title = offer.type;
          this.selectedInteractionType.offers = offer.subtasks;
          this.isSelectSubOfferOpen = true;
          return;
        }
        this.setOpenSideModal(false);
        this.setNewOfferType(offer);
        // Regex:: Opens a new offer page
        if (this.$router.currentRoute.path !== '/offers/new-offer') {
          this.$router.replace('/offers/new-offer');
        }
      },
      loggedInUserIsAdmin() {
        return this.$store.state.user.user.email.includes('@modatta.org');
      },
    },
  };
</script>
