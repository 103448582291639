<template>
  <div class="modatta-side-modal-scroll-middle-area modatta-side-modal-create-audience">
    <div class="sidenav-container">
      <div class="basic-filters">
        <div class="filter">
          <b-field v-bind:label="$t('offers_step2_profile_filter.gender')">
            <modatta-radio-input
              :options="genders"
              :has-other="false"
              @radio-value="(radio) => (this.selectedGender = radio)"
              :suggested-value="selectedGender"
            ></modatta-radio-input>
          </b-field>
        </div>
        <div class="filter">
          <b-field v-bind:label="$t('offers_step2_profile_filter.age')">
            <b-slider
              :value="ages"
              @input="handleAgeSelection($event)"
              :min="16"
              :max="100"
              :step="1"
              tooltip-always
            ></b-slider>
          </b-field>
        </div>
        <div class="filter residence">
          <b-field v-bind:label="$t('offers_step2_profile_filter.residence')">
            <div class="columns">
              <div class="column">
                <div class="modatta-country-select">
                  <multiselect
                    disabled
                    v-bind:placeholder="$t('offers_step2_profile_filter.choose_country')"
                    selectLabel="ENTER to select"
                    v-model="selectedCountry"
                    openDirection="bottom"
                    :options="countries"
                    :allow-empty="false"
                    :showLabels="false"
                    track-by="name"
                    label="name"
                  >
                    <span class="caret" slot="caret">
                      <modatta-icon icon="arrow-down" width="10"></modatta-icon>
                    </span>
                    <template slot="singleLabel" slot-scope="props">
                      <img :src="getFlag(props.option.code)" />
                      {{ props.option.name }}
                    </template>
                    <template slot="option" slot-scope="props">
                      <img :src="getFlag(props.option.code)" />
                      {{ props.option.name }}
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="column">
                <div v-if="selectedCountry != {} && userCountryId !== 'GEO32'" class="modatta-country-select">
                  <multiselect
                    selectLabel="ENTER to select"
                    v-bind:placeholder="$t('offers_step2_profile_filter.choose_city')"
                    @remove="removedCity($event)"
                    :options="sortedCities"
                    openDirection="bottom"
                    v-model="selectedCity"
                    :allow-empty="true"
                    :showLabels="false"
                    track-by="name"
                    label="name"
                  >
                    <span class="caret" slot="caret">
                      <modatta-icon icon="arrow-down" width="10"></modatta-icon>
                    </span>
                  </multiselect>
                </div>
                <div class="modatta-country-select" v-else>
                  <multiselect
                    selectLabel="ENTER to select"
                    v-bind:placeholder="$t('offers_step2_profile_filter.choose_state')"
                    :options="sortedStates"
                    openDirection="bottom"
                    v-model="selectedState"
                    :allow-empty="true"
                    :showLabels="false"
                    track-by="name"
                    label="name"
                  >
                    <span class="caret" slot="caret">
                      <modatta-icon icon="arrow-down" width="10"></modatta-icon>
                    </span>
                  </multiselect>
                </div>
              </div>
            </div>
          </b-field>
        </div>
        <div class="filter">
          <b-field v-bind:label="$t('offers_step2_profile_filter.academic_degree')">
            <modatta-radio-input
              :options="degrees"
              :has-other="false"
              @radio-value="(radio) => (this.selectedDegree = radio)"
              :suggested-value="selectedDegree"
            ></modatta-radio-input>
          </b-field>
        </div>
      </div>
      <button class="button is-info" @click="isAdvancedOpen = true">
        {{ $t('offers_step2_profile_filter.add_adv_filter') }}
      </button>
      <div class="selected-filters">
        <h3>{{ $t('offers_step2_profile_filter.audience_profile') }}</h3>
        <div class="filters-wrapper">
          <template v-if="filters.length > 0">
            <div class="filter-item" v-for="(filter, ind) in filters" :key="ind">
              <div class="concatenation">
                <template v-if="filter.profile_id === 'PRO7'">
                  <strong v-show="!filter.value_id">{{ $t('offers_step2_profile_filter.age') }} </strong>
                  <strong v-show="filter.value_id">{{ $t('offers_step2_profile_filter.birthyear') }} </strong>
                  <u v-show="filter.value_id">{{ $t(`labels.` + filter.operator_id).toLowerCase() }}</u>
                  <u v-show="!filter.value_id">{{ $t(`operators.between`).toLowerCase() }}</u>
                  <span> {{ filter.value }}</span>
                </template>
                <template v-else>
                  <!-- <strong>{{$t(`labels.${filter.profile_id}`)}}</strong> {{$t(`labels.${filter.operator_id}`)}} <template v-if="$t(`labels.${filter.value}`)">{{ ($te(`labels.${filter.value}`) ? $t(`labels.${filter.value}`) : filter.value) }}</template> -->
                  <strong
                    v-text="
                      $te(`labels.${filter.profile_id}`)
                        ? $t(`labels.${filter.profile_id}`)
                        : showCorrectProfileLabel(filter)
                    "
                  >
                  </strong>
                  {{ $t(`labels.${filter.operator_id}`) }}
                  <span v-text="$te(`labels.${filter.value_id}`) ? $t(`labels.${filter.value_id}`) : filter.value">
                  </span>
                </template>
              </div>
              <button class="button" @click="deleteFilter(ind, filter.profile)">
                <modatta-icon icon="times" width="12"></modatta-icon>
              </button>
            </div>
          </template>
          <div v-else class="tag is-green">
            {{ $t('offers_step2_profile_filter.all_users_selected') }}
          </div>
        </div>
      </div>

      <div class="modatta-side-modal-select-logo" :class="{ open: isAdvancedOpen }">
        <modatta-side-modal-new-offer-audience-by-profile-advanced></modatta-side-modal-new-offer-audience-by-profile-advanced>
      </div>
    </div>
    <div class="buttons">
      <button
        data-tooltip=""
        data-tooltip-location="top"
        class="button is-primary"
        :class="[{ 'has-warning keep-visible ': hasWarning }]"
        type="Submit"
        :disabled="isLoading"
        @click="createFilters()"
      >
        <template v-if="!isLoading">{{ hasWarning ? warningSubmitText : $t('offers_step2.create_filter') }}</template>
        <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
      </button>
      <button class="button is-text" @click="$parent.closeSideModal()" :disabled="isLoading">
        <template v-if="!isLoading">{{ $t('offers_step2.cancel_filter') }}</template>
        <template v-else>>{{ $t('offers_step2.loading_filter') }}</template>
      </button>
    </div>
  </div>
</template>
<script>
  import Multiselect from 'vue-multiselect';
  import { mapActions, mapMutations } from 'vuex';
  import { findFlagUrlByIso2Code } from 'country-flags-svg';
  import SetFilterCityValueId from '../helpers/filters/set-filter-city-value-id';
  import setFilterYearValueId from '../helpers/filters/set-filter-year-value-id';
  import SetFilterDegreeValueId from '../helpers/filters/set-filter-degree-value-id';
  import SetFilterGenderValueId from '../helpers/filters/set-filter-gender-value-id';
  import SetFilterCountryValueId from '../helpers/filters/set-filter-country-value-id';
  import SetFilterStateValueId from '../helpers/filters/set-filter-state-value-id';
  import Filters from '../helpers/filters/filter-methods.js';
  import lodash from 'lodash';

  export default {
    props: ['options'],
    components: { Multiselect },
    data() {
      return {
        newOptions: [...this.options.filters],

        isAdvancedOpen: false,
        isLoading: false,

        ages: [16, 100],
        selectedAges: null,

        hasWarning: false,
        warningMessage: '',
        warningSubmitText: '',

        selectedGender: this.$t('offers_step2_profile_filter.gender_all'),
        genders: [
          this.$t('offers_step2_profile_filter.gender_all'),
          this.$t('offers_step2_profile_filter.gender_male'),
          this.$t('offers_step2_profile_filter.gender_female'),
          this.$t('offers_step2_profile_filter.gender_other'),
          this.$t('offers_step2_profile_filter.gender_trans'),
          this.$t('offers_step2_profile_filter.gender_queer'),
        ],

        selectedDegree: this.$t('offers_step2_profile_filter.degree_all'),
        degrees: [
          this.$t('offers_step2_profile_filter.degree_all'),
          this.$t('offers_step2_profile_filter.radio_VAL490'),
          this.$t('offers_step2_profile_filter.radio_VAL491'),
          this.$t('offers_step2_profile_filter.radio_VAL492'),
          this.$t('offers_step2_profile_filter.radio_VAL493'),
          this.$t('offers_step2_profile_filter.radio_VAL494'),
          this.$t('offers_step2_profile_filter.radio_VAL495'),
          this.$t('offers_step2_profile_filter.radio_VAL496'),
          this.$t('offers_step2_profile_filter.radio_VAL1031'),
          this.$t('offers_step2_profile_filter.radio_VAL1032'),
          this.$t('offers_step2_profile_filter.radio_VAL1033'),
        ],

        selectedCountry: {},
        countries: [
          {
            name: this.$t('labels.GEO1'),
            code: 'PT',
            country_id: 'GEO1',
            value_id: 'VAL424',
          },
          {
            name: this.$t('labels.GEO2'),
            code: 'ES',
            country_id: 'GEO2',
            value_id: 'VAL308',
          },
          {
            name: this.$t('labels.GEO32'),
            code: 'BR',
            country_id: 'GEO32',
            value_id: 'VAL271',
          },
        ],

        selectedCity: null,
        selectedState: null,
        userCountryId: '',
      };
    },
    mounted() {
      let country_id = this.$store.state.user.user.country_id; // defining default country id
      this.userCountryId = country_id;

      this.getAges();
      //this.getCities(country_id).then(() => {

      const waitPRO12 = this.userCountryId !== 'GEO32' ? this.getCities(country_id) : this.getStates(country_id);

      waitPRO12
        .then(() => {
          // Obj, Will be used to apply filter values on radio inputs.
          const radioProfileMap = {
            PRO10: 'selectedGender', // PRO: Gender
            PRO21: 'selectedDegree', // PRO: Degree
          };

          // Obj, Will be used to apply filter values on select inputs.
          // selectProfileMap = {} has more keys than the other, because corresponding inputs use objects as value

          // Bugs BR: Applying user's country as the default country of residence within the basic filters
          const defCountry = this.countries.find((f) => f.country_id === this.$store.state.user.user.country_id);

          const selectProfileMap = {
            PRO11: {
              model: 'selectedCountry',
              list: 'countries',
              list_id: 'value_id',
              default: defCountry,
            },
            PRO1775: {
              model: 'selectedState',
              list: 'states',
              list_id: 'id',
            },
          };

          this.initRadioFields(radioProfileMap); // feeding radio inputs with the values existing in the saved filters.
          this.initSelectFields(selectProfileMap); // feeding select inputs with the values existing in the saved filters.
        })
        .catch(() => {});

      this.initAgeField(); // feeding age range input with the values existing in the saved filters.
    },
    watch: {
      selectedGender(value) {
        if (value) {
          const genderProfileId = 'PRO10';
          const nostate_value = this.$t('offers_step2_profile_filter.gender_all');
          value === nostate_value
            ? this.findAndDeleteFilter(this.filters, genderProfileId)
            : this.createGenderFilter(value);
        }
      },

      selectedDegree(value) {
        if (value) {
          const degreeProfileId = 'PRO21';
          const nostate_value = this.$t('offers_step2_profile_filter.degree_all');
          value === nostate_value
            ? this.findAndDeleteFilter(this.filters, degreeProfileId)
            : this.createDegreeFilter(value);
        }
      },

      selectedCountry(value) {
        if (!value) {
          return;
        }
        // Should not be able to change the country - Locked to the user's country
        // Country is defined during mount() / Cities are loaded during mount()
      },

      selectedCity(value) {
        const cityProfileId = 'PRO12';
        const nostate_value = null;
        if (value == nostate_value) {
          this.findAndDeleteFilter(this.filters, cityProfileId);
        } else {
          this.createCityFilter(value);
          // this.createCountryFilter(value);
        }
      },

      selectedState(value) {
        const stateProfileId = 'PRO1775';
        const nostate_value = null;
        if (value == nostate_value) {
          this.findAndDeleteFilter(this.filters, stateProfileId);
        } else {
          // TODO: Change
          this.createStateFilter(value);
          // this.createCountryFilter(value);
        }
      },
    },
    computed: {
      states() {
        return this.$store.state.offer.states.map((c) => {
          return { id: c.id, name: this.$t(`labels.${c.id}`) };
        });
      },
      cities() {
        return this.$store.state.offer.cities.map((c) => {
          return { id: c.id, name: this.$t(`labels.${c.id}`) };
        });
      },
      sortedStates() {
        return this.states.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      },
      sortedCities() {
        return this.cities.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      },
      filters() {
        return this.newOptions;
      },
    },
    methods: {
      ...mapActions(['getCities', 'getStates']),
      ...mapMutations(['upsertProfileNewOfferFilters']),

      // Initializing Basic Filters input values

      initRadioFields(profileMap) {
        for (let [profileId, key] of Object.entries(profileMap)) {
          let savedOption = this.newOptions.find((f) => f.profile_id === profileId);
          if (savedOption) {
            this[key] = this.$t(`labels.` + savedOption.value_id);
          }
        }
      },
      cleanStr(str) {
        return str.trim().toLowerCase();
      },
      initSelectFields(profileMap) {
        let filters = this.newOptions;
        for (let [profileId, key] of Object.entries(profileMap)) {
          const f = filters.find((f) => f.profile_id === profileId);
          if (f !== undefined) {
            const valueId = f.value_id;
            const savedOption = this[key.list].find((f) => f[key.list_id] === valueId);
            if (!(savedOption == null)) {
              this[key.model] = savedOption;
            }
          } else {
            this[key.model] = key.default;
          }
        }
      },
      initAgeField() {
        let birthyears = this.newOptions.filter((f) => f.profile_id === 'PRO7');
        let maxAge = birthyears.findIndex((f) => f.operator_id === 'OPE8'); // findIndex() instead of find() - generally faster
        let minAge = birthyears.findIndex((f) => f.operator_id === 'OPE7'); // findIndex() instead of find() - generally faster
        this.ages = [
          maxAge !== -1 ? Filters.getAge(birthyears[maxAge].value) : this.ages[0],
          minAge !== -1 ? Filters.getAge(birthyears[minAge].value) : this.ages[1],
        ];
      },

      // Creating Filters for Basic Filters inputs

      // TODO: Do not get final values with the translation, value should be an id for the value and translated only on the output
      createGenderFilter(value, genderProfileId = 'PRO10') {
        Filters.upsert(
          {
            profile: 'Gender',
            profile_id: genderProfileId,
            operator: this.$t(`labels.` + this.getOperatorId('Gender')),
            operator_id: this.getOperatorId('Gender'),
            value: SetFilterGenderValueId(this.getValueId(this.selectedGender)),
            value_id: this.getValueId(value),
          },
          this.newOptions,
          this.filters,
        );
      },
      // Gender: SetFilterGenderValueId() will return the ref_id when a value_id is given (this.getValueId(value))

      createDegreeFilter(value, degreeProfileId = 'PRO21') {
        Filters.upsert(
          {
            profile: 'Degree',
            profile_id: degreeProfileId,
            operator: this.$t(`labels.` + this.getOperatorId('Degree')),
            operator_id: this.getOperatorId('Degree'),
            value: SetFilterDegreeValueId(this.getValueId(value)),
            value_id: this.getValueId(value),
          },
          this.newOptions,
          this.filters,
        );
      },
      // Degree: SetFilterDegreeValueId() will return the ref_id when a value_id is given (this.getValueId(value))

      createCountryFilter(value, countryProfileId = 'PRO11') {
        Filters.upsert(
          {
            profile: 'Country',
            profile_id: countryProfileId,
            operator: this.$t(`labels.` + this.getOperatorId('Country')),
            operator_id: this.getOperatorId('Country'),
            value: SetFilterCountryValueId(this.selectedCountry.value_id),
            value_id: this.selectedCountry.value_id,
          },
          this.newOptions,
          this.filters,
        );
        // Country: SetFilterCountryValueId() will return the ref_id when a value_id is given (this.selectedCountry.value_id)
      },

      // TODO: Do not get final values with the translation, value should be an id for the value and translated only on the output
      createCityFilter(value, cityProfileId = 'PRO12') {
        Filters.upsert(
          {
            profile: 'City',
            profile_id: cityProfileId,
            operator: this.$t(`labels.` + this.getOperatorId('City')),
            operator_id: this.getOperatorId('City'),
            value: SetFilterCityValueId(value.id), // SetFilterCityValueId(value.id)
            value_id: value.id,
          },
          this.newOptions,
          this.filters,
        );
        // City: SetFilterCountryValueId() will return the ref_id when a value_id is given (value.id)
      },
      createStateFilter(value, stateProfileId = 'PRO1775') {
        Filters.upsert(
          {
            profile: 'State',
            profile_id: stateProfileId,
            operator: this.$t(`labels.` + this.getOperatorId('State')),
            operator_id: this.getOperatorId('State'),
            value: SetFilterStateValueId(value.id), // SetFilterCityValueId(value.id)
            value_id: value.id,
          },
          this.newOptions,
          this.filters,
        );
        // City: SetFilterCountryValueId() will return the ref_id when a value_id is given (value.id)
      },

      handleAgeSelection(e) {
        const ageProfileName = 'Age'; // WARN: Shall we translate the profileName?
        const ageProfileId = 'PRO7';

        // Always deleting existing filters for PRO7 to avoid new entries incrementing
        this.findAndDeleteFilter(this.filters, ageProfileId);
        if (lodash.isEqual(e, [16, 100])) return;

        const opeMax = 'OPE8';
        const opeMin = 'OPE7';
        const yearMax = Filters.getBirthYear(e[0]);
        const yearMin = Filters.getBirthYear(e[1]);

        // UPSERT - BirthYear (max)
        Filters.upsert(
          {
            profile: ageProfileName,
            profile_id: ageProfileId,
            operator: opeMax,
            operator_id: opeMax,
            value: yearMax,
            value_id: setFilterYearValueId(yearMax.toString()),
          },
          this.newOptions,
          this.filters,
        );

        // // UPSERT - BirthYear (min)
        Filters.upsert(
          {
            profile: ageProfileName,
            profile_id: ageProfileId,
            operator: opeMin,
            operator_id: opeMin,
            value: yearMin,
            value_id: setFilterYearValueId(yearMin.toString()),
          },
          this.newOptions,
          this.filters,
        );
      },
      createFilters() {
        const payload = {
          filters: this.filters,
          id: 'profile',
          targetId: this.options.targetGroupId,
        };

        this.upsertProfileNewOfferFilters(payload);
        this.$parent.closeSideModal();
      },

      // WARNING: Consider using Profile_id to find and delete the filter
      // deleteFilterByProfile() is an alternative for deleteFilter()
      // deleteFilterByProfile("PRO7") instead of deleteFilter("Age")

      deleteFilter(i, profile) {
        this.resetFilter(profile);
        this.newOptions.splice(i, 1);
      },

      deleteFilterByProId(i, profile) {
        this.resetFilterFieldsByProId(profile);
        this.newOptions.splice(i, 1);
      },

      findFilterbyProId(filters, profile_id) {
        return filters.find((f) => f.profile_id === profile_id);
      },
      findIndexByProId(filters, profile_id) {
        return filters.findIndex((f) => f.profile_id === profile_id);
      },
      findAndDeleteFilter(filters, profile_id) {
        let f = this.findIndexByProId(filters, profile_id);
        while (f !== -1) {
          this.deleteFilterByProId(f, profile_id);
          f = this.findIndexByProId(filters, profile_id);
        }
      },
      removedCity() {
        // TODO: Critical Fix this fn
        const i = this.newOptions.findIndex((o) => o.profile === 'City');
        this.newOptions.splice(i, 1);
      },

      /*
    |--------------------------------------------------------------------------
    | HELPERS
    |--------------------------------------------------------------------------
    */

      upsertFilter(profile, profile_id, operator, operator_id, value, value_id) {
        let filter = this.filters.find((f) => f.profile_id === profile_id);
        if (filter) {
          filter.operator = operator;
          filter.operator_id = operator_id;
          filter.value = value;
          filter.value_id = value_id;
          return;
        }
        this.newOptions.push({
          profile,
          profile_id,
          operator,
          operator_id,
          value,
          value_id,
          category: 'profile',
        });
      },

      resetFilter(profile) {
        switch (profile) {
          case 'Gender':
            return (this.selectedGender = this.$t('offers_step2_profile_filter.gender_all'));
          case 'Degree':
            return (this.selectedDegree = this.$t('offers_step2_profile_filter.gender_all'));
          case 'State':
            return (this.selectedState = null);
          case 'City':
            return (this.selectedCity = null);
        }
      },

      resetFilterFieldsByProId(profile) {
        switch (profile) {
          case 'Gender':
          case 'PRO10':
            return (this.selectedGender = this.$t('offers_step2_profile_filter.gender_all'));
          case 'Degree':
          case 'PRO21':
            return (this.selectedDegree = this.$t('offers_step2_profile_filter.gender_all'));
          case 'State':
          case 'PRO1775':
            return (this.selectedState = null);
          case 'City':
          case 'PRO12':
            return (this.selectedCity = null);
        }
      },
      getAges() {
        const ageFilter = this.newOptions.find((f) => f.profile_id === 'PRO7');
        const hasValueId = this.newOptions.filter((f) => f.profile_id === 'PRO7' && f.value_id);
        if (ageFilter == null) return;

        if (hasValueId.length > 0) {
          return;
        } else {
          this.ages = ageFilter.value
            .replace(' - ', ',')
            .split(',')
            .map((i) => Number(i));
        }
      },
      getProfileId(profile) {
        // Aplicar i18n (?)
        switch (profile) {
          case 'Age':
            return 'PRO7';
          case 'City':
            return 'PRO12';
          case 'State':
            return 'PRO1775';
          case 'Gender':
            return 'PRO10';
          case 'Degree':
            return 'PRO21';
          case 'Country':
            return 'PRO11';
        }
      },
      // TODO: Check a better way to get these values
      getOperatorId(profile) {
        switch (profile) {
          case 'Gender':
            return 'OPE2';
          case 'Degree':
            return 'OPE7';
          case 'City':
            return 'OPE2';
          case 'State':
            return 'OPE2';
          case 'Country':
            return 'OPE2';
        }
      },

      getValueId(value) {
        switch (value) {
          case this.$t('offers_step2_profile_filter.gender_male'):
            return 'VAL564';
          case this.$t('offers_step2_profile_filter.gender_female'):
            return 'VAL563';
          case this.$t('offers_step2_profile_filter.gender_other'):
            return 'VAL983';
          case this.$t('offers_step2_profile_filter.gender_trans'):
            return 'VAL1015';
          case this.$t('offers_step2_profile_filter.gender_queer'):
            return 'VAL1016';
          case this.$t('offers_step2_profile_filter.radio_VAL490'):
            return 'VAL490';
          case this.$t('offers_step2_profile_filter.radio_VAL491'):
            return 'VAL491';
          case this.$t('offers_step2_profile_filter.radio_VAL492'):
            return 'VAL492';
          case this.$t('offers_step2_profile_filter.radio_VAL493'):
            return 'VAL493';
          case this.$t('offers_step2_profile_filter.radio_VAL494'):
            return 'VAL494';
          case this.$t('offers_step2_profile_filter.radio_VAL495'):
            return 'VAL495';
          case this.$t('offers_step2_profile_filter.radio_VAL496'):
            return 'VAL496';
          case this.$t('offers_step2_profile_filter.radio_VAL1031'):
            return 'VAL1031';
          case this.$t('offers_step2_profile_filter.radio_VAL1032'):
            return 'VAL1032';
          case this.$t('offers_step2_profile_filter.radio_VAL1033'):
            return 'VAL1033';
        }
      },

      getValueProfile(profile) {
        switch (profile) {
          case 'Gender':
            return this.selectedGender;
          case 'Degree':
            return this.selectedDegree;
          case 'State':
            return this.selectedState;
          case 'City':
            return this.selectedCity;
        }
      },
      getFlag(code) {
        if (code == null) return null;
        return findFlagUrlByIso2Code(code);
      },
    },
  };
</script>
