<template>
  <div class="validation-option">
    <div class="field">
      <label class="label">QR Code</label>
    </div>
    <div class="qr-code">
      <section class="section-to-print" id="section-to-print">
        <div class="image-container">
          <img src="/svg/modatta-logo.svg" alt="Modatta logo" />
        </div>
        <div id="qrcode" class="code" :style="{ 'background-image': `url(${qrCode})` }"></div>
        <div id="qrcode" class="printable-qr-code">
          <img :src="qrCode" alt="QR Code" />
        </div>
      </section>
      <button class="button is-subtle" @click="showPrintModal()">
        <modatta-icon icon="print"></modatta-icon>
        <span>Print</span>
      </button>
    </div>
  </div>
</template>

<script>
  import QRCode from 'qrcode';
  import { v4 as uuidv4 } from 'uuid';
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['offerId', 'offerName', 'offerValidationsCodes'],
    data() {
      return {
        qrCode: null,
      };
    },
    mounted() {
      let code = null;

      let validationCodes = this.offerValidationsCodes;

      if (!validationCodes.length > 0) {
        validationCodes = this.$parent.setValidationCodes([''], 'qrcode');
      }

      if (validationCodes[0].code === '' || validationCodes[0].code === null) {
        code = uuidv4();
      } else {
        code = validationCodes[0].code;
      }

      QRCode.toDataURL(code).then((url) => {
        this.qrCode = url;
        this.$emit('new-codes', [{ type: 'qrcode', code: code }]);
      });
    },
    methods: {
      showPrintModal() {
        window.print();
      },
    },
  };
</script>
