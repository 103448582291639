import i18n from '../i18n.js';

export default function SetOfferType(id) {
  switch (id) {
    case 'OFT1':
      return i18n.t('labels.OFT1'); // 'Read Text Message';
    case 'OFT2':
      return i18n.t('labels.OFT2'); // 'Watch Video';
    case 'OFT3':
      return i18n.t('labels.OFT3'); // 'Visit Website';
    case 'OFT4':
      return i18n.t('labels.OFT4'); // 'Interact with a Website';
    case 'OFT5':
      return i18n.t('labels.OFT5'); // 'Install App';
    case 'OFT6':
      return i18n.t('labels.OFT6'); // 'Interact with an App';
    case 'OFT7':
      return i18n.t('labels.OFT7'); // 'Create an Account an a Website';
    case 'OFT8':
      return i18n.t('labels.OFT8'); // 'Fill Form Online';
    case 'OFT9':
      return i18n.t('labels.OFT9'); // 'Subscribe to a Newsletter';
    case 'OFT10':
      return i18n.t('labels.OFT10'); // 'Make Purchase Online';
    case 'OFT11':
      return i18n.t('labels.OFT11'); // 'Interact Offline';
    case 'OFT12':
      return i18n.t('labels.OFT12'); // 'Visit a Physical Location';
    case 'OFT13':
      return i18n.t('labels.OFT13'); // 'Interact in a Physical Location';
    case 'OFT14':
      return i18n.t('labels.OFT14'); // 'Make a Purchase in a Physical Store';
    case 'OFT15':
      return i18n.t('labels.OFT15'); // 'Have a Meal in a Restaurant';
    case 'OFT16':
      return i18n.t('labels.OFT16'); // 'Have a Drink in a Bar';
    case 'OFT17':
      return i18n.t('labels.OFT17'); // 'Show up to an Event';
    case 'OFT18':
      return i18n.t('labels.OFT18'); // 'Answer a Poll';
    case 'OFT19':
      return i18n.t('labels.OFT19'); // 'Answer a Survey';
    case 'OFT20':
      return i18n.t('labels.OFT20'); // 'Share Personal Data';
    case 'OFT21':
      return i18n.t('labels.OFT21'); // 'ADMIN - Enable a data connector';
    case 'OFT22':
      return i18n.t('labels.OFT22'); // 'ADMIN - Fill one profile field';
    case 'OFT23':
      return i18n.t('labels.OFT23'); // 'ADMIN - Fill one profile family';
    case 'OFT24':
      return i18n.t('labels.OFT24'); // 'Share post on Facebook';
    case 'OFT25':
      return i18n.t('labels.OFT25'); // 'Engage on sales contact';
    case 'OFT26':
      return i18n.t('labels.OFT26'); // 'Like post on Facebook';
    case 'OFT27':
      return i18n.t('labels.OFT27'); // 'Like page on Facebook';
    case 'OFT28':
      return i18n.t('labels.OFT28'); // 'Create post on Facebook';
    case 'OFT29':
      return i18n.t('labels.OFT29'); // 'Share post on Instagram';
    case 'OFT30':
      return i18n.t('labels.OFT30'); // 'Like post on Instagram';
    case 'OFT31':
      return i18n.t('labels.OFT31'); // 'Follow page on Instagram';
    case 'OFT32':
      return i18n.t('labels.OFT32'); // 'Create post on Instagram';
  }
}
