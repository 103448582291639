<template>
  <div class="modatta-create-survey-poll" :class="{ poll: type === 'poll' }">
    <h2>{{ $t('offers_step4.box_section_1_questions') }}<template v-if="type === 'survey'">s</template></h2>
    <div class="questions">
      <div
        class="question-wrapper"
        :class="{ 'is-only': questions.length === 1 }"
        v-for="(question, i) in questions"
        :key="i"
      >
        <div v-if="question.isOpen && questions.length > 1" class="pre-header">
          <button class="button is-icon" @click="deleteQuestion(i)" ref="deleteQuestion">
            <modatta-icon icon="delete"></modatta-icon>
          </button>
        </div>
        <div class="question" :class="{ 'is-open': question.isOpen }">
          <div class="question-header" @click="updateIsOpen(i, question.isOpen)">
            <div class="name">
              <span>{{ question.question }}</span>
            </div>
            <modatta-icon icon="arrow-down" width="12"></modatta-icon>
          </div>
          <div class="question-body">
            <div class="field">
              <label class="label">{{ $t('offers_step4.box_section_1_questions_subtitle_1') }}</label>
              <div class="input-wrapper">
                <input
                  type="text"
                  v-model="question.question"
                  :class="{ 'empty-field': question.question.replace(/\s/g, '') === '' }"
                  :placeholder="$t('offers_step4.box_section_1_questions_input_example')"
                  maxlength="255"
                />
              </div>
            </div>
            <div class="field answers">
              <label class="label">{{ $t('offers_step4.box_section_1_answers_subtitle_1') }}</label>
              <div class="answer" v-for="(answer, index) in question.offer_answers" :key="index">
                <div class="input-wrapper">
                  <input
                    type="text"
                    v-model="answer.answer"
                    maxlength="255"
                    :class="{ 'empty-field': answer.answer.replace(/\s/g, '') === '' }"
                  />
                </div>
                <button
                  v-if="question.offer_answers.length > 1"
                  class="button delete-answer"
                  :class="{ 'last-item': index + 1 === question.offer_answers.length }"
                  @click="deleteAnswer(question, index)"
                  tabindex="-1"
                >
                  <modatta-icon icon="delete"></modatta-icon>
                </button>
              </div>
              <button class="button add-new-answer" @click="addNewAnswer(question)">
                <modatta-icon icon="plus"></modatta-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="type === 'survey'" class="add-question" @click="addNewQuestion()">
      + {{ $t('offers_step4.add_question') }}
    </div>
  </div>
</template>

<script>
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';
  const MAX_QUESTIONS_AND_ANSWERES = 100;
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['type', 'questions'],
    mounted() {
      this.questions.forEach((q, i) => this.$set(this.questions[i], 'isOpen', true));
      this.$emit('questions', this.questions);
      setTimeout(() => this.setTooltips(), 100);
    },
    watch: {
      questions(newVal) {
        this.$emit('questions', newVal);
      },
    },
    methods: {
      updateIsOpen(i, value) {
        this.$set(this.questions[i], 'isOpen', !value);
      },
      deleteQuestion(i) {
        this.questions.splice(i, 1);
        setTimeout(() => this.setTooltips(), 100);
      },
      addNewQuestion() {
        if (this.questions.length === MAX_QUESTIONS_AND_ANSWERES) {
          return;
        }
        const defaultQuestionsArray = {
          id: '',
          profile_id: '',
          question: '',
          isOpen: true,
          offer_answers: [
            { id: '', answer: '', value_id: '' },
            { id: '', answer: '', value_id: '' },
          ],
        };
        this.questions.push(defaultQuestionsArray);
      },
      addNewAnswer(question) {
        if (question.offer_answers.length === MAX_QUESTIONS_AND_ANSWERES) {
          return;
        }
        question.offer_answers.push({ id: '', answer: '', value_id: '' });
      },
      deleteAnswer(question, i) {
        if (question.offer_answers.length === 1) {
          return;
        }
        question.offer_answers.splice(i, 1);
      },

      setTooltips() {
        if (this.$refs.deleteQuestion) {
          tippy(this.$refs.deleteQuestion, {
            maxWidth: 140,
            zIndex: 99999,
            placement: 'top',
            content: 'Delete Question',
          });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .has-errors .empty-field {
    border-color: #f16341;
  }
</style>
