import QRCode from 'qrcode';
import { crc16ccitt } from 'crc';

// NOTE: valores de acordo com a documentação do BACEN https://www.bcb.gov.br/content/estabilidadefinanceira/pix/Regulamento_Pix/II_ManualdePadroesparaIniciacaodoPix.pdf
const GloballyUniqueIdentifier = 'br.gov.bcb.pix';
const Version = '01';
const CurrencyCode = '986'; // código para R$
const DefaultPixKey = '48.909.789/0001-24';
const DefaultMerchantCategoryCode = '0000';
const DefaultCountryCode = 'BR';
const DefaultCity = 'RIO DE JANEIRO';
const DefaultZipCode = '20220297';
const DefaultTransactionId = '***';

function getRawValue(key) {
  return key.replaceAll(/[\.\/\-]/g, '');
}

function generateKey(key, message) {
  const fields = [genEMV('00', GloballyUniqueIdentifier), genEMV('01', key)];

  if (message !== null && message.length) {
    fields.push(genEMV('02', message ?? ''));
  }

  return fields.join('');
}

function genEMV(id, parameter) {
  const length = parameter.length.toString().padStart(2, '0');

  return `${id}${length}${parameter}`;
}

const createPixQrCodePayload = function ({ transactionId, amount, message, name }) {
  const payload = [
    genEMV('00', Version),
    genEMV('26', generateKey(getRawValue(DefaultPixKey), message)),
    genEMV('52', DefaultMerchantCategoryCode),
    genEMV('53', CurrencyCode),
    genEMV('54', ((amount * 100) / 100).toFixed(2)),
    genEMV('58', DefaultCountryCode),
    genEMV('59', name),
    genEMV('60', DefaultCity),
    genEMV('61', DefaultZipCode),
    genEMV('62', genEMV('05', transactionId ?? DefaultTransactionId)),
    '6304',
  ].join('');

  // NOTE: CRC162 (Polinômio 0x1021, C.I. 0xFFFF). De acordo com a documentação do BACEN https://www.bcb.gov.br/content/estabilidadefinanceira/pix/Regulamento_Pix/II_ManualdePadroesparaIniciacaodoPix.pdf
  const crcResult = crc16ccitt(payload).toString(16).toUpperCase().padStart(4, '0');
  return `${payload}${crcResult}`;
};

const createPixQrCodeImage = function (payload) {
  return QRCode.toDataURL(payload);
};

export { createPixQrCodePayload as Pix, createPixQrCodeImage as Img };
