export default function SetFilterGenderValueId(id) {
  switch (id) {
    case 'VAL1340':
      return 'AC';
    case 'VAL1341':
      return 'AL';
    case 'VAL1342':
      return 'AP';
    case 'VAL1343':
      return 'AM';
    case 'VAL1344':
      return 'BA';
    case 'VAL1345':
      return 'CE';
    case 'VAL1346':
      return 'DF';
    case 'VAL1347':
      return 'ES';
    case 'VAL1348':
      return 'GO';
    case 'VAL1349':
      return 'MA';
    case 'VAL1350':
      return 'MT';
    case 'VAL1351':
      return 'MS';
    case 'VAL1352':
      return 'MG';
    case 'VAL1353':
      return 'PA';
    case 'VAL1354':
      return 'PB';
    case 'VAL1355':
      return 'PR';
    case 'VAL1356':
      return 'PE';
    case 'VAL1357':
      return 'PI';
    case 'VAL1358':
      return 'RJ';
    case 'VAL1359':
      return 'RN';
    case 'VAL1360':
      return 'RS';
    case 'VAL1361':
      return 'RO';
    case 'VAL1362':
      return 'RR';
    case 'VAL1363':
      return 'SC';
    case 'VAL1364':
      return 'SP';
    case 'VAL1365':
      return 'SE';
    case 'VAL1366':
      return 'TO';
  }
}
