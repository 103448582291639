export default function SetOfferStatus(id) {
  switch (id) {
    case 'OST1':
      return 'menu_filter_2';
    case 'OST2':
      return 'menu_filter_3';
    case 'OST3':
      return 'menu_filter_4';
    case 'OST4':
      return 'menu_filter_5';
    case 'OST5':
      return 'menu_filter_6';
    case 'OST6':
      return 'menu_filter_7';
    case 'OST7':
      return 'menu_filter_8';
    case 'OST8':
      return 'menu_filter_9';
    case 'OST9':
      return 'menu_filter_10';
  }
}
