<template>
  <div class="modatta-snap-tabs" ref="tabGroup">
    <!-- Tab Header -->
    <div class="snap-tabs-header">
      <!-- Tab Navigation -->
      <div class="snap-tabs-nav" ref="tabNav">
        <span class="nav-indicator" :style="{ left: indicatorOffset, width: indicatorWidth }"></span>
        <div v-for="tab in tabs">
          <a
            :class="{ active: tab.isActive }"
            :key="tab.id"
            ref="tabNavItems"
            :href="`#${tab.id}`"
            :active="tab.isActive"
            @click="
              (e) => {
                activateTab(tab);
                handleNavigation(e);
              }
            "
          >
            {{ $t(`offers_dashboard.table_menu_survey_${tab.id}`) }}
          </a>
        </div>
      </div>
    </div>
    <!-- Tab Content -->
    <div class="snap-tabs-body">
      <div class="snap-tabs section-body" ref="tabSection">
        <div class="snap-tab" v-for="tab in tabs" :key="tab.id" :class="`${tab.id}-table`">
          <div v-if="tab.id === 'answers'" class="questions">
            <div
              class="question"
              :class="{ 'is-selected': question.isSelected }"
              v-for="(question, index) in poll"
              :key="index"
              @click="selectQuestion(question)"
            >
              <span>{{ $t('filters.details.question') }} {{ index + 1 }}</span>
              <div class="body">{{ question.question }}</div>
            </div>
          </div>

          <div v-if="tab.id === 'distribution'" class="raw-data">
            <modatta-offer-decisions-timeline
              :id="offerId"
              :offer-name="$parent.offer.name"
              @decisions-raw-data="(data) => (decisionsRawData = data)"
            ></modatta-offer-decisions-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- .offer  .modatta-snap-tabs .questions .question.is-selected -->
  <!--
    <section class="modatta-snap-tabs" ref="tabGroup">
        <header class="scroll-snap-x">
            <nav ref="tabNav">
                <a
                    :key="tab.id"
                    ref="tabNavItems"
                    v-for="tab in tabs"
                    :href="`#${tab.id}`"
                    :active="tab.isActive"
                >
                    {{ $t(`offers_dashboard.table_menu_survey_${tab.id}`) }}
                </a>
            </nav>
            <span ref="tabIndicator" class="snap-indicator"></span>
        </header>
        <section class="section-body scroll-snap-x" ref="tabSection">
            <div class="tab-body" :id="tab.id" v-for="tab in tabs" :key="tab.id">
                <div v-if="tab.id === 'answers'" class="questions">
                    <div class="question" :class="{'is-selected': question.isSelected}" v-for="(question, index) in poll" :key="index" @click="selectQuestion(question)">
                        <span>{{ $t('filters.details.question') }}  {{index+1}}</span>
                        <div class="body">{{question.question}}</div>
                    </div>
                </div>
                <div v-if="tab.id === 'distribution'" class="raw-data">
                    <modatta-offer-decisions-timeline
                    :id="offerId"
                    :offer-name="$parent.offer.name"
                    @decisions-raw-data="data => decisionsRawData = data"></modatta-offer-decisions-timeline>
                </div>
            </div>
        </section>
    </section> -->
</template>

<script>
  import { mapActions } from 'vuex';
  import '../../../helpers/scroll-timeline.js';

  export default {
    props: ['data', 'offer'],
    watch: {
      decisionsRawData() {},
    },
    data() {
      return {
        decisionsRawData: '',
        offerId: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
        poll: this.data,
        tabs: [
          { id: 'answers', name: 'Answers', isActive: true },
          { id: 'distribution', name: 'Distribution', isActive: false },
          // {id: 'raw', name: 'Raw Data', isActive: false},
        ],
        rawData: '',
        indicatorOffset: 0,
        indicatorWidth: 0,
      };
    },
    mounted() {
      this.populateRawData();
      this.setupSnapTabs();
    },
    methods: {
      ...mapActions(['getAnswersList']),

      populateRawData() {
        this.getAnswersList(this.offer.id).then(() => {
          const list = this.$store.state.analytic.answersList;
          const data = [];

          list.forEach((item) => {
            item.poll.forEach((question) => {
              question.answers.forEach((answer) => {
                if (answer.log.length > 0) {
                  // Todas as respostass que foram dadas: answer.log
                  answer.log.forEach((l) => {
                    const row = {
                      offer_id: this.offer.id,
                      name: this.offer.name,
                      date: l.created_at,
                      question: question.question,
                      answer: answer.answer,
                    };
                    if (l.profiling.length) {
                      l.profiling.forEach((profile) => {
                        // add new column aka object key value pair to row
                        row[this.$t(`labels.${profile.profile_id}`).toLowerCase().replaceAll(' ', '_')] = this.$t(
                          `labels.${profile.value}`,
                        );
                      });
                    }
                    data.push(row);
                  });
                }
              });
            });
          });
          this.rawData = data;
          this.$emit('raw-data', this.rawData);
        });
      },

      selectQuestion(question) {
        this.poll.forEach((q) => (q.isSelected = false));
        question.isSelected = true;
        this.$emit('new-selected-question', question);
      },

      setupSnapTabs() {
        const activeTabId = location.hash ? this.tabs.findIndex((t) => t.id === location.hash.replace('#', '')) : 0;
        this.moveIndicator(this.$refs.tabNavItems[activeTabId]);
        this.activateTab(this.tabs[activeTabId]);
      },

      moveIndicator(to) {
        this.indicatorWidth = `${to.offsetWidth}px`;
        this.indicatorOffset = `${to.offsetLeft}px`;
      },

      changeTab(tabId) {
        this.$refs.tabSection.scrollLeft = tabId * this.$refs.tabSection.offsetWidth;
      },

      activateTab(tab) {
        this.tabs.forEach((t) => (t.isActive = false));
        tab.isActive = true;
      },

      findActiveTab(f) {
        return this.tabs.findIndex((t) => t.id === f);
      },

      handleNavigation(e) {
        this.moveIndicator(e.currentTarget);
        const activeTabId = this.findActiveTab(e.currentTarget.hash.replace('#', ''));
        this.activateTab(this.tabs[activeTabId]);
        this.changeTab(activeTabId);
      },
    },
    computed: {
      activeTab() {
        return this.tabs.find((t) => t.isActive);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modatta-snap-tabs {
    > header {
      position: relative;
      padding: 0 10px;
      padding-top: 16px;
      border-bottom-color: #e7edf9;

      a {
        padding-bottom: 12px;
        font-size: 14px;
      }

      button.button {
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        width: 126px;
        height: 35px;
        font-size: 12px;
        border-radius: 6px;
      }
    }

    .section-body {
      > div {
        padding-top: 12px;
      }
    }
  }

  .modatta-table {
    min-width: 400px;
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;

    .tag {
      width: 75px;
      height: 26px;
      border-radius: 4px;
      font-size: 11px;
    }

    .row {
      height: 50px;
      cursor: pointer;

      &:not(:last-child) {
        .cell {
          border-bottom: 1px solid #e7edf9;
        }
      }

      .cell {
        &:last-child {
          text-align: right;
        }

        .icon-wrapper {
          display: grid;
          place-items: center;
          margin-right: 12px;
          width: 23px;
          height: 23px;
          border-radius: 3px;
          background: #e9eef9;

          .modatta-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 14px;
            height: 14px;

            svg {
              height: 100% !important;
            }
          }
        }
      }
    }
  }

  .modatta-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 20px;
    padding-top: 46px;

    .chart-title {
      margin-bottom: 40px;
      text-align: center;
      font-family: 'Silka Semibold';
    }

    .modatta-pie-chart {
      position: relative;
      margin: 0 auto;
      width: 150px;
      height: 150px;

      .chart-center-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 32px;
        font-family: 'Silka bold';
        text-align: center;

        .value {
          display: inline-block;
          min-width: 60px;
          min-height: 30px;
        }

        .subtitle {
          display: block;
          margin-top: -8px;
          font-size: 16px;
          font-family: 'Silka Regular', sans-serif;
          opacity: 0.7;
        }
      }
    }

    .chart-legend {
      .data-point {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        &.nps {
          height: 54px;
        }

        > div {
          display: flex;
          align-items: center;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #e7edf9;
        }

        .color {
          margin-right: 10px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        .label {
          font-size: 14px;
        }

        .absolute {
          display: grid;
          place-items: center;
          min-width: 30px;
          height: 14px;
          font-size: 10px;
          border-radius: 3px;
        }

        .percentage {
          margin-left: 8px;
          min-width: 50px;
          font-family: 'Silka Semibold';
          font-size: 18px;
        }

        &:not(.nps) {
          .absolute {
            background: rgba(66, 113, 209, 0.1) !important;
            color: #4271d1 !important;
          }

          .percentage {
            color: #4271d1 !important;
          }
        }
      }
    }

    @media (max-width: 1440px) {
      padding: 20px;
      padding-top: 20px;

      .chart-title {
        margin-bottom: 10px;
      }

      .modatta-pie-chart {
        width: 140px;
        height: 140px;
      }

      .chart-legend {
        .data-point {
          height: 30px;

          &.nps {
            height: 34px;
          }
        }
      }
    }
  }

  .modatta-scroller-x {
    padding: 0 50px;
    overflow-x: auto;
    overscroll-behavior-x: contain;

    .items {
      position: relative;
      gap: 16px;
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      grid-auto-columns: 120px;

      .item {
        gap: 12px;
        display: grid;
        align-items: center;
        grid-template-columns: 33px 1fr;
        padding: 4px 8px;
        padding-right: 16px;
        height: 46px;
        font-size: 14px;
        font-family: 'Silka Semibold';
        border-radius: 10px;
        background: #f7f9fd;

        &:last-child:after {
          content: '';
          position: absolute;
          width: 50px;
          height: 100%;
          right: -50px;
          inline-size: 50px;
          block-size: 100%;
        }

        .icon-wrapper {
          display: grid;
          place-items: center;
          width: 32px;
          height: 32px;
          border-radius: 4px;
          background: #e9eef9;

          .modatta-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              height: 100% !important;
            }
          }
        }
      }
    }
  }
</style>
