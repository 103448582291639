export default function SetUserLanguage(id) {
  switch (id) {
    case 'LNG1':
      return 'pt';
    case 'LNG2':
      return 'br';
    case 'LNG3':
      return 'en';
    case 'LNG4':
      return 'de';
    case 'LNG5':
      return 'br';
  }
}
