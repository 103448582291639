<template>
  <div class="modatta-side-modal" :class="{ open: isOpen, close: !isOpen }" @click="closeSideModal()">
    <div class="modal-wrapper" @click.stop="">
      <header>
        <div class="modal-title" v-text="title"></div>
        <div class="modal-subtitle" v-text="subtitle" v-if="subtitle"></div>
        <button class="close button" @click="closeSideModal()">
          <modatta-icon icon="times"></modatta-icon>
        </button>
      </header>
      <modatta-side-modal-new-brand v-if="content === 'brand'" :options="options"></modatta-side-modal-new-brand>
      <modatta-side-modal-new-offer v-else-if="content === 'campaign'"></modatta-side-modal-new-offer>
      <modatta-side-modal-offer-step-media
        :handleUpdateMediaStep="handleUpdateMediaStep"
        v-else-if="content === 'step-media'"
      ></modatta-side-modal-offer-step-media>
      <modatta-side-modal-offer-details
        v-else-if="content === 'offer-details'"
        :options="options"
      ></modatta-side-modal-offer-details>
      <modatta-side-modal-payment-methods
        v-else-if="content === 'payment-methods'"
        :options="options"
      ></modatta-side-modal-payment-methods>
      <modatta-side-modal-cashback-overview
        v-else-if="content === 'cashback'"
        :options="options"
      ></modatta-side-modal-cashback-overview>
      <modatta-side-modal-new-offer-default-banner
        v-else-if="content === 'new-offer-default-banner'"
        @banner="(banner) => emitDefaultBanner(banner)"
      ></modatta-side-modal-new-offer-default-banner>
      <modatta-side-modal-new-offer-preview
        v-else-if="content === 'new-offer-preview'"
        :options="options"
      ></modatta-side-modal-new-offer-preview>
      <modatta-side-modal-new-offer-audience-by-offer
        v-else-if="content === 'new-offer-audience-offers'"
        :options="options"
      ></modatta-side-modal-new-offer-audience-by-offer>
      <modatta-side-modal-new-offer-audience-by-question
        v-else-if="content === 'new-offer-audience-question'"
        :options="options"
      ></modatta-side-modal-new-offer-audience-by-question>
      <modatta-side-modal-new-offer-audience-by-interests
        v-else-if="content === 'new-offer-audience-interests'"
        :options="options"
      ></modatta-side-modal-new-offer-audience-by-interests>
      <modatta-side-modal-new-offer-audience-by-profile-basic
        v-else-if="content === 'new-offer-audience-profile'"
        :options="options"
      ></modatta-side-modal-new-offer-audience-by-profile-basic>
      <modatta-side-modal-new-offer-audience-by-location
        v-else-if="content === 'new-offer-audience-location'"
        :options="options"
      ></modatta-side-modal-new-offer-audience-by-location>
      <modatta-side-modal-new-offer-audience-by-recommendation
        v-else-if="content === 'new-offer-audience-recommendation'"
        :options="options"
      ></modatta-side-modal-new-offer-audience-by-recommendation>
      <modatta-side-modal-loja-integrada
        v-else-if="content === 'loja-integrada'"
        :options="options"
      ></modatta-side-modal-loja-integrada>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import ModattaIcon from './ModattaIcon.vue';
  import ModattaSideModalLojaIntegrada from './ModattaSideModalLojaIntegrada.vue';
  import ModattaSideModalNewOfferAudienceByRecommendation from './ModattaSideModalNewOfferAudienceByRecommendation.vue';
  import ModattaSideModalNewOfferAudienceByLocation from './ModattaSideModalNewOfferAudienceByLocation.vue';
  import ModattaSideModalNewOfferAudienceByProfileBasic from './ModattaSideModalNewOfferAudienceByProfileBasic.vue';
  import ModattaSideModalNewOfferAudienceByInterests from './ModattaSideModalNewOfferAudienceByInterests.vue';
  import ModattaSideModalNewOfferAudienceByQuestion from './ModattaSideModalNewOfferAudienceByQuestion.vue';
  import ModattaSideModalNewOfferAudienceByOffer from './ModattaSideModalNewOfferAudienceByOffer.vue';
  import ModattaSideModalNewOfferPreview from './ModattaSideModalNewOfferPreview.vue';
  import ModattaSideModalNewOfferDefaultBanner from './ModattaSideModalNewOfferDefaultBanner.vue';
  import ModattaSideModalCashbackOverview from './ModattaSideModalCashbackOverview.vue';
  import ModattaSideModalPaymentMethods from './ModattaSideModalPaymentMethods.vue';
  import ModattaSideModalOfferDetails from './ModattaSideModalOfferDetails.vue';
  import ModattaSideModalOfferStepMedia from './ModattaSideModalOfferStepMedia.vue';
  import ModattaSideModalNewOffer from './ModattaSideModalNewOffer.vue';
  import ModattaSideModalNewBrand from './ModattaSideModalNewBrand.vue';

  export default {
    components: {
      ModattaIcon,
      ModattaSideModalLojaIntegrada,
      ModattaSideModalNewOfferAudienceByRecommendation,
      ModattaSideModalNewOfferAudienceByLocation,
      ModattaSideModalNewOfferAudienceByProfileBasic,
      ModattaSideModalNewOfferAudienceByInterests,
      ModattaSideModalNewOfferAudienceByQuestion,
      ModattaSideModalNewOfferAudienceByOffer,
      ModattaSideModalNewOfferPreview,
      ModattaSideModalNewOfferDefaultBanner,
      ModattaSideModalCashbackOverview,
      ModattaSideModalPaymentMethods,
      ModattaSideModalOfferDetails,
      ModattaSideModalOfferStepMedia,
      ModattaSideModalNewOffer,
      ModattaSideModalNewBrand,
    },
    props: ['content', 'title', 'subtitle', 'options', 'handleUpdateMediaStep'],
    data() {
      return {
        isOpen: false,
      };
    },
    mounted() {
      setTimeout(() => (this.isOpen = true), 10);
    },
    methods: {
      ...mapMutations(['setOpenSideModal']),

      emitDefaultBanner(banner) {
        this.$emit('banner', banner.file);
        this.$emit('banner-url', banner.url);
        this.closeSideModal();
      },
      closeSideModal() {
        this.isOpen = false;
        setTimeout(() => this.setOpenSideModal(false), 300); // Animation close time
      },
    },
  };
</script>
