export default function SetValidationType(id) {
  switch (id) {
    case 'ITV1':
    case 'ITV10':
      return 'Auto';
    case 'ITV2':
      return 'Universal Code';
    case 'ITV3':
      return 'Unique Code';
    case 'ITV4':
      return 'Post Back';
    case 'ITV5':
      return 'QR Code';
    case 'ITV6':
      return 'Google Tag Manager';
    case 'ITV12':
      return 'Deeplink';
    case 'ITV13':
      return 'Loja Integrada';
  }
}
