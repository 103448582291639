<template>
  <section class="portal">
    <modatta-sidenav page="brand"></modatta-sidenav>
    <main>
      <section class="brand offer dashboard components-wrapper">
        <modatta-navbar v-bind:title="$t('brands-dashboard.page_title')" :has-icon="false"></modatta-navbar>
        <template>
          <div class="module-grip-wrap">
            <div class="module-grid limit-width dashboard-components">
              <div class="module col-span-8 row-span-10 p-0">
                <div class="inner">
                  <div class="information">
                    <div class="start">
                      <div class="media" :class="{ 'is-loading': brand == '' }">
                        <div class="logo-wrapper" :style="`background-image: url(${brand.logo_url})`"></div>
                      </div>
                      <b-dropdown aria-role="list">
                        <template #trigger>
                          <div class="details">
                            <div class="name" :class="{ 'is-loading': brand == '' }">{{ brand.name }}</div>
                          </div>
                        </template>
                        <b-dropdown-item
                          aria-role="listitem"
                          v-for="(brand, index) in activeBrands.filter((b) => b.id != brandId)"
                          :key="index"
                          @click="handleBrandClick(brand)"
                        >
                          <div class="logo-wrapper" :style="`background-image: url(${brand.logo_url})`"></div>
                          <div class="type-name">{{ brand.name }}</div>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div class="module col-span-4 row-span-10 p-0">
                <div class="inner p-0">
                  <modatta-business-value-generated
                    :totalInvested="totalInvested"
                    :businessValueGenerated="totalGenerated"
                    :body="$t('brands-dashboard.investment_box_label')"
                  ></modatta-business-value-generated>
                </div>
              </div>
              <div class="module col-span-12 row-span-18 row-auto transparent p-0">
                <div class="inner p-0 no-overflow">
                  <modatta-dashboard-funnel
                    :id="brandId"
                    type="brand"
                    :delivered="totalDelivered"
                  ></modatta-dashboard-funnel>
                </div>
              </div>
              <div class="module col-span-6 row-span-50 p-0">
                <div class="inner pt-0">
                  <modatta-brand-snap-tabs :id="brandId"></modatta-brand-snap-tabs>
                </div>
              </div>
              <div class="module col-span-3 row-span-50">
                <div class="inner modatta-chart">
                  <div>
                    <div class="chart-title">{{ charts[0].title }}</div>
                    <div class="modatta-pie-chart" :class="{ 'is-loading oval': !acceptedDataLoaded }">
                      <modatta-pie-chart
                        v-if="acceptedDataLoaded"
                        :chart-data="charts[0].data"
                        :labels="charts[0].labels"
                        :colors="charts[0].colors"
                      ></modatta-pie-chart>
                      <div v-if="acceptedDataLoaded" class="chart-center-content nps">
                        <span class="value" :class="{ 'is-loading': nps === '' }">{{ nps }}</span>
                        <span class="subtitle">NPS</span>
                      </div>
                    </div>
                  </div>
                  <div class="chart-legend">
                    <div class="data-point nps" v-for="(data, i) in charts[0].labels.length" :key="charts[0].labels[i]">
                      <div>
                        <div class="color" :style="{ background: charts[0].colors[i] }"></div>
                        <div class="label">{{ charts[0].labels[i] }}</div>
                      </div>
                      <div v-if="acceptedDataLoaded">
                        <div
                          class="absolute"
                          :style="{ background: setAlphaColor(charts[0].colors[i], 0.1), color: charts[0].colors[i] }"
                        >
                          {{ charts[0].data[i] }}
                        </div>
                        <div
                          class="percentage"
                          v-text="getPercentage(charts[0], i)"
                          :style="{ color: charts[0].colors[i] }"
                        ></div>
                      </div>
                    </div>
                    <div class="data-point nps">
                      <div>
                        <div class="color" :style="{ background: 'rgba(236, 240, 248, 1)' }"></div>
                        <div class="label">{{ $t(`offers_dashboard.nps_box_not_evaluated`) }}</div>
                      </div>
                      <div v-if="acceptedDataLoaded">
                        <div class="absolute" :style="{ background: 'rgba(236, 240, 248, 0.7)' }">
                          {{ notEvaluated }}
                        </div>
                        <div class="percentage"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="module col-span-3 row-span-50">
                <div class="inner modatta-chart">
                  <div>
                    <div class="chart-title">{{ charts[1].title }}</div>
                    <div class="modatta-pie-chart" :class="{ 'is-loading oval': !declinedDataLoaded }">
                      <modatta-pie-chart
                        v-if="declinedDataLoaded"
                        :chart-data="charts[1].data"
                        :labels="charts[1].labels"
                        :colors="charts[1].colors"
                      ></modatta-pie-chart>
                      <div v-if="declinedDataLoaded" class="chart-center-content">
                        <span class="value" :class="{ 'is-loading': charts[1].center === '' }">{{
                          charts[1].center
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="chart-legend">
                    <div class="data-point" v-for="(data, i) in charts[1].labels.length" :key="charts[1].labels[i]">
                      <div>
                        <div class="color" :style="{ background: charts[1].colors[i] }"></div>
                        <div class="label">{{ charts[1].labels[i] }}</div>
                      </div>
                      <div v-if="declinedDataLoaded">
                        <div
                          class="absolute"
                          :style="{ background: setAlphaColor(charts[1].colors[i], 0.1), color: charts[1].colors[i] }"
                        >
                          {{ charts[1].data[i] }}
                        </div>
                        <div
                          class="percentage"
                          v-text="getPercentage(charts[1], i)"
                          :style="{ color: charts[1].colors[i] }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <modatta-side-modal
          v-if="openSideModal"
          :content="selectedOption.name"
          :title="selectedOption.title"
          :options="selectedOption.options"
        ></modatta-side-modal>
        <modatta-get-analytics-renderless source="brand" :id="brandId"></modatta-get-analytics-renderless>
      </section>
    </main>
  </section>
</template>

<script>
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';
  import Utilities from '../../helpers/utilities.js';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import tinycolor from 'tinycolor2';
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import ModattaBusinessValueGenerated from '../../components/ModattaBusinessValueGenerated.vue';
  import ModattaDashboardFunnel from '../../components/ModattaDashboardFunnel.vue';
  import ModattaPieChart from '../../components/ModattaPieChart.vue';
  import ModattaSideModal from '../../components/ModattaSideModal.vue';
  import ModattaGetAnalyticsRenderless from '../../components/ModattaGetAnalyticsRenderless.vue';

  import ModattaBrandSnapTabs from './components/ModattaBrandSnapTabs.vue';

  export default {
    components: {
      ModattaSidenav,
      ModattaNavbar,
      ModattaBusinessValueGenerated,
      ModattaDashboardFunnel,
      ModattaBrandSnapTabs,
      ModattaPieChart,
      ModattaSideModal,
      ModattaGetAnalyticsRenderless,
    },
    data() {
      return {
        brand: '',
        brandId: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
        brands: this.$store.state.brand.brands,
        metricCards: [
          {
            layout: '',
            body: this.$t('brands-dashboard.active_counter_label'),
            title: this.$store.state.analytic.brandActiveOffers,
            media: { type: 'icon', source: 'clock', color: '#9EBE0E', hasShadow: true }, // it could be a chart or an icon
          },
          {
            layout: '',
            body: this.$t('brands-dashboard.delivered_counter_label'),
            title: this.$store.state.analytic.brandTotalDelivered,
            media: { type: 'icon', source: 'send', color: '#7494EA', hasShadow: true }, // it could be a chart or an icon
          },
          {
            layout: '',
            detail: { isRed: false },
            body: this.$t('brands-dashboard.accepted_counter_label'),
            title: this.$store.state.analytic.brandTotalAccepted,
            media: { type: 'icon', source: 'check', color: '#19D2B4', hasShadow: true }, // it could be a chart or an icon
          },
          {
            layout: '',
            detail: { isRed: true },
            body: this.$t('brands-dashboard.declined_counter_label'),
            title: this.$store.state.analytic.brandTotalDeclined,
            media: { type: 'icon', source: 'close', color: '#F48266', hasShadow: true }, // it could be a chart or an icon
          },
        ],
        charts: [
          {
            title: this.$t('brands-dashboard.nps_box_title'),
            type: 'nps',
            center: '',
            labels: [
              this.$t('brands-dashboard.nps_box_promoter'),
              this.$t('brands-dashboard.nps_box_passive'),
              this.$t('brands-dashboard.nps_box_detractor'),
            ],
            data: [0, 0, 0],
            colors: ['#19D2B4', '#F7B05B', '#EF876E'],
          },
          {
            title: this.$t('brands-dashboard.decline_box_title'),
            type: '',
            center: '',
            labels: [
              this.$t('brands-dashboard.decline_box_other'),
              this.$t('brands-dashboard.decline_box_value'),
              this.$t('brands-dashboard.decline_box_brand'),
              this.$t('brands-dashboard.decline_box_product'),
            ],
            data: [0, 0, 0, 0],
            colors: ['#4271D1', '#7C9CDF', '#C2D1F0', '#E2EAF8'],
          },
        ],
        acceptedDataLoaded: false,
        declinedDataLoaded: false,
        nps: '',

        selectedOption: {},
      };
    },
    mounted() {
      if (this.$store.state.brand.brands.length === 0) {
        // 0. Call specific brand API
        this.getBrand(this.brandId).then((response) => {
          this.brand = response.data.result[0];
          setTimeout(() => this.setTooltips(), 100);
        });
        this.getBrands().then(() => (this.brands = this.$store.state.brand.brands));
      } else {
        // 1. Use current brand list in Store to get brand info
        this.brand = this.getBrandById(this.brandId);
        setTimeout(() => this.setTooltips(), 100);
      }
    },
    watch: {
      $route(to, from) {
        if (to.path != from.path) {
          // this is needed because of Vue limitation on
          // render the same component with different data
          window.location.reload();
        }
      },
      brands(newVal) {
        this.brands = newVal;
      },
      activeOffers(newVal) {
        this.$set(this.metricCards[0], 'title', newVal);
      },
      totalDelivered(newVal) {
        this.$set(this.metricCards[1], 'title', newVal);
      },
      totalAccepted(newVal) {
        this.$set(this.metricCards[2], 'title', newVal);
        const detailValue = Math.round((this.totalAccepted / this.totalDelivered) * 100);
        this.$set(this.metricCards[2].detail, 'value', isFinite(detailValue) ? `${detailValue}%` : '0%');
      },
      acceptedData(newVal) {
        this.$set(this.charts[0], 'data', newVal);
      },
      totalDeclined(newVal) {
        this.$set(this.metricCards[3], 'title', newVal);
        this.$set(this.charts[1], 'center', `${Math.round((this.totalDeclined / this.totalDelivered) * 100)}%`);
        const detailValue = Math.round((this.totalDeclined / this.totalDelivered) * 100);
        this.$set(this.metricCards[3].detail, 'value', isFinite(detailValue) ? `${detailValue}%` : '0%');
      },
      declinedData(newVal) {
        this.$set(this.charts[1], 'data', newVal);
      },
    },
    computed: {
      ...mapState(['openSideModal', 'analytic']),
      ...mapGetters(['getBrandById']),

      activeBrands() {
        return this.brands.filter((b) => b.status_id === 'BST2');
      },

      totalInvested() {
        if (this.analytic.brandTotalInvested == null) return null;
        return (
          this.$t(`labels.${this.analytic.brandTotalInvested.currency}`) +
          `${Utilities.numberWithCommas(Utilities.toDecimalPlaces(this.analytic.brandTotalInvested.value, 2))}`
        );
      },
      totalGenerated() {
        if (this.analytic.brandTotalGenerated == null) return null;
        return (
          this.$t(`labels.${this.analytic.brandTotalGenerated.currency}`) +
          `${Utilities.numberWithCommas(Utilities.toDecimalPlaces(this.analytic.brandTotalGenerated.value, 2))}`
        );
      },

      activeOffers() {
        return this.analytic.brandActiveOffers;
      },
      totalDelivered() {
        return this.analytic.brandTotalDelivered;
      },

      totalAccepted() {
        return this.analytic.brandTotalAccepted;
      },
      acceptedData() {
        return this.analytic.brandAcceptedData;
      },
      totalDeclined() {
        return this.analytic.brandTotalDeclined;
      },
      declinedData() {
        return this.analytic.brandDeclinedData;
      },

      notEvaluated() {
        return this.analytic.brandNotEvaluatedData;
      },
    },
    methods: {
      ...mapActions(['getBrand', 'getBrands', 'archiveBrand']),
      ...mapMutations(['setOpenSideModal', 'setOpenToast']),
      archive() {
        this.archiveBrand({ status_id: 'BST4', id: this.brandId })
          .then(() => {
            this.updateArchivedBrand(this.brandId);
            this.setOpenToast({
              title: 'Archived successfully',
              isSuccess: true
            });
          })
          .catch(() => {
            this.setOpenToast({
              title: "Couldn't archive this brand. Please try again.",
              isSuccess: false
            });
          });
      },
      updateArchivedBrand() {
        this.brand.status_id = 'BST4';
      },
      handleBrandClick(brand) {
        this.$router.replace({ path: `/brands/${brand.id}` });
      },

      /*********************************
      | HELPERS
      **********************************/
      setTooltips() {
        if (this.$refs.archiveBrand) {
          tippy(this.$refs.archiveBrand, {
            maxWidth: 140,
            zIndex: 99999,
            placement: 'top',
            content: 'Archive brand',
          });
        }
        if (this.$refs.editBrand) {
          tippy(this.$refs.editBrand, {
            maxWidth: 140,
            zIndex: 99999,
            placement: 'top',
            content: 'Edit brand',
          });
        }
      },
      selectOption(name, title, options) {
        this.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
      },
      getPercentage(chart, i) {
        const total = chart.data.reduce((a, b) => a + b, 0);
        if (total === 0) {
          return '0%';
        }
        const result = Math.round((chart.data[i] / total) * 100 * 10) / 10 || 0;
        return isFinite(result) ? `${result}%` : '0%';
      },
      getNotEvaluatedPercentage() {
        const result = Math.round((this.notEvaluated / this.totalAccepted) * 100 * 10) / 10 || 0;
        return isFinite(result) ? `${result}` : '0';
        // Removed % Percentage from NOT EVAL list item
      },
      setAlphaColor(color, alpha) {
        const c = tinycolor(color);
        return c.setAlpha(alpha).toRgbString();
      },
      nFormatter(num, digits) {
        const si = [
          { value: 1, symbol: '' },
          { value: 1e3, symbol: 'k' },
          { value: 1e6, symbol: 'M' },
          { value: 1e9, symbol: 'G' },
          { value: 1e12, symbol: 'T' },
          { value: 1e15, symbol: 'P' },
          { value: 1e18, symbol: 'E' },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
          if (num >= si[i].value) {
            break;
          }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
      },
    },
  };
</script>
