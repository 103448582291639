import headers from './headers';

async function getBrandsAndOffers(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/list${id != null ? `?brand=${id}` : ''}`;
  return await axios.get(URL, { headers });
}
async function getTotalInvested(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/invested${id != null ? `?brand=${id}` : ''}`;
  return await axios.get(URL, { headers });
}
async function getInteractions() {
  const URL = `${process.env.VUE_APP_API_URL}analytics/interactions`;
  return await axios.get(URL, { headers });
}
async function getActiveOffers(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/active${id != null ? `?brand=${id}` : ''}`;
  return await axios.get(URL, { headers });
}
async function getTotalDelivered(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/delivered${id != null ? `?brand=${id}` : ''}`;
  return await axios.get(URL, { headers });
}
async function getAcceptedDecisions(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/decisions/completed${id != null ? `?brand=${id}` : ''}`;
  return await axios.get(URL, { headers });
}
async function getDeclinedDecisions(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/decisions/declined${id != null ? `?brand=${id}` : ''}`;
  return await axios.get(URL, { headers });
}

async function getAnswersList(id) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/answers/list${id != null ? `?offer=${id}` : ''}`;
  return await axios.get(URL, { headers });
}

// FUNNEL
async function getFunnelData(data) {
  const URL = `${process.env.VUE_APP_API_URL}analytics/decisions/funnel${data.id != null ? `?${data.type}=${data.id}` : ''}`;
  return await axios.get(URL, { headers });
}

export default {
  getInteractions: () => getInteractions(),
  getAnswersList: (id) => getAnswersList(id),
  getActiveOffers: (id) => getActiveOffers(id),
  getFunnelData: (data) => getFunnelData(data),
  getTotalInvested: (id) => getTotalInvested(id),
  getTotalDelivered: (id) => getTotalDelivered(id),
  getBrandsAndOffers: (id) => getBrandsAndOffers(id),
  getAcceptedDecisions: (id) => getAcceptedDecisions(id),
  getDeclinedDecisions: (id) => getDeclinedDecisions(id),
};
