<template>
  <div class="step-details">
    <div class="details">
      <h2>{{ $t('offers_step6.section_1_title') }}</h2>
      <p>{{ $t('offers_step6.section_1_subtitle') }}</p>

      <div class="budget">
        <div class="info">
          <div class="dates">
            <div class="date">
              <div class="field">
                <label class="label">{{ $t('offers_step6.box_section_1_title') }}</label>
              </div>
              <div class="modatta-datepicker">
                <b-datetimepicker
                  v-model="startDateTime"
                  :datetime-formatter="dateFormatter"
                  :min-datetime="new Date()"
                  v-bind:placeholder="$t('offers_step6.box_section_1_title')"
                  :max-datetime="endDateTime"
                  :timepicker="timepicker"
                  ref="startDatePicker"
                >
                  <template #right>
                    <button class="button is-primary" @click="$refs.startDatePicker.toggle()">OK</button>
                  </template>
                </b-datetimepicker>
                <modatta-icon icon="calendar"></modatta-icon>
              </div>
              <label class="text">{{ tzInfoStart }}</label>
            </div>
            <div class="date">
              <div class="field">
                <label class="label">{{ $t('offers_step6.box_section_1_title2') }}</label>
              </div>
              <div class="modatta-datepicker">
                <b-datetimepicker
                  v-model="endDateTime"
                  :min-datetime="startDateTime"
                  :datetime-formatter="dateFormatter"
                  v-bind:placeholder="$t('offers_step6.box_section_1_title2')"
                  position="is-bottom-left"
                  :timepicker="timepicker"
                  ref="endDatePicker"
                >
                  <template #right>
                    <button class="button is-primary" @click="$refs.endDatePicker.toggle()">OK</button>
                  </template>
                </b-datetimepicker>
                <modatta-icon icon="calendar"></modatta-icon>
              </div>
              <label class="text">{{ tzInfoEnd }}</label>
            </div>
          </div>
          <div class="unit-cost">
            <div class="field">
              <label class="label">
                {{ $t('offers_step6.box_section_2_title') }}
                <b-tooltip
                  v-bind:label="$t('offers_step6.box_section_2_tooltip')"
                  type="is-dark"
                  position="is-right"
                  size="is-small"
                  multilined
                >
                  <modatta-icon icon="info-square"></modatta-icon>
                </b-tooltip>
              </label>
              <div v-if="hasCashbackOption" class="modatta-segment-control">
                <button class="button" @click="paymentType = 'cash'" :active="paymentType === 'cash'">
                  {{ $t('offers_details.section_6_budget_payment_type_cash') }}
                </button>
                <button disabled class="button" @click="paymentType = 'cashback'" :active="paymentType === 'cashback'">
                  {{ $t('offers_details.section_6_budget_payment_type_cashback') }}
                </button>
              </div>
            </div>
            <modatta-radio-input
              v-if="paymentType == 'cash'"
              :suggestedValue="$parent.newOffer.unit_tcost"
              :options="unitCostOptions"
              :has-other="true"
              @radio-value="(radio) => ($parent.newOffer.unit_tcost = radio)"
              :min="0.1"
              :has-error="unit_tcost_validation"
            ></modatta-radio-input>
            <b-slider
              v-if="paymentType == 'cashback'"
              indicator
              :tooltip="false"
              v-model="$parent.newOffer.perc_tcost"
              :custom-formatter="(val) => Math.round(val * 100) + '%'"
              :min="0.02"
              :max="1.0"
              :step="0.01"
            ></b-slider>
          </div>
          <div class="expected-business unit-cost">
            <div class="field">
              <label class="label">
                {{ $t('offers_step6.box_section_4_title') }}
                <strong>{{ $t('offers_step6.box_section_4_optional') }}</strong>
                <b-tooltip
                  v-bind:label="$t('offers_step6.box_section_4_tooltip')"
                  type="is-dark"
                  position="is-top"
                  size="is-small"
                  multilined
                >
                  <modatta-icon icon="info-square"></modatta-icon>
                </b-tooltip>
              </label>
            </div>
            <div class="input-wrapper">
              <input
                type="number"
                v-model="$parent.newOffer.business_value"
                :placeholder="$t(`labels.${$parent.newOffer.currency_id}`)"
                min="0"
                class="has-validation"
                :class="{ 'is-error force-error': business_value_validation }"
              />
            </div>
          </div>
          <div class="people">
            <div class="field">
              <label class="label">
                {{ $t('offers_step6.box_section_3_title') }}
                <b-tooltip
                  v-bind:label="$t('offers_step6.box_section_3_tooltip')"
                  type="is-dark"
                  position="is-top"
                  size="is-small"
                  multilined
                >
                  <modatta-icon icon="info-square"></modatta-icon>
                </b-tooltip>
              </label>
            </div>
            <modatta-radio-input
              :suggestedValue="$parent.newOffer.max_accepts"
              :options="peopleOptions"
              min="1"
              :accepts-only-integer="true"
              :has-other="true"
              @radio-value="(radio) => (this.$parent.newOffer.max_accepts = radio)"
              :has-error="max_accepts_validation"
            ></modatta-radio-input>
          </div>
          <div class="expires">
            <div class="field">
              <label class="label">
                {{ $t('offers_step6.box_section_5_title') }}
                <b-tooltip
                  v-bind:label="$t('offers_step6.box_section_5_tooltip')"
                  type="is-dark"
                  position="is-top"
                  size="is-small"
                  multilined
                >
                  <modatta-icon icon="info-square"></modatta-icon>
                </b-tooltip>
              </label>
            </div>
            <modatta-radio-input
              :suggestedValue="$parent.newOffer.validation_max_hours"
              :options="validationMaxHourOpts"
              min="1"
              :max="336"
              :step="1"
              :accepts-only-integer="true"
              :has-other="true"
              @radio-value="(radio) => (this.$parent.newOffer.validation_max_hours = radio)"
              :has-error="max_hours_validation"
            ></modatta-radio-input>
          </div>
          <div class="end">
            <button class="button is-secondary" @click="handlePreviousClick()">
              <template>{{ $t('offers_step2.back_button_label') }}</template>
            </button>
            <button
              class="button is-primary"
              :disabled="!$parent.isBudgetStepCompleted || $parent.isLoading"
              @click="handleNextClick()"
            >
              <template>{{ $t('offers_step4.box_section_3_button_label') }}</template>
              <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
            </button>
          </div>
        </div>
        <div class="price">
          <div
            v-if="($parent.newOffer.unit_tcost || $parent.newOffer.perc_tcost) && $parent.newOffer.max_accepts"
            class="estimations"
          >
            <div class="field">
              <label class="label">
                {{ $t('offers_step6.box_budget_label1') }}
              </label>
            </div>
            <div class="card">
              <div class="value">
                {{ $t(`labels.${$parent.newOffer.currency_id}`) }}
                {{ calculateTotalCost() }}
              </div>
              <div class="text">{{ $t('offers_step6.box_budget_label2') }}</div>
            </div>
            <div class="card">
              <div class="value">
                {{ $t(`labels.${$parent.newOffer.currency_id}`) }}
                {{ calculateBusiness() }}
              </div>
              <div class="text">{{ $t('offers_step6.box_budget_label3') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      aria-modal
      trap-focus
      has-modal-card
      aria-role="dialog"
      v-model="showPopUp"
      aria-label="Pop up"
      :destroy-on-hide="false"
      :can-cancel="['escape', 'outside']"
    >
      <template #default="props">
        <header>
          <div class="title">{{ $t('offers_details.section_6_budget_pop_up_title') }}</div>
          <button class="button is-text" @click="props.close">
            <modatta-icon icon="times"></modatta-icon>
          </button>
        </header>
        <div class="modal-content">
          <p>{{ $t('offers_details.section_6_budget_pop_up_body') }}</p>
        </div>
        <footer>
          <button class="button is-text" :disabled="$parent.isLoading" @click="handleNextClickClean()">
            {{ $t('offers_details.section_6_budget_pop_up_continue_btn') }}
          </button>
          <button class="button is-secondary" :disabled="$parent.isLoading" @click="props.close">
            {{ $t('offers_details.section_6_budget_pop_up_review_btn') }}
          </button>
        </footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { DateTime } from 'luxon';
  import { i18n2tz as I18N2TZ } from '../../../../helpers/tz-i18n';
  import UnitCostCashOptions from '../../../../helpers/unit-cost-cash-options.js';
  import ModattaIcon from '../../../../components/ModattaIcon.vue';
  import ModattaRadioInput from '../../../../components/ModattaRadioInput.vue';

  export default {
    components: { ModattaIcon, ModattaRadioInput },
    data() {
      return {
        startDateTime: null,
        endDateTime: null,
        offerZone: null,
        hourFormat: undefined,
        timepicker: { enableSeconds: false, incrementMinutes: 1, hourFormat: '24' },
        peopleOptions: [100, 500, 1000, 2000, 5000, 7500, 10000],
        validationMaxHourOpts: [1, 2, 4, 8, 12, 24, 48],
        paymentType: this.$parent.newOffer.unit_tcost ? 'cash' : this.$parent.newOffer.perc_tcost ? 'cashback' : 'cash',
        showPopUp: false,
      };
    },
    mounted() {
      this.handleSuggestedValues();
      this.paymentType = 'cash';
      if (
        !(this.$parent.newOffer.type_label_id === 'OFT10' && this.$parent.newOffer.interaction_validation_id === 'ITV4')
      ) {
        this.$parent.newOffer.perc_tcost = null;
        this.paymentType = 'cash';
      }
    },
    beforeMount() {
      this.offerZone = I18N2TZ(this.$parent.newOffer.country_id);
      const sdt =
        this.$parent.newOffer.online_on == null
          ? DateTime.now()
          : DateTime.fromSQL(`${this.$parent.newOffer.online_on} Z`, { zone: this.offerZone }).setZone(
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              { keepLocalTime: true },
            );

      const edt =
        this.$parent.newOffer.offline_on == null
          ? null
          : DateTime.fromSQL(`${this.$parent.newOffer.offline_on} Z`, { zone: this.offerZone }).setZone(
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              { keepLocalTime: true },
            );

      this.startDateTime = sdt.toJSDate();
      this.endDateTime = edt.toJSDate();
    },
    watch: {
      startDateTime: function (val) {
        this.$parent.newOffer.online_on = DateTime.fromJSDate(val)
          .setZone(this.offerZone, { keepLocalTime: true })
          .toUTC()
          .toSQL();
      },
      endDateTime: function (val) {
        this.$parent.newOffer.offline_on = DateTime.fromJSDate(val)
          .setZone(this.offerZone, { keepLocalTime: true })
          .toUTC()
          .toSQL();
      },
    },
    computed: {
      unit_tcost_validation() {
        let unit_tcost = this.$parent.newOffer.unit_tcost;
        if (unit_tcost && unit_tcost > 0.1 && !isNaN(unit_tcost)) {
          return false;
        } else {
          // this.$parent.newOffer.unit_tcost = '';
          return true;
        }
      },
      business_value_validation() {
        let business_value = this.$parent.newOffer.business_value;
        if (business_value) {
          if (business_value > 0 && !isNaN(business_value)) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      },
      max_accepts_validation() {
        let max_accepts = this.$parent.newOffer.max_accepts;
        if (max_accepts && max_accepts > 0 && !isNaN(max_accepts)) {
          return false;
        } else {
          return true;
        }
      },
      max_hours_validation() {
        let max_hours = this.$parent.newOffer.validation_max_hours;
        if (max_hours && max_hours > 0 && !isNaN(max_hours)) {
          return false;
        } else {
          return true;
        }
      },
      inputEmpty() {
        return this.$parent.newOffer.unit_tcost === '';
      },
      inputInvalid() {
        return isNaN(this.$parent.newOffer.unit_tcost);
      },
      unitCostOptions() {
        const currGeo = UnitCostCashOptions[this.$parent.newOffer.country_id];
        if (!currGeo || !this.$parent.newOffer.interaction_type_id) {
          return [];
        }
        return currGeo(this.$parent.newOffer.interaction_type_id);
      },
      hasCashbackOption() {
        return (
          this.$parent.newOffer.type_label_id === 'OFT10' && this.$parent.newOffer.interaction_validation_id === 'ITV4'
        );
      },
      tzInfoStart() {
        const dtObj = DateTime.fromJSDate(this.startDateTime, { zone: this.offerZone });
        // return this.tzInfo(dtObj)
        if (dtObj.invalid) {
          return;
        }
        return 'UTC '.concat(dtObj.toFormat('ZZ'));
      },
      tzInfoEnd() {
        const dtObj = DateTime.fromJSDate(this.endDateTime, { zone: this.offerZone });
        // return this.tzInfo(dtObj)
        if (dtObj.invalid) {
          return;
        }
        return 'UTC '.concat(dtObj.toFormat('ZZ'));
      },
    },
    methods: {
      tzInfo(dtObj) {
        if (dtObj.invalid) {
          return;
        }
        if (DateTime.now().offset == dtObj.offset) {
          return;
        }
        const datetime = dtObj.toLocaleString(DateTime.DATETIME_SHORT);
        const country = this.$t(`labels.${this.$parent.newOffer.country_id}`) || dtObj.zoneName;
        return this.$t('offers_step6.box_section_1_tzinfo', { datetime, country });
      },
      closeDatePicker() {
        this.$refs.endDatePicker.getElementsByTagName('input')[0].blur();
      },
      handleSuggestedValues() {
        if (this.$parent.newOffer.unit_tcost !== null || this.$parent.newOffer.unit_tcost !== '') return;

        switch (this.$parent.newOffer.interaction_type_id) {
          case 'INT1':
          case 'INT3':
          case 'INT6':
          case 'INT7':
            this.$parent.newOffer.unit_tcost = '0.15';
            break;
          case 'INT2':
            this.$parent.newOffer.unit_tcost = '0.50';
            break;
          case 'INT4':
          case 'INT5':
            this.$parent.newOffer.unit_tcost = '2';
            break;
          case 'INT8':
            this.$parent.newOffer.unit_tcost = '10';
            break;
          default:
            this.$parent.newOffer.unit_tcost = '1.00';
            break;
        }
      },
      dateFormatter(newDate) {
        return DateTime.fromJSDate(newDate)
          .setZone(this.offerZone, { keepLocalTime: true })
          .toFormat('dd-MM-yyyy HH:mm');
      },

      handlePreviousClick() {
        this.$parent.currentStep--;
      },
      handleNextClick() {
        this.handleNextClickClean();
      },
      handleNextClickClean() {
        this.cleanTcost();
        this.$parent.handleNextClick(5, {
          offline_on: this.$parent.newOffer.offline_on,
          online_on: this.$parent.newOffer.online_on,
          business_value: this.$parent.newOffer.business_value,
          max_accepts: this.$parent.newOffer.max_accepts,
          unit_tcost: this.$parent.newOffer.unit_tcost,
          validation_max_hours: this.$parent.newOffer.validation_max_hours,
        });
      },
      cleanTcost() {
        if (this.paymentType !== 'cashback') {
          this.$parent.newOffer.perc_tcost = null;
        } else {
          this.$parent.newOffer.unit_tcost = null;
        }
      },
      submitOffer() {
        this.$parent.submitNewOffer();
      },
      calculateTotalCost() {
        if (this.paymentType !== 'cashback') {
          return (this.$parent.newOffer.unit_tcost * this.$parent.newOffer.max_accepts).toLocaleString();
        } else {
          const percentage = this.$parent.newOffer.perc_tcost;
          const value = this.$parent.newOffer.business_value * percentage;
          return (value * this.$parent.newOffer.max_accepts).toLocaleString();
        }
      },
      calculateBusiness() {
        if (this.$parent.newOffer.business_value) {
          return (this.$parent.newOffer.max_accepts * this.$parent.newOffer.business_value).toLocaleString();
        } else {
          return (this.$parent.newOffer.max_accepts * this.$parent.newOffer.unit_tcost).toLocaleString();
        }
      },
    },
  };
</script>
