<template>
  <div class="authentication">
    <div class="form-container">
      <div class="languages select">
        <b-dropdown append-to-body aria-role="menu" scrollable max-height="200" trap-focus>
          <template #trigger>
            <a class="navbar-item" role="button">
              <span>{{ selectedLanguageLabel }}</span>
              <b-icon icon="menu-down"></b-icon>
            </a>
          </template>
          <b-dropdown-item custom aria-role="listitem" v-for="(language, index) in languages"
            :active="selectedLanguageId === language.lang_id" :key="index">
            <b-radio name="language" :id="language.lang_id" v-model="selectedLanguageId"
              :native-value="language.lang_id" @input="handleLanguageClick(language)">
              {{ language.code }}
            </b-radio>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="clouds">
        <img class="left" src="/svg/decorations/left-cloud.svg" alt="Illustration representing a cloud" />
        <img class="right" src="/svg/decorations/right-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="tree">
        <img src="/svg/decorations/tree.svg" alt="Illustration representing a tree" />
      </div>
      <div class="illustrations">
        <div class="background">
          <img src="/svg/decorations/sign-up-background.svg"
            alt="Illustration representing mountains, plants and sky" />
        </div>
        <div class="cloud">
          <img src="/svg/decorations/cloud.svg" alt="Illustration representing a cloud" />
        </div>
        <div class="character signup">
          <img src="/svg/decorations/sign-up-character.svg"
            alt="Illustration representing a woman working on a computer" />
        </div>
        <router-link to="/" class="logo"></router-link>
      </div>
      <div class="form">
        <h1>{{ $t('signup.title') }}</h1>
        <p>{{ $t('signup.description') }}</p>
        <div v-if="errors != null" class="error-block">
          <modatta-icon icon="error-exclamation-point" width="20"></modatta-icon>
          <p v-html="errors"></p>
        </div>
        <form @submit.prevent="registerUser()">
          <div class="input-wrapper" :class="{ 'has-error': $v.form.name.$error, 'is-valid': !$v.form.name.$invalid }">
            <modatta-icon icon="profile-outline" width="16"></modatta-icon>
            <input v-model="$v.form.name.$model" type="text" :placeholder="$t('signup.name_input')" required
              autocomplete="off" />
            <modatta-icon v-if="$v.form.name.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.name.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <div class="input-wrapper"
            :class="{ 'has-error': $v.form.email.$error, 'is-valid': !$v.form.email.$invalid }">
            <modatta-icon icon="email" width="23"></modatta-icon>
            <input v-model.trim="$v.form.email.$model" type="email" :placeholder="$t('signup.email_input')" required
              autocomplete="off" />
            <modatta-icon v-if="$v.form.email.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.email.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <div class="input-wrapper"
            :class="{ 'has-error': $v.form.password.$error, 'is-valid': !$v.form.password.$invalid }">
            <modatta-icon icon="lock" width="23"></modatta-icon>
            <input v-model.trim="$v.form.password.$model" type="password" :placeholder="$t('signup.psw_input')" required
              autocomplete="off" />
            <modatta-icon v-if="$v.form.password.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.password.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <div class="input-wrapper"
            :class="{ 'has-error': $v.form.passwordConfirm.$error, 'is-valid': !$v.form.passwordConfirm.$invalid }">
            <modatta-icon icon="lock" width="23"></modatta-icon>
            <input v-model.trim="$v.form.passwordConfirm.$model" type="password"
              :placeholder="$t('signup.psw_confirm_input')" required />
            <modatta-icon v-if="$v.form.passwordConfirm.$error" icon="error-exclamation-point"
              width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.passwordConfirm.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <div class="input-wrapper" :class="{ 'has-error': $v.form.name.$error, 'is-valid': !$v.form.name.$invalid }">
            <modatta-icon icon="phone" width="24"></modatta-icon>
            <input v-model="$v.form.phone.$model" v-mask="'+55 (##) #####-####'" type="text"
              :placeholder="$t('signup.phone_input')" required autocomplete="off" />
            <modatta-icon v-if="$v.form.phone.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.phone.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <div class="modatta-country-select">
            <multiselect v-bind:placeholder="$t('sigup.choose_country')" selectLabel="ENTER to select"
              v-model="selectedCountry" openDirection="bottom" :options="countries" :allow-empty="false"
              :showLabels="false" track-by="name" label="name" disabled>
              <span class="caret" slot="caret">
                <modatta-icon icon="arrow-down" width="10"></modatta-icon>
              </span>
              <template slot="singleLabel" slot-scope="props">
                <img :src="getFlag(props.option.code)" alt="Modatta" />
                {{ props.option.name }}
              </template>
              <template slot="option" slot-scope="props">
                <img :src="getFlag(props.option.code)" alt="Modatta" />
                {{ props.option.name }}
              </template>
            </multiselect>
          </div>
          <b-field>
            <b-checkbox v-model.trim="$v.form.terms.$model">
              {{ $t('signup.tc_disclaimer') }}
              <a href="https://modatta.org/legal.html" target="_blank">{{ $t('signup.tc_link_1') }}</a>
            </b-checkbox>
          </b-field>
          <div class="recaptcha">
            <vue-recaptcha :sitekey="recaptchaSitekey" @verify="onVerifyRecaptcha" @expired="onExpiredRecaptcha"
              :loadRecaptchaScript="true"></vue-recaptcha>
          </div>
          <button class="button is-primary" type="submit" :disabled="$v.form.$invalid || isLoading">
            <template v-if="!isLoading">{{ $t('signup.button_label') }}</template>
            <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
          </button>
          <footer>
            {{ $t('signup.signin_label') }} <router-link to="/login">{{ $t('signup.signin_link') }}</router-link>
          </footer>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import VueRecaptcha from 'vue-recaptcha';
  import { required, minLength, sameAs, email, helpers } from 'vuelidate/lib/validators';
  import { mask } from 'vue-the-mask';
  import Multiselect from 'vue-multiselect';
  import { findFlagUrlByIso2Code } from 'country-flags-svg';
  import { sha256 } from 'js-sha256';
  import ModattaIcon from '../../components/ModattaIcon.vue';

  export default {
    components: { VueRecaptcha, Multiselect, ModattaIcon },
    directives: { mask },
    data() {
      return {
        browserLang: '',
        selectedLanguageLabel: null,
        selectedLanguageId: '',
        recaptchaSitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        isLoading: false,
        errors: null,
        form: {
          name: null,
          email: null,
          terms: false,
          password: null,
          passwordConfirm: null,
          phone: null,
          recaptcha: false,
        },
        countries: [
          {
            name: this.$t('labels.GEO32'),
            code: 'BR',
            country_id: 'GEO32',
            currency_id: 'CUR2',
            lang_id: 'LNG5',
            vat: 0.0,
          },
        ],
        selectedCountry: {},
        languages: [
          { name: this.$t('labels.LNG1'), lang_id: 'LNG1', i18n_code: 'pt', code: 'pt-pt' },
          { name: this.$t('labels.LNG5'), lang_id: 'LNG5', i18n_code: 'br', code: 'pt-br' },
          { name: this.$t('labels.LNG3'), lang_id: 'LNG3', i18n_code: 'en', code: 'en' },
        ],
      };
    },
    watch: {
      selectedLanguageId() {
        let current = this.languages.find((o) => o.lang_id === this.selectedLanguageId);
        this.$i18n.locale = current.i18n_code;
        this.selectedLanguageLabel = current.code;
      },
    },
    mounted() {
      this.browserLang = navigator.language;
      switch (this.browserLang) {
        case 'pt':
          this.selectedLanguageId = 'LNG1';
          break;
        case 'pt-BR':
          this.selectedLanguageId = 'LNG5';
          break;
        default:
          this.selectedLanguageId = 'LNG3';
          break;
      }
      this.selectedCountry = {
        name: this.$t('labels.GEO32'),
        code: 'BR',
        country_id: 'GEO32',
        currency_id: 'CUR2',
        lang_id: 'LNG5',
        vat: 0.0,
      };
    },
    validations: {
      form: {
        name: {
          required,
          minLength: minLength(4),
        },
        email: {
          required,
          email,
          async isEmailAvailable(value) {
            if (value === '' || value === null) return true;
            let response = await this.isEmailAvailable({ username: sha256(value) }).catch(
              () => (this.errors = this.$t('signup.error_email_2')),
            );

            if (response.status === 200) {
              this.clearErrors();
            }
            return response.status === 200;
          },
        },
        terms: {
          sameAs: sameAs(() => true),
        },
        recaptcha: {
          required,
          sameAs: sameAs(() => true),
        },
        password: {
          required,
          minLength: minLength(6),
        },
        passwordConfirm: {
          required,
          sameAsPassword: sameAs('password'),
        },
        phone: {
          required,
          validPhone: helpers.regex('validPhone', /^\+55 \(\d{2}\) \d{5}-\d{4}$/),
        },
      },
    },
    methods: {
      ...mapActions(['createUser', 'isEmailAvailable']),

      onVerifyRecaptcha() {
        this.form.recaptcha = true;
      },
      onExpiredRecaptcha() {
        this.form.recaptcha = false;
      },
      async registerUser() {
        try {
          if (this.$v.form.$invalid) {
            this.errors = this.$t('signup.error_invalid');
            return;
          }

          this.isLoading = true;

          const body = JSON.stringify({
            email: this.form.email,
            personal_name: this.form.name,
            username: sha256(this.form.email),
            password: sha256(this.form.password),
            country_id: this.selectedCountry.country_id,
            lang_id: this.selectedCountry.lang_id,
            vat: this.selectedCountry.vat,
            phone: this.form.phone,
          });
          await this.createUser(body);
          this.$router.push('/success');
        } catch (error) {
          this.errors = this.$t('signup.error_invalid');
        } finally {
          this.isLoading = false;
        }
      },
      clearErrors() {
        if (this.errors != null) {
          this.errors = null;
        }
      },
      getFlag(code) {
        if (code == null) return null;
        return findFlagUrlByIso2Code(code);
      },
      handleLanguageClick(lang) {
        this.selectedLanguageId = lang.lang_id;
      },
    },
  };
</script>
