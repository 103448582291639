<template>
  <div class="modatta-credit-card">
    <div id="card-element"><!-- A Stripe Element will be inserted here. --></div>
    <div v-if="errorMessage" class="error-message type type--xsmall" v-text="errorMessage"></div>
    <button ref="cardButton" :disabled="isLoading" @click="sendToStripe()" class="button is-primary">Pay</button>
  </div>
</template>

<script>
  import { loadStripe } from '@stripe/stripe-js';
  import api from '../api/budget_api';

  export default {
    name: 'modatta-credit-card',
    props: ['pack', 'vat'],
    data() {
      return {
        card: null,
        stripe: null,
        isLoading: false,
        errorMessage: null,
      };
    },
    mounted() {
      this.setupStripeCardElement();
    },
    computed: {},
    methods: {
      async setupStripeCardElement() {
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE);
        const elements = this.stripe.elements({
          fonts: [{ cssSrc: 'https://rsms.me/inter/inter.css' }],
          locale: 'auto',
        });
        const card = elements.create('card', {
          hidePostalCode: true,
          style: this.getStripeElementStyles(),
        });
        card.mount('#card-element');
        this.card = card;
      },
      async sendToStripe() {
        this.isLoading = true;
        let clientSecret;
        try {
          const intentResp = await api.getCheckoutIntent({
            pack: this.pack,
            fiscal_number: this.vat,
          });
          clientSecret = intentResp.data.result;
        } catch (error) {
          this.isLoading = false;
          this.errorMessage = 'Error on payment';
          return;
        }

        try {
          await this.stripe.confirmCardPayment(clientSecret, {
            payment_method: { card: this.card }, // billing_details: { name: cardHolderName.value }
          });
          this.$router.go();
        } catch (error) {
          this.errorMessage = error.message;
        } finally {
          this.isLoading = false;
        }
      },

      getStripeElementStyles() {
        return {
          base: {
            color: '#0A1735',
            padding: '16px',
            fontWeight: 500,
            fontFamily: 'Silka, Inter, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#CFD7DF',
            },
          },
          invalid: {
            color: '#E25950',
          },
        };
      },
    },
  };
</script>
