<template>
  <div class="modatta-settings other">
    <section class="language">
      <header>
        <h2>{{ $t('settings_settings.section_1_title') }}</h2>
        <modatta-icon icon="globe-filled" width="24" height="24"></modatta-icon>
      </header>
      <div class="languages field">
        <label class="label">{{ $t('settings_settings.section_1_language_label') }}</label>
        <div class="modatta-radio-buttons">
          <div
            class="radio-button"
            style="flex-grow: 1"
            v-for="(language, index) in languages"
            :active="selectedLanguageId === language.lang_id"
            :key="index"
          >
            <b-radio
              name="language"
              :id="language.lang_id"
              v-model="selectedLanguageId"
              :native-value="language.name"
              @input="handleLanguageClick(language)"
            >
              {{ language.name }}
            </b-radio>
          </div>
        </div>
      </div>
    </section>
    <section class="account">
      <header>
        <h2>{{ $t('settings_settings.section_2_title') }}</h2>
      </header>
      <div class="close-account">
        <div>
          <h3>{{ $t('settings_settings.section_2_disable_label') }}</h3>
          <p>{{ $t('settings_settings.section_2_disable_description') }}</p>
        </div>
        <a
          href="mailto:support@modatta.org?subject=I'd like to close my account"
          target="_blank"
          class="button is-primary"
          >{{ $t('settings_settings.button_disable_label') }}</a
        >
      </div>
    </section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ModattaIcon from '../../../components/ModattaIcon.vue';

  export default {
    components: {
      ModattaIcon,
    },
    data() {
      return {
        selectedLanguageId: null,
        languages: [
          { name: this.$t('labels.LNG5'), lang_id: 'LNG5', i18n_code: 'br' },
          { name: this.$t('labels.LNG1'), lang_id: 'LNG1', i18n_code: 'pt' },
          { name: this.$t('labels.LNG3'), lang_id: 'LNG3', i18n_code: 'en' },
        ],
      };
    },
    mounted() {
      this.selectedLanguageId = this.user.user.lang;
    },
    computed: {
      ...mapState(['user']),
    },
    methods: {
      handleLanguageClick(lang) {
        this.selectedLanguageId = lang.lang_id;
        this.$i18n.locale = lang.i18n_code;
        const body = JSON.stringify({ lang: lang.lang_id });
        this.$parent.changeUser(body);
      },
    },
    filters: {
      capitalize: (s) => s.charAt(0).toUpperCase() + s.slice(1),
    },
  };
</script>
