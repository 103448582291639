<template>
  <section class="portal">
    <modatta-sidenav page="settings"></modatta-sidenav>
    <main>
      <template>
        <div class="settings components-wrapper">
          <modatta-navbar v-bind:title="$t('settings.menu_settings')" :hasIcon="{ name: 'settings' }"></modatta-navbar>
          <div class="components">
            <div class="component">
              <modatta-profile-snap-tabs></modatta-profile-snap-tabs>
            </div>
          </div>
        </div>
      </template>
    </main>
  </section>
</template>

<script>
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import ModattaProfileSnapTabs from '../../views/settings/components/ModattaProfileSnapTabs.vue';

  export default {
    components: { ModattaSidenav, ModattaNavbar, ModattaProfileSnapTabs },
  };
</script>
