<template>
  <section class="portal">
    <modatta-sidenav page="dashboard"></modatta-sidenav>
    <main>
      <section class="dashboard components-wrapper">
        <div>
          <!-- Page Nav -->
          <modatta-navbar :title="$t('dashboard.page_title')" :hasIcon="{ name: 'dashboard' }"></modatta-navbar>
          <!-- Dashboard Main Content-->
          <template v-if="!isLoading">
            <!-- Dashboard -->
            <div class="module-grip-wrap" v-if="hasOffers">
              <!-- Dashboard Components -->
              <div class="module-grid limit-width dashboard-components">
                <!-- Component -->
                <!-- total cost / business value generate -->
                <div class="module col-span-4 row-span-10 p-0">
                  <div class="inner p-0">
                    <modatta-business-value-generated
                      :totalInvested="totalInvested"
                      :businessValueGenerated="totalGenerated"
                      :body="$t('dashboard.investment_box_label')"
                    ></modatta-business-value-generated>
                  </div>
                </div>
                <div class="module col-span-8 row-span-10 scroll-container uses-title p-0">
                  <div class="inner p-0">
                    <span class="module-title outside">{{ $t('dashboard.cost_distribution_box_title') }}</span>
                    <modatta-chip-scroll
                      :items="interactions"
                      v-if="!(!interactions || interactions == null || Object.keys(interactions).length == 0)"
                    />
                    <span style="line-height: 6.25rem; padding: 0 1rem" v-else>{{
                      $t('budget.table_no_content')
                    }}</span>
                  </div>
                </div>
                <div class="module col-span-12 row-span-18 row-auto transparent p-0">
                  <div class="inner p-0 no-overflow">
                    <modatta-dashboard-funnel
                      :id="null"
                      :type="null"
                      :delivered="totalDelivered"
                    ></modatta-dashboard-funnel>
                  </div>
                </div>

                <!-- Component-->
                <!-- Dashboard Snap Tabs -->
                <div class="module col-span-6 row-span-50 p-0">
                  <div class="inner p-0">
                    <modatta-dashboard-snap-tabs></modatta-dashboard-snap-tabs>
                  </div>
                </div>

                <!-- Component -->
                <!-- Pie Chart: NPS -->
                <div class="module col-span-3 row-span-50 nps">
                  <div class="inner modatta-chart">
                    <!-- Title -->
                    <div class="chart-title">{{ charts[0].title }}</div>
                    <!-- Chart -->
                    <div class="modatta-pie-chart" :class="{ 'is-loading oval': !acceptedDataLoaded }">
                      <modatta-pie-chart
                        v-if="acceptedDataLoaded"
                        :chart-data="charts[0].data"
                        :labels="charts[0].labels"
                        :colors="charts[0].colors"
                      ></modatta-pie-chart>
                      <div v-if="acceptedDataLoaded" class="chart-center-content nps">
                        <span class="value" :class="{ 'is-loading': nps === '' }">{{ nps }}</span>
                        <span class="subtitle">NPS</span>
                      </div>
                    </div>
                    <div class="chart-legend">
                      <div
                        class="data-point nps"
                        v-for="(data, i) in charts[0].labels.length"
                        :key="charts[0].labels[i]"
                      >
                        <div>
                          <div class="color" :style="{ background: charts[0].colors[i] }"></div>
                          <div class="chart-label">{{ charts[0].labels[i] }}</div>
                        </div>
                        <div v-if="acceptedDataLoaded">
                          <div
                            class="absolute"
                            :style="{ background: setAlphaColor(charts[0].colors[i], 0.1), color: charts[0].colors[i] }"
                          >
                            {{ charts[0].data[i] }}
                          </div>
                          <!-- Loads percentage for promotor - neutro - detrator -->
                          <div
                            class="percentage"
                            v-text="getPercentage(charts[0], i)"
                            :style="{ color: charts[0].colors[i] }"
                          ></div>
                        </div>
                      </div>
                      <div class="data-point nps">
                        <div>
                          <div class="color" :style="{ background: 'rgba(236, 240, 248, 1)' }"></div>
                          <div class="chart-label">{{ $t(`offers_dashboard.nps_box_not_evaluated`) }}</div>
                        </div>
                        <div v-if="acceptedDataLoaded">
                          <div class="absolute" :style="{ background: 'rgba(236, 240, 248, 0.7)' }">
                            {{ notEvaluated }}
                          </div>
                          <div class="percentage"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Component -->
                <!-- Pie Chart: Rejected -->
                <div class="module col-span-3 row-span-50 p-0">
                  <div class="inner modatta-chart">
                    <div class="chart-title">{{ charts[1].title }}</div>
                    <div class="modatta-pie-chart" :class="{ 'is-loading oval': !declinedDataLoaded }">
                      <modatta-pie-chart
                        v-if="declinedDataLoaded"
                        :chart-data="charts[1].data"
                        :labels="charts[1].labels"
                        :colors="charts[1].colors"
                      ></modatta-pie-chart>
                      <div v-if="declinedDataLoaded" class="chart-center-content">
                        <span class="value" :class="{ 'is-loading': charts[1].center === '' }">{{
                          charts[1].center
                        }}</span>
                      </div>
                    </div>

                    <div class="chart-legend">
                      <div class="data-point" v-for="(data, i) in charts[1].labels.length" :key="charts[1].labels[i]">
                        <div>
                          <div class="color" :style="{ background: charts[1].colors[i] }"></div>
                          <div class="chart-label">{{ charts[1].labels[i] }}</div>
                        </div>
                        <div v-if="declinedDataLoaded">
                          <div
                            class="absolute"
                            :style="{ background: setAlphaColor(charts[1].colors[i], 0.1), color: charts[1].colors[i] }"
                          >
                            {{ charts[1].data[i] }}
                          </div>
                          <div
                            class="percentage"
                            v-text="getPercentage(charts[1], i)"
                            :style="{ color: charts[1].colors[i] }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Dashboard Components -->
            </div>

            <!-- Empty State -  If has not Offers -->
            <modatta-dashboard-empty-screen v-else></modatta-dashboard-empty-screen>
          </template>
        </div>

        <!-- Loading Component -->
        <modatta-global-loading v-if="isLoading" />
        <!-- Gathering Data -->
        <modatta-get-analytics-renderless source="dashboard"></modatta-get-analytics-renderless>
        <!-- Modal: New Brand, New Offer -->
        <modatta-side-modal
          v-if="openSideModal"
          :content="selectedOption.name"
          :title="selectedOption.title"
          :options="selectedOption.options"
        ></modatta-side-modal>
      </section>
    </main>
  </section>
</template>

<script>
  import Utilities from '../../helpers/utilities.js';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import tinycolor from 'tinycolor2';
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import ModattaBusinessValueGenerated from '../../components/ModattaBusinessValueGenerated.vue';
  import ModattaDashboardFunnel from '../../components/ModattaDashboardFunnel.vue';
  import ModattaPieChart from '../../components/ModattaPieChart.vue';
  import ModattaGetAnalyticsRenderless from '../../components/ModattaGetAnalyticsRenderless.vue';
  import ModattaSideModal from '../../components/ModattaSideModal.vue';

  import ModattaDashboardEmptyScreen from './components/ModattaDashboardEmptyScreen.vue';
  import ModattaDashboardSnapTabs from './components/ModattaDashboardSnapTabs.vue';
  import ModattaGlobalLoading from './components/ModattaGlobalLoading.vue';

  export default {
    components: {
      ModattaNavbar,
      ModattaSidenav,
      ModattaBusinessValueGenerated,
      ModattaDashboardFunnel,
      ModattaDashboardSnapTabs,
      ModattaPieChart,
      ModattaDashboardEmptyScreen,
      ModattaGetAnalyticsRenderless,
      ModattaSideModal,
      ModattaGlobalLoading,
    },
    data() {
      return {
        metricCards: [
          {
            layout: '',
            body: 'active_counter_label',
            title: this.$store.state.analytic.activeOffers,
            media: { type: 'icon', source: 'clock', color: '#9EBE0E', hasShadow: true },
          },
          {
            layout: '',
            body: 'delivered_counter_label',
            title: this.$store.state.analytic.totalDelivered,
            media: { type: 'icon', source: 'send', color: '#7494EA', hasShadow: true },
          },
          {
            layout: '',
            detail: { isRed: false },
            body: 'accepted_counter_label',
            title: this.$store.state.analytic.totalAccepted,
            media: { type: 'icon', source: 'check', color: '#19D2B4', hasShadow: true },
          },
          {
            layout: '',
            detail: { isRed: true },
            body: 'declined_counter_label',
            title: this.$store.state.analytic.totalDeclined,
            media: { type: 'icon', source: 'close', color: '#F48266', hasShadow: true },
          },
        ],
        charts: [
          {
            title: this.$t('dashboard.nps_box_title'),
            type: 'nps',
            center: '',
            labels: [
              this.$t('dashboard.nps_box_promoter'),
              this.$t('dashboard.nps_box_passive'),
              this.$t('dashboard.nps_box_detractor'),
            ],
            data: this.$store.state.analytic.acceptedData,
            colors: ['#19D2B4', '#F7B05B', '#EF876E'],
          },
          {
            title: this.$t('dashboard.decline_box_title'),
            type: '',
            center: '',
            labels: [
              this.$t('dashboard.decline_box_other'),
              this.$t('dashboard.decline_box_value'),
              this.$t('dashboard.decline_box_brand'),
              this.$t('dashboard.decline_box_product'),
            ],
            data: this.$store.state.analytic.declinedData,
            colors: ['#4271D1', '#7C9CDF', '#C2D1F0', '#E2EAF8'],
          },
        ],
        acceptedDataLoaded: false,
        declinedDataLoaded: false,
        nps: '',

        selectedOption: {},
        isLoading: true,
      };
    },
    // Mounted
    mounted() {
      this.getOffers().then(() => (this.isLoading = false));
    },
    // We need to watch this because of Vue's array reactivity limitation
    watch: {
      activeOffers(newVal) {
        this.$set(this.metricCards[0], 'title', newVal);
      },
      totalDelivered(newVal) {
        this.$set(this.metricCards[1], 'title', newVal);
      },
      totalAccepted(newVal) {
        this.$set(this.metricCards[2], 'title', newVal);
        const detailValue = Math.round((this.totalAccepted / this.totalDelivered) * 100) || 0;
        this.$set(this.metricCards[2].detail, 'value', isFinite(detailValue) ? `${detailValue}%` : '0%');
      },
      acceptedData(newVal) {
        this.$set(this.charts[0], 'data', newVal);
      },
      totalDeclined(newVal) {
        this.$set(this.metricCards[3], 'title', newVal);
        this.$set(this.charts[1], 'center', `${Math.round((this.totalDeclined / this.totalDelivered) * 100) || 0}%`);
        const detailValue = Math.round((this.totalDeclined / this.totalDelivered) * 100) || 0;
        this.$set(this.metricCards[3].detail, 'value', isFinite(detailValue) ? `${detailValue}%` : '0%');
      },
      declinedData(newVal) {
        this.$set(this.charts[1], 'data', newVal);
      },
    },
    // Methods
    methods: {
      ...mapMutations(['setOpenSideModal']),
      ...mapActions([
        'getOffers',
        'getTotalInvested',
        'getActiveOffers',
        'getTotalDelivered',
        'getAcceptedDecisions',
        'getDeclinedDecisions',
      ]),

      setAlphaColor(color, alpha) {
        const c = tinycolor(color);
        return c.setAlpha(alpha).toRgbString();
      },

      getPercentage(chart, i) {
        const total = chart.data.reduce((a, b) => a + b, 0);
        if (total === 0) {
          return '0%';
        }
        const result = Math.round((chart.data[i] / total) * 100 * 10) / 10 || 0;
        return isFinite(result) ? `${result}%` : '0%';
      },

      selectOption(name, title, options) {
        this.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
      },
    },
    // Computed properties
    computed: {
      ...mapState(['openSideModal', 'analytic', 'offer']),

      hasOffers() {
        return this.offer.offers.length > 0;
      },

      totalInvested() {
        if (this.analytic.totalInvested == null) return null;
        const [value, currency] = [
          this.analytic.totalInvested.value,
          this.$t(`labels.${this.analytic.totalInvested.currency}`),
        ];
        return isFinite(value) && isFinite(value) > 0
          ? Utilities.currency(value, currency)
          : Utilities.currency(0, currency);
      },

      totalGenerated() {
        if (this.analytic.totalGenerated == null) return null;
        const [value, currency] = [
          this.analytic.totalGenerated.value,
          this.$t(`labels.${this.analytic.totalGenerated.currency}`),
        ];
        return isFinite(value) && isFinite(value) > 0
          ? Utilities.currency(value, currency)
          : Utilities.currency(0, currency);
      },
      interactions() {
        return this.analytic.interactions;
      },

      activeOffers() {
        return this.analytic.activeOffers;
      },
      totalDelivered() {
        return this.analytic.totalDelivered;
      },
      totalAccepted() {
        return this.analytic.totalAccepted;
      },
      acceptedData() {
        return this.analytic.acceptedData;
      },
      totalDeclined() {
        return this.analytic.totalDeclined;
      },
      declinedData() {
        return this.analytic.declinedData;
      },

      notEvaluated() {
        return this.analytic.notEvaluatedData;
      },
    },
  };
</script>
