<template>
  <div class="modatta-upload-image">
    <div class="field upload-file">
      <label class="label">
        {{ label }}
        <b-tooltip
          v-bind:label="$t('offers_step3.box_section_2_tooltip')"
          type="is-dark"
          position="is-top"
          size="is-small"
          multilined
        >
          <modatta-icon icon="info-square"></modatta-icon>
        </b-tooltip>
      </label>
      <div class="help-text" v-if="!warning">
        {{ $t('offers_step3.box_section_2_subtitle') }} {{ size }},
        {{ $t('offers_step3.box_section_2_subtitle_2') }} 1:1
      </div>
      <div class="help-text text-warning" v-else>{{ $t('offers_step3.box_section_2_error') }}</div>
      <b-upload
        v-model="uploadedImage"
        @input="uploadImage()"
        drag-drop
        expanded
        accept="image/png,image/jpg,image/jpeg"
      >
        <section class="section">
          <div class="content has-text-centered">
            <modatta-icon icon="upload"></modatta-icon>
            <p>
              {{ $t('offers_step3.upload_your') }} {{ name }}
              <span>{{ $t('offers_step3.box_section_2_upload_subtitle') }}</span>
            </p>
          </div>
        </section>
      </b-upload>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['label', 'size', 'name', 'tooltip', 'showPreview', 'source'],
    data() {
      return {
        warning: false,
        uploadedImage: null,
      };
    },
    mounted() {
      if (this.uploadedImage !== null) {
        this.uploadImage();
      }
    },
    methods: {
      uploadImage() {
        setTimeout(() => {
          if (this.uploadedImage) {
            if (this.uploadedImage.size < 1000000) {
              let output = document.getElementById('preview-image');
              output.src = URL.createObjectURL(this.uploadedImage);
              output.onload = function () {
                URL.revokeObjectURL(output.src); // free memory
              };
            }
          }
        }, 100);

        if (this.uploadedImage && this.uploadedImage.size < 1000000) {
          this.warning = false;
          this.$emit('new-banner-image', this.uploadedImage);
        } else {
          this.warning = true;
        }
      },
    },
  };
</script>

<style>
  .text-warning {
    color: #dc3545 !important;
    opacity: 1 !important;
  }
</style>
