<template>
  <div class="authentication">
    <div class="form-container login">
      <div class="languages select">
        <b-dropdown append-to-body aria-role="menu" scrollable max-height="200" trap-focus>
          <template #trigger>
            <a class="navbar-item" role="button">
              <span>
                {{ selectedLanguageLabel }}
              </span>
              <b-icon icon="menu-down"></b-icon>
            </a>
          </template>
          <b-dropdown-item custom aria-role="listitem" v-for="(language, index) in languages" :key="index">
            <b-radio
              name="language"
              :id="language.lang_id"
              v-model="selectedLanguageId"
              :native-value="language.lang_id"
              @input="handleLanguageClick(language)"
            >
              {{ language.code }}
            </b-radio>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="clouds">
        <img class="left" src="/svg/decorations/left-cloud.svg" alt="Illustration representing a cloud" />
        <img class="right" src="/svg/decorations/right-clouds.svg" alt="Illustration representing clouds" />
      </div>

      <div class="tree">
        <img src="/svg/decorations/tree.svg" alt="Illustration representing a tree" />
      </div>

      <div class="illustrations">
        <div class="background">
          <img src="/svg/decorations/log-in-background.svg" alt="Illustration representing mountains, plants and sky" />
        </div>
        <div class="cloud">
          <img src="/svg/decorations/cloud.svg" alt="Illustration representing a cloud" />
        </div>
        <div class="character login">
          <img
            src="/svg/decorations/log-in-character.svg"
            alt="Illustration representing a woman working on a computer"
          />
        </div>
        <router-link to="/" class="logo" />
      </div>
      <div class="form">
        <h1>{{ $t('signin.title') }}</h1>
        <p>{{ $t('signin.description') }}</p>
        <form @submit.prevent="login()">
          <div
            class="input-wrapper"
            :class="{ 'has-error': $v.form.email.$error, 'is-valid': !$v.form.email.$invalid }"
          >
            <modatta-icon icon="email" width="23"></modatta-icon>
            <input v-model.trim="$v.form.email.$model" type="email" placeholder="Email" autofocus required />
            <modatta-icon v-if="$v.form.email.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.email.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <div
            class="input-wrapper"
            :class="{ 'has-error': $v.form.password.$error, 'is-valid': !$v.form.password.$invalid }"
          >
            <modatta-icon icon="lock" width="23"></modatta-icon>
            <input v-model.trim="$v.form.password.$model" type="password" placeholder="Password" required />
            <modatta-icon v-if="$v.form.password.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            <modatta-icon v-if="!$v.form.password.$invalid" icon="checkmark-green" width="18"></modatta-icon>
          </div>
          <b-field>
            <router-link to="reset-password" class="logo" @click.stop="">
              {{ $t('signin.forgot_password') }}</router-link
            >
          </b-field>
          <div class="recaptcha">
            <vue-recaptcha
              :sitekey="recaptchaSitekey"
              @verify="onVerifyRecaptcha"
              @expired="onExpiredRecaptcha"
              :loadRecaptchaScript="true"
            ></vue-recaptcha>
          </div>
          <button class="button is-primary" type="submit" :disabled="$v.form.$invalid || isLoading">
            <template v-if="!isLoading">{{ $t('signin.button_label') }}</template>
            <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
          </button>
          <footer>
            {{ $t('signin.go_to_signup_1') }} <router-link to="/signup">{{ $t('signin.go_to_signup_2') }}</router-link>
          </footer>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import VueRecaptcha from 'vue-recaptcha';
  import { required, minLength, sameAs, email } from 'vuelidate/lib/validators';
  import { sha256 } from 'js-sha256';
  import ModattaIcon from '../../components/ModattaIcon.vue';
  import Bugsnag from '@bugsnag/js';
  import UserAPI from '../../api/user_api';
  import IntegrationAPI from '../../api/integration_api.js';

  export default {
    components: { VueRecaptcha, ModattaIcon },
    data() {
      return {
        browserLang: '',
        selectedLanguageLabel: null,
        selectedLanguageId: '',
        recaptchaSitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        isLoading: false,
        form: {
          email: null,
          password: null,
          recaptcha: false,
        },
        languages: [
          { name: this.$t('labels.LNG1'), lang_id: 'LNG1', i18n_code: 'pt', code: 'pt-pt' },
          { name: this.$t('labels.LNG5'), lang_id: 'LNG5', i18n_code: 'br', code: 'pt-br' },
          { name: this.$t('labels.LNG3'), lang_id: 'LNG3', i18n_code: 'en', code: 'en' },
        ],
      };
    },
    mounted() {
      this.browserLang = navigator.language;
      switch (this.browserLang) {
        case 'pt':
          this.selectedLanguageId = 'LNG1';
          break;
        case 'pt-BR':
          this.selectedLanguageId = 'LNG5';
          break;
        default:
          this.selectedLanguageId = 'LNG3';
          break;
      }
    },
    validations: {
      form: {
        email: {
          required,
          email,
        },
        recaptcha: {
          sameAs: sameAs(() => true),
        },
        password: {
          required,
          minLength: minLength(6),
        },
      },
    },
    watch: {
      selectedLanguageId() {
        let currentLanguage = this.languages.find((o) => o.lang_id === this.selectedLanguageId);
        this.$i18n.locale = currentLanguage.i18n_code;
        this.selectedLanguageLabel = currentLanguage.code;
      },
    },
    methods: {
      ...mapActions(['authenticateUser', 'attemptAuthentication']),
      ...mapMutations(['setConfigIntegration', 'setOpenToast']),
      onVerifyRecaptcha() {
        this.form.recaptcha = true;
      },
      onExpiredRecaptcha() {
        this.form.recaptcha = false;
        this.isLoading = false;
      },
      async login() {
        try {
          this.isLoading = true;

          if (this.$v.form.$invalid) {
            this.setOpenToast({
              title: this.$t('signin.error_signin_2'),
              isSuccess: false
            });
            return;
          }

          const response = await UserAPI.authenticateUser({
            username: sha256(this.form.email),
            password: sha256(this.form.password),
          })
          this.attemptAuthentication(response.data.token);

          if (Bugsnag.isStarted()) {
            Bugsnag.setUser(response.data.uid, response.data.email, response.data.personal_name);
          }

          IntegrationAPI.getSocialWorkPlataformConfigSdk().then(({ data: responseConfigSdk }) => {
            this.setConfigIntegration(responseConfigSdk);
          });

          this.$router.push('/brands')
        } catch (error) {
          this.setOpenToast({
            title: this.$t('signin.error_signin_1'),
            isSuccess: false
          });
        } finally {
          this.isLoading = false;
        }
      },
      handleLanguageClick(lang) {
        this.selectedLanguageId = lang.lang_id;
      },
    },
  };
</script>
