<template>
  <div class="modatta-empty-screen">
    <h1>{{ $t('dashboard.page_title') }}</h1>
    <div class="subtitle">{{ $t('dashboard.page_subtitle') }}</div>
    <div class="empty-screen-cards">
      <div class="empty-screen-card" v-for="(card, index) in emptyScreenCards" :key="index">
        <div class="illustration" :style="{ 'background-image': `url(/svg/decorations/${card.illustration})` }"></div>
        <div class="card-info">
          <h2>{{ card.title }}</h2>
          <p>{{ card.description }}</p>
          <router-link :to="card.href" class="button is-primary wrap-button">{{ card.buttonText }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        emptyScreenCards: [
          {
            href: '/brands',
            illustration: 'empty-screen-brands.svg',
            title: this.$t('dashboard.define_brands'),
            description: this.$t('dashboard.define_brands_desc'),
            buttonText: this.$t('brands_edit.title'),
          },
          {
            href: '/offers',
            illustration: 'empty-screen-offers.svg',
            title: this.$t('dashboard.create_offers'),
            description: this.$t('dashboard.create_offer_desc'),
            buttonText: this.$t('offers_step6.title'),
          },
          {
            href: '/budget',
            illustration: 'empty-screen-budget.svg',
            title: this.$t('dashboard.define_budget'),
            description: this.$t('dashboard.balance_ready'),
            buttonText: this.$t('budget_cc.button_topup_label'),
          },
        ],
      };
    },
    mounted() {},
    computed: {},
    methods: {},
  };
</script>
