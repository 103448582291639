<template>
  <section class="portal">
    <modatta-sidenav page="budget"></modatta-sidenav>
    <main>
      <div class="budget">
        <modatta-side-modal
          v-if="openSideModal"
          :content="selectedOption.name"
          :title="selectedOption.title"
          :options="selectedOption.options"
        ></modatta-side-modal>
        <modatta-navbar v-bind:title="$t('budget.title')" :has-icon="{ name: 'budget' }"></modatta-navbar>
        <div class="components">
          <div class="chart-component">
            <div class="committed">
              <p>{{ $t('budget.box_funding_label_1') }}</p>
              <h2 :class="{ 'is-loading': committed == null }">{{ committed }}</h2>
            </div>
            <div class="semi-pie-chart" :class="{ 'is-loading': balanceChart == null }">
              <modatta-pie-chart
                v-if="balanceChart != null"
                :chart-data="balanceChart.data"
                :labels="balanceChart.labels"
                :colors="balanceChart.colors"
                :cutout="75"
                :semi="true"
              ></modatta-pie-chart>
              <div v-if="balanceChart != null" class="current-balance">
                <div :style="{ color: availableBalance.value - committedBalance.value < 0 ? '#A60202' : '#05B8C8' }">
                  {{ $t(`labels.${availableBalance.currency}`)
                  }}{{ balanceDifference(availableBalance.value, committedBalance.value) }}
                </div>
                <p
                  v-text="
                    availableBalance.value - committedBalance.value < 0
                      ? $t('budget.box_funding_label_4')
                      : availableBalance.value - committedBalance.value == 0
                        ? $t('budget.box_funding_label_3')
                        : $t('budget.box_funding_label_3')
                  "
                ></p>
              </div>
            </div>
            <div class="available">
              <h2 :class="{ 'is-loading': available == null }">{{ available }}</h2>
              <p>{{ $t('budget.box_funding_label_2') }}</p>
            </div>
          </div>
          <div class="available">
            <modatta-metric-card
              tooltip-position="is-left"
              layout="vertical"
              v-bind:body="$t('budget.box_available_label_1')"
              :title="available"
              :detail="{ tooltip: $t('budget.box_available_tooltip_1') }"
              :media="{ type: 'icon', source: 'available-balance', color: 'rgba(158, 190, 14, 0.1)', hasShadow: false }"
            ></modatta-metric-card>
          </div>
          <div class="committed">
            <modatta-metric-card
              tooltip-position="is-left"
              layout="vertical"
              v-bind:body="$t('budget.box_committed_label')"
              :title="committed"
              :detail="{ tooltip: $t('budget.box_committed_tooltip_1') }"
              :media="{ type: 'icon', source: 'committed-balance', color: 'rgba(86, 204, 242, 0.1)', hasShadow: false }"
            ></modatta-metric-card>
          </div>
          <div class="recharge">
            <div class="field">
              <label class="label">{{ $t('budget.section_payments_title') }}</label>
            </div>
            <div class="payment-options">
              <div
                :key="index"
                class="payment"
                @click="selectPayment(payment)"
                v-for="(payment, index) in paymentsPerCountry[userCountryId]"
                :class="{ 'is-disabled': payment.isDisabled }"
              >
                <modatta-icon :icon="payment.icon" width="24"></modatta-icon>
                <div v-html="$t('budget.' + payment.text)"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="balance-movements">
          <div class="field">
            <label class="label">{{ $t('budget.section_2_title') }}</label>
          </div>
          <div class="header">
            <div class="search">
              <div class="input-wrapper">
                <b-field>
                  <b-input v-model="query" v-bind:placeholder="$t('budget.search')" type="search"></b-input>
                </b-field>
                <modatta-icon icon="magnifier"></modatta-icon>
              </div>
            </div>
          </div>
          <div v-if="!isLoadingMovements" class="balance-table">
            <modatta-table :data="filteredData" side-padding="20" :labels="tableLabels"></modatta-table>
          </div>
          <div v-else class="loading">
            <div class="is-loading loading-row" v-for="(row, index) in 4" :key="index"></div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
  import Fuse from 'fuse.js';
  import { DateTime } from 'luxon';
  import Utilities from '../../helpers/utilities.js';
  import { mapState, mapActions, mapMutations } from 'vuex';
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import ModattaSideModal from '../../components/ModattaSideModal.vue';
  import ModattaPieChart from '../../components/ModattaPieChart.vue';
  import ModattaMetricCard from '../../components/ModattaMetricCard.vue';
  import ModattaIcon from '../../components/ModattaIcon.vue';
  import ModattaTable from '../../components/ModattaTable.vue';

  export default {
    components: {
      ModattaSidenav,
      ModattaNavbar,
      ModattaSideModal,
      ModattaPieChart,
      ModattaMetricCard,
      ModattaIcon,
      ModattaTable,
    },
    data() {
      return {
        committedBalance: this.$store.state.budget.committedBalance,
        balanceChart: this.$store.state.budget.balanceChart,
        userCountryId: '',
        paymentsPerCountry: {
          GEO1: [
            { id: 1, icon: 'bank', type: 'transfer', text: 'box_bank_label', isSelected: false, isDisabled: true },
            { id: 2, icon: 'credit-card', type: 'card', text: 'box_cc_label', isSelected: false },
          ],
          GEO32: [
            { id: 1, icon: 'credit-card', type: 'card', text: 'box_cc_label', isSelected: false, isDisabled: true },
            { id: 2, icon: 'pix', type: 'pix', text: 'box_pix_label', isSelected: false, isDisabled: false },
            { id: 3, icon: 'ticket', type: 'voucher', text: 'box_voucher_label', isSelected: false, isDisabled: true },
          ],
        },
        payments: [
          { id: 1, icon: 'bank', type: 'transfer', text: 'box_bank_label', isSelected: false, isDisabled: true },
          { id: 2, icon: 'credit-card', type: 'card', text: 'box_cc_label', isSelected: false },
          { id: 3, icon: 'voucher', type: 'voucher', text: 'box_voucher_label', isSelected: false },
          // {id: 3, icon: 'bitcoin', text: 'Crypto <br/>Currency', isSelected: false, isDisabled: true},
        ],
        table: {
          GEO1: {
            columns: {
              get: [
                { order: 4, name: 'sub_value', label: 'sub_value', modifier: this.setAmount },
                { order: 3, name: 'vat', label: 'vat', modifier: this.setPercentage },
                { order: 5, name: 'vat_value', label: 'vat_value', modifier: this.setAmount },
                { order: 0, name: 'bonus', label: 'bonus', modifier: this.setAmount },
                { order: 2, name: 'value', label: 'value', modifier: this.setAmount },
                { order: 1, name: 'day', label: 'day', modifier: this.setDate },
                { order: 6, name: 'type', label: 'type' },
                { order: 7, name: 'paymentMethod', label: 'payment method' },
                { order: 8, name: 'pay_method_id', label: 'pay_method_id', modifier: this.setTranslation },
                { order: 99, name: 'currency', label: 'currency', modifier: this.setTranslation },
                // Do not put currency within excluded: [] otherwise the table won't show the symbols
                { order: 0, name: 'currency_id', label: 'currency_id' },
              ],
              excluded: ['currency_id', 'bonus', 'currency', 'paymentMethod'],
            },
          },
          GEO32: {
            columns: {
              get: [
                { order: 0, name: 'sub_value', label: 'sub_value', modifier: this.setAmount },
                { order: 0, name: 'vat', label: 'vat', modifier: this.setPercentage },
                { order: 0, name: 'vat_value', label: 'vat_value', modifier: this.setAmount },
                { order: 0, name: 'bonus', label: 'bonus', modifier: this.setAmount },
                { order: 2, name: 'value', label: 'value', modifier: this.setAmount },
                { order: 1, name: 'day', label: 'day', modifier: this.setDate },
                { order: 3, name: 'type', label: 'type' },
                { order: 5, name: 'pay_method_id', label: 'pay_method_id', modifier: this.setTranslation },
                { order: 99, name: 'currency', label: 'currency', modifier: this.setTranslation },
                // Do not put currency within excluded: [] otherwise the table won't show the symbols
                { order: 0, name: 'currency_id', label: 'currency_id' },
              ],
              excluded: ['sub_value', 'vat', 'vat_value', 'bonus', 'currency_id', 'currency', 'paymentMethod'],
            },
          },
        },
        query: '',
        isLoadingMovements: false,
        tableLabels: [],
        movements: this.$store.state.budget.movements,
        selectedOption: {},
      };
    },
    mounted() {
      // Saving table's columns depending on user's country
      let country_id = this.user.user.country_id;
      this.userCountryId = country_id;
      let columns = this.table[country_id].columns;
      // Order entries with columns.get.´order´
      columns.get.sort(function (a, b) {
        return a.order - b.order;
      });
      // Create Array from Object's entries with ´.label´
      let keys = Object.keys(columns.get).map((e) => columns.get[e].label);
      // Removing unused Labels
      keys = keys.filter((x) => columns.excluded.indexOf(x) < 0);
      // Saving table's labels - will be passed to the ´modatta-table´ component
      // ps. UpperCase MUST be applied - otherwise Labels won't show
      this.tableLabels = keys.map((key) => {
        return key.toUpperCase();
      });

      if (this.$store.state.budget.movements.length === 0) {
        this.isLoadingMovements = true;

        this.getMovements().then(() => {
          Object.entries(this.$store.state.budget.movements).forEach(([key, movement]) => {
            // Saving each Movement Object - to a diff variable
            let move = movement;
            // Looping ´columns.get´ Array
            columns.get.forEach((column) => {
              // Removing rejected columns - depending on country's column configuration
              if (!columns.excluded.includes(column.name)) {
                // See if has - modifiers
                if (column.modifier) {
                  // Modificers are functions that will format or transform column's initial value
                  movement[column.name] = column.modifier(movement, movement[column.name]);
                  movement[column.name] = movement[column.name] ? movement[column.name] : '-';
                }
                // If has no modifier - keep value
              }
              if (columns.excluded.includes(column.name)) {
                delete movement[column.name];
                delete move[column.name];
              }
            });

            /**
             * We will use the order given by the first array to order the second array by it's Keys.
             * Using .sort() method and provide a comparison function as the argument.
             * sort() function should take two parameters and compare each property of the objects
             * being passed in based on the order of the provided array.
             *
             * let x = ['c','a','b'];
             * let y = ["a": '...', "b": '....', "c": '...'];
             * result: ["c": '...', "a": '...', "b": '....', ]
             **/

            move = Object.keys(move)
              .sort((a, b) => (keys.indexOf(a) > keys.indexOf(b) ? 1 : -1))
              .reduce((res, key) => ((res[key] = move[key]), res), {});
            // Pushing the ordered data to the $store movements variable
            this.$store.state.budget.movements[key] = move;
          });

          // save changes to ´this.movements´
          this.movements = this.$store.state.budget.movements;
          this.isLoadingMovements = false;
        });
      }

      this.getBalance().then(() => {
        this.getCommittedBalance(this.availableBalance).then(() => {
          this.committedBalance = this.$store.state.budget.committedBalance;
          this.balanceChart = this.$store.state.budget.balanceChart;
        });
      });
    },
    watch: {
      movements(newVal) {
        this.brands = newVal;
      },
    },
    computed: {
      ...mapState(['user', 'budget', 'isSidenavCollapsed', 'openSideModal']),

      availableBalance() {
        return this.user.user.balance || { value: '', currency: '' };
      },
      committed() {
        if (this.committedBalance.value == null || this.committedBalance.value === '')
          return `${this.committedBalance.currency || ''}0`;
        return `${this.$t(`labels.${this.committedBalance.currency}`)}${Utilities.numberWithCommas(Utilities.toDecimalPlaces(this.committedBalance.value, 2))}`;
      },
      available() {
        if (this.availableBalance.value == null || this.availableBalance.value === '')
          return `${this.availableBalance.currency || ''}0`;
        return `${this.$t(`labels.${this.availableBalance.currency}`)}${Utilities.numberWithCommas(Utilities.toDecimalPlaces(this.availableBalance.value, 2))}`;
      },

      filteredData() {
        this.movements.reverse();
        if (this.query === null || this.query == '') return this.movements;

        const fuse = new Fuse(this.movements, {
          threshold: 0.25,
          keys: ['sub_value', 'vat', 'vat_value', 'bonus', 'value', 'day', 'value', 'type', 'pay_method_id'],
        });
        // keys: ['sub_value', 'vat', 'vat_value', 'bonus', 'value', 'day', 'value', 'type', 'paymentMethod'],
        return fuse.search(this.query).map((item) => item.item);
      },
    },
    methods: {
      ...mapActions(['getMovements', 'getBalance', 'getCommittedBalance']),
      ...mapMutations(['setOpenSideModal']),

      /**
       *
       * MODIFIERS:
       * setAmount() - f/ currency amounts
       * setPercentage() - f/ percentages
       * setDate() - f/ dates
       * setUppcerCase() - f/ uppercase
       * setTranslation() - f/ translation from i18n locales
       *
       * ... when creating new modifier functions always provide two mandatory parameters:
       * function mymodifier(movement, value) { return x; }
       *
       **/

      setTranslation(movement, value) {
        return this.$t(`labels.${value}`);
      },

      setAmount(movement, value, decimal = 2) {
        if (value && !isNaN(value)) {
          return (
            this.$t(`labels.${movement.currency}`) +
            Utilities.numberWithCommas(Utilities.toDecimalPlaces(value, decimal))
          );
        }
      },

      setPercentage(movement, value, decimal = 0) {
        if (value && !isNaN(value)) {
          return Utilities.numberWithCommas(Utilities.toDecimalPlaces(value * 100, decimal)) + ' %';
        }
      },

      setDate(movement, date) {
        return DateTime.fromFormat(date, 'yyyy-MM-dd').toLocaleString(DateTime.DATE_SHORT);
      },

      setUpperCase(value) {
        return value.toString().toUpperCase();
      },

      selectPayment(payment) {
        this.payments.forEach((p) => (p.isSelected = false));
        payment.isSelected = true;
        this.selectOption('payment-methods', this.getModalTitle(payment.type), { type: payment.type });
      },

      getModalTitle(type) {
        switch (type) {
          case 'transfer':
            return this.$t('budget.box_bank_label');
          case 'card':
            return this.$t('budget.box_cc_label');
          case 'voucher':
            return this.$t('budget.box_voucher_label');
          case 'crypto':
            return this.$t('box_crypto_label');
          case 'pix':
            return 'PIX';
        }
      },

      selectOption(name, title, options) {
        this.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
      },

      removeFractionNumbers(n) {
        return n - Math.floor(n) === 0 ? Math.round(n) : n;
      },
      balanceDifference(availableBalance, committedBalance) {
        const diff = Utilities.toDecimalPlaces(availableBalance - committedBalance, 2);
        return Utilities.numberWithCommas(diff);
      },
    },
  };
</script>
