<template>
  <div class="modatta-table" :class="{ stripped: stripped }">
    <template v-if="data.length > 0">
      <header v-if="(showHeader || showHeader == null) && labels" class="row">
        <div
          class="cell"
          v-for="(label, i) in labels"
          :key="i"
          :style="{
            width: equalWidth ? `${100 / columns.length}%` : '',
            padding: sidePadding ? `0 ${sidePadding}px` : '',
          }"
        >
          <div class="cell-content">
            {{ getLabel(label) | sanitize }}
          </div>
        </div>
      </header>
      <header v-if="(showHeader || showHeader == null) && !labels" class="row">
        <div
          class="cell"
          v-for="(cell, i) in columns"
          :key="i"
          :style="{
            width: equalWidth ? `${100 / columns.length}%` : '',
            padding: sidePadding ? `0 ${sidePadding}px` : '',
          }"
        >
          <div class="cell-content">
            {{ cell | sanitize }}
          </div>
        </div>
      </header>
      <div class="row" v-for="(row, rid) in data" :key="rid" @click="handleRowClick(row)">
        <div
          class="cell text-ellipsis"
          v-for="(cell, i) in Object.values(row)"
          :key="i"
          :style="{
            width: equalWidth ? `${100 / Object.values(row).length}%` : '',
            padding: sidePadding ? `0 ${sidePadding}px` : '',
          }"
        >
          <div v-if="cell != null" class="cell-content" :data-cell="Object.keys(row)[i]">
            <template v-if="typeof cell === 'object'">
              <template v-if="cell.type === 'icon'">
                <div class="icon-wrapper">
                  <modatta-icon :icon="cell.icon"></modatta-icon>
                </div>
              </template>
              <template v-if="cell.type === 'image'">
                <div class="image-wrapper" :style="`background-image: url(${cell.image})`"></div>
              </template>
              <template v-if="cell.type === 'button'">
                <button @click="handleButtonClick(cell)">
                  <modatta-icon v-if="cell.icon" :icon="cell.icon"></modatta-icon>
                  <template v-if="cell.text"></template>
                </button>
              </template>
              <template v-if="cell.type === 'badge'">
                <div
                  class="tag"
                  :class="{
                    'is-green':
                      cell.status === 'offers_status.menu_filter_6' ||
                      cell.status === 'menu_filter_6' ||
                      cell.status === 'menu_filter_5' ||
                      cell.status === 'BST2',
                    'is-yellow':
                      cell.status === 'offers_status.menu_filter_2' ||
                      cell.status === 'menu_filter_2' ||
                      cell.status === 'menu_filter_9' ||
                      cell.status === 'BST1',
                    'is-gray':
                      cell.status === 'offers_status.menu_filter_7' ||
                      cell.status === 'menu_filter_7' ||
                      cell.status === 'menu_filter_4' ||
                      cell.status === 'menu_filter_3' ||
                      cell.status === 'BST4',
                    'is-red':
                      cell.status === 'offers_status.menu_filter_8' ||
                      cell.status === 'menu_filter_8' ||
                      cell.status === 'menu_filter_10' ||
                      cell.status === 'BST3',
                  }"
                >
                  {{
                    isOffer
                      ? $t('offers.' + cell.status)
                      : isDashboard
                        ? $t('labels.' + cell.status)
                        : $t('brands.' + cell.status)
                  }}
                </div>
              </template>
            </template>
            <template v-else>{{ cell }}</template>
          </div>
        </div>
      </div>
    </template>
    <div v-else>{{ $t('budget.table_no_content') }}</div>
  </div>
</template>

<script>
  import lodash from 'lodash';

  export default {
    props: ['data', 'labels', 'stripped', 'showHeader', 'equalWidth', 'sidePadding', 'isOffer'],
    computed: {
      columns() {
        return Object.keys(this.data[0]);
      },
      isDashboard() {
        return this.$route.path === '/dashboard';
      },
    },
    methods: {
      cellContent(cell) {
        return Object.values(cell);
      },
      handleRowClick(row) {
        if (lodash.has(row.state, 'isClickable')) {
          this.$router.replace({ path: `/${row.state.isClickable.type}/${row.state.id}` });
        }
      },
      handleButtonClick(cell) {
        if (lodash.has(cell, 'url')) {
          window.open(cell.url);
        }
      },
      getLabel(id) {
        return this.$t('budget.movements.table.' + id);
      },
    },
    filters: {
      sanitize: (w) =>
        w.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
          return str.toUpperCase();
        }),
    },
  };
</script>
