<template>
  <div class="validate-fb-url">
    <button class="button is-primary" @click="validateUrl()">{{ $t('offers_step4.validate_fb_url') }}</button>
    <b-modal
      aria-modal
      trap-focus
      has-modal-card
      aria-role="dialog"
      v-model="showPopUp"
      aria-label="Pop up"
      :can-cancel="false"
      :destroy-on-hide="false"
    >
      <template #default="props">
        <header>
          <div class="title">{{ $t('offers_step4.validate_fb_url_pop_up_title') }}</div>
          <button class="button is-text" @click="props.close">
            <modatta-icon icon="times"></modatta-icon>
          </button>
        </header>
        <div class="modal-content">
          <p v-if="isLoading">{{ $t('offers_step4.validate_fb_url_pop_up_body_loading') }}</p>
          <template v-else>
            <p v-if="isValidUrl" v-text="$t('offers_step4.validate_fb_url_pop_up_body_success')"></p>
            <p v-else v-text="$t('offers_step4.validate_fb_url_pop_up_body_error')"></p>
          </template>
        </div>
        <footer>
          <button v-if="isValidUrl != null" class="button is-secondary" @click="props.close">
            {{ $t('offers_step4.validate_fb_url_pop_up_button') }}
          </button>
        </footer>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['url'],
    data() {
      return {
        showPopUp: false,
        isLoading: false,
        isValidUrl: null,
      };
    },
    mounted() {},
    computed: {},
    methods: {
      validateUrl() {
        this.showPopUp = true;
        this.isLoading = true;
      },
    },
  };
</script>
