<template>
  <div class="modatta-side-modal-new-offer modatta-side-modal-offer-details">
    <div class="offer-wrapper">
      <b-field v-bind:label="$t('offers_details.section_1_title')">
        <div class="offer-brand">
          <div class="logo-container">
            <img v-if="brand" :src="brand.logo_url" :alt="`${brand.name} logo`" />
          </div>
          <div class="brand-name">{{ brand.name }}</div>
        </div>
      </b-field>
      <hr />
      <div class="audience">
        <div class="title-wrapper">
          <span class="title">{{ $t('offers_details.section_2_title') }}</span>

          <!--
                        Se houverem pelo menos 1 missão, podemos editar para remover;
                     -->
          <template v-if="filters.length > 0 && !editFilters">
            <!-- Edit filters -->
            <button v-if="!isFiltersLoading" class="button is-text" @click="changeFilters()">
              <span v-text="editFilters ? 'Save' : 'Edit'"></span>
              <modatta-icon :icon="editFilters ? '' : 'edit'"></modatta-icon>
            </button>
            <b-button v-else class="button is-text" style="background-image: none !important" loading></b-button>
            <!-- end: Edit filters -->
          </template>

          <template v-if="editFilters">
            <button v-if="!isFiltersLoading" class="button is-text" @click="changeFilters()">
              <span v-text="'Save'"></span>
            </button>
            <b-button v-else class="button is-text" style="background-image: none !important" loading></b-button>
          </template>
        </div>
        <div v-if="filters.length === 0" class="tag is-green">
          {{ $t('offers_step2_profile_filter.all_users_selected') }}
        </div>
        <b-field
          v-else
          :label="$t('offers_details.section_2_target_group') + ' ' + parseInt(i + 1)"
          v-for="(target, i) in targets"
          :key="i"
        >
          <div class="targets-wrapper">
            <div class="group-wrapper">
              <div>
                <div class="filter-wrapper" v-for="(filter, fId) in target" :key="fId">
                  <!-- Filter Category By Profile-->
                  <span v-if="filter.category === 'profile'">
                    <!-- Filters ProfileID by Year of Birth - PRO7 -->
                    <span v-if="filter.profile_id === 'PRO7'">
                      {{ $t('offers_step2_profile_filter.birthyear') }}
                      {{ $t(`labels.${filter.operator_id}`) }}
                      {{ filter.value }}
                    </span>
                    <span v-else>
                      <strong
                        v-text="
                          $te(`labels.${filter.profile_id}`)
                            ? $t(`labels.${filter.profile_id}`)
                            : showCorrectProfileLabel(filter)
                        "
                      >
                      </strong>
                      {{ $t(`labels.${filter.operator_id}`) }}
                      <span v-text="$te(`labels.${filter.value_id}`) ? $t(`labels.${filter.value_id}`) : filter.value">
                      </span>
                    </span>
                  </span>
                  <!-- Filter Category By Interests-->
                  <span v-if="filter.category === 'interests'">
                    <span class="filter-name"
                      >{{ $t('labels.' + filter.profile_id) }} {{ $t('labels.' + filter.operator_id) }}
                      {{ filter.value.toLowerCase() === 'true' ? $t('labels.VAL4') : $t('labels.VAL3') }}</span
                    >
                  </span>
                  <!-- Filter Category By Offer-->
                  <span v-if="filter.category === 'offers'">
                    <!-- Trimming offer ID - AAA...AAA -->
                    <span class="filter-name"
                      >ID
                      {{
                        filter.profile_id.substring(0, 3) +
                        '...' +
                        filter.profile_id.substring(filter.profile_id.length - 3)
                      }}
                      {{ $t('labels.' + filter.operator_id) }}</span
                    >
                  </span>
                  <!-- Filter Category By Question-->
                  <span v-if="filter.category === 'question'">
                    <span class="filter-name"
                      >ID
                      {{
                        filter.profile_id.substring(0, 3) +
                        '...' +
                        filter.profile_id.substring(filter.profile_id.length - 3)
                      }}
                      {{ $t('labels.' + filter.operator_id) }} {{ filter.value }}</span
                    >
                  </span>
                  <!-- && this.filters > 1: condição para editar filtros se houver pelo menos 2 existentes.         -->
                  <button
                    v-if="editFilters && filters.length > 0"
                    class="button deleteFilter"
                    @click="deleteFilter(target, fId)"
                  >
                    <modatta-icon icon="times" width="12"></modatta-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-field>
      </div>
      <hr />
      <div class="details">
        <div class="title">{{ $t('offers_details.section_3_title') }}</div>
        <div class="offer-name">{{ offer.name }}</div>
        <div class="offer-description">{{ offer.description }}</div>
        <div class="offer-banner" v-if="offer.banner_url">
          <img :src="offer.banner_url" :alt="`${offer.name} banner image`" />
        </div>
        <div v-if="!isOfferPollOrSurvey" class="offer-steps">
          <div class="step" v-for="(step, index) in offer.steps" :key="index">{{ step.description }}</div>
        </div>
      </div>
      <hr />
      <div class="interaction">
        <div class="title">{{ $t('offers_details.section_4_title') }}</div>
        <div class="offer-type">
          <div class="icon-wrapper">
            <modatta-icon :icon="setOfferIcon(offer)" height="20"></modatta-icon>
          </div>
          <div class="type">{{ $t(`labels.${setOfferType(offer)}`) }}</div>
        </div>
        <div class="interaction-description">
          <div v-if="offer.interaction_type_id === 'INT2'" class="watch-video">
            <b-field label="Vimeo video ID">{{ offer.action }}</b-field>
          </div>
          <div
            v-else-if="offer.interaction_type_id === 'INT3' || offer.interaction_type_id === 'INT4'"
            class="visit-website-online-task"
          >
            <b-field label="Website url">{{ offer.action }}</b-field>
          </div>
          <div
            v-else-if="offer.interaction_type_id === 'INT6' || offer.interaction_type_id === 'INT7'"
            class="survey-poll"
          >
            <div class="questions" v-for="(question, index) in offer.questions" :key="index">
              <b-field :label="`Question ${index + 1}`">
                <div class="question">{{ question.question }}</div>
                <div class="answers">
                  <b-field label="Answers">
                    <div class="answer" v-for="(answer, i) in question.offer_answers" :key="i">
                      {{ i + 1 }}. {{ answer.answer }}
                    </div>
                  </b-field>
                </div>
              </b-field>
            </div>
          </div>
          <div v-else-if="offer.interaction_validation_id === 'ITV12'" class="visit-website-online-task">
            <p>URL: {{ JSON.parse(offer.action).urlAction }}</p>
            <p>Validation URL: {{ JSON.parse(offer.action).urlValidation }}</p>
          </div>
          <div v-else>
            {{ offer.action }}
          </div>
        </div>
        <b-field v-bind:label="$t('offers_details.section_4_subtitle')">
          <div class="validation-type">
            <modatta-icon :icon="setValidationTypeIcon(offer)"></modatta-icon>
            <span>{{ setValidationType(offer) }}</span>
          </div>
        </b-field>
        <div class="validation-description-details">
          <div v-if="offer.interaction_validation_id === 'ITV4'" class="post-back">
            <p>
              {{ $t('offers_details.itv4_text1') }}<span>https://pb.modatta.org/external/pb/{modid}</span
              >{{ $t('offers_details.itv4_text2') }}
            </p>
            <div class="config">
              <modatta-icon icon="green-checkmark"></modatta-icon>
              <div>{{ $t('offers_details.itv4_correct') }}</div>
            </div>
          </div>
          <div v-else-if="offer.interaction_validation_id === 'ITV6'" class="post-back">
            <p>{{ $t('offers_details.itv6_text') }}</p>
          </div>
          <div v-else-if="offer.interaction_validation_id === 'ITV2'" class="codes">
            <p>{{ $t('offers_details.itv2_text') }}</p>
            <b-field label="Validation Code">{{ offer.validations_codes[0].code }}</b-field>
          </div>
          <div v-else-if="offer.interaction_validation_id === 'ITV3'" class="codes">
            <p>{{ $t('offers_details.itv3_text') }}</p>
            <b-field label="Validation Codes">
              <div class="list hide-codes-list hidden" @click="(e) => e.target.classList.toggle('hidden')">
                <div v-for="(code, i) in offer.validations_codes" :key="i">
                  <span>{{ code.code }}</span>
                  <span class="text-muted">•</span>
                </div>
              </div>
            </b-field>
          </div>
          <div v-else-if="offer.interaction_validation_id === 'ITV5'" class="codes">
            <p>{{ $t('offers_details.itv5_text') }}</p>
            <b-field label="">
              <div id="qrcode" class="qr-code" :style="{ 'background-image': `url(${qrCode})` }"></div>
            </b-field>
          </div>
          <div v-else>
            <p>{{ $t('offers_details.itv1_text') }}</p>
          </div>
        </div>
      </div>
      <hr />
      <div class="data">
        <div class="title">{{ $t('offers_details.section_5_title') }}</div>
        <b-field v-bind:label="$t('offers_details.section_5_subtitle_1')">
          {{ $t('offers_details.section_5_no_data') }}
        </b-field>
        <b-field v-bind:label="$t('offers_details.section_5_subtitle_2')">{{
          $t('offers_details.section_5_no_data2')
        }}</b-field>
        <div class="columns">
          <div class="column">
            <b-field v-bind:label="$t('offers_details.section_5_subtitle_3')">{{
              $t('offers_details.section_5_no_data2')
            }}</b-field>
          </div>
          <div class="column">
            <b-field v-bind:label="$t('offers_details.section_5_subtitle_4')">{{
              $t('offers_details.section_5_no_data2')
            }}</b-field>
          </div>
        </div>
      </div>
      <hr />
      <!--
                Editar detalhes da missão.
                > Data de início
                > Data de término
                > NÚMERO DE PESSOAS
                > Investimento/interação validada (não editável)
                # Bugs: Campo `NÚMERO DE PESSOAS` não grava alterações
              -->
      <div class="budget">
        <div class="title-wrapper">
          <span class="title">{{ $t('offers_details.section_6_title') }}</span>
          <button v-if="!isBudgetLoading" class="button is-text" @click="changeBudget()">
            <span v-text="editBudget ? 'Save' : 'Edit'"></span>
            <modatta-icon :icon="editBudget ? '' : 'edit'"></modatta-icon>
          </button>
          <b-button v-else class="button is-text" style="background-image: none !important" loading></b-button>
        </div>
        <div class="timeframe-wrapper">
          <div class="dates">
            <div class="date">
              <div class="field">
                <label class="label">{{ $t('offers_details.section_6_subtitle_start_data') }}</label>
              </div>
              <div class="modatta-datepicker">
                <b-datetimepicker
                  :datetime-formatter="dateFormatter"
                  @input="setStartDateTime($event)"
                  :max-datetime="getEndDateTime"
                  :value="getStartDateTime"
                  :timepicker="timepicker"
                  v-bind:placeholder="$t('offers_details.section_6_subtitle_start_data')"
                  position="is-top-right"
                  :disabled="!editBudget"
                  ref="startDatePicker"
                >
                  <template #right>
                    <button class="button is-primary" @click="$refs.startDatePicker.toggle()">Done</button>
                  </template>
                </b-datetimepicker>
                <modatta-icon icon="calendar" width="20" height="20"></modatta-icon>
              </div>
            </div>
            <div class="date">
              <div class="field">
                <label class="label">{{ $t('offers_details.section_6_subtitle_end_data') }}</label>
              </div>
              <div class="modatta-datepicker">
                <b-datetimepicker
                  :datetime-formatter="dateFormatter"
                  :min-datetime="getStartDateTime"
                  @input="setEndDateTime($event)"
                  position="is-top-left"
                  :timepicker="timepicker"
                  :value="getEndDateTime"
                  :disabled="!editBudget"
                  v-bind:placeholder="$t('offers_details.section_6_subtitle_end_data')"
                  ref="endDatePicker"
                >
                  <template #right>
                    <button class="button is-primary" @click="$refs.endDatePicker.toggle()">Done</button>
                  </template>
                </b-datetimepicker>
                <modatta-icon icon="calendar" width="20" height="20"></modatta-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="budget-values">
          <b-field v-bind:label="$t('offers_details.section_6_subtitle_2')">
            <div class="modatta-radio-input-editable input-wrapper">
              <span>{{ calcUnitCost }}</span>
            </div>
          </b-field>
          <b-field v-bind:label="$t('offers_details.section_6_subtitle_3')">
            <div class="modatta-radio-input-editable input-wrapper">
              <input
                :disabled="!editBudget"
                v-model="offer.max_accepts"
                type="number"
                placeholder="Other"
                min="1"
                @input="handlePositiveNumberInput($event)"
              />
            </div>
          </b-field>
          <div class="price">
            <div v-if="offer.unit_tcost && offer.max_accepts" class="final-cost">
              <div class="price">
                {{ $t(`labels.${offer.currency_id}`) }} {{ (offer.unit_tcost * offer.max_accepts).toLocaleString() }}
              </div>
              <p>{{ $t('offers_details.section_6_budget_label') }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- end: edit offer details -->
    </div>
  </div>
</template>

<script>
  import QRCode from 'qrcode';
  import { DateTime } from 'luxon';
  import { mapActions, mapMutations } from 'vuex';
  import Utilities from '../helpers/utilities.js';
  import SetValidationType from '../helpers/set-validation-type.js';
  import SetInteractionType from '../helpers/set-interaction-type.js';
  import SetValidationTypeIcon from '../helpers/set-validation-type-icon.js';
  import SetInteractionTypeIcon from '../helpers/set-interaction-type-icon.js';
  import Filters from '../helpers/filters/filter-methods.js';
  import { sha256 } from 'js-sha256';
  import ModattaIcon from './ModattaIcon.vue';

  export default {
    components: {
      ModattaIcon,
    },
    props: ['options'],
    data() {
      return {
        offer: this.options.offer,
        brand: '',
        editFilters: false,
        isFiltersLoading: false,
        editBudget: false,
        isBudgetLoading: false,
        qrCode: null,
        endDateTime: null,
        startDateTime: null,
        timepicker: { enableSeconds: false, incrementMinutes: 1, hourFormat: '24' },
        detail_filters: '',
        max_accepts: 0,
        online_on: 0,
        offline_on: 0,
      };
    },
    mounted() {
      this.detail_filters = Filters.setTargetGroups(this.targets);
      this.getBrand(this.offer.brand_id).then((r) => (this.brand = r.data.result[0]));
      this.online_on = this.offer.online_on;
      this.offline_on = this.offer.offline_on;
      if (this.offer.interaction_validation_id === 'ITV5') {
        this.setupQrCode();
      }
    },
    computed: {
      targets() {
        return this.offer.grouped_filters;
      },
      filters() {
        return this.offer.grouped_filters.flat();
      },
      tags() {
        const tags = this.offer.grouped_filters.flat();
        const filteredTags = tags.reduce((accumulator, currentValue) => {
          if (
            currentValue['category'] === 'profile' ||
            currentValue['category'] === 'interests' ||
            !accumulator.find((item) => item['category'] === currentValue['category'])
          ) {
            accumulator.push(currentValue);
          }
          return accumulator;
        }, []);
        if (filteredTags.length == 0) return ['No data will be used'];
        return filteredTags;
      },
      getStartDateTime() {
        // Caso seja null não pode apresentar a data, mesmo que esta não exista
        // porque está apenas a ser visualizada assim, pode não haver update
        if (this.online_on == null) {
          return null;
        }
        return new Date(DateTime.fromSQL(this.online_on, { zone: 'utc' }).toLocal().ts);
      },
      getEndDateTime() {
        if (this.offline_on == null) {
          return null;
        }
        return new Date(DateTime.fromSQL(this.offline_on, { zone: 'utc' }).toLocal().ts);
      },

      isOfferPollOrSurvey() {
        return this.offer.type_label_id === 'OFT18' || this.offer.type_label_id === 'OFT19';
      },
      calcUnitCost() {
        if (this.offer.unit_tcost > 0) {
          let value = Utilities.numberWithCommas(Utilities.toDecimalPlaces(this.offer.unit_tcost, 2));
          return this.$t(`labels.${this.offer.currency_id}`) + value;
        } else {
          const num = (this.offer.perc_tcost || 0) * 100;
          return (Math.round(num * 100) / 100).toFixed(2) + '%';
        }
      },
    },
    methods: {
      ...mapActions(['getOffer', 'getBrand', 'updateOffer']),
      ...mapMutations(['setOpenToast']),
      shortenOfferId(offerId) {
        return `ID ${Utilities.trimStringCenter(offerId, 3, '...')}`;
      },
      shortenQuestionID(questionid) {
        return `ID ${Utilities.trimStringCenter(questionid, 3, '...')}`;
      },
      changeFilters() {
        if (this.editFilters) {
          this.offer.grouped_filters = this.offer.grouped_filters.filter((item) => item.length > 0);
          this.isFiltersLoading = true;
          const body = JSON.stringify({
            filters: this.offer.grouped_filters,
          });
          const payload = { id: this.offer.id, data: body };
          this.updateOffer(payload).then(() => {
            this.setOpenToast({
              title: 'Saved sucessfully',
              isSuccess: true
            });
            this.isFiltersLoading = false;
          });
        }
        this.editFilters = !this.editFilters;
      },
      changeBudget() {
        switch (['editing', 'saving'][this.editBudget ? 1 : 0]) {
          case 'saving':
            this.offer.online_on = this.online_on;
            this.offer.offline_on = this.offline_on;

            this.isBudgetLoading = true;
            const body = JSON.stringify({
              online_on: this.offer.online_on,
              offline_on: this.offer.offline_on,
              unit_tcost: this.offer.unit_tcost,
              max_accepts: this.offer.max_accepts,
              filters: this.offer.grouped_filters,
            });
            const payload = { id: this.offer.id, data: body };
            this.updateOffer(payload).then(() => {
              this.$store.state.offer.objective = this.offer.max_accepts;
              this.$store.state.offer.online_on = this.offer.online_on;
              this.$store.state.offer.offline_on = this.offer.offline_on;
              this.setOpenToast({
                title: 'Saved sucessfully',
                isSuccess: true
              });
              this.isBudgetLoading = false;
            });
            break;
        }
        this.editBudget = !this.editBudget;
      },
      setupQrCode() {
        let code = null;
        if (!this.offer.validations_codes || this.offer.validations_codes.lenght < 1) {
          code = sha256(`${this.$store.state.user.user.id}-${this.offer.id}`);
        } else {
          code = this.offer.validations_codes[0].code;
        }
        QRCode.toDataURL(code).then((url) => (this.qrCode = url));
      },
      deleteFilter(targetGroup, fIndex) {
        targetGroup.splice(fIndex, 1);
      },
      handlePositiveNumberInput(e) {
        if (e.target.value < 1) {
          e.target.value = 1;
        }
      },

      /*
        |--------------------------------------------------------------------------
        | DATE PICKER
        |--------------------------------------------------------------------------
        */
      setStartDateTime(newDate) {
        // const date = DateTime.fromMillis(Date.parse(newDate));
        // this.offer.online_on = date.toSQL({ includeOffset: false }).replace(".000", "");
        this.online_on = DateTime.fromJSDate(newDate).toUTC().toSQL();
      },
      setEndDateTime(newDate) {
        // const date = DateTime.fromMillis(Date.parse(newDate));
        // this.offer.offline_on = date.toSQL({ includeOffset: false }).replace(".000", "");
        this.offline_on = DateTime.fromJSDate(newDate).toUTC().toSQL();
      },
      dateFormatter(newDate) {
        return DateTime.fromJSDate(newDate).toFormat('dd-MM-yyyy HH:mm');
      },

      /*
        |--------------------------------------------------------------------------
        | HELPERS
        |--------------------------------------------------------------------------
        */
      showCorrectProfileLabel(filter) {
        if (filter.category === 'offers') {
          this.getOffer(filter.profile_id).then((r) => {
            const offer = r.data.result;
            return offer.name;
          });
        }
      },
      getHumanReadableProfile(filter) {
        return filter.profile_id === 'PRO7' ? 'Age' : this.$t(`labels.${filter.profile_id}`);
      },
      getHumanReadableValue(filter) {
        return filter.profile_id === 'PRO7' ? filter.value : this.$t(`labels.${filter.value}`);
      },
      setOfferIcon(offer) {
        return SetInteractionTypeIcon(SetInteractionType(offer.interaction_type_id));
      },
      setOfferType(offer) {
        return SetInteractionType(offer.interaction_type_id);
      },
      setValidationTypeIcon(offer) {
        return SetValidationTypeIcon(offer.interaction_validation_id);
      },
      setValidationType(offer) {
        return SetValidationType(offer.interaction_validation_id);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button.deleteFilter {
    border: 0;
    background: transparent;
    margin: 0 0 0 8px;
    padding: 0;
    height: 14px;
  }
</style>
