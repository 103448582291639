//TODO: Não conseguir entender a necessiade dessa estrutura ainda ;(
export default function SetInteractionType(id) {
  switch (id) {
    case 'INT1':
      return 'INT1';
    case 'INT2':
      return 'INT2';
    case 'INT3':
      return 'INT3';
    case 'INT4':
      return 'INT4';
    case 'INT5':
      return 'INT5';
    case 'INT6':
      return 'INT6';
    case 'INT7':
      return 'INT7';
    case 'INT8':
      return 'INT8';
    case 'INT9':
      return 'INT9';
    case 'INT10':
      return 'INT10';
    case 'INT11':
      return 'INT11';
    case 'INT12':
      return 'INT12';
    case 'INT13':
      return 'INT13';
    case 'INT14':
      return 'INT14';
    case 'INT15':
      return 'INT15';
    case 'INT16':
      return 'INT16';
    case 'INT17':
      return 'INT17';
    case 'INT18':
      return 'INT18';
    case 'INT19':
      return 'INT19';
  }
}
