<template>
  <section class="portal">
    <modatta-sidenav page="campaigns"></modatta-sidenav>
    <main>
      <div class="campaigns components-wrapper new-offer">
        <modatta-side-modal
          v-if="openSideModal"
          :content="selectedOption.name"
          :title="selectedOption.title"
          :options="selectedOption.options"
          @banner="(file) => (this.newOffer.banner = file)"
          :handleUpdateMediaStep="handleUpdateMediaStep"
          @banner-url="(url) => (this.newOffer.banner_url = url)"
        ></modatta-side-modal>
        <modatta-navbar
          :title="$t('offers_step0.title')"
          :offer-type="offerType"
          :has-icon="{ name: 'campaigns' }"
        ></modatta-navbar>
        <div class="steps-wrapper modatta-scroller-x">
          <div class="steps">
            <div
              class="step"
              v-for="(step, index) in steps"
              :key="index"
              :class="{
                current: currentStep === index + 1,
                completed: step.isCompleted,
                disabled: offerId == null,
              }"
            >
              <span>{{ step.name }}</span>
              <span v-if="step.isCompleted" class="badge">
                <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="8" fill="#2355C3" />
                  <path
                    d="M11.334 5.5L6.75 10.083 4.667 8"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div class="new-offer-main-content">
          <modatta-step-brand v-if="currentStep === 1"></modatta-step-brand>
          <modatta-step-audience v-else-if="currentStep === 2"></modatta-step-audience>
          <modatta-step-offer v-else-if="currentStep === 3"></modatta-step-offer>
          <modatta-step-interaction v-else-if="currentStep === 4"></modatta-step-interaction>
          <modatta-step-budget v-else-if="currentStep === 5"></modatta-step-budget>
          <modatta-step-summary v-else-if="currentStep === 6" :status="status"></modatta-step-summary>
          <button
            v-if="currentStep < 2"
            class="button is-secondary preview-offer-btn"
            @click="selectOption('campaign', $t('offers_step0.edit_offer_type'))"
          >
            {{ $t('offers_step0.edit_offer_type') }}
          </button>
          <button
            v-if="currentStep !== 6 && currentStep > 1"
            class="button is-secondary preview-offer-btn"
            @click="
              selectOption('new-offer-preview', $t('offers_step0.preview_button'), {
                brandId: newOffer.brand_id,
                offer: newOffer,
              })
            "
          >
            {{ $t('offers_step0.preview_button') }}
          </button>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import Bugsnag from '@bugsnag/js';
  import SetOfferType from '../../helpers/set-offer-type.js';
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaSideModal from '../../components/ModattaSideModal.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import ModattaStepBrand from './components/step-brand/ModattaStepBrand.vue';
  import ModattaStepAudience from './components/step-audience/ModattaStepAudience.vue';
  import ModattaStepOffer from './components/step-offer/ModattaStepOffer.vue';
  import ModattaStepInteraction from './components/step-interaction/ModattaStepInteraction.vue';
  import ModattaStepBudget from './components/step-budget/ModattaStepBudget.vue';
  import ModattaStepSummary from './components/step-summary/ModattaStepSummary.vue';
  import IntegrationAPI from '../../api/integration_api.js';

  export default {
    components: {
      ModattaSidenav,
      ModattaNavbar,
      ModattaSideModal,
      ModattaStepBrand,
      ModattaStepAudience,
      ModattaStepOffer,
      ModattaStepBudget,
      ModattaStepSummary,
    },
    data() {
      return {
        status: null,
        currentStep: 1,
        steps: [
          {
            id: 1,
            name: this.$t('offers_steps_menu.step_1'),
            description: '',
            isCompleted: false,
          },
          {
            id: 2,
            name: this.$t('offers_steps_menu.step_2'),
            description: '',
            isCompleted: false,
          },
          {
            id: 3,
            name: this.$t('offers_steps_menu.step_3'),
            description: '',
            isCompleted: false,
          },
          {
            id: 4,
            name: this.$t('offers_steps_menu.step_4'),
            description: '',
            isCompleted: false,
          },
          {
            id: 5,
            name: this.$t('offers_steps_menu.step_6'),
            description: '',
            isCompleted: false,
          },
          {
            id: 6,
            name: this.$t('offers_steps_menu.step_7'),
            description: '',
            isCompleted: false,
          },
        ],
        isLoading: false,
        selectedBrand: null,
        selectedOption: null,
        offerId: new URLSearchParams(window.location.search).get('offer') || null,
        newOffer: {
          id: null,
          status_id: null,
          type_label_id: null,
          interaction_type_id: null,
          country_id: null,
          currency_id: null,
          // 1. Brand:
          brand_id: null,
          // 2. Audience:
          grouped_filters: [],
          filters: [], // Deprecated
          // 3. Offer:
          name: null,
          description: null,
          banner: null,
          banner_url: null,
          steps: [],
          // 4. Interaction:
          validations_codes: [{ type: 'universal', code: null }],
          action: null,
          urlAction: {
            action: '',
            validation: '',
          },
          questions: [],
          interaction_validation_id: null,
          // 5. Data:
          retention_end: null,
          // 6. Budget:
          online_on: null,
          offline_on: null,
          validation_max_hours: null,
          unit_points: null, // ????
          unit_tcost: null,
          perc_tcost: null,
          max_accepts: null,
          business_value: null,
        },
      };
    },
    mounted() {
      this.newOffer.country_id = this.$store.state.user.user.country_id;
      this.newOffer.currency_id = this.$store.state.user.user.balance.currency;
      if (this.offerId != null) {
        this.getCurrentOffer(this.offerId);
      }
      this.setupNewOfferSteps();
    },
    computed: {
      ...mapState(['newOfferType', 'openSideModal']),
      offerType() {
        return SetOfferType(this.newOffer.type_label_id);
      },

      // 1. Brand
      isBrandStepCompleted() {
        return this.newOffer.brand_id != null;
      },

      // 2. Audience
      isAudienceStepCompleted() {
        return this.newOffer.brand_id != null;

        // return this.newOffer.grouped_filters.length > 0;
      },

      // 3. Offer
      isOfferStepCompleted() {
        return (
          this.isOfferNameFilled && this.isOfferDescriptionFilled && this.isOfferBannerFilled && this.areStepsFilled
        );
      },
      isOfferBannerFilled() {
        return this.newOffer.banner != null;
      },
      areStepsFilled() {
        if (this.newOffer.interaction_type_id === 'INT6' || this.newOffer.interaction_type_id === 'INT7') {
          return true;
        }
        return this.newOffer.steps?.every((step) => step.description != '');
      },
      isOfferNameFilled() {
        return this.newOffer.name != null && this.newOffer.name != '';
      },
      isOfferDescriptionFilled() {
        return this.newOffer.description != null && this.newOffer.description != '';
      },

      // 4. Interaction
      isInteractionStepCompleted() {
        if (this.newOffer.interaction_validation_id === 'ITV2' || this.newOffer.interaction_validation_id === 'ITV3') {
          // Offline types
          return this.isInteractionActionFilled && this.isInteractionTypeFilled && this.isInteractionCodesFilled;
        } else if (this.newOffer.interaction_type_id === 'INT6' || this.newOffer.interaction_type_id === 'INT7') {
          // Survey or Poll Types
          return this.isInteractionTypeFilled && this.isInteractionQuestionsFilled;
        } else if (
          this.newOffer.interaction_validation_id === 'ITV4' ||
          this.newOffer.interaction_validation_id === 'ITV6'
        ) {
          // Postback or GTM
          //&& this.isInteractionPostbackValidated
          return this.isInteractionActionFilled && this.isInteractionTypeFilled;
        } else {
          return this.isInteractionActionFilled && this.isInteractionTypeFilled;
        }
      },
      isInteractionQuestionsFilled() {
        return this.newOffer.questions.length > 0;
      },
      isInteractionCodesFilled() {
        return this.newOffer.validations_codes.length > 0 && this.newOffer.validations_codes[0].code;
      },
      isInteractionPostbackValidated() {
        return this.$store.state.offer.validatePostback;
      },
      isInteractionTypeFilled() {
        return this.newOffer.interaction_validation_id != null;
      },
      isInteractionActionFilled() {
        return this.newOffer.action != null && this.newOffer.action != '';
      },

      // 5. Data
      isDataStepCompleted() {
        return true;
      },

      // 6. Budget
      isBudgetStepCompleted() {
        return (
          this.isBudgetOnlineOnFilled &&
          (this.isBudgetUnitCostFilled || this.isBudgetPercCostFilled) &&
          this.isBudgetOfflineOnFilled &&
          this.isBudgetMaxAcceptsFilled &&
          this.isValidationMaxHoursFilled &&
          this.isBudgetBusinessValueFilled
        );
      },
      isBudgetOnlineOnFilled() {
        return this.newOffer.online_on != null && this.newOffer.online_on != '';
      },
      isBudgetUnitCostFilled() {
        return this.newOffer.unit_tcost != null && this.newOffer.unit_tcost != '';
      },
      isBudgetPercCostFilled() {
        return this.newOffer.perc_tcost != null && this.newOffer.perc_tcost != '';
      },
      isBudgetOfflineOnFilled() {
        return this.newOffer.offline_on != null && this.newOffer.offline_on != '';
      },
      isBudgetMaxAcceptsFilled() {
        return this.newOffer.max_accepts != null && this.newOffer.max_accepts != '' && this.newOffer.max_accepts > 0;
      },
      isBudgetBusinessValueFilled() {
        if (this.newOffer.business_value != null && this.newOffer.business_value != '') {
          if (this.newOffer.business_value > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      isValidationMaxHoursFilled() {
        return (
          this.newOffer.validation_max_hours != null &&
          this.newOffer.validation_max_hours != '' &&
          this.newOffer.validation_max_hours > 0
        );
      },
      allStepsCompleted() {
        return (
          this.isBrandStepCompleted &&
          this.isAudienceStepCompleted &&
          this.isOfferStepCompleted &&
          this.isInteractionStepCompleted &&
          this.isDataStepCompleted &&
          this.isBudgetStepCompleted
        );
      },
    },
    methods: {
      ...mapActions(['getOffer', 'upsertOffer', 'upsertOfferFormData', 'updateBannerFile', 'updateOfferStatus']),
      ...mapMutations(['setNewOfferType', 'setOpenSideModal', 'setOpenToast']),
      getCurrentOffer(id) {
        this.isLoading = true;
        this.getOffer(id)
          .then((r) => {
            // If this offer is not a draft forward to Dashboard.
            if (r.data.result.status_id !== 'OST1') {
              this.$router.replace({ path: `/offers/${r.data.result.id}` });
              return;
            }

            this.newOffer = r.data.result;
            this.setupNewOfferSteps();
            this.isLoading = false;
          })
          .catch(() => {
            // If the Offer ID doesn't exists redirect to new-offer without query string
            this.$router.replace({ path: '/offers/new-offer' });

            this.isLoading = false;
          });
      },
      async handleNextClick(stepId, updatedData) {
        this.isLoading = true;
        if (stepId === 3 && this.isOfferBannerFilled) {
          await this.updateBannerImage();
        }

        if (
          (this.$store?.state?.newOfferType?.code &&
            this.newOffer.type_label_id != this.$store.state.newOfferType.code) ||
          (this.$store?.state?.newOfferType?.id &&
            this.newOffer.interaction_type_id != this.$store.state.newOfferType.id)
        ) {
          updatedData.type_label_id = this.$store?.state?.newOfferType?.code;
          updatedData.interaction_type_id = this.$store?.state?.newOfferType?.id;
          updatedData.steps = [];
          updatedData.action = '';
          updatedData.questions = [];
          updatedData.validations_codes = [];
          updatedData.interaction_validation_id = '';
        }

        if (stepId === 3) {
          const formData = new FormData();
          formData.append('name', updatedData.name);
          formData.append('description', updatedData.description);

          updatedData.steps.forEach((step, index) => {
            Object.keys(step).forEach((key) => {
              if (step[key] === null || step[key] === undefined) {
                return;
              }

              if (key === 'media_source' && step[key] instanceof File) {
                formData.append(`steps[${index}][${key}]`, step[key], step[key].name);
              } else if (key === 'file' && !step[key]) {
                formData.append(`steps[${index}][${key}]`, null);
              } else {
                formData.append(`steps[${index}][${key}]`, step[key]);
              }
            });
          });
          this.upsertOfferFormData({ id: this.offerId, payload: formData })
            .then(() => {
              this.finalize(stepId);
            })
            .catch((error) => {
              if (Bugsnag.isStarted()) {
                Bugsnag.notify(error);
              }
              this.setOpenToast({
                title: 'Error ao salvar os passos da missão.',
                isSuccess: false
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else if (stepId === 4 && updatedData.action instanceof File) {
          const formData = new FormData();
          formData.append('action', null);
          formData.append('video', updatedData.action);
          formData.append('interaction_validation_id', updatedData.interaction_validation_id);
          formData.append('questions', updatedData.questions);
          formData.append('validations_codes', updatedData.validations_codes);
          this.upsertOfferFormData({ id: this.offerId, payload: formData })
            .then(() => {
              this.finalize(stepId);
            })
            .catch((error) => {
              if (Bugsnag.isStarted()) {
                Bugsnag.notify(error);
              }
              this.setOpenToast({
                title: 'Error ao salvar os passos da missão.',
                isSuccess: false
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else if (updatedData.interaction_validation_id === 'ITV15') {
            try {
              this.isLoading = true;
              const actionCommentInstagram = JSON.parse(updatedData.action);
              const accountSocialId = this.$store.state.user.userIntegration?.social_config.map((social) => {
                // TODO: levar para uma lugar melhor.
                const plataformInstagram = '9bb8913b-ddd9-430b-a66a-d74d846e6c66';
                if (social.work_platform_social_id === plataformInstagram) {
                  return social.account_social_id;
                }
              })
              const resultIntegration = await IntegrationAPI.getInstagramPostId(accountSocialId[0], actionCommentInstagram.url)
              updatedData.action = JSON.stringify({
                url: actionCommentInstagram.url,
                post_id: resultIntegration.data.id
              });
              const resultSaveOffer = await this.upsertOffer({ body: JSON.stringify(updatedData), id: this.offerId })
              this.newOffer = resultSaveOffer.data.result;
              this.updateOfferType();
              this.updateInteractionType();

              this.finalize(stepId);
              if (this.offerId == null) {
                this.$router.replace({ path: `?offer=${resultSaveOffer.data.result.id}` });
                this.offerId = resultSaveOffer.data.result.id;
              }
            } catch (error) {
              if (Bugsnag.isStarted()) {
                Bugsnag.notify(error);
              }
              this.setOpenToast({
                title: 'Error ao salvar a interação da missão.',
                message: 'Já vamos alertados do erro, uma investigação será iniciada.',
                isSuccess: false
              });
            } finally {
              this.isLoading = false;
            }
        } else {
          try {
            this.isLoading = true;
            const resultSaveOffer = await this.upsertOffer({ body: JSON.stringify(updatedData), id: this.offerId })
            this.newOffer = resultSaveOffer.data.result;
            this.updateOfferType();
            this.updateInteractionType();

            this.finalize(stepId);
            if (this.offerId == null) {
              this.$router.replace({ path: `?offer=${resultSaveOffer.data.result.id}` });
              this.offerId = resultSaveOffer.data.result.id;
            }
          } catch (error) {
            if (Bugsnag.isStarted()) {
              Bugsnag.notify(error);
            }
            this.setOpenToast({
              title: 'Error ao salvar a interação da missão.',
              message: 'Já vamos alertados do erro, uma investigação será iniciada.',
              isSuccess: false
            });
          } finally {
            this.isLoading = false;
          }
        }
      },
      handleUpdateMediaStep(media) {
        this.newOffer.steps = this.newOffer.steps.map((step, index) => {
          if (index === this.selectedOption.index) {
            if (media === null) {
              return {
                offer_id: step.offer_id,
                description: step.description,
                index: step.index,
              };
            } else {
              return { ...step, ...media };
            }
          }
          return step;
        });
      },
      saveAsDraft() {
        this.isLoading = true;
        this.updateOfferType();
        this.updateInteractionType();
        const body = JSON.stringify(this.newOffer);
        const payload = { body, id: this.offerId };
        this.upsertOffer(payload).then(() => {
          this.isLoading = false;
          this.setOpenToast({
            title: 'Salvo como rascunho com sucesso!',
            isSuccess: true
          });
          this.$router.push({ name: 'Offers' });
        });
      },
      updateOfferType() {
        this.newOffer.type_label_id = this.newOfferType != null ? this.newOfferType.code : this.newOffer.type_label_id;
      },
      updateInteractionType() {
        this.newOffer.interaction_type_id =
          this.newOfferType != null ? this.newOfferType.id : this.newOffer.interaction_type_id;
      },
      async updateBannerImage() {
        if (typeof this.newOffer.banner === 'string') {
          return;
        } // If not a blob, return
        const body = new FormData();
        body.append('banner', this.newOffer.banner);
        const payload = { body, id: this.offerId };
        await this.updateBannerFile(payload);
      },
      finalize(stepId) {
        if (stepId !== 6) {
          this.goToNextStep();
        }
        this.isLoading = false;
        this.completeStep(stepId);
      },
      completeStep(stepId) {
        let currentStep = this.steps.find((s) => s.id === stepId);
        currentStep.isCompleted = true;
      },
      goToNextStep() {
        this.currentStep++;
      },
      goToPreviousStep(stepId) {
        if (stepId !== 6) {
          return;
        }
        this.updateOfferType();
        this.updateInteractionType();
        this.isLoading = true;
        const body = JSON.stringify(this.newOffer);
        const payload = { body, id: this.offerId };
        this.upsertOffer(payload).then((r) => {
          this.isLoading = false;
          this.currentStep--;
          if (this.offerId == null) {
            this.$router.replace({ path: `?offer=${r.data.result.id}` });
            this.offerId = r.data.result.id;
          }
        });
      },
      submitNewOffer() {
        this.isLoading = true;
        if (!this.newOffer.business_value) {
          this.newOffer.business_value = this.newOffer.unit_tcost;
        }
        const body = JSON.stringify(this.newOffer);
        const payload = { body, id: this.offerId };
        this.upsertOffer(payload).then((r) => {
          const data = { data: { status_id: 'OST3' }, id: r.data.result.id };
          this.updateOfferStatus(data);
          this.updateOfferInOffersTable(r.data.result);
          this.isLoading = false;
          this.status = 'success';
          if (this.offerId == null) {
            this.$router.replace({ path: `?offer=${r.data.result.id}` });
          }
        });
      },
      setupNewOfferSteps() {
        if (this.isInteractionStepCompleted) this.completeStep(4);
        if (this.isAudienceStepCompleted) this.completeStep(2);
        if (this.isBudgetStepCompleted) this.completeStep(5);
        if (this.isBrandStepCompleted) this.completeStep(1);
        if (this.isOfferStepCompleted) this.completeStep(3);
      },
      selectOption(name, title, options) {
        this.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
      },
      updateOfferInOffersTable(offer) {
        const offerIndex = this.$store.state.offer.offers.findIndex((o) => o.id === this.offerId);
        this.$set(this.$store.state.offer.offers, offerIndex, offer);
      },
    },
  };
</script>
