var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modatta-table",class:{ stripped: _vm.stripped }},[(_vm.data.length > 0)?[((_vm.showHeader || _vm.showHeader == null) && _vm.labels)?_c('header',{staticClass:"row"},_vm._l((_vm.labels),function(label,i){return _c('div',{key:i,staticClass:"cell",style:({
          width: _vm.equalWidth ? `${100 / _vm.columns.length}%` : '',
          padding: _vm.sidePadding ? `0 ${_vm.sidePadding}px` : '',
        })},[_c('div',{staticClass:"cell-content"},[_vm._v(" "+_vm._s(_vm._f("sanitize")(_vm.getLabel(label)))+" ")])])}),0):_vm._e(),((_vm.showHeader || _vm.showHeader == null) && !_vm.labels)?_c('header',{staticClass:"row"},_vm._l((_vm.columns),function(cell,i){return _c('div',{key:i,staticClass:"cell",style:({
          width: _vm.equalWidth ? `${100 / _vm.columns.length}%` : '',
          padding: _vm.sidePadding ? `0 ${_vm.sidePadding}px` : '',
        })},[_c('div',{staticClass:"cell-content"},[_vm._v(" "+_vm._s(_vm._f("sanitize")(cell))+" ")])])}),0):_vm._e(),_vm._l((_vm.data),function(row,rid){return _c('div',{key:rid,staticClass:"row",on:{"click":function($event){return _vm.handleRowClick(row)}}},_vm._l((Object.values(row)),function(cell,i){return _c('div',{key:i,staticClass:"cell text-ellipsis",style:({
          width: _vm.equalWidth ? `${100 / Object.values(row).length}%` : '',
          padding: _vm.sidePadding ? `0 ${_vm.sidePadding}px` : '',
        })},[(cell != null)?_c('div',{staticClass:"cell-content",attrs:{"data-cell":Object.keys(row)[i]}},[(typeof cell === 'object')?[(cell.type === 'icon')?[_c('div',{staticClass:"icon-wrapper"},[_c('modatta-icon',{attrs:{"icon":cell.icon}})],1)]:_vm._e(),(cell.type === 'image')?[_c('div',{staticClass:"image-wrapper",style:(`background-image: url(${cell.image})`)})]:_vm._e(),(cell.type === 'button')?[_c('button',{on:{"click":function($event){return _vm.handleButtonClick(cell)}}},[(cell.icon)?_c('modatta-icon',{attrs:{"icon":cell.icon}}):_vm._e(),(cell.text)?void 0:_vm._e()],2)]:_vm._e(),(cell.type === 'badge')?[_c('div',{staticClass:"tag",class:{
                  'is-green':
                    cell.status === 'offers_status.menu_filter_6' ||
                    cell.status === 'menu_filter_6' ||
                    cell.status === 'menu_filter_5' ||
                    cell.status === 'BST2',
                  'is-yellow':
                    cell.status === 'offers_status.menu_filter_2' ||
                    cell.status === 'menu_filter_2' ||
                    cell.status === 'menu_filter_9' ||
                    cell.status === 'BST1',
                  'is-gray':
                    cell.status === 'offers_status.menu_filter_7' ||
                    cell.status === 'menu_filter_7' ||
                    cell.status === 'menu_filter_4' ||
                    cell.status === 'menu_filter_3' ||
                    cell.status === 'BST4',
                  'is-red':
                    cell.status === 'offers_status.menu_filter_8' ||
                    cell.status === 'menu_filter_8' ||
                    cell.status === 'menu_filter_10' ||
                    cell.status === 'BST3',
                }},[_vm._v(" "+_vm._s(_vm.isOffer ? _vm.$t('offers.' + cell.status) : _vm.isDashboard ? _vm.$t('labels.' + cell.status) : _vm.$t('brands.' + cell.status))+" ")])]:_vm._e()]:[_vm._v(_vm._s(cell))]],2):_vm._e()])}),0)})]:_c('div',[_vm._v(_vm._s(_vm.$t('budget.table_no_content')))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }