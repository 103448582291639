<template>
  <div class="metric-cards-row">
    <template v-for="(card, index) in cards">
      <div class="metric-card" :key="index">
        <strong :class="{ 'is-loading': card.value == null }">{{ card.value }}</strong>
        <span>{{ card.text }}</span>
      </div>
    </template>
  </div>
</template>

<script>
  import Utilities from '../helpers/utilities.js';

  export default {
    props: {
      body: String,
      totalInvested: String,
      businessValueGenerated: String,
    },
    data() {
      return {
        cards: [],
      };
    },

    watch: {
      totalInvested(newVal) {
        this.$set(this.cards[0], 'value', newVal);
      },
      businessValueGenerated(newVal) {
        this.$set(this.cards[1], 'value', newVal);
      },
    },

    methods: {
      currency(value) {
        return Utilities.currency(value, currency);
      },
    },

    mounted() {
      this.cards = [
        { text: this.body, value: this.totalInvested },
        {
          text: this.$t('offers_step6.box_budget_label3'),
          value: this.businessValueGenerated,
        },
      ];
    },
  };
</script>
