<template>
  <div class="authentication" v-if="success">
    <div class="form-container reset-password welcome" :class="{ success: success }">
      <div class="clouds-left">
        <img src="/svg/decorations/reset-left-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="clouds-right">
        <img src="/svg/decorations/reset-right-clouds.svg" alt="Illustration representing clouds" />
      </div>
      <div class="tree">
        <img src="/svg/decorations/tree.svg" alt="Illustration representing a tree" />
      </div>
      <div class="illustrations">
        <div class="background">
          <img src="/svg/decorations/new-password-success-background.svg" alt="Illustration a green checkmark" />
        </div>
      </div>
      <div class="form">
        <h1>{{ $t('signup_success.message_label') }}</h1>
        <router-link to="/dashboard" class="button is-primary" type="submit">{{
          $t('signup_success.button_label')
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { required, sameAs, minLength } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        browserLang: '',
        isLoading: false,
        success: true,
        code: null,
        selectedLanguageId: '',
        languages: [
          { name: this.$t('labels.LNG1'), lang_id: 'LNG1', i18n_code: 'pt', code: 'pt-pt' },
          { name: this.$t('labels.LNG5'), lang_id: 'LNG5', i18n_code: 'br', code: 'pt-br' },
          { name: this.$t('labels.LNG3'), lang_id: 'LNG3', i18n_code: 'en', code: 'en' },
        ],
      };
    },
    mounted() {
      this.code = this.getCodeFromUrl();
      if (!this.success) {
        this.$router.push('login');
      }
      this.browserLang = navigator.language;
      switch (this.browserLang) {
        case 'pt':
          this.selectedLanguageId = 'LNG1';
          break;
        case 'pt-BR':
          this.selectedLanguageId = 'LNG5';
          break;
        default:
          this.selectedLanguageId = 'LNG3';
          break;
      }
    },
    watch: {
      selectedLanguageId() {
        let currentLanguage = this.languages.find((o) => o.lang_id === this.selectedLanguageId);
        this.$i18n.locale = currentLanguage.i18n_code;
        this.selectedLanguageLabel = currentLanguage.code;
      },
    },
    validations: {
      form: {
        password: {
          required,
          minLength: minLength(6),
        },
        passwordConfirm: {
          required,
          sameAsPassword: sameAs('password'),
        },
      },
    },
    methods: {
      getCodeFromUrl() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('code');
      },
    },
  };
</script>
