<template>
  <section class="portal">
    <modatta-sidenav page="integration"></modatta-sidenav>
    <main>
      <div class="integration components-wrapper fill-vp flex-columns">
        <div class="fit-content">
          <modatta-navbar :title="$t('integration.title')" :has-icon="{ name: 'puzzle' }"></modatta-navbar>
          <span class="subtitle">{{ $t('integration.subtitle') }}</span>
        </div>
        <div class="center-v wrap-options">
          <div v-show="isLoading" class="global-loading">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div v-show="!isLoading" class="toggle-options">
            <b-tooltip type="is-dark" position="is-bottom" size="is-large" multilined>
              <template v-slot:content>
                <b>Por que a Modatta precisa conectar sua conta?</b></br>Para garantir a validação das missões dos
                usuários.
                </br>
                </br>
                <b>Privacidade</b></br>A Modatta só tem acesso às informações públicas da conta do Instagram, como
                comentários dos posts.
                </br>
                </br>
                <b>Segurança</b></br>A Modatta usa a integraçào oficial do Instagram que só permite acesso às
                informações
                públicas.
              </template>
              <button :key="index" class="option"
                v-for="(socialMedia, index) in socialMediaSdkConfiguration?.socialMediaList"
                @click="onClickSocialMedia(socialMedia)">
                <div class="inner">
                  <div class="button-integration">
                    <modatta-icon :icon="socialMedia.icon"></modatta-icon>
                    <p class="name-integration">{{ $t(`${socialMedia.title}`) }}</p>
                  </div>
                  <p class="integration-card-subtitle">
                    {{ $t(socialMedia.subtitle) }}
                    </br>
                    </br>
                    Saiba mais sobre
                    </br>
                    <span style="color: #2355c3; text-decoration: underline;">Privacidade, segurança e por que
                      conectar.</span>
                  </p>
                  <div class="integration-card-footer">
                    <div class="integration-card-status">
                      <p class="integration-card-label-status">{{ getLabelStatus(socialMedia.accountSocialStatus) }}</p>
                      <modatta-icon v-show="socialMedia.accountSocialStatus !== 'WAITING_CONNECTION'"
                        :icon="getIconStatus(socialMedia.accountSocialStatus)"></modatta-icon>
                      <div v-show="socialMedia.accountSocialStatus === 'WAITING_CONNECTION'"
                        class="integration-card-loading"></div>
                    </div>
                  </div>
                </div>
              </button>
            </b-tooltip>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
  import { mapMutations } from 'vuex';
  import Bugsnag from '@bugsnag/js';
  import ModattaIcon from '../../components/ModattaIcon.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import IntegrationAPI from '../../api/integration_api.js';

  export default {
    components: { ModattaIcon, ModattaNavbar },
    data() {
      return {
        isLoading: false,
        socialMediaSdkConfiguration: null,
        workPlatformIdClicked: null,
      };
    },
    async mounted() {
      try {
        this.isLoading = true;
        const { data: responseConfigSdk } = await IntegrationAPI.getSocialWorkPlataformConfigSdk();
        this.socialMediaSdkConfiguration = {
          userId: responseConfigSdk.social_user_id,
          token: responseConfigSdk.social_token,
          socialMediaList: responseConfigSdk.social_config.map((config) => {
            return {
              id: config.id,
              icon: this.getNameIcon(config.work_platform_social_id),
              title: this.getTitle(config.work_platform_social_id),
              subtitle: this.getSubTitle(config.work_platform_social_id),
              accountId: config.account_social_id,
              workPlatformId: config.work_platform_social_id,
              accountSocialStatus: config.account_social_status,
            }
          })
        }
        this.setConfigIntegration(responseConfigSdk);
      } catch (error) {
        if (Bugsnag.isStarted()) {
          Bugsnag.notify(error);
        }
        this.setOpenToast({
          title: this.$t('integration.toast_error.title'),
          message: this.$t('integration.toast_error.sub_title'),
          isSuccess: false
        });
      } finally {
        this.isLoading= false;
      }
    },
    methods: {
      ...mapMutations(['setOpenToast', 'setConfigIntegration']),
      getNameIcon(socialMediaId) {
        switch (socialMediaId) {
          case '9bb8913b-ddd9-430b-a66a-d74d846e6c66':
            return 'instagram';
          default:
            return 'modatta-icon';
        }
      },
      getTitle(socialMediaId) {
        switch (socialMediaId) {
          case '9bb8913b-ddd9-430b-a66a-d74d846e6c66':
            return 'integration.instagram.title';
          default:
            return 'Modatta';
        }
      },
      getSubTitle(socialMediaId) {
        switch (socialMediaId) {
          case '9bb8913b-ddd9-430b-a66a-d74d846e6c66':
            return 'integration.instagram.sub_title';
          default:
            return 'Modatta';
        }
      },
      getLabelStatus(accountSocialStatus) {
        switch (accountSocialStatus) {
          case 'NOT_CONNECTED':
            return 'Desativado';
          case 'WAITING_CONNECTION':
            return 'Ativando...';
          case 'CONNECTED':
            return 'Ativado';
        }
      },
      getIconStatus(accountSocialStatus) {
        switch (accountSocialStatus) {
          case 'NOT_CONNECTED':
            return 'close';
          case 'CONNECTED':
            return 'checkmark-green';
        }
      },
      onClickSocialMedia(socialMedia) {
        if (socialMedia.accountSocialStatus !== 'WAITING_CONNECTION') {
          this.workPlatformIdClicked = socialMedia.workPlatformId;

          const phylloConnect = this.startConnectPhyllo(socialMedia.workPlatformId);
          phylloConnect.open();
        }
      },
      startConnectPhyllo(workPlatformId) {
        try {
          const phylloConnect = window.PhylloConnect.initialize({
            environment: process.env.VUE_APP_ENVIRONMENT_PHYLLO,
            userId: this.socialMediaSdkConfiguration.userId,
            token: this.socialMediaSdkConfiguration.token,
            clientDisplayName: "Modatta",
            workPlatformId: workPlatformId,
            language: "pt"
          });

          phylloConnect.on(
            "accountConnected",
            (accountId, workplatformId, userId) => {
              this.socialMediaSdkConfiguration.socialMediaList.forEach(socialMedia => {
                if (socialMedia.workPlatformId === workPlatformId) {
                  IntegrationAPI.connectWithSocialNetwork(socialMedia.id, accountId)
                    .then(() => {
                      socialMedia.accountSocialStatus = 'WAITING_CONNECTION';
                      this.setOpenToast({
                        title: this.$t('integration.toast_success.title'),
                        message: this.$t('integration.toast_success.sub_title'),
                        isSuccess: true
                      });
                    })
                    .catch((error) => {
                      socialMedia.accountSocialStatus = 'NOT_CONNECTION';
                      this.setOpenToast({
                        title: this.$t('integration.toast_error.title'),
                        message: this.$t('integration.toast_error.sub_title'),
                        isSuccess: false
                      });
                      if (Bugsnag.isStarted()) {
                        Bugsnag.notify(error);
                      }
                    })
                }
              });
            }
          );
          phylloConnect.on(
            "accountDisconnected",
            (accountId, workplatformId, userId) => {
              // gives the successfully disconnected account ID and work platform ID for the given user ID
              this.socialMediaSdkConfiguration.socialMediaList.forEach(socialMedia => {
                if (socialMedia.workPlatformId === workPlatformId) {
                  IntegrationAPI.disconnectWithSocialNetwork(socialMedia.id)
                    .then(() => {
                      socialMedia.accountSocialStatus = 'NOT_CONNECTION';
                      this.setOpenToast({
                        title: this.$t('integration.toast_success.title'),
                        message: this.$t('integration.toast_success.sub_title'),
                        isSuccess: true
                      });
                    })
                    .catch((error) => {
                      this.setOpenToast({
                        title: this.$t('integration.toast_error.title'),
                        message: this.$t('integration.toast_error.sub_title'),
                        isSuccess: false
                      });
                      if (Bugsnag.isStarted()) {
                        Bugsnag.notify(error);
                      }
                    })
                }
              });
            }
          );
          phylloConnect.on("tokenExpired", (userId) => {
            // gives the user ID for which the token has expired
            // the SDK closes automatically in case the token has expired, and you need to handle this by showing an appropriate UI and messaging to the users
            this.setOpenToast({
              title: this.$t('integration.toast_error.title'),
              message: this.$t('integration.toast_error.sub_title'),
              isSuccess: false
            });
            if (Bugsnag.isStarted()) {
              Bugsnag.notify(new Error(`Token do usuário ${userId} esta expirado. Token: ${this.socialMediaSdkConfiguration.token}`));
            }
          });
          phylloConnect.on("exit", (reason, userId) => {
            // indicates that the user with given user ID has closed the SDK and gives an appropriate reason for it
          });
          phylloConnect.on(
            "connectionFailure",
            (reason, workplatformId, userId) => {
              this.setOpenToast({
                title: this.$t('integration.toast_error.title'),
                message: this.$t('integration.toast_error.sub_title'),
                isSuccess: false
              });
              if (Bugsnag.isStarted()) {
                Bugsnag.notify({ reason, workplatformId, userId }, "Failure to connect with the Phyllo platform");
              }
            }
          );
          return phylloConnect;
        } catch (error) {
          this.setOpenToast({
            title: this.$t('integration.toast_error.title'),
            message: this.$t('integration.toast_error.sub_title'),
            isSuccess: false
          });
          if (Bugsnag.isStarted()) {
            Bugsnag.notify(error);
          }
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .subtitle {
    padding-left: 36px;
    color: #0a1735;
    text-align: center;
    font-family: 'Silka Regular';
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
  }

  .fill-vp {
    height: 100%;
  }

  .wrap-options {
    min-height: 360px;
  }

  .flex-columns {
    display: flex;
    flex-direction: column;

    .fit-content {
      flex: 0 1 auto;
    }

    .fill {
      flex: 1 1 auto;
    }

    .center-v {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }
    }
  }

  .toggle-options {
    gap: 16px;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    padding: 1rem 0;
    justify-content: center;

    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }

    button.option {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      text-align: left;
    }

    .option .inner {
      width: 270px;
      height: 264px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px;
      border-radius: 8px;
      border: 1px solid #fff;
      background: hsl(0, 0%, 100%);
      transition: all 300ms ease;
      cursor: pointer;

      .name {
        color: #0a1735;
        font-family: 'Silka Semibold';
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
      }

      .button-integration {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;

        .name-integration {
          font-family: 'Silka Semibold';
          font-size: 24px;
          color: #070A22;
          margin-left: 12px;
        }
      }

      .integration-card-subtitle {
        font-family: 'Silka regular';
        font-size: 12px;
        color: #5D626F;
        line-height: 18px;
        text-align: center;
      }

      .integration-card-footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 12px;

        .integration-card-status {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .integration-card-loading {
              color: #585858;
              font-size: 3px;
              width: 1em;
              height: 1em;
              border-radius: 50%;
              position: relative;
              text-indent: -9999em;
              animation: mulShdSpin 1.3s infinite linear;
              transform: translateZ(0);
              margin-left: 24px;

              @keyframes mulShdSpin {
                0%, 100% {
                  box-shadow: 0 -3em 0 0.2em,
                    2em -2em 0 0em, 3em 0 0 -1em,
                    2em 2em 0 -1em, 0 3em 0 -1em,
                    -2em 2em 0 -1em, -3em 0 0 -1em,
                    -2em -2em 0 0;
                }

                12.5% {
                  box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
                    3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
                    -2em 2em 0 -1em, -3em 0 0 -1em,
                    -2em -2em 0 -1em;
                }

                25% {
                  box-shadow: 0 -3em 0 -0.5em,
                    2em -2em 0 0, 3em 0 0 0.2em,
                    2em 2em 0 0, 0 3em 0 -1em,
                    -2em 2em 0 -1em, -3em 0 0 -1em,
                    -2em -2em 0 -1em;
                }

                37.5% {
                  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                    3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
                    -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
                }

                50% {
                  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                    3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
                    -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
                }

                62.5% {
                  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
                    3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
                    -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
                }

                75% {
                  box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
                    3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
                    -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
                }

                87.5% {
                  box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
                    3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
                    -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
                }
              }
          }
        }

        .integration-card-label-status {
          font-family: 'Silka semibold';
          font-size: 14px;
          color: #545D72;
          margin-right: 12px;
        }
      }
    }

    button.option:not(:disabled):hover .inner,
    button.option:not(:disabled):focus .inner {
      // &.is-selected
      border-color: #2355c3;
      cursor: pointer;
    }

    button.option:disabled:hover .inner,
    button.option:disabled:focus .inner {
      cursor: default;
    }

    .option:disabled .inner {
      border-color: rgba(#d7d7d7, 0.5);
      background-color: rgba(#dee5f6, 0.3);
    }
  }
</style>
