<script>
  import { Doughnut } from 'vue-chartjs';

  export default {
    props: ['chartData', 'labels', 'colors', 'cutout', 'semi'],
    extends: Doughnut,
    data() {
      return {
        chart: {
          data: {
            labels: '',
            datasets: [
              {
                data: '',
                borderWidth: 0,
                backgroundColor: '',
              },
            ],
          },
          options: {
            cutoutPercentage: this.cutout != null ? this.cutout : 64, // 85
            circumference: this.semi ? 1.25 * Math.PI : 2 * Math.PI,
            rotation: this.semi ? 0.88 * Math.PI : -0.5 * Math.PI,
            tooltips: { enabled: false },
            legend: { display: false },
            hover: { mode: null },
          },
        },
      };
    },

    mounted() {
      this.chart.data.labels = this.labels;
      // Fixing console error within main dashboard
      this.chart.data.datasets[0].data = this.chartData;
      this.chart.data.datasets[0].backgroundColor = this.colors;
      this.renderChart(this.chart.data, this.chart.options);
    },
  };
</script>
