<template>
  <section class="portal">
    <modatta-sidenav page="campaigns"></modatta-sidenav>
    <main>
      <section class="brand offer dashboard components-wrapper">
        <modatta-navbar v-bind:title="$t('offers_dashboard.page_title')" :has-icon="false"></modatta-navbar>
        <!-- Dashboard Main Content-->
        <template>
          <!-- Dashboard -->
          <div class="module-grip-wrap">
            <div class="module-grid limit-width dashboard-components">
              <div class="module col-span-6 row-span-10 p-0">
                <div class="inner">
                  <div class="information">
                    <div class="start">
                      <div
                        class="tag"
                        :class="{
                          'is-loading': offer.status_id == null,
                          'is-yellow': offer.status_id === 'OST1',
                          'is-green': offer.status_id === 'OST4' || offer.status_id === 'OST5',
                          'is-red': offer.status_id === 'OST7' || offer.status_id === 'OST9',
                          'is-blue': offer.status_id === 'OST3' || offer.status_id === 'OST8',
                          'is-gray': offer.status_id === 'OST2' || offer.status_id === 'OST6',
                        }"
                      >
                        {{
                          $te('offers.' + getHumanReadableStatus(offer.status_id))
                            ? $t('offers.' + getHumanReadableStatus(offer.status_id))
                            : ''
                        }}
                      </div>

                      <div class="name text-ellipsis" :class="{ 'is-loading': offer == '' }">
                        <span>{{ offer.name }}</span>
                      </div>
                    </div>
                    <div class="end">
                      <button
                        class="button"
                        ref="playButton"
                        :disabled="disablePlayPauseButton(offer)"
                        @click="changeOfferStatus(offer)"
                      >
                        <modatta-icon
                          v-if="offer.status_id === 'OST4' || offer.status_id === 'OST5'"
                          icon="pause"
                          height="1.375"
                          :rem="true"
                        ></modatta-icon>
                        <modatta-icon v-else icon="play" width="1.375" height="1.375" :rem="true"></modatta-icon>
                      </button>
                      <button
                        class="button"
                        ref="archiveOffer"
                        @click="isComponentModalActive = true"
                        :disabled="disableArchiveButton(offer)"
                      >
                        <modatta-icon icon="archive" width="1.375" height="1.375" :rem="true"></modatta-icon>
                      </button>
                      <button
                        class="button is-secondary"
                        ref="editOffer"
                        @click="setOpenSideModal(true)"
                        :disabled="offer == ''"
                      >
                        {{ $t('offers_dashboard.details') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="module col-span-4 row-span-10 p-0">
                <div class="inner p-0">
                  <modatta-business-value-generated
                    :totalInvested="accumulatedCost"
                    :businessValueGenerated="totalGenerated"
                    :body="$t('offers_dashboard.investment_box_label')"
                  ></modatta-business-value-generated>
                </div>
              </div>

              <div class="module col-span-2 row-span-10 p-0">
                <div class="inner p-0">
                  <modatta-metric-card
                    layout="horizontal"
                    :body="$t('offers_dashboard.goal_counter_label')"
                    :title="$store.state.offer.objective"
                    :media="{ type: 'chart', source: 'clock', color: '#9EBE0E', hasShadow: false }"
                    :options="{ completed: validatedOffers }"
                  ></modatta-metric-card>
                </div>
              </div>

              <!-- Component (funnel) -->
              <div class="module col-span-12 row-span-18 row-auto transparent p-0">
                <div class="inner p-0 no-overflow">
                  <modatta-dashboard-funnel
                    :id="offerId"
                    type="offer"
                    :delivered="totalDelivered"
                  ></modatta-dashboard-funnel>
                </div>
              </div>
              <!-- funnel -->

              <!-- Component (snap tabs)-->
              <div class="module col-span-6 row-span-35 p-0">
                <div class="inner p-0 auto-oy component data single">
                  <!-- .component.data .sub-component.poll -->
                  <div v-if="!hasPoll" class="component data single">
                    <!--
                                decisionsRawData is fed by this component
                                decisionsRawData will be used to download that from offers that are not Polls
                            -->
                    <modatta-offer-decisions-timeline
                      :id="offerId"
                      :offer-name="this.offer.name"
                      @decisions-raw-data="(data) => (this.decisionsRawData = data)"
                      @data-is-ready="(ready) => (this.dataIsReady = ready)"
                    ></modatta-offer-decisions-timeline>
                  </div>
                  <div v-else class="sub-component poll">
                    <!--
                                rawdata is fed by this component
                                rawData will be used to download that from offers that are Polls
                            -->
                    <modatta-offer-poll-snap-tabs
                      :data="poll"
                      :offer="offer"
                      @new-selected-question="(question) => (this.selectedQuestion = question)"
                      @raw-data="(data) => (this.rawData = data)"
                    ></modatta-offer-poll-snap-tabs>
                  </div>
                </div>
                <!-- snap tabs -->
              </div>

              <!-- Component () -->
              <div class="module col-span-3 row-span-50" v-if="!hasPoll">
                <div class="inner modatta-chart">
                  <div>
                    <div class="chart-title">{{ charts[0].title }}</div>
                    <div class="modatta-pie-chart" :class="{ 'is-loading oval': !acceptedDataLoaded }">
                      <modatta-pie-chart
                        v-if="acceptedDataLoaded"
                        :chart-data="charts[0].data"
                        :labels="charts[0].labels"
                        :colors="charts[0].colors"
                      ></modatta-pie-chart>
                      <div
                        v-if="acceptedDataLoaded"
                        class="chart-center-content"
                        :class="{ nps: charts[0].type === 'nps' }"
                      >
                        <span class="value" :class="{ 'is-loading': nps === '' }">{{ nps }}</span>
                        <span class="subtitle">NPS</span>
                      </div>
                    </div>
                  </div>
                  <div class="chart-legend">
                    <div
                      class="data-point"
                      v-for="(data, i) in charts[0].labels.length"
                      :key="charts[0].labels[i]"
                      :class="{ nps: charts[0].type === 'nps' }"
                    >
                      <div>
                        <div class="color" :style="{ background: charts[0].colors[i] }"></div>
                        <div class="label">{{ charts[0].labels[i] }}</div>
                      </div>
                      <div v-if="acceptedDataLoaded">
                        <div
                          class="absolute"
                          :style="{ background: setAlphaColor(charts[0].colors[i], 0.1), color: charts[0].colors[i] }"
                        >
                          {{ charts[0].data[i] }}
                        </div>
                        <div
                          class="percentage"
                          v-text="getPercentage(charts[0], i)"
                          :style="{ color: charts[0].colors[i] }"
                        ></div>
                      </div>
                    </div>
                    <div class="data-point nps">
                      <div>
                        <div class="color" :style="{ background: 'rgba(236, 240, 248, 1)' }"></div>
                        <div class="label">{{ $t(`offers_dashboard.nps_box_not_evaluated`) }}</div>
                      </div>
                      <div v-if="acceptedDataLoaded">
                        <div class="absolute" :style="{ background: 'rgba(236, 240, 248, 0.7)' }">
                          {{ notEvaluated }}
                        </div>
                        <div class="percentage"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- chart -->

              <div class="module col-span-3 row-span-50 component modatta-chart poll" v-else>
                <div class="question">
                  <span>{{ $t('filters.details.question') }} {{ selectedQuestion.number }}</span>
                  {{ poll.find((p) => p.isSelected).question }}
                </div>
                <div class="chart-legend">
                  <div class="progressbar-container" v-for="(a, index) in sortedAnswers" :key="index">
                    <div class="header">
                      <div class="q">{{ index + 1 }}. {{ a.option }}</div>
                      <div class="votes">{{ Math.round((a.votes / selectedQuestion.votes) * 100) || 0 }}%</div>
                    </div>
                    <div class="modatta-progress-bar">
                      <div class="progressbar" :style="{ width: `${(a.votes / selectedQuestion.votes) * 100}%` }"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Component (pie chart) -->
              <!-- .dashboard .components .modatta-chart .modatta-pie-chart .chart-center-content -->
              <div class="module col-span-3 row-span-50 p-0">
                <div class="inner modatta-chart">
                  <div>
                    <div class="chart-title">{{ charts[1].title }}</div>
                    <div class="modatta-pie-chart" :class="{ 'is-loading oval': !declinedDataLoaded }">
                      <modatta-pie-chart
                        v-if="declinedDataLoaded"
                        :chart-data="charts[1].data"
                        :labels="charts[1].labels"
                        :colors="charts[1].colors"
                      ></modatta-pie-chart>
                      <div
                        v-if="declinedDataLoaded"
                        class="chart-center-content"
                        :class="{ nps: charts[1].type === 'nps' }"
                      >
                        <span class="value">{{ charts[1].center }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="chart-legend">
                    <div
                      class="data-point"
                      v-for="(data, i) in charts[1].labels.length"
                      :key="charts[1].labels[i]"
                      :class="{ nps: charts[1].type === 'nps' }"
                    >
                      <div>
                        <div class="color" :style="{ background: charts[1].colors[i] }"></div>
                        <div class="label">{{ charts[1].labels[i] }}</div>
                      </div>
                      <div v-if="declinedDataLoaded">
                        <div
                          class="absolute"
                          :style="{ background: setAlphaColor(charts[1].colors[i], 0.1), color: charts[1].colors[i] }"
                        >
                          {{ charts[1].data[i] }}
                        </div>
                        <div
                          class="percentage"
                          v-text="getPercentage(charts[1], i)"
                          :style="{ color: charts[1].colors[i] }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- pie chart -->

              <div class="module col-span-3 row-span-10 p-0">
                <div class="inner component data single p-0">
                  <div class="sub-component">
                    <div class="timeframe">
                      <modatta-icon icon="calendar"></modatta-icon>
                      <div>
                        <p>{{ $t('offers_dashboard.timeframe_box_title') }}</p>

                        <div class="date">
                          {{ getOnlineOn }} <br />
                          {{ getOfflineOn }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="module col-span-3 row-span-10 p-0">
                <div class="inner component data single p-0">
                  <div class="sub-component">
                    <button class="button is-text export" @click="downloadData()" :disabled="!dataIsReady">
                      <div v-if="dataIsReady">{{ $t('offers_dashboard.export_box_label') }}</div>
                      <div v-if="!dataIsReady">{{ $t('offers_dashboard.export_box_loading_label') }}</div>
                      <div class="icon-wrapper">
                        <modatta-icon icon="download" width="1.5" height="1.5" :rem="true"></modatta-icon>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <modatta-get-analytics-renderless
          source="offer"
          :id="offerId"
          :is-poll="hasPoll"
        ></modatta-get-analytics-renderless>

        <modatta-side-modal
          v-if="openSideModal"
          content="offer-details"
          v-bind:title="$t('offers_details.title')"
          v-bind:subtitle="'ID: ' + offer.id"
          :options="{ offer }"
        ></modatta-side-modal>

        <b-modal
          v-model="isComponentModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal
        >
          <template #default="props">
            <header>
              <div class="title">Are you sure?</div>
              <button class="button is-text" @click="props.close">
                <modatta-icon icon="times"></modatta-icon>
              </button>
            </header>
            <div class="modal-content">
              <p>You are about to archive an offer. Are you sure you want to do it?</p>
            </div>
            <footer>
              <button class="button is-text" @click="props.close">Cancel</button>
              <button class="button is-secondary" @click="archiveOffer()" :disabled="isLoadingModal">
                <span v-if="isLoadingModal">Archiving...</span>
                <span v-else>Archive offer</span>
              </button>
            </footer>
          </template>
        </b-modal>
      </section>
    </main>
  </section>
</template>

<script>
  import tippy from 'tippy.js';
  import { DateTime } from 'luxon';
  import 'tippy.js/dist/tippy.css';
  const ObjectsToCsv = require('objects-to-csv');
  import Utilities from '../../helpers/utilities.js';
  import SetOfferStatus from '../../helpers/set-offer-status.js';
  import SetInteractionType from '../../helpers/set-interaction-type.js';
  import SetInteractionTypeIcon from '../../helpers/set-interaction-type-icon.js';
  import { i18n2tz as I18N2TZ } from '../../helpers/tz-i18n';
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import tinycolor from 'tinycolor2';
  import ModattaIcon from '../../components/ModattaIcon.vue';
  import ModattaSideModal from '../../components/ModattaSideModal.vue';
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import ModattaBusinessValueGenerated from '../../components/ModattaBusinessValueGenerated.vue';
  import ModattaMetricCard from '../../components/ModattaMetricCard.vue';
  import ModattaDashboardFunnel from '../../components/ModattaDashboardFunnel.vue';
  import ModattaPieChart from '../../components/ModattaPieChart.vue';
  import ModattaGetAnalyticsRenderless from '../../components/ModattaGetAnalyticsRenderless.vue';
  import ModattaOfferPollSnapTabs from './components/ModattaOfferPollSnapTabs.vue';
  import ModattaOfferDecisionsTimeline from './components/ModattaOfferDecisionsTimeline.vue';

  export default {
    components: {
      ModattaIcon,
      ModattaSideModal,
      ModattaSidenav,
      ModattaNavbar,
      ModattaBusinessValueGenerated,
      ModattaMetricCard,
      ModattaDashboardFunnel,
      ModattaOfferDecisionsTimeline,
      ModattaOfferPollSnapTabs,
      ModattaPieChart,
      ModattaGetAnalyticsRenderless,
    },
    data() {
      return {
        offer: '',
        offerId: window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
        offers: this.$store.state.offer.offers,
        metricCards: [
          {
            layout: '',
            body: this.$t('offers_dashboard.goal_counter_label'),
            title: this.$store.state.offer.objective,
            options: { completed: this.$store.state.offer.totalAccepted },
            media: { type: 'chart', source: 'clock', color: '#9EBE0E', hasShadow: false }, // it could be a chart or an icon
          },
          {
            layout: '',
            body: this.$t('offers_dashboard.delivered_counter_label'),
            title: this.$store.state.offer.totalDelivered,
            media: { type: 'icon', source: 'send', color: '#7494EA', hasShadow: true }, // it could be a chart or an icon
          },
          {
            layout: '',
            body: this.$t('offers_dashboard.accepted_counter_label'),
            detail: { isRed: false },
            title: this.$store.state.offer.totalAccepted,
            media: { type: 'icon', source: 'check', color: '#19D2B4', hasShadow: true }, // it could be a chart or an icon
          },
          {
            layout: '',
            body: this.$t('offers_dashboard.declined_counter_label'),
            detail: { isRed: true },
            title: this.$store.state.offer.totalDeclined,
            media: { type: 'icon', source: 'close', color: '#F48266', hasShadow: true }, // it could be a chart or an icon
          },
        ],
        charts: [
          {
            title: this.$t('offers_dashboard.nps_box_title'),
            type: 'nps',
            center: '',
            labels: [
              this.$t('offers_dashboard.nps_box_promoter'),
              this.$t('offers_dashboard.nps_box_passive'),
              this.$t('offers_dashboard.nps_box_detractor'),
            ],
            data: [0, 0, 0],
            colors: ['#19D2B4', '#F7B05B', '#EF876E'],
          },
          {
            title: this.$t('offers_dashboard.decline_box_title'),
            type: '',
            center: '',
            labels: [
              this.$t('offers_dashboard.decline_box_other'),
              this.$t('offers_dashboard.decline_box_value'),
              this.$t('offers_dashboard.decline_box_brand'),
              this.$t('offers_dashboard.decline_box_product'),
            ],
            data: [0, 0, 0, 0],
            colors: ['#4271D1', '#7C9CDF', '#C2D1F0', '#E2EAF8'],
          },
        ],
        acceptedDataLoaded: false,
        declinedDataLoaded: false,
        nps: '',
        hasPoll: '',
        poll: '',
        selectedQuestion: '',
        rawData: '',
        rawDataCsv: null,
        decisionsRawData: '',
        decisionsRawDataCsv: null,
        isComponentModalActive: false,
        isLoadingModal: false,
        validatedOffers: 0,
        dataIsReady: false,
      };
    },
    beforeCreate() {
      // Solving bug # upon tab change @ PollSnapTabs and Reload, the page breaks
      const clean = window.location.href.split('#')[0];
      window.history.replaceState({}, document.title, clean);
    },
    mounted() {
      this.getValidatedOffers();
      this.getOffer(this.offerId).then((response) => {
        this.offer = response.data.result;
        if (this.offer.status_id === 'OST1') {
          this.$router.replace({ path: `new-offer?offer=${this.offer.id}` });
        } // Redirect if Draft
        const hasPoll = this.isOfferAPoll();
        if (hasPoll) {
          this.getOfferAnswers(this.offer.id).then(() => {
            let poll = this.answersData.result[0].poll;

            poll.sort(function (a, b) {
              return a.order - b.order;
            });
            let questions = [];
            let answers = [];
            poll.forEach((p, i) => {
              p.stats.forEach((a) => {
                const answer = {
                  option: a.answer,
                  votes: a.answer_count,
                  order: a.order,
                };
                answers.push(answer);
              });
              const question = {
                question: p.question,
                votes: p.total_answers,
                isSelected: i === 0,
                number: i + 1,
                answers: answers,
              };
              questions.push(question);
              answers = [];
            });
            this.poll = questions;
            this.selectedQuestion = this.poll.find((q) => q.isSelected);
            // this.sortedAnswers = this.selectedQuestion.answers.sort((a, b) => (a.order - b.order));
            this.hasPoll = true;
          });
        }
        setTimeout(() => this.setTooltips(), 100);
      });
      this.getOffers().then(() => (this.offers = this.$store.state.offer.offers));
    },
    watch: {
      $route(to, from) {
        if (to.path != from.path) {
          // this is needed because of Vue limitation on
          // render the same component with different data
          window.location.reload();
        }
      },
      offers(newVal) {
        this.offers = newVal;
      },
      objective(newVal) {
        this.$set(this.metricCards[0], 'title', newVal);
      },
      totalDelivered(newVal) {
        this.$set(this.metricCards[1], 'title', newVal);
      },
      totalAccepted(newVal) {
        this.$set(this.metricCards[2], 'title', newVal);
        this.$set(this.metricCards[0].options, 'completed', newVal);
        const detailValue = Math.round((this.totalAccepted / this.totalDelivered) * 100);
        this.$set(this.metricCards[2].detail, 'value', isFinite(detailValue) ? `${detailValue}%` : '0%');
      },
      totalDeclined(newVal) {
        this.$set(this.metricCards[3], 'title', newVal);
        this.$set(this.charts[1], 'center', `${Math.round((this.totalDeclined / this.totalDelivered) * 100)}%`);
        const detailValue = Math.round((this.totalDeclined / this.totalDelivered) * 100);
        this.$set(this.metricCards[3].detail, 'value', isFinite(detailValue) ? `${detailValue}%` : '0%');
      },
      acceptedData(newVal) {
        this.$set(this.charts[0], 'data', newVal);
      },
      declinedData(newVal) {
        this.$set(this.charts[1], 'data', newVal);
      },
    },
    computed: {
      ...mapState(['openSideModal']),
      ...mapGetters(['getOfferById']),
      // POPULATE DASHBOARD
      accumulatedCost() {
        if (this.$store.state.offer.accumulatedCost == null) return null;
        return (
          this.$t(`labels.${this.$store.state.offer.accumulatedCost.currency}`) +
          `${Utilities.numberWithCommas(Utilities.toDecimalPlaces(this.$store.state.offer.accumulatedCost.value, 2))}`
        );
      },
      totalGenerated() {
        if (this.$store.state.offer.totalGenerated == null) return null;
        return (
          this.$t(`labels.${this.$store.state.offer.totalGenerated.currency}`) +
          `${Utilities.numberWithCommas(Utilities.toDecimalPlaces(this.$store.state.offer.totalGenerated.value, 2))}`
        );
      },
      totalDelivered() {
        return this.$store.state.offer.totalDelivered;
      },
      totalAccepted() {
        return this.$store.state.offer.totalAccepted;
      },
      totalDeclined() {
        return this.$store.state.offer.totalDeclined;
      },
      acceptedData() {
        return this.$store.state.offer.acceptedData;
      },
      declinedData() {
        return this.$store.state.offer.declinedData;
      },
      objective() {
        return this.$store.state.offer.objective;
      },
      notEvaluated() {
        return this.$store.state.offer.notEvaluatedData;
      },
      // POLL
      answersData() {
        return this.$store.state.offer.answersData;
      },
      sortedAnswers() {
        return this.selectedQuestion.answers.sort(this.sortByOrder);
      },
      getOnlineOn() {
        return this.setDate(this.offer.online_on);
      },
      getOfflineOn() {
        return this.setDate(this.offer.offline_on);
      },
    },
    methods: {
      ...mapActions(['getOffer', 'getOffers', 'getOfferAnswers', 'updateOfferStatus', 'getFunnelData']),
      ...mapMutations(['setOpenSideModal', 'setOpenToast']),
      sortByOrder(a, b) {
        return a.order - b.order;
      },
      async getValidatedOffers() {
        const payload = { id: this.offerId, type: 'offer' };
        const result = await this.getFunnelData(payload);
        this.validatedOffers = result.data.validated;
      },
      disablePlayPauseButton(offer) {
        // Disable this button (play/pause) if offer is in
        // Draft || In Review || Pending || Archived || Rejected || Locked
        return (
          offer.status_id === 'OST1' ||
          offer.status_id === 'OST2' ||
          offer.status_id === 'OST3' ||
          offer.status_id === 'OST6' ||
          offer.status_id === 'OST7' ||
          offer.status_id === 'OST9'
        );
      },
      disableArchiveButton(offer) {
        return offer.status_id !== 'OST5';
      },
      changeOfferStatus(offer) {
        if (offer.status_id === 'OST4' || offer.status_id === 'OST5') {
          this.editOfferStatus('OST8', this.offer, 'Paused');
        } else if (offer.status_id === 'OST8') {
          this.editOfferStatus('OST5', this.offer, 'Resumed');
        }
      },
      handleOfferClick(offer) {
        this.$router.replace({ path: `/offers/${offer.id}` });
      },
      archiveOffer() {
        this.editOfferStatus('OST6', this.offer, 'Archived');
      },
      editOfferStatus(status, offer, message) {
        this.isLoadingModal = true;
        this.offer.status_id = status;
        // // Call API
        const payload = { data: { status_id: status }, id: offer.id };
        this.updateOfferStatus(payload)
          .then(() => {
            this.setOpenToast({
              title: `${message} sucessfully`,
              isSuccess: true
            });
            this.isComponentModalActive = false;
            this.isLoadingModal = false;
          })
          .catch(() => {
            this.setOpenToast({
              title: "Couldn't edit this offer. Please try again.",
              isSuccess: false
            });
            this.isComponentModalActive = false;
            this.isLoadingModal = false;
          });
      },
      async downloadData() {
        if (this.hasPoll) {
          if (this.rawData.length > 0) {
            const csv = new ObjectsToCsv(this.rawData);
            this.rawDataCsv = await csv.toString();
            this.triggerDownload(this.rawDataCsv, 'offer-raw-data');
          } else {
            this.setOpenToast({
              title: this.$t('offers_dashboard.csv_no_data'),
              isSuccess: true
            });
          }
        } else {
          if (this.decisionsRawData.length > 0) {
            const csv = new ObjectsToCsv(this.decisionsRawData);
            this.decisionsRawDataCsv = await csv.toString();
            this.triggerDownload(this.decisionsRawDataCsv, 'offer-decision-raw-data');
          } else {
            this.setOpenToast({
              title: this.$t('offers_dashboard.csv_no_data'),
              isSuccess: true
            });
          }
        }
      },
      triggerDownload(csv, name) {
        let link = document.createElement('a');
        link.id = 'download-csv';
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', `${name}.csv`);
        document.body.appendChild(link);
        document.querySelector('#download-csv').click();
        document.body.removeChild(link);
      },

      /*
        |--------------------------------------------------------------------------
        | HELPERS
        |--------------------------------------------------------------------------
        */
      setTooltips() {
        if (this.$refs.archiveOffer) {
          tippy(this.$refs.archiveOffer, {
            maxWidth: 140,
            zIndex: 99999,
            placement: 'top',
            content: this.$t('offers_dashboard.archive_offer'),
          });
        }
        if (this.$refs.editOffer) {
          tippy(this.$refs.editOffer, {
            maxWidth: 140,
            zIndex: 99999,
            placement: 'top',
            content: this.$t('offers_dashboard.edit_offer'),
          });
        }
        if (this.$refs.playButton) {
          tippy(this.$refs.playButton, {
            maxWidth: 140,
            zIndex: 99999,
            placement: 'top',
            content:
              this.offer.status_id === 'OST8'
                ? this.$t('offers_dashboard.play_offer')
                : this.$t('offers_dashboard.pause_offer'),
          });
        }
      },
      isOfferAPoll() {
        return this.offer.interaction_type_id === 'INT6' || this.offer.interaction_type_id === 'INT7';
      },
      toLocalTimezone(datetime) {
        const zone = I18N2TZ(this.offer.country_id, 'locale');
        return DateTime.fromSQL(`${datetime} Z`, { zone })
          .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, { keepLocalTime: true })
          .toJSDate();
      },
      setDate(date) {
        if (date == null) {
          return;
        }
        const locales = {
          en: 'en-GB',
          pt: 'pt-PT',
          br: 'pt-BR',
        };

        return this.toLocalTimezone(date).toLocaleString(locales[this.$i18n.locale] || 'en-GB');
      },
      getHumanReadableStatus(status_id) {
        return SetOfferStatus(status_id);
      },
      getInteractionTypeIcon(id) {
        return SetInteractionTypeIcon(SetInteractionType(id));
      },
      getPercentage(chart, i) {
        const total = chart.data.reduce((a, b) => a + b, 0);
        if (total === 0) {
          return '0%';
        }
        const result = Math.round((chart.data[i] / total) * 100 * 10) / 10 || 0;
        return isFinite(result) ? `${result}%` : '0%';
      },
      getNotEvaluatedPercentage() {
        const result = Math.round((this.notEvaluated / this.totalAccepted) * 100 * 10) / 10 || 0;
        return isFinite(result) ? `${result}` : '0';
      },
      setAlphaColor(color, alpha) {
        const c = tinycolor(color);
        return c.setAlpha(alpha).toRgbString();
      },
      nFormatter(num, digits) {
        const si = [
          { value: 1, symbol: '' },
          { value: 1e3, symbol: 'k' },
          { value: 1e6, symbol: 'M' },
          { value: 1e9, symbol: 'G' },
          { value: 1e12, symbol: 'T' },
          { value: 1e15, symbol: 'P' },
          { value: 1e18, symbol: 'E' },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
          if (num >= si[i].value) {
            break;
          }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
      },
    },
  };
</script>

<style scoped lang="scss">
  .b-tooltip {
    transform: scale(0.8);
    z-index: 100;
    display: none;
    text-align: left;
  }

  .hover-for-tooltip:hover .b-tooltip {
    display: block;
  }
</style>
