export default class Utilities {
  /**
   * Returns a formatted currency string
   *
   */
  static currency(amount, currency = '€') {
    amount = this.setDecimalPlaces(amount, 2); // decimal places - value, decimal places
    amount = this.setCommaNotation(amount); // comma notation - value
    amount = currency + ' ' + amount; // define currency
    return amount;
  }

  /**
   * Applies comma notation to a given numeric value for better readability.
   *
   * @param {number} value - The numeric value to which comma notation will be applied.
   * @returns {string} The value with comma notation for better readability.
   */
  static setCommaNotation(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  /**
   * Sets the number of decimal places for a given numeric value and returns the formatted string.
   *
   * @param {number} value - The numeric value to be formatted.
   * @param {number} decimalValue - The number of decimal places to round the value to.
   * @returns {string} The formatted value with the specified number of decimal places.
   */
  static setDecimalPlaces(value, decimalValue) {
    return (Math.round(value * 100) / 100).toFixed(decimalValue);
  }

  static aggregate(data, keyFields, accumulators) {
    const createNewObj = (ref, fields) => {
      return fields.reduce((result, key) => {
        return Object.assign(result, { [key]: ref[key] });
      }, {});
    };

    return Object.values(
      data.reduce((result, object, index, ref) => {
        let key = keyFields.map((key) => object[key]).join('');
        let val = result[key] || createNewObj(object, keyFields);
        accumulators.forEach((accumulator) => {
          val[accumulator] = this.parseIfInteger(val[accumulator] || 0) + this.parseIfInteger(object[accumulator]);
        });
        return Object.assign(result, { [key]: val });
      }, {}),
    );
  }

  static parseIfInteger(n) {
    return this.isInteger(n) ? parseInt(n) : n;
  }

  static isInteger(n) {
    // src: https://stackoverflow.com/a/52223983/6009707
    return (n) => (n >= 1e21 ? true : n === (n | 0));
  }

  static toDecimalPlaces(num, decimalValue) {
    return (Math.round(num * 100) / 100).toFixed(decimalValue);
  }

  static numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  static trimStringCenter(string, trim = 1, replace = '') {
    return string.substring(0, trim) + replace + string.substring(string.length - trim);
  }

  static nFormatter(num, digits) {
    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
  }

  // Postback / GTM

  static addModid(url) {
    let formattedUrl = url;
    if (url) {
      if (!url.includes('modid={modid}')) {
        formattedUrl = `${url}${url.includes('?') ? '&' : '?'}modid={modid}`;
      }
    }
    return formattedUrl;
  }

  static replaceModid(url, id) {
    let formattedUrl = url;
    if (url) {
      if (url.includes('{modid}')) {
        formattedUrl = url.replaceAll('{modid}', id);
      }
    }
    return formattedUrl;
  }
}
