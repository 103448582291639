<template>
  <section class="portal">
    <modatta-sidenav page="campaigns"></modatta-sidenav>
    <main>
      <div class="campaigns components-wrapper">
        <modatta-side-modal
          v-if="openSideModal"
          :content="selectedOption.name"
          :title="selectedOption.title"
          :options="selectedOption.options"
        ></modatta-side-modal>
        <modatta-navbar v-bind:title="$t('offers.title')" :has-icon="{ name: 'campaigns' }"></modatta-navbar>
        <div class="components">
          <div class="component main-table-header">
            <div class="search">
              <div class="filter">
                <b-dropdown aria-role="list">
                  <template #trigger>
                    <button class="button is-dropdown">
                      {{ $t('offers.' + activeFilter.name) }}
                    </button>
                  </template>

                  <b-dropdown-item
                    aria-role="listitem"
                    v-for="(option, index) in filterOptions"
                    :key="index"
                    @click="filterData(option)"
                    >{{ $t('offers.' + option.name) }}</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div class="input-wrapper">
                <b-field>
                  <b-input v-model="query" v-bind:placeholder="$t('offers.search')" type="search"></b-input>
                </b-field>
                <modatta-icon icon="magnifier"></modatta-icon>
              </div>
            </div>
            <b-button type="is-primary" @click="selectOption('campaign', $t('offers_step0.title'), {})">{{
              $t('offers.button_label')
            }}</b-button>
          </div>
          <div v-if="isLoading" class="loading component main-table">
            <div class="is-loading loading-row" v-for="(row, index) in 7" :key="index"></div>
          </div>
          <div v-else class="component main-table">
            <b-table
              :data="filteredData"
              :sticky-header="true"
              checkbox-position="left"
              :selected.sync="selected"
              :checked-rows.sync="checkedRows"
            >
              <b-table-column field="name" v-bind:label="$t('offers.table_col_1')" v-slot="props">
                <div class="name">
                  <div>
                    <span class="is-xs">{{ shortenOfferId(props.row.id) }}</span
                    ><br />
                    <span class="t-row-name">{{ props.row.name }}</span>
                    <span class="is-xs">{{ getOfferType(props.row) }}</span>
                  </div>
                </div>
              </b-table-column>
              <b-table-column field="brand" v-bind:label="$t('offers.table_col_2')" v-slot="props">
                <div class="brand">
                  <div v-text="props.row.brand != null ? props.row.brand.name : '-'"></div>
                </div>
              </b-table-column>
              <b-table-column field="online" v-bind:label="$t('offers.table_col_5')" v-slot="props">
                <div class="online" v-text="props.row.online_on != null ? setDate(props.row.online_on) : '-'"></div>
              </b-table-column>
              <b-table-column field="offline" v-bind:label="$t('offers.table_col_6')" v-slot="props">
                <div class="offline" v-text="props.row.offline_on != null ? setDate(props.row.offline_on) : '-'"></div>
              </b-table-column>
              <b-table-column field="completed" v-bind:label="$t('offers.table_col_4')" v-slot="props">
                <div class="completed">{{ props.row.completed_total || '-' }}</div>
              </b-table-column>
              <b-table-column field="cost" v-bind:label="$t('offers.table_col_7')" v-slot="props">
                <div class="cost">{{ $t(`labels.${props.row.currency_id}`) }} {{ getTotalCost(props) }}</div>
              </b-table-column>
              <b-table-column field="status" v-bind:label="$t('offers.table_col_8')" v-slot="props">
                <div class="status">
                  <div
                    class="tag"
                    :class="{
                      'is-yellow': props.row.status_id === 'OST1',
                      'is-green': props.row.status_id === 'OST4' || props.row.status_id === 'OST5',
                      'is-red': props.row.status_id === 'OST7' || props.row.status_id === 'OST9',
                      'is-blue': props.row.status_id === 'OST3' || props.row.status_id === 'OST8',
                      'is-gray':
                        props.row.status_id === 'OST2' || props.row.status_id === 'OST6' || props.row.status_id == null,
                    }"
                  >
                    {{ $t('offers.' + getHumanReadableStatus(props.row.status_id)) }}
                  </div>
                </div>
              </b-table-column>
              <b-table-column field="actions" label="" width="40" v-slot="props">
                <div class="actions">
                  <b-dropdown aria-role="list" class="user-info" position="is-bottom-left" @click.native.stop="">
                    <template #trigger>
                      <div class="button p-0">
                        <modatta-icon icon="vertical-dots"></modatta-icon>
                      </div>
                    </template>

                    <b-dropdown-item
                      :key="index"
                      aria-role="listitem"
                      v-for="(action, index) in rowActions"
                      @click="handleActionClick(action, props.row.id)"
                      :class="{
                        hide:
                          (props.row.status_id !== 'OST5' &&
                            props.row.status_id !== 'OST8' &&
                            action.action === 'archive') ||
                          (props.row.status_id !== 'OST3' && action.action === 'edit'),
                      }"
                    >
                      <modatta-icon :icon="action.icon"></modatta-icon>
                      {{ $t('offers.' + action.name) }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-table-column>

              <template #empty>
                <div class="modatta-empty-screen">
                  <div class="empty-screen-card">
                    <div
                      class="illustration"
                      :style="{ 'background-image': 'url(/svg/decorations/empty-screen-offers.svg)' }"
                    ></div>
                    <div class="card-info">
                      <p>{{ $t('offer_insufficient_balance.description') }}</p>
                      <button class="button is-primary" @click="selectOption('campaign', $t('offers_step0.title'), {})">
                        {{ $t('offers_step0.title') }}
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </b-table>
            <b-loading :is-full-page="true" v-model="isActionLoading" :can-cancel="false">
              <div class="global-loading">
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </b-loading>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
  import Fuse from 'fuse.js';
  import { DateTime } from 'luxon';
  import Utilities from '../../helpers/utilities.js';
  import SetOfferType from '../../helpers/set-offer-type.js';
  import SetOfferStatus from '../../helpers/set-offer-status.js';
  import OfferTypesPriority from '../../helpers/offer-type-priority.js';
  import SetInteractionType from '../../helpers/set-interaction-type.js';
  import { i18n2tz as I18N2TZ } from '../../helpers/tz-i18n';
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
  import ModattaSidenav from '../../components/ModattaSidenav.vue';
  import ModattaNavbar from '../../components/ModattaNavbar.vue';
  import ModattaSideModal from '../../components/ModattaSideModal.vue';
  import ModattaIcon from '../../components/ModattaIcon.vue';

  export default {
    components: { ModattaSidenav, ModattaNavbar, ModattaSideModal, ModattaIcon },
    data() {
      return {
        isLoading: false,
        query: '',
        activeFilter: { id: 'OST5', name: 'menu_filter_6' },
        filterOptions: [
          { id: 'all', name: 'menu_filter_1' },
          { id: 'OST5', name: 'menu_filter_6' },
          { id: 'OST6', name: 'menu_filter_7' },
        ],
        rowActions: [
          { name: 'table_menu_1', icon: 'eye', action: 'details' },
          { name: 'table_menu_5', icon: 'edit', action: 'edit' },
          { name: 'table_menu_2', icon: 'duplicate', action: 'duplicate' },
          { name: 'table_menu_3', icon: 'archive', action: 'archive' },
          { name: 'table_menu_4', icon: 'delete', action: 'delete' },
        ],
        offers: this.$store.state.offer.offers,
        tableOffers: this.$store.state.offer.offers,
        selectedOption: {},
        selected: null,
        checkedRows: [],
        isActionLoading: false,
      };
    },
    mounted() {
      if (this.offer.offers.length === 0) {
        this.isLoading = true;

        this.getOffers().then(() => {
          this.offers = this.$store.state.offer.offers.filter((offer) => offer.status_id !== 'OST6');
          this.offers = this.offers.sort(function (a, b) {
            const nameA = a.status_id.toUpperCase(); // ignore upper and lowercase
            const nameB = b.status_id.toUpperCase(); // ignore upper and lowercase
            if (nameA !== 'OST5' || nameB !== 'OST5') {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          this.isLoading = false;
        });
      }
    },
    watch: {
      offers(newVal) {
        this.offers = newVal;
      },
      selected(newVal) {
        if (newVal === null) {
          return;
        }
        newVal.status_id === 'OST1'
          ? this.$router.replace({ path: `/offers/new-offer?offer=${newVal.id}` }) : null
          // : this.$router.replace({ path: `/offers/${newVal.id}` });
      },
    },
    computed: {
      ...mapState(['openSideModal', 'offer']),
      ...mapGetters(['getBrandById']),

      filteredData() {
        const sortedOffers = this.setOffersPriority();
        if (this.query === null || this.query == '') {
          return sortedOffers;
        }
        const fuse = new Fuse(sortedOffers, {
          threshold: 0.25,
          keys: ['name', 'brand.name'],
        });
        return fuse.search(this.query).map((item) => item.item);
      },
    },
    methods: {
      ...mapActions([
        'getOffers',
        'getOffer',
        'getBrand',
        'upsertOffer',
        'createOffer',
        'updateOfferStatus',
        'updateBannerFile',
        'deleteOffer',
      ]),
      ...mapMutations(['setOpenSideModal', 'setOpenToast']),
      getOrderedOffers() {
        this.isLoading = true;
        this.getOffers().then(() => {
          this.filterData(this.activeFilter);
          this.offers = this.offers.sort(function (a, b) {
            const nameA = a.status_id.toUpperCase(); // ignore upper and lowercase
            const nameB = b.status_id.toUpperCase(); // ignore upper and lowercase
            if (nameA !== 'OST5' || nameB !== 'OST5') {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          this.isLoading = false;
        });
      },

      filterOffersByStatus(offers, statusId) {
        offers.filter((offer) => offer.status_id !== 'OST6');
      },

      orderOffersBySatus(offers) {
        offers.sort(function (a, b) {
          const nameA = a.status_id.toUpperCase(),
            nameB = b.status_id.toUpperCase();
          if (nameA !== 'OST5' || nameB !== 'OST5') {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      },

      getTotalCost(props) {
        return props.row.cost_total ? Utilities.toDecimalPlaces(props.row.cost_total, 2) : '-';
      },
      getAverageCost(props) {
        return props.row.cost_avg ? Utilities.toDecimalPlaces(props.row.cost_avg, 2) : '-';
      },
      shortenOfferId(id) {
        return 'ID: ' + `${Utilities.trimStringCenter(id, 3, '...')}`;
      },

      /*********************************
      | SETUP
      **********************************/
      filterData(filter) {
        this.offers = this.$store.state.offer.offers;
        this.activeFilter = filter;
        if (filter.id === 'all') {
          return;
        }
        if (filter.id === 'OST5') {
          this.offers = this.offers.filter((offer) => offer.status_id !== 'OST6');
          return;
        }
        this.offers = this.offers.filter((offer) => offer.status_id === filter.id);
      },
      getBrandName(id) {
        return this.getBrand(id).then((r) => r.brand_name);
        //eturn (this.$store.state.brand.brands.length > 0) ? this.getBrandById(id).name : this.getBrand(id).then((r) => r.name);
      },
      selectOption(name, title, options) {
        this.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
      },
      setFilteredOffersPriority() {
        this.filterData(this.activeFilter);
        this.offers.sort((a, b) => {
          const A = a.status_id,
            B = b.status_id;
          return OfferTypesPriority().indexOf(A) > OfferTypesPriority().indexOf(B) ? 1 : -1;
        });
        return this.offers;
      },
      sortOffersByPriority(offers) {
        offers.sort((a, b) => {
          const A = a.status_id,
            B = b.status_id;
          return OfferTypesPriority().indexOf(A) > OfferTypesPriority().indexOf(B) ? 1 : -1;
        });
        return offers;
      },
      setOffersPriority() {
        this.offers.sort((a, b) => {
          const A = a.status_id,
            B = b.status_id;
          return OfferTypesPriority().indexOf(A) > OfferTypesPriority().indexOf(B) ? 1 : -1;
        });
        return this.offers;
      },
      /*********************************
      | HELPERS
      **********************************/
      getOfferType(offer) {
        return SetOfferType(offer.type_label_id);
      },
      handleActionClick(action, offerId) {
        switch (action.action) {
          case 'details':
            const offer = this.offers.find((o) => o.id === offerId);
            offer.status_id === 'OST1'
              ? this.$router.replace({ path: `/offers/new-offer?offer=${offer.id}` })
              : this.$router.replace({ path: `/offer-dashboard/${offer.id}` });
            break;
          case 'duplicate':
            this.duplicateOffer(offerId);
            break;
          case 'edit':
            const editingOffer = this.offers.find((o) => o.id === offerId);
            const editingOfferStatus = editingOffer.status_id;
            const p = { data: { status_id: 'OST1' }, id: offerId };
            this.updateOfferStatus(p);
            this.updateOfferStatusUi(offerId, 'OST1');
            if (editingOfferStatus === 'OST3') {
              this.$router.replace({ path: `/offers/new-offer?offer=${editingOffer.id}` });
            }
            break;
          case 'archive':
            const payload = { data: { status_id: 'OST6' }, id: offerId };
            this.updateOfferStatus(payload).then(() => {
              this.getOrderedOffers();
            });
            this.updateOfferStatusUi(offerId, 'OST6');
            break;
          case 'delete':
            this.isActionLoading = true;
            this.deleteOffer({ id: offerId })
              .then(() => {
                this.removeOffer(offerId);
                this.getOrderedOffers();
                this.isActionLoading = false;
              })
              .catch(() => {
                this.setOpenToast({
                  title: "Couldn't delete this offer",
                  isSuccess: false
                });
                this.isActionLoading = false;
              });
            break;
        }
      },
      duplicateOffer(id) {
        this.isActionLoading = true;
        let off = this.$store.state.offer.offers.find((o) => o.id === id);
        const brand = off.brand;
        this.getOffer(id).then((r) => {
          let offer = r.data.result;
          offer.status_id = 'OST1';
          const body = JSON.stringify(offer);
          const payload = { body, id: null };
          this.upsertOffer(payload).then((r) => {
            r.data.result.brand = brand;
            this.$store.state.offer.offers.unshift(r.data.result);
            this.offers.unshift(r.data.result);
            this.updateBannerImage(offer.banner_url, r.data.result.id);
            this.setOffersPriority();
            this.isActionLoading = false;
            this.getOrderedOffers();
          });
        });
      },
      updateBannerImage(bannerUrl, offerId) {
        const body = new FormData();
        const blob = this.getBlob(bannerUrl);
        blob.then((response) => {
          body.append('banner', response);
          const payload = { body, id: offerId };
          this.updateBannerFile(payload);
        });
      },
      updateOfferStatusUi(id, statusId) {
        let offer = this.offers.find((o) => o.id === id);
        this.$set(offer, 'status_id', statusId);
      },
      removeOffer(id) {
        const index = this.offers.findIndex((o) => o.id === id);
        this.offers.splice(index, 1);
        this.$store.state.offer.offers.splice(index, 1);
      },
      getPayloadBody(brand) {
        const body = new FormData();
        body.append('name', brand.name);
        body.append('company_nif', brand.company_nif);
        body.append('brand_logo', brand.brand_url);
        body.append('company_url', brand.company_url);
        body.append('company_name', brand.company_name);
        body.append('company_privacy_url', brand.company_privacy_url);
        body.append('activate', brand.status_id === 'BST1' ? false : true);
        return body;
      },
      async getBlob(url) {
        return await fetch(url).then((r) => r.blob());
      },

      // TODO: Utilities
      toLocalTimezone(datetime) {
        const offerZone = I18N2TZ(this.$store.state.user.user.country_id);
        return DateTime.fromSQL(datetime, { zone: 'utc' })
          .setZone(offerZone)
          .toFormat('dd/MM/yyyy HH:mm:ss')
          .toLocaleString();
      },
      // TODO: Utilities
      setDate(date) {
        // Caso seja null não pode apresentar a data, mesmo que esta não exista
        // porque está apenas a ser visualizada assim, pode não haver update
        date = this.toLocalTimezone(date);
        return date;
      },

      setInteractionType(id) {
        return SetInteractionType(id);
      },
      getHumanReadableStatus(status_id) {
        if (status_id == null) return 'menu_filter_4';
        return SetOfferStatus(status_id);
      },
    },
  };
</script>
