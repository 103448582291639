<template>
  <div class="modatta-side-modal-payment-methods">
    <div class="sidenav-container">
      <template v-if="options.type === 'transfer'">
        <h3>{{ $t('budget_cc.subtitle') }}</h3>
        <div class="bonus-packs">
          <div
            class="bonus"
            @mouseenter="enterPack(index)"
            @mouseleave="leavePack(index)"
            :class="{ 'is-popular': pack.isPopular }"
            v-for="(pack, index) in bonusPacks"
            :key="index"
          >
            <div v-if="pack.isPopular" class="popular-tag">Popular</div>
            <div v-if="pack.bonus != 0 && pack.bonus != null" class="bonus-info">Bonus {{ pack.bonus }}%</div>
            <div class="value">{{ $t(`labels.${pack.currency}`) }}{{ pack.value }}</div>
            <div class="available">
              {{ pack.value + pack.value * (pack.bonus / 100) }}
              {{ $t(`labels.${pack.currency}`) }}
              {{ $t('budget_cc.available') }}
            </div>
          </div>
        </div>
        <div class="benefits-table">
          <div class="table-title">
            <h3>{{ $t('budget_cc.on_average') }}</h3>
            <b-tooltip
              v-bind:label="$t('budget_cc.average_tooltip')"
              type="is-dark"
              position="is-top"
              size="is-small"
              multilined
            >
              <modatta-icon icon="info-square-light"></modatta-icon>
            </b-tooltip>
          </div>
          <div class="table-body">
            <div class="table-row" v-for="(item, index) in benefitsValues" :key="index">
              <div class="start">
                {{ $t('budget_cc.up_to') }}
                <span>{{ item.delta * hoveredPack.value }}</span>
                <span v-if="hoveredPack.bonus != 0 && hoveredPack.bonus != null" class="bonus-tag"
                  >+{{ item.delta * (hoveredPack.value * (hoveredPack.bonus / 100)) }} {{ $t('budget_cc.free') }}</span
                >
              </div>
              <div class="end">
                <div>{{ $t('budget_cc.' + item.name) }}</div>
                <div class="icon-wrapper">
                  <modatta-icon :icon="item.icon" height="18"></modatta-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bank-information">
          <h3>{{ $t('budget_cc.choose_value') }}</h3>
          <div class="title">
            <modatta-icon icon="bank"></modatta-icon>
            <h2>Bank Information</h2>
          </div>
          <div class="bank-details">
            <div class="name">Vivawallet</div>
            <div class="bank-info">- IBAN: PT50 6496589 740879835748 90</div>
            <div class="bank-info">- BIC (Swift): BBPIPTPL</div>
          </div>
        </div>
      </template>
      <template v-if="options.type === 'card'">
        <div class="forms cc">
          <div class="form amount">
            <div class="modatta-input modal-container amount-input">
              <p>{{ $t('forms.payment.cc.amount.label') }}</p>
              <div class="row">
                <div class="grow">
                  <div class="input-wrapper">
                    <input class="input" type="number" :placeholder="$t('forms.payment.cc.amount.placeholder.text')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="modatta-radio-input modal-container">
              <div class="field">
                <modatta-radio-input
                  :options="[
                    setAmount('50', 'CUR1', 0),
                    setAmount('100', 'CUR1', 0),
                    setAmount('500', 'CUR1', 0),
                    setAmount('1000', 'CUR1', 0),
                    setAmount('2000', 'CUR1', 0),
                  ]"
                  :min="'0.10'"
                  :step="0.25"
                  :has-other="true"
                >
                </modatta-radio-input>
              </div>
            </div>
          </div>
          <hr />
          <div class="form payment-details">
            <div class="modatta-form modal-container">
              <div class="row form-header">
                <div class="col">
                  <p class="icon-before">{{ $t('forms.payment.cc.details.title') }}</p>
                </div>
              </div>
              <div class="form-body">
                <div class="row field">
                  <div class="input-wrapper">
                    <label>{{ $t('forms.payment.cc.details.name.label') }}*</label>
                    <input class="input" type="text" />
                  </div>
                </div>
                <div class="row field">
                  <div class="input-wrapper">
                    <label>{{ $t('forms.payment.cc.details.number.label') }}*</label>
                    <div class="icon-input card-visa">
                      <input class="input" type="text" />
                    </div>
                  </div>
                </div>
                <div class="row footer">
                  <div class="col">
                    <label>{{ $t('forms.payment.cc.details.expiration.label') }}*</label>
                    <div class="row">
                      <div class="input-wrapper">
                        <input class="input" type="text" />
                      </div>
                      <div class="input-wrapper">
                        <input class="input" type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <label>
                      <span>
                        {{ $t('forms.payment.cc.details.cvv.label') }}*
                        <b-tooltip
                          v-bind:label="$t('forms.payment.cc.details.cvv.tooltip')"
                          type="is-dark"
                          position="is-top"
                          size="is-small"
                          multilined
                        >
                          <modatta-icon icon="info-square-light"></modatta-icon>
                        </b-tooltip>
                      </span>
                    </label>
                    <div class="field input-wrapper">
                      <input class="input" type="text" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="form payment-settings">
            <div class="modatta-checkbox modal-container">
              <div class="row">
                <div class="field input-wrapper">
                  <b-checkbox name="automatic">{{ $t('forms.payment.cc.automatic.label') }}</b-checkbox>
                </div>
              </div>
            </div>
            <div class="modatta-conditional-input modal-container">
              <div class="row">
                <div class="field input-wrapper">
                  <div class="condition">
                    <span>{{ $t('forms.payment.cc.automatic.if.condition') }}</span>
                  </div>
                  <div>
                    <label>{{ $t('forms.payment.cc.automatic.if.label') }}</label>
                    <input
                      class="input"
                      type="text"
                      maxlength="4"
                      value=""
                      :placeholder="$t('forms.payment.cc.automatic.if.placeholder')"
                    />
                  </div>
                </div>
                <div class="field input-wrapper">
                  <div class="condition">
                    <span>{{ $t('forms.payment.cc.automatic.buy.condition') }}</span>
                  </div>
                  <div>
                    <label>{{ $t('forms.payment.cc.automatic.buy.label') }}</label>
                    <input
                      class="input"
                      type="text"
                      maxlength="6"
                      value=""
                      :placeholder="$t('forms.payment.cc.automatic.buy.placeholder')"
                    />
                  </div>
                </div>
                <div class="field button-wrapper">
                  <button class="button is-primary">
                    <template>OK</template>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="form submit">
            <p>{{ $t('forms.payment.cc.automatic.description') }}</p>
            <button class="button is-primary">
              <template>Pay</template>
            </button>
          </div>
        </div>
      </template>
      <template v-if="options.type === 'pix'">
        <modatta-side-modal-payment-pix :options="pixOptions"></modatta-side-modal-payment-pix>
      </template>
      <template v-if="options.type === 'voucher'">
        <div class="forms voucher">
          <div class="form amount">
            <div class="modatta-input modal-container">
              <p>{{ form.code.options.text.label }}</p>
              <div class="row">
                <div class="grow">
                  <div class="input-wrapper" :class="{ error: !form.code.valid }">
                    <input
                      v-model="form.code.model"
                      :placeholder="form.code.options.text.placeholder"
                      class="input"
                      type="text"
                    />
                    <div class="message" :class="form.code.message" v-if="!form.code.valid">
                      <span>{{ form.code.message }}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <button class="button is-primary" @click="form.code.valid = !form.code.valid">
                    <template>{{ form.code.options.button.label }}</template>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Table Vouchers Management -->
          <div class="modal-table vouchers">
            <div class="modatta-input modal-container">
              <div class="row">
                <p>{{ tables.vouchers.header.title }}</p>
              </div>
              <div class="row table">
                <table class="vouchers">
                  <thead>
                    <tr>
                      <th v-for="(label, index) in tables.vouchers.labels" :key="index">
                        {{ $t(`tables.vouchers.labels.` + label) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in tables.vouchers.data" :key="index">
                      <td>{{ row.name }}</td>
                      <td>
                        <strong class="chip">{{ row.code }}</strong>
                      </td>
                      <td>{{ setAmount(row.amount, 'CUR1', 0) }}</td>
                      <td>
                        <span class="status" :class="row.status.toLowerCase()">{{
                          $t('tables.vouchers.status.' + row.status)
                        }}</span>
                      </td>
                      <td>{{ setDate(row.expiration) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- end for: vouchers management-->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { DateTime } from 'luxon';
  import ModattaSideModalPaymentPix from './ModattaSideModalPaymentPix.vue';
  import ModattaIcon from './ModattaIcon.vue';
  import ModattaRadioInput from './ModattaRadioInput.vue';
  import Utilities from '../helpers/utilities.js';

  export default {
    components: { ModattaSideModalPaymentPix, ModattaIcon, ModattaRadioInput },
    props: ['options'],
    data() {
      return {
        benefitsValues: [
          { delta: 1, name: 'benefitsValues1', icon: 'globe' },
          { delta: 2, name: 'benefitsValues2', icon: 'video-camera' },
          { delta: 1, name: 'benefitsValues3', icon: 'document-outline' },
          { delta: 1, name: 'benefitsValues4', icon: 'users-group' },
          { delta: 1, name: 'benefitsValues5', icon: 'bars' },
          { delta: 1, name: 'benefitsValues6', icon: 'profile-circle' },
        ],
        availableBonusPacks: [
          {
            value: 500,
            currency: 'CUR1',
            bonus: this.calcBonus(500),
            isPopular: false,
            isHovered: false,
          },
          {
            value: 2500,
            currency: 'CUR1',
            bonus: this.calcBonus(2500),
            isPopular: true,
            isHovered: true,
          },
          {
            value: 5000,
            currency: 'CUR1',
            bonus: this.calcBonus(5000),
            isPopular: false,
            isHovered: false,
          },
          {
            value: 500,
            currency: 'CUR2',
            bonus: this.calcBonus(500),
            isPopular: false,
            isHovered: false,
          },
          {
            value: 5000,
            currency: 'CUR2',
            bonus: this.calcBonus(5000),
            isPopular: true,
            isHovered: true,
          },
        ],
        selectedPack: {},
        customValue: '',
        validValue: null,
        tables: {
          vouchers: {
            header: {
              title: this.$t('modals.payment.vouchers.tables.title'),
            },
            labels: ['name', 'code', 'amount', 'status', 'expiration'],
            data: [
              {
                name: 'Company X',
                code: 'XADXFUG9',
                amount: 7,
                status: 'Active',
                expiration: '24-02-2022',
              },
              {
                name: 'Company Y',
                code: 'YBDYGUH8',
                amount: 6,
                status: 'Pending',
                expiration: '28-03-2023',
              },
              {
                name: 'Company Z',
                code: 'ZCDZMHI5',
                amount: 9,
                status: 'Expired',
                expiration: '31-12-2021',
              },
              {
                name: 'Company A',
                code: 'ADEFJKL4',
                amount: 5,
                status: 'Active',
                expiration: '15-06-2024',
              },
            ],
          },
        },

        form: {
          code: {
            model: '',
            valid: true,
            message: this.$t('forms.vouchers.voucher.error'),
            options: {
              text: {
                placeholder: this.$t('forms.vouchers.voucher.placeholder'),
                label: this.$t('forms.vouchers.voucher.labels.text'),
                available: true,
              },
              button: {
                label: this.$t('forms.vouchers.voucher.labels.button'),
                available: true,
              },
            },
          },
        },

        pixOptions: {},
      };
    },
    mounted() {
      this.selectedPack = this.bonusPacks.find((bp) => bp.isPopular) || {};
    },
    watch: {
      customValue(newVal) {
        this.selectedPack = {
          value: newVal,
          bonus: this.calcBonus(newVal),
          currency: this.currCurrencyId,
        };
      },
    },
    computed: {
      ...mapState({
        currCurrencyId: (state) => state.user.user.balance.currency,
      }),
      bonusPacks() {
        return this.availableBonusPacks.filter((avbp) => avbp.currency == this.currCurrencyId);
      },
      hoveredPack() {
        return this.bonusPacks.find((b) => b.isHovered) || {};
      },
    },
    methods: {
      filterInput(evt, chars) {
        if (!chars.includes(evt.key)) {
          evt.preventDefault();
        }
      },
      handleBlur(evt) {
        if (!Number.isSafeInteger(this.customValue)) {
          this.customValue = Number(this.customValue).toFixed(0);
        }
      },
      selectPack(pack, i) {
        this.selectedPack = pack;
        this.bonusPacks.forEach((i) => (i.isHovered = false));
        this.$set(this.bonusPacks[i], 'isHovered', true);
      },
      enterPack(i) {
        this.bonusPacks.forEach((i) => (i.isHovered = false));
        this.$set(this.bonusPacks[i], 'isHovered', true);
      },
      leavePack(i) {
        this.$set(this.bonusPacks[i], 'isHovered', false);
        this.$set(this.bonusPacks[1], 'isHovered', true);
      },
      calcBonus(value) {
        if (value >= 5000) {
          return 8;
        }
        if (value >= 2500) {
          return 5;
        }
        return 0;
      },

      setTranslation(value) {
        return this.$t(`labels.${value}`);
      },

      setAmount(value, currency, decimal = 2) {
        if (value && !isNaN(value)) {
          return this.$t(`labels.${currency}`) + Utilities.numberWithCommas(Utilities.toDecimalPlaces(value, decimal));
        }
      },

      setPercentage(value, decimal = 0) {
        if (value && !isNaN(value)) {
          return Utilities.numberWithCommas(Utilities.toDecimalPlaces(value * 100, decimal)) + ' %';
        }
      },

      setDate(date) {
        return DateTime.fromFormat(date, 'dd-MM-yyyy').toLocaleString(DateTime.DATE_SHORT);
      },

      setUpperCase(value) {
        return value.toString().toUpperCase();
      },
    },
  };
</script>
