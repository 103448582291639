<template>
  <div class="modatta-stripe-payment">
    <template v-if="pack.value < minimumValue">{{ $t('budget_cc.minimum_value', [minimumValue]) }}</template>
    <template v-else>
      <div class="custom-value vat-input">
        <h3>{{ $t('budget_cc.add_vat_number') }}</h3>
        <div class="input-wrapper">
          <input type="text" v-model="vat" />
        </div>
      </div>
      <modatta-credit-card :pack="pack" :vat="vat"></modatta-credit-card>
    </template>
  </div>
</template>

<script>
  import ModattaCreditCard from './ModattaCreditCard.vue';

  export default {
    components: { ModattaCreditCard },
    props: ['pack'],
    data() {
      return {
        vat: this.$store.state.user.user.company_vat || this.$store.state.user.user.personal_vat,
        minimumValue: 50,
      };
    },
    mounted() {},
    methods: {},
  };
</script>
