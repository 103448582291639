var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modatta-side-modal-new-brand"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.callAPI()}}},[_c('b-field',{attrs:{"label":_vm.$t('brands_edit.brand_name_title')}},[_c('div',{staticClass:"input-wrapper",class:{
          'has-error': _vm.$v.form.name.$error,
          'is-valid': !_vm.$v.form.name.$invalid && !_vm.isDisabled,
        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.name.$model),expression:"$v.form.name.$model"}],attrs:{"maxlength":"255","id":"brand-name","type":"text","disabled":_vm.isDisabled},domProps:{"value":(_vm.$v.form.name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.name, "$model", $event.target.value)}}}),(_vm.$v.form.name.$error)?_c('modatta-icon',{attrs:{"icon":"error-exclamation-point","width":"20"}}):_vm._e(),(!_vm.$v.form.name.$invalid && !_vm.isDisabled)?_c('modatta-icon',{attrs:{"icon":"checkmark-green","width":"18"}}):_vm._e()],1)]),_c('modatta-upload-logo-file',{attrs:{"logo-url":_vm.options.type === 'edit' ? _vm.form.logo : null,"disabled":_vm.isDisabled}}),_c('hr'),_c('b-field',{attrs:{"label":_vm.$t('brands_edit.company_name_title')}},[_c('div',{staticClass:"input-wrapper",class:{
          'has-error': _vm.$v.form.company.$error,
          'is-valid': !_vm.$v.form.company.$invalid && !_vm.isDisabled,
        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.company.$model),expression:"$v.form.company.$model"}],attrs:{"maxlength":"255","type":"text","disabled":_vm.isDisabled},domProps:{"value":(_vm.$v.form.company.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.company, "$model", $event.target.value)}}}),(_vm.$v.form.company.$error)?_c('modatta-icon',{attrs:{"icon":"error-exclamation-point","width":"20"}}):_vm._e(),(!_vm.$v.form.company.$invalid && !_vm.isDisabled)?_c('modatta-icon',{attrs:{"icon":"checkmark-green","width":"18"}}):_vm._e()],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('brands_edit.company_web_title'))+" "),_c('b-tooltip',{attrs:{"label":_vm.$t('brands_edit.company_web_tooltip'),"type":"is-dark","position":"is-top","size":"is-small","multilined":""}},[_c('modatta-icon',{attrs:{"icon":"info-square"}})],1)],1),_c('div',{staticClass:"input-wrapper",class:{
          'has-error': _vm.$v.form.website.$error,
          'is-valid': !_vm.$v.form.website.$invalid && !_vm.isDisabled,
        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.website.$model),expression:"$v.form.website.$model"}],attrs:{"maxlength":"255","type":"text","disabled":_vm.isDisabled},domProps:{"value":(_vm.$v.form.website.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.website, "$model", $event.target.value)}}}),(_vm.$v.form.website.$error)?_c('modatta-icon',{attrs:{"icon":"error-exclamation-point","width":"20"}}):_vm._e(),(!_vm.$v.form.website.$invalid && !_vm.isDisabled)?_c('modatta-icon',{attrs:{"icon":"checkmark-green","width":"18"}}):_vm._e()],1),(_vm.$v.form.website.$error)?_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_vm.$t('brands_edit.url_error'))+" ")]):_vm._e()]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('brands_edit.company_privacy_title'))+" "),_c('b-tooltip',{attrs:{"label":_vm.$t('brands_edit.company_privacy_tooltip'),"type":"is-dark","position":"is-top","size":"is-small","multilined":""}},[_c('modatta-icon',{attrs:{"icon":"info-square"}})],1)],1),_c('div',{staticClass:"input-wrapper",class:{
          'has-error': _vm.$v.form.privacy.$error,
          'is-valid': !_vm.$v.form.privacy.$invalid && !_vm.isDisabled,
        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.privacy.$model),expression:"$v.form.privacy.$model"}],attrs:{"maxlength":"255","type":"text","disabled":_vm.isDisabled},domProps:{"value":(_vm.$v.form.privacy.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.privacy, "$model", $event.target.value)}}}),(_vm.$v.form.privacy.$error)?_c('modatta-icon',{attrs:{"icon":"error-exclamation-point","width":"20"}}):_vm._e(),(!_vm.$v.form.privacy.$invalid && !_vm.isDisabled)?_c('modatta-icon',{attrs:{"icon":"checkmark-green","width":"18"}}):_vm._e()],1),(_vm.$v.form.privacy.$error)?_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_vm.$t('brands_edit.url_error'))+" ")]):_vm._e()]),(!_vm.isDisabled)?_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-primary",attrs:{"type":"Submit","disabled":_vm.isLoading.hasLoading || _vm.$v.form.$invalid || _vm.form.logo == null}},[(!_vm.isLoading.save)?[_vm._v(_vm._s(_vm.$t('brands_edit.button_save_label')))]:_c('modatta-icon',{staticClass:"rotating",attrs:{"icon":"loading"}})],2)]):_vm._e()],1),_c('div',{staticClass:"modatta-side-modal-select-logo",class:{ open: _vm.isSelectLogoOpen }},[_c('div',{staticClass:"modal-wrapper"},[_c('header',[_c('button',{staticClass:"close button",on:{"click":function($event){_vm.isSelectLogoOpen = false}}},[_c('modatta-icon',{attrs:{"icon":"arrow-left-primary"}})],1),_c('div',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t('brands_edit_logo.title')))])]),_c('div',{staticClass:"logos-wrapper"},[_c('p',[_vm._v(_vm._s(_vm.$t('brands_edit_logo.description')))]),_c('div',{staticClass:"logos"},_vm._l((_vm.logos),function(logo,index){return _c('div',{key:index,staticClass:"logo",class:{ selected: logo === _vm.selectedLogo },style:({
              backgroundImage: `url('/svg/generic-logos/${logo.icon}.svg')`,
            }),on:{"click":function($event){return _vm.selectLogo(logo)}}})}),0),(_vm.selectedLogo != null)?_c('button',{staticClass:"button is-primary",on:{"click":function($event){return _vm.saveLogo()}}},[_vm._v("Save")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }