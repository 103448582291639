import api from '../api/analytic_api';
import SetInteractionTypeIcon from '../helpers/set-interaction-type-icon.js';

const state = {
  // Home Dashboard
  brands: [],
  interactions: null,
  activeOffers: null,
  totalInvested: null,
  totalGenerated: null,
  totalDelivered: null,
  totalAccepted: null,
  totalDeclined: null,
  acceptedData: null,
  declinedData: null,
  notEvaluatedData: null,

  // ---- Funnel Data ---- //
  funnelData: null,
  brandFunnelData: null,
  offerFunnelData: null,

  // Brand Dashboard
  brandOffers: [],
  brandActiveOffers: null,
  brandTotalInvested: null,
  brandTotalGenerated: null,
  brandTotalDelivered: null,
  brandTotalAccepted: null,
  brandTotalDeclined: null,
  brandAcceptedData: null,
  brandDeclinedData: null,
  brandNotEvaluatedData: null,

  // Offer Dashboard
  answersList: [],
};

const getters = {};

const actions = {
  getBrandsAndOffers({ commit }, id) {
    return api
      .getBrandsAndOffers(id)
      .then((response) =>
        id == null ? commit('setBrandsAndOffers', response.data) : commit('setBrandOffers', response.data),
      );
  },
  getActiveOffers({ commit }, id) {
    return api
      .getActiveOffers(id)
      .then((response) =>
        id == null ? commit('setActiveOffers', response.data) : commit('setBrandActiveOffers', response.data),
      );
  },
  getTotalInvested({ commit }, id) {
    return api
      .getTotalInvested(id)
      .then((response) =>
        id == null ? commit('setTotalInvested', response.data) : commit('setBrandTotalInvested', response.data),
      );
  },
  getInteractions({ commit }) {
    return api.getInteractions().then((response) => commit('setInteractions', response.data));
  },
  getTotalDelivered({ commit }, id) {
    return api
      .getTotalDelivered(id)
      .then((response) =>
        id == null ? commit('setTotalDelivered', response.data) : commit('setBrandTotalDelivered', response.data),
      );
  },
  getAcceptedDecisions({ commit }, id) {
    return api.getAcceptedDecisions(id).then((response) => {
      if (id == null) {
        commit('setTotalAccepted', response.data);
        commit('setAcceptedData', response.data);
      } else {
        commit('setBrandTotalAccepted', response.data);
        commit('setBrandAcceptedData', response.data);
      }
    });
  },
  getDeclinedDecisions({ commit }, id) {
    return api.getDeclinedDecisions(id).then((response) => {
      if (id == null) {
        commit('setTotalDeclined', response.data);
        commit('setDeclinedData', response.data);
      } else {
        commit('setBrandTotalDeclined', response.data);
        commit('setBrandDeclinedData', response.data);
      }
    });
  },

  async getAnswersList({ commit }) {
    return await api.getAnswersList().then((response) => commit('setAnswersList', response.data));
  },

  // FUNNEL
  async getFunnelData({ commit }, data) {
    const result = await api.getFunnelData(data);
    const payload = { data: result.data.result, type: data.type };
    commit('setFunnelData', payload);
    return payload;
  },
};

const mutations = {
  // Home Dashboard
  setBrandsAndOffers(state, payload) {
    state.brands = payload.result;
  },
  setActiveOffers(state, payload) {
    state.activeOffers = payload.result;
  },
  setTotalInvested(state, payload) {
    state.totalInvested = payload.invested;
    state.totalGenerated = payload.business;
  },
  setInteractions(state, payload) {
    const entries = Object.entries(payload.result);
    const interactions = [];
    entries.forEach((i) => {
      let interaction = {};
      interaction.icon = SetInteractionTypeIcon(i[0]);
      interaction.value = i[1].value;
      interaction.currency = i[1].currency;
      interactions.push(interaction);
    });
    state.interactions = interactions;
  },
  setTotalDelivered(state, payload) {
    state.totalDelivered = payload.result;
  },
  setTotalAccepted(state, payload) {
    state.totalAccepted = payload.result.total;
  },
  setAcceptedData(state, payload) {
    state.acceptedData = Object.values(payload.result.statistic).reverse();
    state.notEvaluatedData = payload.result.not_evaluated;
  },
  setTotalDeclined(state, payload) {
    state.totalDeclined = payload.result.total;
  },
  setDeclinedData(state, payload) {
    const result = payload.result.statistic;
    Object.keys(result).forEach((key) => {
      if (key === '1010') delete result[key]; // Don't show 1010 => expired offers
    });
    state.declinedData = Object.values(result).reverse();
  },

  // Brand Dashboard
  setBrandOffers(state, payload) {
    state.brandOffers = payload.result;
  },
  setBrandActiveOffers(state, payload) {
    state.brandActiveOffers = payload.result;
  },
  setBrandTotalInvested(state, payload) {
    state.brandTotalInvested = payload.invested;
    state.brandTotalGenerated = payload.business;
  },
  setBrandTotalDelivered(state, payload) {
    state.brandTotalDelivered = payload.result;
  },
  setBrandTotalAccepted(state, payload) {
    state.brandTotalAccepted = payload.result.total;
  },
  setBrandAcceptedData(state, payload) {
    state.brandAcceptedData = Object.values(payload.result.statistic).reverse();
    state.brandNotEvaluatedData = payload.result.not_evaluated;
  },
  setBrandTotalDeclined(state, payload) {
    state.brandTotalDeclined = payload.result.total;
  },
  setBrandDeclinedData(state, payload) {
    state.brandDeclinedData = Object.values(payload.result.statistic).reverse();
  },
  setAnswersList(state, payload) {
    state.answersList = payload.result;
  },
  setFunnelData(state, payload) {
    switch (payload.type) {
      case 'brand':
        state.brandFunnelData = payload.data;
        break;
      case 'offer':
        state.offerFunnelData = payload.data;
        break;
      default:
        state.funnelData = payload.data;
        break;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
