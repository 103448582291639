<template>
  <div class="new-offer-summary">
    <template v-if="status === null">
      <div class="grid">
        <div class="offer-summary">
          <div class="intro">
            <h2>{{ $t('offers_details.title') }}</h2>
            <p>{{ $t('offers_details.subtitle') }}</p>
          </div>
          <div class="steps">
            <div class="step brand">
              <modatta-new-offer-summary-step-header
                :title="$t('offers_details.section_1_title')"
                :offer="$parent.newOffer"
                :is-step-completed="$parent.isBrandStepCompleted"
                @current-step="(step) => ($parent.currentStep = step)"
                :step-id="1"
              ></modatta-new-offer-summary-step-header>
              <div v-if="brand" class="main-content">
                <div class="logo-container">
                  <img v-if="brand.logo_url" :src="brand.logo_url" :alt="`${brand.name} logo`" />
                </div>
                <div class="brand-name">{{ brand.name }}</div>
              </div>
            </div>

            <div class="step audience">
              <modatta-new-offer-summary-step-header
                :title="$t('offers_details.section_2_title')"
                :offer="$parent.newOffer"
                :is-step-completed="$parent.isAudienceStepCompleted"
                @current-step="(step) => ($parent.currentStep = step)"
                :step-id="2"
              ></modatta-new-offer-summary-step-header>
              <div class="main-content">
                <div v-if="filters.length === 0" class="tag is-green">
                  {{ $t('offers_details.section_2_no_filters') }}
                </div>

                <b-field
                  v-else
                  :label="$t('offers_details.section_2_target_group') + ' ' + parseInt(i + 1)"
                  v-for="(target, i) in resume_filters"
                  :key="i"
                >
                  <div class="targets-wrapper" v-if="target.options.length > 0 && group.filters.length > 0">
                    <div class="group-wrapper" v-for="(group, i) in target.options" :key="i">
                      <!-- translated group name -->
                      <span class="group-name" v-if="group.filters.length > 0">{{
                        $t('filters.groups.' + group.id)
                      }}</span>
                      <!-- Profile -->
                      <div v-if="group.id === 'profile' && group.filters.length > 0">
                        <div class="filter-wrapper" v-for="(filter, i) in group.filters" :key="i">
                          <span v-if="filter.profile_id === 'PRO7' && isNaN(new Date(filter.value))"
                            >{{ filter.value }} {{ $t('offers_step2_profile_filter.age') }}</span
                          >
                          <span v-else-if="filter.profile_id === 'PRO7' && !isNaN(new Date(filter.value))">
                            {{ $t('offers_step2_profile_filter.birthyear') }} {{ $t('labels.' + filter.operator_id) }}
                            {{ filter.value }}</span
                          >
                          <span v-else>
                            <strong
                              v-text="
                                $te(`labels.${filter.profile_id}`)
                                  ? $t(`labels.${filter.profile_id}`)
                                  : showCorrectProfileLabel(filter)
                              "
                            >
                            </strong>
                            {{ $t(`labels.${filter.operator_id}`) }}
                            <span
                              v-text="$te(`labels.${filter.value_id}`) ? $t(`labels.${filter.value_id}`) : filter.value"
                            >
                            </span>
                          </span>
                        </div>
                      </div>
                      <!-- Interest -->
                      <div v-if="group.id === 'interests' && group.filters.length > 0">
                        <div class="filter-wrapper" v-for="(filter, i) in group.filters" :key="i">
                          <span class="filter-name"
                            >{{ $t('labels.' + filter.profile_id) }} {{ $t('labels.' + filter.operator_id) }}
                            {{ filter.value.toLowerCase() === 'true' ? $t('labels.VAL4') : $t('labels.VAL3') }}</span
                          >
                        </div>
                      </div>
                      <!-- Offers -->
                      <div v-if="group.id === 'offers' && group.filters.length > 0">
                        <div class="filter-wrapper" v-for="(filter, i) in group.filters" :key="i">
                          <!-- Trimming offer ID - AAA...AAA -->
                          <span class="filter-name"
                            >ID
                            {{
                              filter.profile_id.substring(0, 3) +
                              '...' +
                              filter.profile_id.substring(filter.profile_id.length - 3)
                            }}
                            {{ $t('labels.' + filter.operator_id) }}</span
                          >
                        </div>
                      </div>
                      <!-- Question -->
                      <div v-if="group.id === 'question' && group.filters.length > 0">
                        <div class="filter-wrapper" v-for="(filter, i) in group.filters" :key="i">
                          <!-- Trimming offer ID - AAA...AAA -->
                          <span class="filter-name"
                            >ID
                            {{
                              filter.profile_id.substring(0, 3) +
                              '...' +
                              filter.profile_id.substring(filter.profile_id.length - 3)
                            }}
                            {{ $t('labels.' + filter.operator_id) }} {{ filter.value }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </b-field>
              </div>
            </div>
            <div class="step details">
              <modatta-new-offer-summary-step-header
                :title="$t('offers_details.section_3_title')"
                :offer="$parent.newOffer"
                :is-step-completed="$parent.isOfferStepCompleted"
                @current-step="(step) => ($parent.currentStep = step)"
                :step-id="3"
              ></modatta-new-offer-summary-step-header>
              <div class="main-content">
                <section>
                  <div class="offer-banner">
                    <img :src="$parent.newOffer.banner_url" :alt="`${$parent.newOffer.name} banner`" />
                  </div>
                  <div class="end">
                    <div class="offer-name">{{ $parent.newOffer.name }}</div>
                    <div class="offer-description">{{ $parent.newOffer.description }}</div>
                  </div>
                </section>
                <div v-if="!isOfferPollOrSurvey" class="offer-steps">
                  <div class="step" v-for="(step, index) in $parent.newOffer.steps" :key="index">
                    {{ step.description }}
                  </div>
                </div>
              </div>
            </div>
            <div class="step interaction">
              <modatta-new-offer-summary-step-header
                :title="$t('offers_details.section_4_title')"
                :offer="$parent.newOffer"
                :is-step-completed="$parent.isInteractionStepCompleted"
                @current-step="(step) => ($parent.currentStep = step)"
                :step-id="4"
              ></modatta-new-offer-summary-step-header>
              <div class="main-content">
                <div class="offer-type">
                  <div class="icon-wrapper">
                    <modatta-icon :icon="setOfferIcon($parent.newOffer)" height="20"></modatta-icon>
                  </div>
                  <div class="type">{{ $t(`labels.${$parent.newOffer.interaction_type_id}`) }}</div>
                </div>
                <div class="interaction-description">
                  <div v-if="$parent.newOffer.interaction_type_id === 'INT2'" class="watch-video">
                    <button class="button is-text" @click="handleOpenVideo">Abrir vídeo</button>
                  </div>
                  <div
                    v-else-if="$parent.newOffer.interaction_validation_id === 'ITV12'"
                    class="visit-website-online-task"
                  >
                    <p>URL: {{ JSON.parse($parent.newOffer.action).urlAction }}</p>
                    <p>Validation URL: {{ JSON.parse($parent.newOffer.action).urlValidation }}</p>
                  </div>
                  <div
                    v-else-if="
                      $parent.newOffer.interaction_validation_id === 'ITV6' ||
                      $parent.newOffer.interaction_validation_id === 'ITV4' ||
                      $parent.newOffer.interaction_type_id === 'INT3' ||
                      $parent.newOffer.interaction_type_id === 'INT4'
                    "
                    class="visit-website-online-task"
                  >
                    <b-field label="Website url">{{ $parent.newOffer.action }}</b-field>
                  </div>
                  <div
                    v-else-if="
                      $parent.newOffer.interaction_type_id === 'INT6' || $parent.newOffer.interaction_type_id === 'INT7'
                    "
                    class="survey-poll"
                  >
                    <div class="questions" v-for="(question, index) in $parent.newOffer.questions" :key="index">
                      <b-field :label="`Question ${index + 1} `">
                        <div class="question">{{ question.question }}</div>
                        <div class="answers">
                          <b-field label="Answers">
                            <div class="answer" v-for="(answer, i) in question.offer_answers" :key="i">
                              {{ i + 1 }}. {{ answer.answer }}
                            </div>
                          </b-field>
                        </div>
                      </b-field>
                    </div>
                  </div>
                  <div v-if="$parent.newOffer.interaction_type_id === 'INT10'" class="data-points-list">
                    <div class="data-points" v-for="(value, index) in JSON.parse($parent.newOffer.action)" :key="index">
                      <span>{{ index }}&nbsp;-&nbsp;</span>
                      <strong>{{ $t(`labels.${value}`) }}</strong>
                      <span>&nbsp;{{ $t('labels.OPE37') }}</span>
                    </div>
                  </div>
                  <div v-else>{{ $parent.newOffer.action }}</div>
                </div>
                <b-field v-bind:label="$t('offers_details.section_4_subtitle')">
                  <div class="validation-type">
                    <modatta-icon :icon="setValidationTypeIcon($parent.newOffer)"></modatta-icon>
                    <span>{{ setValidationType($parent.newOffer) }}</span>
                  </div>
                </b-field>
                <!-- Formatted -->
                <div class="validation-description">
                  <div v-if="$parent.newOffer.interaction_validation_id === 'ITV4'" class="post-back">
                    <p style="padding-bottom: 16px">
                      {{ $t('offers_details.itv4_text1') }}
                      <span>https://pb.modatta.org/external/pb/{modid}</span>
                      {{ $t('offers_details.itv4_text2') }}
                    </p>
                    <div v-if="$parent.isInteractionPostbackValidated" class="config">
                      <modatta-icon icon="green-checkmark"></modatta-icon>
                      <div>{{ $t('offers_details.itv4_correct') }}</div>
                    </div>
                    <div v-else class="config">
                      <modatta-icon icon="error-octagon"></modatta-icon>
                      <div>{{ $t('offers_details.itv4_incorrect') }}</div>
                    </div>
                  </div>
                  <div v-else-if="$parent.newOffer.interaction_validation_id === 'ITV6'" class="post-back">
                    <p>{{ $t('offers_details.itv6_text') }}</p>
                  </div>
                  <div v-else-if="$parent.newOffer.interaction_validation_id === 'ITV2'" class="codes">
                    <p>{{ $t('offers_details.itv2_text') }}</p>
                    <b-field label="Validation Code">{{ $parent.newOffer.validations_codes[0].code }}</b-field>
                  </div>
                  <div v-else-if="$parent.newOffer.interaction_validation_id === 'ITV3'" class="codes">
                    <p>{{ $t('offers_details.itv3_text') }}</p>
                    <b-field label="Validation Codes">
                      <div class="hide-codes-list hidden" @click="(e) => e.target.classList.toggle('hidden')">
                        <span v-for="(code, i) in $parent.newOffer.validations_codes" :key="i">
                          {{ code.code }}
                          <template v-if="$parent.newOffer.validations_codes.length != i + 1"> - </template>
                        </span>
                      </div>
                    </b-field>
                  </div>
                  <div v-else-if="$parent.newOffer.interaction_validation_id === 'ITV5'" class="codes">
                    <p>{{ $t('offers_details.itv5_text') }}</p>
                    <b-field label="">
                      <div id="qrcode" class="qr-code" :style="{ 'background-image': `url(${qrCode})` }"></div>
                    </b-field>
                  </div>
                  <div v-else-if="$parent.newOffer.interaction_validation_id === 'ITV12'">
                    <p>-</p>
                  </div>
                  <div v-else-if="$parent.newOffer.interaction_validation_id === 'ITV13'">
                    <p>-</p>
                  </div>
                  <div v-else>
                    <p>{{ $t('offers_details.itv1_text') }}</p>
                  </div>
                </div>
                <!-- Formatted -->
              </div>
            </div>
            <!-- Investimentos/Objetivo -->
            <div class="step budget">
              <modatta-new-offer-summary-step-header
                :title="$t('offers_details.section_6_title')"
                :offer="$parent.newOffer"
                :is-step-completed="$parent.isBudgetStepCompleted"
                @current-step="(step) => (this.$parent.currentStep = step)"
                :step-id="5"
              ></modatta-new-offer-summary-step-header>

              <div class="main-content">
                <section class="columns">
                  <div class="column">
                    <!-- INTERVALO -->
                    <div class="field">
                      <label class="label">{{ $t('offers_details.section_6_subtitle_interval') }}</label>
                      <div class="timeframe">
                        <div class="value">
                          {{ dateFormatter($parent.newOffer.online_on) }} -
                          {{ dateFormatter($parent.newOffer.offline_on) }}
                        </div>
                      </div>
                    </div>
                    <!-- INTERVALO -->
                  </div>
                  <div class="column">
                    <!-- INVESTIMENTO/INTERAÇÃO VALIDADA -->
                    <div class="field">
                      <label class="label">{{ $t('offers_details.section_6_subtitle_2') }}</label>
                      <div class="value">
                        {{ $t(`labels.${$parent.newOffer.currency_id}`) }}
                        {{ calcUnitCost }}
                      </div>
                    </div>
                    <!-- INVESTIMENTO/INTERAÇÃO VALIDADA -->
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">{{ $t('offers_details.section_6_subtitle_3') }}</label>
                      <div class="value">
                        {{ $parent.newOffer.max_accepts }}
                      </div>
                    </div>
                  </div>
                </section>
                <div class="columns price">
                  <div class="column is-one-third">
                    <div
                      v-if="
                        ($parent.newOffer.unit_tcost || $parent.newOffer.perc_tcost) && $parent.newOffer.max_accepts
                      "
                      class="final-cost"
                    >
                      <div class="value">
                        {{ $t(`labels.${$parent.newOffer.currency_id}`) }}
                        {{ calcEstimatedCost }}
                      </div>
                      <div class="text">{{ $t('offers_details.section_6_budget_label') }}</div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="value">
                      {{ $t(`labels.${$parent.newOffer.currency_id}`) }}
                      {{ calculateBusiness }}
                    </div>
                    <div class="text">{{ $t('offers_details.section_6_budget_business_value') }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Investimentos/Objetivo -->
          </div>
        </div>
        <div class="offer-preview">
          <h3>{{ $t('offers_details.preview') }}</h3>
          <modatta-new-offer-preview
            :offer="$parent.newOffer"
            :brand-id="$parent.newOffer.brand_id"
          ></modatta-new-offer-preview>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button class="button is-secondary" @click="handlePreviousClick()">
          <template>{{ $t('offers_step2.back_button_label') }}</template>
        </button>
        <button class="button is-tertiary" :disabled="$parent.isLoading" @click="saveDraft()">
          <template>{{ $t('offers_step6.box_button_draft_label') }}</template>
        </button>
        <button
          class="button is-primary"
          :disabled="
            !$parent.allStepsCompleted ||
            $parent.isLoading ||
            ($parent.newOffer.interaction_validation_id === 'ITV4' && !$parent.isInteractionPostbackValidated)
          "
          @click="submitOffer()"
        >
          <template>{{ $t('offers_step6.box_button_submit_label') }}</template>
        </button>
        <div class="icon-container">
          <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
        </div>
      </div>
    </template>
    <modatta-new-offer-step-success v-else-if="status === 'success'"></modatta-new-offer-step-success>
    <modatta-new-offer-step-error v-else-if="status === 'error'"></modatta-new-offer-step-error>
  </div>
</template>

<script>
  import QRCode from 'qrcode';
  import { DateTime } from 'luxon';
  import { mapActions } from 'vuex';
  import Utilities from '../../../../helpers/utilities.js';
  import Filters from '../../../../helpers/filters/filter-methods.js';
  import SetValidationType from '../../../../helpers/set-validation-type.js';
  import SetInteractionType from '../../../../helpers/set-interaction-type.js';
  import SetValidationTypeIcon from '../../../../helpers/set-validation-type-icon.js';
  import SetInteractionTypeIcon from '../../../../helpers/set-interaction-type-icon.js';
  import { i18n2tz as I18N2TZ } from '../../../../helpers/tz-i18n.js';
  import { v4 as uuidv4 } from 'uuid';
  import lodash from 'lodash';
  import ModattaIcon from '../../../../components/ModattaIcon.vue';
  import ModattaNewOfferSummaryStepHeader from './components/ModattaNewOfferSummaryStepHeader.vue';
  import ModattaNewOfferStepSuccess from './components/ModattaNewOfferStepSuccess.vue';
  import ModattaNewOfferStepError from './components/ModattaNewOfferStepError.vue';
  import ModattaNewOfferPreview from './components/ModattaNewOfferPreview.vue';

  export default {
    components: {
      ModattaNewOfferSummaryStepHeader,
      ModattaNewOfferStepSuccess,
      ModattaNewOfferStepError,
      ModattaIcon,
      ModattaNewOfferPreview,
    },
    props: ['status'],
    data() {
      return {
        brand: '',
        qrCode: null,
        offer: this.$parent.newOffer,
        resume_filters: '',
      };
    },
    mounted() {
      let tg = this.targets;
      let filters = Filters.setTargetGroups(tg);
      this.resume_filters = filters;

      this.getBrand(this.$parent.newOffer.brand_id).then((r) => (this.brand = r.data.result[0]));

      // ITV3 - CSV
      // ITV5 - QRCODE

      if (this.$parent.newOffer.interaction_validation_id === 'ITV5') {
        this.setupQrCode();
      }
    },
    computed: {
      targets() {
        return this.$parent.newOffer.grouped_filters;
      },
      filters() {
        return this.$parent.newOffer.grouped_filters.flat();
      },
      isOfferPollOrSurvey() {
        return this.$parent.newOffer.type_label_id === 'OFT18' || this.$parent.newOffer.type_label_id === 'OFT19';
      },
      calcUnitCost() {
        if (this.$parent.newOffer.unit_tcost > 0) {
          // Number().toFixed(2)
          return `${Utilities.numberWithCommas(Utilities.toDecimalPlaces(this.$parent.newOffer.unit_tcost, 2))}`;
        } else {
          const num = (this.$parent.newOffer.perc_tcost || 0) * 100;
          return (Math.round(num * 100) / 100).toFixed(2) + '%';
        }
      },
      calcEstimatedCost() {
        if (this.$parent.newOffer.unit_tcost > 0) {
          return (this.$parent.newOffer.unit_tcost * this.$parent.newOffer.max_accepts).toLocaleString();
        } else {
          return (
            this.$parent.newOffer.perc_tcost *
            this.$parent.newOffer.max_accepts *
            this.$parent.newOffer.business_value
          ).toLocaleString();
        }
      },
      calculateBusiness() {
        if (this.$parent.newOffer.business_value) {
          return (this.$parent.newOffer.max_accepts * this.$parent.newOffer.business_value).toLocaleString();
        } else {
          return (this.$parent.newOffer.max_accepts * this.$parent.newOffer.unit_tcost).toLocaleString();
        }
      },
    },
    methods: {
      ...mapActions(['getBrand']),
      handlePreviousClick() {
        this.$parent.currentStep--;
      },
      saveDraft() {
        // this.$parent.handleNextClick(6);
        this.$parent.saveAsDraft();
      },
      submitOffer() {
        this.$parent.submitNewOffer();
      },

      /*
		|--------------------------------------------------------------------------
		| HELPERS
		|--------------------------------------------------------------------------
		*/
      dateFormatter(newDate) {
        const offerZone = I18N2TZ(this.$parent.newOffer.country_id);
        let formatted = DateTime.fromSQL(`${newDate}Z`, { zone: offerZone }).toFormat('dd-MM-yyyy HH:mm');
        return formatted;
      },

      setupQrCode() {
        let code = null;
        if (
          !this.$parent.newOffer.validations_codes ||
          this.$parent.newOffer.validations_codes.lenght < 1 ||
          !this.$parent.newOffer.validations_codes[0].code
        ) {
          code = uuidv4();
        } else {
          code = this.$parent.newOffer.validations_codes[0].code;
        }

        QRCode.toDataURL(code).then((url) => {
          this.qrCode = url;
        });
      },

      showCorrectProfileLabel(filter) {
        if (filter.category === 'offers') {
          this.getOffer(filter.profile_id).then((r) => {
            const offer = r.data.result;
            return offer.name;
          });
        }
      },
      showCorrectOfferLabel(profile_id) {
        this.getOffer(profile_id).then((r) => {
          const offer = r.data.result;
          return offer.name;
        });
      },
      getHumanReadableProfile(filter) {
        return filter.profile_id === 'PRO7' ? 'Age' : this.$t(`labels.${filter.profile_id}`);
      },
      getHumanReadableValue(filter) {
        return filter.profile_id === 'PRO7' ? filter.value : this.$t(`labels.${filter.value}`);
      },
      setOfferIcon(offer) {
        return SetInteractionTypeIcon(SetInteractionType(offer.interaction_type_id));
      },
      setOfferType(offer) {
        return SetInteractionType(offer.interaction_type_id);
      },
      setValidationTypeIcon(offer) {
        return SetValidationTypeIcon(offer.interaction_validation_id);
      },
      setValidationType(offer) {
        return SetValidationType(offer.interaction_validation_id);
      },
      setReadableAgeProfileFilters(group) {
        const profileFilters = group.options[0].filters;
        const birthYearFilters = profileFilters.filter((filter) => filter.profile_id === 'PRO7');

        if (birthYearFilters.length > 1) {
          // 1. Create Age Filter
          let ageValue = '',
            minAge = '',
            maxAge = '';
          if (birthYearFilters.filter((filter) => filter.value_id).length > 0) {
            birthYearFilters.forEach((filter) => {
              ageValue = `${filter.value}`;
            });
          } else {
            birthYearFilters.forEach((filter) => {
              minAge = filter.operator_id === 'OPE8' ? parseInt(filter.value.split(',')[0]) : minAge;
              maxAge = filter.operator_id === 'OPE7' ? parseInt(filter.value.split(',')[1]) : maxAge;
              ageValue = `${minAge} - ${maxAge}`;
            });
          }
          const ageFilter = {
            group: birthYearFilters[0].group,
            category: birthYearFilters[0].category,
            profile_id: birthYearFilters[0].profile_id,
            operator_id: birthYearFilters[0].operator_id,
            value: ageValue,
          };
          // 2. Delete Birth Year Filters
          lodash.remove(profileFilters, (filter) => filter.profile_id === 'PRO7');
          // 3. Push Age Filter
          profileFilters.push(ageFilter);
        } else {
          // 1. Create Age Filter
          const ageFilter = {
            // Read only
            group: birthYearFilters[0].group,
            category: birthYearFilters[0].category,
            profile_id: birthYearFilters[0].profile_id,
            operator_id: birthYearFilters[0].operator_id,
            value: `${birthYearFilters.value}`,
          };
          // 2. Delete Birth Year Filters
          lodash.remove(profileFilters, (filter) => filter.profile_id === 'PRO7');
          // 3. Push Age Filter
          profileFilters.push(ageFilter);
        }
      },
      handleOpenVideo() {
        window.open(this.$parent.newOffer.action, '_blank');
      },
    },
  };
</script>

<style lang="scss">
  .data-points-list {
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
  }

  .data-points {
    display: flex;
    align-items: center;
    margin: 4px;
    padding: 4px 12px;
    min-height: 38px;
    font-size: 13px;
    border-radius: 6px;
    background: #e7edf9;

    .data-points-profile-label {
      margin-right: 4px;
    }
    .data-points-operator-label {
      margin-right: 4px;
    }
  }
</style>
