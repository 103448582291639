<template>
  <div class="modatta-side-modal-scroll-middle-area modatta-side-modal-create-audience">
    <div class="sidenav-container">
      <div class="basic-filters">
        <div class="filter">
          <b-field v-bind:label="$t('offers_step2_interest_filter.interest')">
            <div class="modatta-country-select">
              <multiselect
                v-bind:placeholder="$t('offers_step2_interest_filter.choose_interest')"
                selectLabel="ENTER to select"
                v-model="selectedInterest"
                openDirection="bottom"
                :options="interests"
                :allow-empty="false"
                :showLabels="false"
                track-by="name"
                label="name"
              >
                <span class="caret" slot="caret">
                  <modatta-icon icon="arrow-down" width="10"></modatta-icon>
                </span>
              </multiselect>
            </div>
          </b-field>
        </div>
        <div class="filter">
          <b-field v-bind:label="$t('offers_step2_interest_filter.operator')">
            <modatta-radio-input
              :options="operators"
              :has-other="false"
              @radio-value="(radio) => (this.selectedOperator = radio)"
              :suggested-value="selectedOperator"
            ></modatta-radio-input>
          </b-field>
        </div>
        <button
          class="button is-info"
          @click="addFilter()"
          :disabled="!allFieldsCompleted"
          style="margin-top: 0; min-width: 100px; height: 42px"
        >
          {{ $t('offers_step2.button_add_filter') }}
        </button>
      </div>
      <div v-if="filters.length > 0" class="selected-filters">
        <div class="filters-wrapper">
          <div class="filter-item" v-for="(filter, ind) in filters" :key="ind">
            <div class="concatenation">
              {{ $t('labels.' + filter.profile_id) }} <strong>{{ $t('labels.' + filter.value_id) }}</strong>
            </div>
            <button class="button" @click="deleteFilter(ind)">
              <modatta-icon icon="times" width="12"></modatta-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <button
        class="button is-primary"
        type="Submit"
        :disabled="filters.length < 1 || isLoading"
        @click="createFilters()"
      >
        <template v-if="!isLoading">{{ $t('offers_step2.create_filter') }}</template>
        <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
      </button>
      <button class="button is-text" @click="$parent.closeSideModal()" :disabled="isLoading">
        <template v-if="!isLoading">{{ $t('offers_step2.cancel_filter') }}</template>
        <template v-else>{{ $t('offers_step2.loading_filter') }}</template>
      </button>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapMutations } from 'vuex';
  import Multiselect from 'vue-multiselect';
  export default {
    props: ['options'],
    components: { Multiselect },
    data() {
      return {
        isLoading: false,

        newOptions: [...this.options.filters],

        // interests: ["INT1", "INT2", "INT3", "INT4"],
        selectedInterest: null,

        operators: [this.$t('labels.VAL4'), this.$t('labels.VAL3')],
        selectedOperator: null,
      };
    },
    mounted() {
      this.getInterests();
    },
    computed: {
      filters() {
        return this.newOptions;
      },
      interests() {
        let interests = this.$store.state.offer.interests.map((i) => {
          return { id: i.id, name: this.$t(`labels.${i.id}`) };
        });
        interests.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        return interests;
      },
      allFieldsCompleted() {
        return (
          this.selectedInterest != null &&
          this.selectedInterest != '' &&
          this.selectedOperator != null &&
          this.selectedOperator != ''
        );
      },
    },
    methods: {
      ...mapActions(['getInterests']),
      ...mapMutations(['upsertProfileNewOfferFilters']),

      createFilters() {
        const payload = { filters: this.filters, id: 'interests', targetId: this.options.targetGroupId };

        this.upsertProfileNewOfferFilters(payload);
        this.$parent.closeSideModal();
      },
      addFilter() {
        let filter = this.newOptions.find((f) => f.profile === this.selectedInterest.name);
        let selected = this.selectedOperator.toLowerCase();
        let isTrue = this.$t('labels.VAL4').toLowerCase();
        if (filter) {
          filter.operator = this.selectedOperator;
          filter.value = '';
          filter.value_id = this.selectedOperator === isTrue ? 'VAL4' : 'VAL3';
          return;
        }

        this.newOptions.push({
          profile: this.selectedInterest.name,
          profile_id: this.selectedInterest.id,
          operator: this.$t('labels.OPE2'),
          operator_id: 'OPE2',
          // (this.selectedOperator === "TRUE") TRUE : FALSE was causing it to be "FALSE", because:
          // the value selectedOperator was being transalted but not verified with the translation here.
          value: selected === isTrue ? 'TRUE' : 'FALSE',
          value_id: selected === isTrue ? 'VAL4' : 'VAL3',
          category: 'interests',
        });
        // FALSE/TRUE are valid ref values for VAL4/VAL3
        this.resetFilters();
      },
      deleteFilter(i) {
        this.newOptions.splice(i, 1);
      },
      resetFilters() {
        this.selectedInterest = null;
        this.selectedOperator = null;
      },
    },
  };
</script>
