import api from '../api/brand_api';

// Initial state
const state = {
  brands: [],
};

const getters = {
  getBrandById: (state) => (id) => {
    // https://vuex.vuejs.org/guide/getters.html#method-style-access
    return state.brands.find((brand) => brand.id === id);
  },
};

const actions = {
  // READ
  async getBrands({ commit }) {
    return await api.getBrands().then((response) => commit('setBrands', response.data));
  },
  async getBrand(_, id) {
    return await api.getBrand(id);
  },

  // CREATE
  async createBrand({ commit }, data) {
    return await api.createBrand(data);
    //.then((response) => commit('setBrand', response.data));
  },

  // UPDATE
  async updateBrand({ commit }, data) {
    return await api.updateBrand(data);
    //.then((response) => commit('updateBrand', response.data));
  },
  async archiveBrand({ commit }, data) {
    return await api.archiveBrand(data).then(() => commit('archiveBrand', data));
  },

  // DELETE
  async deleteBrand({ commit }, data) {
    return await api.deleteBrand(data).then(() => commit('removeBrand', data.id));
  },
};

const mutations = {
  setBrands(state, payload) {
    state.brands = payload.result.reverse();
  },

  setBrand(state, payload) {
    state.brands.push(payload.result);
  },
  updateBrand(state, payload) {
    let brand = state.brands.find((b) => b.id === payload.result.id);
    brand = payload.result;
  },
  archiveBrand(state, payload) {
    let brand = state.brands.find((b) => b.id == payload.id);
    brand.status_id = payload.status_id;
  },
  removeBrand(state, id) {
    const index = state.brands.findIndex((b) => b.id === id);
    state.brands.splice(index, 1);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
