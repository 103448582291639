import headers from './headers';

async function getBilling() {
  const URL = `${process.env.VUE_APP_API_URL}budgets/billing`;
  return await axios.get(URL, { headers });
}
async function getMovements() {
  const URL = `${process.env.VUE_APP_API_URL}budgets/movements`;
  return await axios.get(URL, { headers });
}
async function getCommittedBalance() {
  const URL = `${process.env.VUE_APP_API_URL}budgets/commited`;
  return await axios.get(URL, { headers });
}
async function getCheckoutSession(pack) {
  const URL = `${process.env.VUE_APP_API_URL}budgets/cashins/checkoutsession`;
  return await axios.post(URL, { pack }, { headers });
}
async function getCheckoutIntent(data) {
  const URL = `${process.env.VUE_APP_API_URL}budgets/cashins/checkoutintent`;
  return await axios.post(URL, data, { headers });
}

async function getPixTransaction(data) {
  const URL = `${process.env.VUE_APP_API_URL}budgets/cashins/pix`;
  return await axios.post(URL, data, { headers });
}

export default {
  getBilling: () => getBilling(),
  getMovements: () => getMovements(),
  getCommittedBalance: () => getCommittedBalance(),
  getCheckoutSession,
  getCheckoutIntent,
  getPixTransaction,
};
