<template>
  <div class="modatta-accordion-target-group">
    <div class="targets">
      <div class="target-wrapper" :class="{ 'is-only': targets.length === 1 }" v-for="(target, i) in targets" :key="i">
        <div v-if="target.isOpen && targets.length > 1" class="pre-header">
          <button class="button is-icon" @click="deleteTargetGroup(i)" ref="deleteTargetGroup">
            <modatta-icon icon="delete"></modatta-icon>
          </button>
        </div>
        <div class="target" :class="{ 'is-open': target.isOpen }">
          <div class="target-header" @click="target.isOpen = !target.isOpen">
            <div class="name">{{ target.name }}</div>
            <modatta-icon icon="arrow-down" width="12"></modatta-icon>
          </div>
          <div class="target-body">
            <div class="target-options" v-for="(option, index) in target.options" :key="index">
              <div class="option">
                <div class="start">
                  <modatta-icon :icon="option.icon"></modatta-icon>
                  <p>{{ option.name }}</p>
                </div>
                <button
                  v-if="option.filters.length === 0"
                  class="button is-yellow"
                  @click="selectSideModalOption(option, target)"
                >
                  {{ $t('offers_step2.button_add_filter') }}
                </button>
              </div>
              <div v-if="option.filters.length > 0" class="filters-wrapper">
                <div class="filters">
                  <div class="filter" v-for="(filter, ind) in option.filters" :key="ind">
                    <div class="concatenation">
                      <template v-if="filter.profile_id === 'PRO7'">
                        <strong v-show="isNaN(new Date(filter.value))"
                          >{{ $t('offers_step2_profile_filter.age') }}
                        </strong>
                        <strong v-show="!isNaN(new Date(filter.value))"
                          >{{ $t('offers_step2_profile_filter.birthyear') }}
                        </strong>
                        <span v-show="!isNaN(new Date(filter.value))">{{
                          $t(`labels.` + filter.operator_id).toLowerCase()
                        }}</span>
                        <span v-show="isNaN(new Date(filter.value))">{{ $t(`operators.between`).toLowerCase() }}</span>
                        <span> {{ filter.value }}</span>
                      </template>
                      <template v-else-if="filter.category === 'offers'">
                        {{ $t(`labels.${filter.operator_id}`) }}
                        <strong>{{ $t('filters.details.offer_id') }}: {{ shortenOfferID(filter.profile_id) }}</strong>
                      </template>
                      <template v-else-if="filter.category === 'question'">
                        <strong
                          ><span>{{ shortenQuestionID(filter.profile_id) }}</span></strong
                        >
                        {{ $t(`labels.${filter.operator_id}`) }}
                        <span>{{ filter.value }}</span>
                      </template>
                      <template v-else>
                        <strong
                          v-text="
                            $te(`labels.${filter.profile_id}`)
                              ? $t(`labels.${filter.profile_id}`)
                              : showCorrectProfileLabel(filter)
                          "
                        >
                        </strong>
                        {{ $t(`labels.${filter.operator_id}`) }}
                        <span
                          v-if="filter.value !== '0'"
                          v-text="$te(`labels.${filter.value_id}`) ? $t(`labels.${filter.value_id}`) : filter.value"
                        >
                        </span>
                      </template>
                    </div>
                    <button class="button" @click="deleteFilter(option, ind)">
                      <modatta-icon icon="times" width="12"></modatta-icon>
                    </button>
                  </div>
                </div>

                <div class="actions">
                  <button class="button is-text" @click="clearAllFilters(option)">
                    {{ $t('offers_step2.clear_all') }}
                  </button>
                  <button class="button is-secondary" @click="selectSideModalToEditFilter(option, target)">
                    {{ $t('offers_step2.edit') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-target" @click="addNewTarget()">
      {{ $t('offers_step2.button_add_group') }}
    </div>
  </div>
</template>

<script>
  import tippy from 'tippy.js';
  import 'tippy.js/dist/tippy.css';
  import Utilities from '../../../../../helpers/utilities.js';
  import * as short from 'short-uuid';
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import ModattaIcon from '../../../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    props: ['targetGroups'],
    mounted() {
      this.setupTargetGroups();
      this.getOffers();
      this.setTooltips();
    },
    computed: {
      ...mapGetters(['getOfferById']),
      ...mapState(['newOfferTargetGroups']),

      targets() {
        return this.newOfferTargetGroups;
      },
    },
    methods: {
      ...mapActions(['getOffers', 'getOffer']),
      ...mapMutations(['setNewOfferTargetGroups']),
      shortenQuestionID(questionid) {
        return `ID ${Utilities.trimStringCenter(questionid, 3, '...')}`;
      },
      shortenOfferID(offerid) {
        return `ID ${Utilities.trimStringCenter(offerid, 3, '...')}`;
      },
      setupTargetGroups() {
        if (this.targetGroups.length > 0) {
          this.setNewOfferTargetGroups(this.buildTargetGroups(this.targetGroups));
        } else {
          const targetGroup = [
            {
              id: short.generate(),
              name: this.$t('offers_step2.table_subtitle'),
              isOpen: true,
              options: [
                {
                  id: 'profile',
                  name: this.$t('offers_step2.table_section_1_title'),
                  icon: 'profile-outline',
                  filters: [],
                },
                {
                  id: 'offers',
                  name: this.$t('offers_step2.table_section_3_title'),
                  icon: 'gift',
                  filters: [],
                },
                {
                  id: 'question',
                  name: this.$t('offers_step2.table_section_4_title'),
                  icon: 'help-circle',
                  filters: [],
                },
              ],
            },
          ];
          this.setNewOfferTargetGroups(targetGroup);
        }
      },
      buildTargetGroups(targetGroups) {
        let returnedTargetGroups = [];
        targetGroups.forEach((group) => {
          let g = {
            id: null,
            name: this.$t('offers_step2.table_subtitle'),
            isOpen: true,
            options: [
              {
                id: 'profile',
                name: this.$t('offers_step2.table_section_1_title'),
                icon: 'profile-outline',
                filters: [],
              },
              {
                id: 'offers',
                name: this.$t('offers_step2.table_section_3_title'),
                icon: 'gift',
                filters: [],
              },
              {
                id: 'question',
                name: this.$t('offers_step2.table_section_4_title'),
                icon: 'help-circle',
                filters: [],
              },
            ],
          };
          group.forEach((filter) => {
            g.id = filter.group;
            switch (filter.category) {
              // TODO: AT THE MOMENT WE ACTIVATE INTERESTS DONT FORGET TO CHANGE the INDEX FOR: g.options[0]
              case 'profile':
                this.pushFilterToCategory(g.options[0].filters, filter);
                break;
              // case 'interests':
              // 	this.pushFilterToCategory(g.options[1].filters, filter);
              // 	break;
              case 'offers':
                this.pushFilterToCategory(g.options[1].filters, filter);
                break;
              case 'question':
                this.pushFilterToCategory(g.options[2].filters, filter);
                break;
              // case 'location':
              // 	this.pushFilterToCategory(g.options[4].filters, filter);
              // 	break;
              // case 'recommendation':
              // 	this.pushFilterToCategory(g.options[5].filters, filter);
              // 	break;
            }
          });

          // this.setReadableAgeProfileFilters(g);
          returnedTargetGroups.push(g);
        });
        return returnedTargetGroups;
      },
      // TODO: Move to utilities
      pushFilterToCategory(array, filter) {
        array.push({
          group: filter.group,
          value: filter.value,
          value_id: filter.value_id,
          category: filter.category,
          profile: filter.profile,
          profile_id: filter.profile_id,
          operator_id: filter.operator_id,
        });
      },
      showCorrectProfileLabel(filter) {
        if (filter.category === 'offers') {
          this.getOffer(filter.profile_id).then((r) => {
            const offer = r.data.result;
            return offer.name;
          });
        }
      },
      selectSideModalOption(option, target) {
        const title = this.$t('define_audience.' + option.id);
        const modal = `new-offer-audience-${option.id}`;
        this.$parent.selectOption(modal, title, {
          filters: [],
          targetGroupId: target.id,
        });
      },
      selectSideModalToEditFilter(option, target) {
        const title = this.$t('define_audience.' + option.id);
        const modal = `new-offer-audience-${option.id}`;
        this.$parent.selectOption(modal, title, {
          filters: option.filters,
          targetGroupId: target.id,
        });
      },
      addNewTarget() {
        const defaultTargetGroup = {
          id: short.generate(),
          name: this.$t('offers_step2.table_subtitle'),
          isOpen: true,
          options: [
            {
              id: 'profile',
              name: this.$t('offers_step2.table_section_1_title'),
              icon: 'profile-outline',
              filters: [],
            },
            {
              id: 'offers',
              name: this.$t('offers_step2.table_section_3_title'),
              icon: 'gift',
              filters: [],
            },
            {
              id: 'question',
              name: this.$t('offers_step2.table_section_4_title'),
              icon: 'help-circle',
              filters: [],
            },
          ],
        };
        if (this.targets.length === 10) {
          return;
        }
        this.targets.push(defaultTargetGroup);
        setTimeout(() => this.setTooltips(), 10);
      },
      deleteTargetGroup(i) {
        this.targets.splice(i, 1);
        setTimeout(() => this.setTooltips(), 100);
      },
      deleteFilter(option, i) {
        option.filters.splice(i, 1);
      },
      clearAllFilters(option) {
        option.filters = [];
      },
      selectOption(name, title, options) {
        this.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
      },
      setTooltips() {
        if (this.$refs.deleteTargetGroup) {
          tippy(this.$refs.deleteTargetGroup, {
            maxWidth: 140,
            zIndex: 99999,
            placement: 'top',
            content: this.$t('offers_step2.delete_tooltip'),
          });
        }
      },
    },
  };
</script>
