import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import user from './user';
import brand from './brand';
import offer from './offer';
import budget from './budget';
import analytic from './analytic';
import toast from './toast';

export default new Vuex.Store({
  modules: {
    user,
    brand,
    offer,
    budget,
    analytic,
    toast,
  },
  state: {
    modal: {
      name: '',
      title: '',
      options: {},
      isOpen: false,
    },
    newOfferType: null,
    openSideModal: false,
    isSidenavCollapsed: false,
    newOfferTargetGroups: [],
  },
  mutations: {
    setNewOfferTargetGroups(state, value) {
      state.newOfferTargetGroups = value;
    },
    upsertProfileNewOfferFilters(state, payload) {
      const target = state.newOfferTargetGroups.find((t) => t.id === payload.targetId);
      const profile = target.options.find((o) => o.id === payload.id);
      profile.filters = payload.filters;
    },
    setNewOfferType(state, value) {
      state.newOfferType = value;
    },
    setOpenSideModal(state, value) {
      state.openSideModal = value;
      state.modal.isOpen = value;
    },
    setSidenavCollapsed(state, value) {
      state.isSidenavCollapsed = value;
    },
  },
});
