<template>
  <div class="modatta-settings profile">
    <header>
      <h2>{{ $t('settings_profile.section_1_title') }}</h2>
      <modatta-icon icon="profile" width="15" height="19"></modatta-icon>
    </header>
    <section class="personal-info">
      <div class="user-avatar-username">
        <div class="avatar">
          <img v-if="user.user.avatar" :src="user.user.avatar" alt="Authenticated user avatar" />
          <span v-else>{{ user.user.personal_name.charAt(0).toUpperCase() }}</span>
        </div>
        <div class="username">
          <div class="input-wrapper field">
            <label class="label">{{ $t('settings_profile.section_1_username_label') }}</label>
            <span>{{ user.user.email }}</span>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('settings_profile.section_1_name_label') }}</label>
        <div class="input-wrapper" :class="{ 'has-error': $v.form.personal_name.$error }">
          <input v-model="$v.form.personal_name.$model" type="text" />
          <modatta-icon v-if="$v.form.personal_name.$error" icon="error-exclamation-point" width="20"></modatta-icon>
        </div>
      </div>
      <div class="columns">
        <div class="column is-three-fifths">
          <div class="field">
            <label class="label">{{ $t('settings_profile.section_1_email_label') }}</label>
            <div class="input-wrapper">
              <input
                v-model="user.user.email"
                type="email"
                v-bind:placeholder="$t('settings_profile.section_1_email_input')"
                disabled="true"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <header>
      <h2>{{ $t('settings_profile.section_2_title') }}</h2>
      <modatta-icon icon="suitcase-outline" width="21" height="21"></modatta-icon>
    </header>
    <section class="company-info">
      <div class="field">
        <label class="label">{{ $t('settings_profile.section_2_title') }}</label>
        <div class="input-wrapper" :class="{ 'has-error': $v.form.company_name.$error }">
          <input v-model="$v.form.company_name.$model" type="text" />
          <modatta-icon v-if="$v.form.company_name.$error" icon="error-exclamation-point" width="20"></modatta-icon>
        </div>
      </div>
      <div class="columns">
        <div class="column is-three-fifths">
          <div class="field">
            <label class="label">{{ $t('settings_profile.section_2_web_label') }}</label>
            <div class="input-wrapper" :class="{ 'has-error': $v.form.company_web.$error }">
              <input
                type="text"
                v-model="$v.form.company_web.$model"
                v-bind:placeholder="$t('settings_profile.section_2_web_input')"
              />
              <modatta-icon v-if="$v.form.company_web.$error" icon="error-exclamation-point" width="20"></modatta-icon>
            </div>
          </div>
        </div>
      </div>
      <button class="button is-primary" :disabled="$v.form.$invalid || $parent.isLoading" @click="updateUser()">
        <template>{{ $t('settings_profile.button_save_label') }}</template>
        <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
      </button>
    </section>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { required, url, minLength, maxLength } from 'vuelidate/lib/validators';
  import ModattaIcon from '../../../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    data() {
      return {
        form: {},
      };
    },
    mounted() {
      this.form = {
        company_vat: this.user.user.company_vat,
        personal_vat: this.user.user.personal_vat,
        company_web: this.user.user.company_web,
        company_name: this.user.user.company_name,
        personal_name: this.user.user.personal_name,
      };
    },
    validations: {
      form: {
        company_vat: {
          minLength: minLength(8),
          maxLength: maxLength(14),
        },
        company_web: {
          url,
        },
        company_name: {
          minLength: minLength(3),
        },
        personal_name: {
          required,
          minLength: minLength(3),
        },
        personal_vat: {
          minLength: minLength(8),
          maxLength: maxLength(14),
        },
      },
    },
    computed: {
      ...mapState(['user']),
    },
    methods: {
      updateUser() {
        const body = JSON.stringify(this.form);
        this.$parent.changeUser(body);
      },
    },
  };
</script>
