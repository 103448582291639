<template>
  <div class="modatta-toast" :class="{ active: this.$store.state.toast.isOpen }">
    <div class="modatta-toast-vertical-bar" :class="{ success: this.$store.state.toast.isSuccess }"></div>
    <div class="modatta-toast-message">
      <span class="title">{{ this.$store.state.toast.title }}</span>
      <span class="subtitle">{{ this.$store.state.toast.message }}</span>
    </div>
    <modatta-icon :icon="this.$store.state.toast.isSuccess ? 'icon-check-circle' : 'icon-close-circle'" width="34"
      height="34"></modatta-icon>
    <div class="modatta-toast-close" @click="this.setCloseToast">X</div>
    <div class="modatta-toast-progress active" :style="{ width: `${this.progressValue}%` }"></div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import ModattaIcon from '../components/ModattaIcon.vue';

  export default {
    components: { ModattaIcon },
    data() {
      return {
        progressValue: 100
      }
    },
    watch: {
      '$store.state.toast.isOpen': function () {
        if (!this.$store.state.toast.isOpen) {
          return;
        }
        const intervalo = setInterval(() => {
          this.progressValue = this.progressValue - 0.1;
          if (this.progressValue <= 0) {
            this.onClose();
            clearInterval(intervalo);
          }
        }, 10);
      }
    },
    methods: {
      ...mapMutations(['setCloseToast']),
      onClose() {
        this.progressValue = 100;
        this.setCloseToast();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modatta-toast {
    display: flex;
    align-items: center;
    z-index: 9999999;
    max-width: 24vw;
    position: absolute;
    top: 25px;
    right: 30px;
    border-radius: 12px;
    background: #0A1735;
    padding: 16px;
    box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transform: translateX(calc(100% + 30px));
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);

    &.active {
      transform: translateX(0%);
    }

    .modatta-toast-vertical-bar {
      height: 86px;
      width: 12px;
      background: #C30052;
      border-radius: 6px;

      &.success {
        background: #00BA88;
      }
    }

    .modatta-toast-message {
      display: flex;
      flex-direction: column;
      padding: 0 12px;

      .title {
        font-family: 'Silka Bold', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 22.4px;
        letter-spacing: 0.001em;
        color: #FFF3F8;
      }

      .subtitle {
        font-family: 'Silka Regular', sans-serif;
        font-size: 12px;
        color: #FFF3F8;
      }
    }

    .modatta-toast-close {
      font-family: 'Silka Bold', sans-serif;
      font-size: 12px;
      position: absolute;
      top: 10px;
      right: 15px;
      padding: 5px;
      cursor: pointer;
      color: #FFF3F8;
    }

    .modatta-toast-progress {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100%;
      background-color: #B4B7C0;
    }
  }

</style>
