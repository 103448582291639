<template>
  <div class="step-details">
    <div class="details">
      <h2>{{ $t('offers_step2.section_1_title') }}</h2>
      <p>{{ $t('offers_step2.section_1_subtitle') }}</p>
      <div class="audience">
        <h4>{{ $t('offers_step2.table_title') }}</h4>
        <modatta-new-offer-audience-target-group
          :target-groups="$parent.newOffer.grouped_filters"
        ></modatta-new-offer-audience-target-group>
        <div class="buttons-wrapper">
          <button class="button is-secondary" @click="$parent.currentStep--">
            {{ $t('offers_step2.back_button_label') }}
          </button>
          <button
            class="button is-primary"
            :disabled="!$parent.isAudienceStepCompleted || $parent.isLoading"
            @click="prepareFiltersForApiCall()"
          >
            <template>{{ $t('offers_step2.button_label') }}</template>
            <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import ModattaIcon from '../../../../components/ModattaIcon.vue';
  import ModattaNewOfferAudienceTargetGroup from './components/ModattaNewOfferAudienceTargetGroup.vue';

  export default {
    components: { ModattaIcon, ModattaNewOfferAudienceTargetGroup },
    data() {
      return {
        selectedOption: {},
      };
    },
    computed: {
      ...mapState(['openSideModal']),
      filtersLength() {
        return this.$store.state.newOfferTargetGroups.length > 0
          ? [...this.$store.state.newOfferTargetGroups[0].options.map((o) => o.filters.length)].reduce(
              (accumulator, currentValue) => accumulator + currentValue,
            )
          : 0;
      },
    },
    mounted() {},
    methods: {
      ...mapMutations(['setOpenSideModal']),

      /**
       *
       * Métodos:
       *
       * - setOpenSideModal: Mapeia a mutação 'setOpenSideModal' do Vuex.
       *
       * - selectOption: Define a opção selecionada e aciona a abertura de um modal lateral:
       * > Este 'selectOption' é usado em 'ModattaNewOfferAudienceTargetGroup.vue' com 'this.$parent.selectOption' (aponta para este componente)
       *
       * - prepareFiltersForApiCall: Prepara os filtros para uma chamada de API.
       * > Cria filtros agrupados e aciona a próxima etapa.
       *
       * - setFiltersByGroup: Cria uma Array de filtros agrupados.
       * > Cada item da Array corresponde a um grupo e contém todos os filtros desse grupo específico.
       *
       */

      selectOption(name, title, options) {
        this.$parent.selectedOption = { name, title, options };
        this.setOpenSideModal(true);
      },

      /**
       * Prepara os filtros para uma chamada de API.
       * 1) Cria filtros agrupados
       * 2) aciona a próxima etapa.
       */
      prepareFiltersForApiCall() {
        // Cria filtros agrupados com a função ´setFiltersByGroup´
        // this.$parent.newOffer.grouped_filters ()
        this.$parent.newOffer.grouped_filters = this.setFiltersByGroup(this.$store.state.newOfferTargetGroups);

        // Aciona a próxima etapa
        this.$parent.handleNextClick(2, {
          grouped_filters: this.$parent.newOffer.grouped_filters,
        });
      },
      /**
       * Cria uma Array de filtros agrupados.
       * Cada item da Array corresponde a um grupo e contém todos os filtros desse grupo específico.
       *
       * Alterações:
       * # group.options.some(option => option.filters.length > 0) verifica se existe pelo menos 1 filtro em uma das categorias
       * # group.options.some(option => option.filters.length > 0) substitui: (targetGroup.options[0].filters.length > 0 || targetGroup.options[1].filters.length > 0 || targetGroup.options[2].filters.length > 0)
       * # toda a manipulação ao newOfferTargetGroups só é realizada se group.options.some(option => option.filters.length > 0)
       * # .forEach() foram substituídos por for()
       * # nome de variáveis corrigidas para uma melhor leitura,
       * > tg = targetGroup, g = group, category = Categoria, filter = Filtro
       *
       * @param {Array} tg - Objeto TargetGroups contêm: Grupos, Categorias e Filtros.
       * @returns {Array} - Array de filtros agrupados.
       */
      setFiltersByGroup(tg) {
        const filters = [];
        // Percorre cada grupo dentro do objeto TargetGroups
        for (let i = 0; i < tg.length; i++) {
          const g = tg[i]; // Obtém o grupo atual
          // Verifica se o grupo atual possui filtros
          if (g.options.some((option) => option.filters.length > 0)) {
            // cria uma nova Array vazia para os filtros dentro do grupo atual
            filters.push([]);
            // Percorre cada Categoria dentro do Grupo
            for (const category of g.options) {
              // Percorre cada Filtro dentro da Categoria
              for (const filter of category.filters) {
                // Cria um Objeto de filtro e adiciona-o à Array de filtros do grupo atual
                const filterObj = {
                  profile: filter.profile,
                  profile_id: filter.profile_id,
                  operator_id: filter.operator_id,
                  value: filter.value,
                  value_id: filter.value_id,
                  group: g.id,
                  category: category.id,
                };
                // adiciona o filtro à Array de filtros do grupo atual
                filters[filters.length - 1].push(filterObj);
              }
            }
          }
          // caso contrário: Ignora se não existirem filtros
        }
        // retorna a Array de grupos contendo todos os filtros
        return filters;
      },
    },
  };
</script>
