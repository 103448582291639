<template>
  <div class="modatta-upload-image">
    <b-field v-bind:label="$t('brands_edit.brand_logo_title')" class="upload-file">
      <template v-if="noLogo">
        <div v-if="!disabled" class="help-text">{{ $t('brands_edit.brand_logo_tooltip') }}</div>
        <b-upload v-model="$parent.form.logo" @input="uploadLogo()" drag-drop expanded :disabled="disabled">
          <section class="section">
            <div class="content has-text-centered">
              <modatta-icon icon="upload-arrow" width="32" style="display: contents"></modatta-icon>
              <p>{{ $t('brands_edit.brand_logo_upload_tip') }}</p>
            </div>
          </section>
        </b-upload>
      </template>
      <div v-else class="selected-logo">
        <div class="logo">
          <template v-if="logoUrl != null">
            <img v-if="hasFormIcon()" :src="`/svg/generic-logos/${logoUrl.icon}.svg`" />
            <img v-else-if="$parent.selectedLogo.type == 'file'" id="preview-logo" />
            <img v-else :src="logoUrl" />
          </template>
          <img v-else-if="$parent.selectedLogo.type == 'file'" id="preview-logo" />
          <img v-else :src="`/svg/generic-logos/${$parent.selectedLogo.icon}.svg`" />
        </div>
        <button class="button is-subtle" @click.prevent="removeLogo()" :disabled="disabled">
          {{ $t('brands_edit.brand_logo_button_title_2') }}
        </button>
      </div>
    </b-field>
    <div v-if="noLogo" class="select-logo">
      <p>{{ $t('brands_edit.brand_logo_disclaimer') }}</p>
      <button class="button is-subtle" @click.prevent="$parent.isSelectLogoOpen = true" :disabled="disabled">
        {{ $t('brands_edit.brand_logo_button_title_1') }}
      </button>
    </div>
  </div>
</template>
<script>
  import lodash from 'lodash';

  export default {
    props: ['logoUrl', 'disabled'],
    computed: {
      noLogo() {
        return this.$parent.form.logo == '' || this.$parent.form.logo == null;
      },
    },
    methods: {
      uploadLogo() {
        setTimeout(() => {
          let output = document.getElementById('preview-logo');
          output.src = URL.createObjectURL(this.$parent.form.logo);
          output.onload = function () {
            URL.revokeObjectURL(output.src); // free memory
          };
        }, 100);
        this.$parent.selectedLogo = { type: 'file', image: this.$parent.form.logo };
      },
      removeLogo() {
        this.$parent.form.logo = null;
        this.$parent.selectedLogo = null;
      },
      hasFormIcon() {
        return lodash.has(this.$parent.form.logo, 'icon');
      },
    },
  };
</script>
