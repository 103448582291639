<template>
  <div class="step-details">
    <div class="details">
      <h2>{{ $t('offers_step4.section_1_title') }}</h2>
      <p>{{ $t('offers_step4.section_1_subtitle') }}</p>

      <!-- No interaction type -->
      <div v-if="isInteractionType('none')" class="interaction no-offer">
        <section>
          <header>
            <h3>{{ $t('dashboard.table_menu_offer_type') }}</h3>
          </header>
          <footer>
            <button class="button is-primary" @click="selectOption('campaign', this.$t('offers_step4.title'))">
              {{ $t('dashboard.table_button_label') }}
            </button>
          </footer>
        </section>
      </div>
      <!-- OR -->
      <div v-else class="interaction">
        <!-- # Page Contents Header  -->
        <div class="offer-type">
          <div class="icon-wrapper">
            <modatta-icon :icon="interactionTypeIcon" height="20"></modatta-icon>
          </div>
          <div class="type">{{ offerType }}</div>
        </div>

        <template v-if="type === 'message'">
          <div class="field">
            <label class="label">{{ $t('offers_step4.box_section_1_text') }}</label>
          </div>
          <div class="input-wrapper">
            <textarea type="text" v-model="$parent.newOffer.action" maxlength="255"></textarea>
          </div>
        </template>
        <template v-else-if="type === 'video'">
          <div class="field">
            <label class="label">
              {{ $t('offers_step4.box_section_1_video') }}
              <b-tooltip v-bind:label="$t('offers_step4.box_section_1_video_tooltip')" type="is-dark"
                position="is-right" size="is-small" multilined>
                <modatta-icon icon="info-square"></modatta-icon>
              </b-tooltip>
            </label>
            <div class="help-text">{{ $t('offers_step4.box_section_1_video_sub_title') }}</div>
          </div>
          <div class="input-wrapper">
            <div class="input-wrapper">
              <b-upload v-if="!videoURL" v-model="videoFile" @input="handleVideoURL" drag-drop expanded
                accept="video/*">
                <section class="section">
                  <div class="content has-text-centered">
                    <modatta-icon icon="upload-arrow" width="32" style="display: contents"></modatta-icon>
                    <p>{{ $t('offers_step3.step_media_video_upload_placehold') }}</p>
                  </div>
                </section>
              </b-upload>
              <div v-if="videoURL">
                <video controls preload="metadata" :src="videoURL"></video>
              </div>
            </div>
            <button class="button is-secondary" @click="handleRemoveVideo" style="margin-top: 16px">
              <template>Remover vídeo</template>
            </button>
          </div>
        </template>
        <template v-else-if="type === 'website' || type === 'online' || type === 'follow-page'">
          <modatta-step-interaction-input-url :title="$t('offers_step4.box_section_1_url')" :isValid="true"
            :isInvalid="false" :deeplink="true" @url="(url) => ($parent.newOffer.action = url)"
            @is-valid="(valid) => (isValidUrl = valid)"
            :suggested-value="$parent.newOffer.action || ''"></modatta-step-interaction-input-url>
          <p class="error-message" v-if="$parent.newOffer.action && !isValidUrl">
            {{ $t('offers_step4.url_error') }}
          </p>
          <modatta-step-interaction-validate-url-to-like-facebook-page
            v-if="isValidUrl === true && type === 'follow-page'" :url="$parent.newOffer.action"
            @is-valid="(value) => (hasValidFacebookUrl = value)"></modatta-step-interaction-validate-url-to-like-facebook-page>
        </template>
        <template v-else-if="type === 'share-post'">
          <modatta-step-interaction-input-url :title="'URL of the post you want users to share'" :isValid="true"
            :isInvalid="false" :deeplink="true" @url="(url) => ($parent.newOffer.action = url)"
            @is-valid="(valid) => (isValidUrl = valid)"></modatta-step-interaction-input-url>
          <div class="field">
            <label class="label">Text for the share (optional)</label>
            <div class="input-wrapper">
              <textarea type="text" v-model="socialInteractionsMessage"></textarea>
            </div>
          </div>
        </template>
        <template v-else-if="type === 'create-post'">
          <div class="field">
            <label class="label">Post's Text</label>
            <div class="input-wrapper">
              <textarea type="text" v-model="socialInteractionsMessage"
                @input="handleSocialInteractionsMessageInput($event)"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">A URL you want the user to attach (optional)</label>
            <div class="input-wrapper" :class="{
                'has-error': isValidUrl === false,
                'is-valid': isValidUrl === true,
              }">
              <input type="text" v-model="socialInteractionsUrl" @input="handleSocialInteractionsUrlInput()" />
              <modatta-icon v-if="isValidUrl === false" icon="error-exclamation-point" width="20"></modatta-icon>
              <modatta-icon v-if="isValidUrl === true" icon="checkmark-green" width="18"></modatta-icon>
            </div>
          </div>
        </template>
        <template v-else-if="type === 'url-action'">
          <modatta-step-interaction-input-url :title="$t('offers_step4.box_section_1_url')" :isValid="true"
            :isInvalid="false" :deeplink="true" @url="(url) => (urlAction.action = url)"
            @is-valid="(valid) => (isValidUrl = valid)" :placeholder="$t('offers_step4.url_placeholder')"
            :suggested-value="urlAction.action || ''"></modatta-step-interaction-input-url>
          <p class="error-message" v-if="urlAction.action && !isValidUrl">
            {{ $t('offers_step4.url_error') }}
          </p>
        </template>
        <template v-else-if="type === 'data'">
          <div class="field">
            <label class="label">{{ $t('offers_step4.box_section_1_text') }}</label>
          </div>
          <div class="input-wrapper">
            <textarea type="text" disabled="true" v-model="$parent.newOffer.action" maxlength="255"></textarea>
          </div>
        </template>
        <template v-else-if="type === 'poll' || type === 'survey'">
          <div :class="{ 'has-errors': hasEmptyQuestions || hasEmptyAnswers }">
            <modatta-step-interaction-survey-and-poll :type="type" :questions="$parent.newOffer.questions"
              @questions="(questions) => updateQuestions(questions)"></modatta-step-interaction-survey-and-poll>
          </div>
        </template>
        <template v-else-if="type === 'loja'">
          <modatta-step-interaction-input-url :title="$t('offers_step4.box_section_1_url')" :isValid="true"
            :isInvalid="false" :deeplink="true" @url="(url) => ($parent.newOffer.action = url)"
            @is-valid="(valid) => (isValidUrl = valid)"
            :suggested-value="$parent.newOffer.action || ''"></modatta-step-interaction-input-url>
          <p class="error-message" v-if="$parent.newOffer.action && !isValidUrl">
            {{ $t('offers_step4.url_error') }}
          </p>
        </template>
        <template v-else-if="type === 'data-points'">
          <modatta-step-interaction-data-point
            @data-points-list="(dataPointsList) => ($parent.newOffer.action = dataPointsList)" />
        </template>
        <template v-else-if="type === 'comment-instagram'">
          <modatta-step-interaction-comment-instagram
            @instagram-post-url="(instagramPostUrl) => { $parent.newOffer.action = instagramPostUrl }" />
        </template>
        <div class="validation">
          <!-- # Header for Validation Section  -->
          <h3>{{ $t('offers_step4.box_section_2_title') }}</h3>
          <p>{{ $t('offers_step4.box_section_2_subtitle') }}</p>

          <!-- # Validation Options  -->
          <div class="validations-options">
            <div class="validations">
              <div :key="index" class="validation-card" @click="setSelectedValidation(validation)"
                v-for="(validation, index) in validations" :class="{ 'is-selected': validation.isSelected }">
                <modatta-icon :icon="validation.icon" width="100%" height="auto"></modatta-icon>
                <div v-html="validation.text"></div>
              </div>
            </div>
            <div v-if="objectHasProperty(selectedValidation, 'description')" class="field">
              <label class="label">{{ $t('offers_step4.box_section_3_val_1_subtitle_1') }}</label>
            </div>
            <p v-if="objectHasProperty(selectedValidation, 'description')">
              {{ selectedValidation.description }}
            </p>
          </div>

          <template v-if="selectedValidation != null">
            <div v-if="selectedValidation.id === 2" class="validation-option">
              <div class="field">
                <label class="label">{{ $t('offers_step4.box_section_3_val_2_subtitle_2') }}</label>
              </div>
              <div class="input-wrapper">
                <input type="text" v-model="universalCode" maxlength="255" />
              </div>
            </div>
            <modatta-step-interaction-unique-code v-if="selectedValidation.id === 3"
              :validation-codes="$parent.newOffer.validations_codes" @new-codes="(codes) => (this.newCodes = codes)"
              @csvFile="(csvFile) => (this.csvFile = csvFile)"></modatta-step-interaction-unique-code>
            <modatta-step-interaction-tag v-if="selectedValidation.id === 4 && type !== 'url-action'"
              :offer-id="$parent.offerId" :isValid="isValidUrl"
              :url="$parent.newOffer.action"></modatta-step-interaction-tag>
            <modatta-step-interaction-tag v-if="selectedValidation.id === 4 && type === 'url-action'"
              :offer-id="$parent.offerId" :isValid="isValidUrl" :url="urlAction.action"></modatta-step-interaction-tag>
            <modatta-step-interaction-postback v-if="selectedValidation.id === 5 && type !== 'url-action'"
              :offer-id="$parent.offerId" :isValid="isValidUrl"
              :url="$parent.newOffer.action"></modatta-step-interaction-postback>
            <modatta-step-interaction-postback v-if="selectedValidation.id === 5 && type === 'url-action'"
              :offer-id="$parent.offerId" :isValid="isValidUrl"
              :url="urlAction.action"></modatta-step-interaction-postback>
            <modatta-step-interaction-qr-code v-if="selectedValidation.id === 6" :offer-id="$parent.offerId"
              :offer-name="$parent.newOffer.name" :offer-validations-codes="$parent.newOffer.validations_codes"
              @new-codes="(codes) => (this.newCodes = codes)"></modatta-step-interaction-qr-code>
            <div v-if="selectedValidation.id === 7" class="validation-option">
              <modatta-step-interaction-input-url :title="$t('offers_step4.box_section_3_val_2_subtitle_2')"
                :isValid="true" :isInvalid="false" :deeplink="true"
                @url="(url) => ($parent.newOffer.action.urlValidation = url)"
                @is-valid="(valid) => (deeplink.isValid = valid)"
                :suggested-value="$parent.newOffer.action.urlValidation"
                :placeholder="$t('offers_step4.url_placeholder')"></modatta-step-interaction-input-url>
            </div>
            <div v-if="selectedValidation.id === 8" class="validation-option">
              <label class="label">Tipo de Coupom</label>
              <modatta-object-radio-input class="my-0" :options="couponOptions" prop="label" :has-to-translate="false"
                @radio-value="(radio) => (selectedCoupon = radio)"
                :suggested-value="couponOptions[0]"></modatta-object-radio-input>
              <div class="field field-container">
                <div class="columns mt-2">
                  <div class="column">
                    <div class="input-wrapper" :class="{
                        'has-error':
                          (!coupon.min && coupon.min.length > 0) ||
                          (isNaN(parseFloat(coupon.min)) && coupon.min.length > 0),
                        'is-valid': coupon.min > 0,
                      }">
                      <label class="label">Valor Minimo de compra <small>(R$)</small></label>
                      <input type="text" disabled v-model="coupon.min" :placeholder="'R$ 0,00'"
                        v-if="selectedCoupon.id === 1 || selectedCoupon.id === 2" />
                      <input type="text" disabled :placeholder="'0,00'" v-else />
                    </div>
                  </div>
                  <div class="column">
                    <div class="input-wrapper" :class="{
                        'has-error':
                          (!coupon.value && coupon.value.length > 0) ||
                          (isNaN(parseFloat(coupon.value)) && coupon.value.length > 0),
                        'is-valid': coupon.value > 0,
                      }">
                      <label class="label" v-if="selectedCoupon.id === 1">Valor <small>(%)</small></label>
                      <label class="label" v-if="selectedCoupon.id === 2">Valor <small>(R$)</small></label>
                      <label class="label" v-if="selectedCoupon.id === 3">Valor </label>
                      <input type="text" disabled v-model="coupon.value" :placeholder="'0%'"
                        v-if="selectedCoupon.id === 1" />
                      <input type="text" disabled v-model="coupon.value" :placeholder="'R$ 0,00'"
                        v-if="selectedCoupon.id === 2" />
                      <input type="text" disabled :placeholder="'0,00'" v-if="selectedCoupon.id === 3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="buttons-wrapper">
          <button class="button is-secondary" @click="handleBackClick()">
            {{ $t('offers_step2.back_button_label') }}
          </button>
          <button class="button is-primary" :disabled="disableInputs" @click="handleNextClick()">
            <template>{{ $t('offers_step4.box_section_3_button_label') }}</template>
            <modatta-icon v-if="$parent.isLoading" icon="loading" class="rotating"></modatta-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import lodash from 'lodash';
  import SetOfferType from '../../../../helpers/set-offer-type.js';
  import SetInteractionType from '../../../../helpers/set-interaction-type.js';
  import SetInteractionTypeIcon from '../../../../helpers/set-interaction-type-icon.js';
  import Utilities from '../../../../helpers/utilities.js';
  import ModattaIcon from '../../../../components/ModattaIcon.vue';
  import ModattaObjectRadioInput from '../../../../components/ModattaObjectRadioInput.vue';
  import ModattaStepInteractionQrCode from './components/ModattaStepInteractionQrCode.vue';
  import ModattaStepInteractionPostback from './components/ModattaStepInteractionPostback.vue';
  import ModattaStepInteractionTag from './components/ModattaStepInteractionTag.vue';
  import ModattaStepInteractionUniqueCode from './components/ModattaStepInteractionUniqueCode.vue';
  import ModattaStepInteractionSurveyAndPoll from './components/ModattaStepInteractionSurveyAndPoll.vue';
  import ModattaStepInteractionValidateUrlToLikeFacebookPage from './components/ModattaStepInteractionValidateUrlToLikeFacebookPage.vue';
  import ModattaStepInteractionInputUrl from './components/ModattaStepInteractionInputUrl.vue';
  import ModattaStepInteractionDataPoint from './components/ModattaStepInteractionDataPoint.vue';


  export default {
    components: {
      ModattaIcon,
      ModattaObjectRadioInput,
      ModattaStepInteractionQrCode,
      ModattaStepInteractionPostback,
      ModattaStepInteractionTag,
      ModattaStepInteractionUniqueCode,
      ModattaStepInteractionSurveyAndPoll,
      ModattaStepInteractionValidateUrlToLikeFacebookPage,
      ModattaStepInteractionInputUrl,
      ModattaStepInteractionDataPoint
    },
    data() {
      return {
        typeId: '',
        type: '',
        url: '',
        interaction: '',
        validations: [],
        isValidUrl: null,
        videoFile: null,
        videoURL: null,
        newCodes: null,
        csvFile: null,
        hasIntegrationAPI: {
          'loja-integrada': false,
        },
        universalCode: '',
        urlAction: {
          action: '',
          validation: '',
        },
        deeplink: {
          url: '',
          isValid: false,
        },
        selectedCoupon: { id: 1, label: 'Percentual' },
        couponOptions: [
          { id: 1, label: 'Percentual' },
          { id: 2, label: 'Fixo' },
          { id: 3, label: 'Frete Gratis' },
        ],
        coupon: {
          value: '',
          min: '',
        },
        hasValidFacebookUrl: false,
        socialInteractionsUrl: '',
        socialInteractionsMessage: '',
        hasEmptyAnswers: false,
        hasEmptyQuestions: false,
        hasAuthKey: '',
      };
    },
    mounted() {
      this.typeId = this.getOfferTypeId();
      this.type = this.getOfferType(this.typeId);

      this.setValidationOptions();
      this.setSocialInteractionsValues();
      this.selectValidationOptionIfAlreadySelected();

      if (this.type === 'video') {
        this.handleVideoURLAction();
      } else if (this.type === 'website' || this.type === 'online') {
        this.handleUrlInput();
        if (this.$parent.newOffer.validations_codes.length === 0) {
          this.$parent.newOffer.validations_codes = [{ type: 'universal', code: null }];
        }
      } else if (this.type === 'offline' || this.type === 'data') {
        this.$parent.newOffer.action = 'Default message';
        if (this.$parent.newOffer.validations_codes.length === 0) {
          this.$parent.newOffer.validations_codes = [{ type: 'universal', code: null }];
        }
      } else if (this.type === 'poll' || this.type === 'survey') {
        this.$parent.newOffer.questions.length === 0
          ? (this.$parent.newOffer.questions = [
              {
                id: '',
                profile_id: '',
                question: '',
                isOpen: true,
                offer_answers: [
                  { id: '', answer: '', value_id: '' },
                  { id: '', answer: '', value_id: '' },
                ],
              },
            ])
          : this.questions;
      }
      if (this.type === 'url-action') {
        if (JSON.parse(this.$parent.newOffer.action).urlAction) {
          this.urlAction.action = JSON.parse(this.$parent.newOffer.action).urlAction;
        }
        if (this.selectedValidation.id === 7) {
          if (JSON.parse(this.$parent.newOffer.action).urlValidation) {
            this.urlAction.validation = JSON.parse(this.$parent.newOffer.action).urlValidation;
          }
        }
      } else if (this.type === 'loja') {
        this.getCredentials().then(() => {
          // this.$store.state.user.credentials
        });
      }

      if (this.isValidationOption('ITV13')) {
        this.coupon.min = 0;
        this.coupon.value = 0;
      }

      if (this.selectedValidation.id === 2) {
        if (this.$parent.newOffer.validations_codes.length > 0) {
          if (
            this.$parent.newOffer.validations_codes[0].code !== '' ||
            this.$parent.newOffer.validations_codes[0].code !== null
          ) {
            this.universalCode = this.$parent.newOffer.validations_codes[0].code;
          }
        }
      }
    },
    watch: {
      newCodes(newVal) {
        this.$parent.newOffer.validations_codes = newVal;
      },
      universalCode(newVal) {
        this.$parent.newOffer.validations_codes = this.setValidationCodes(
          [newVal],
          this.getValidationTypeName(this.$parent.newOffer.interaction_validation_id),
        );
      },
      urlAction: {
        handler(newVal) {
          if (this.$parent.newOffer.interaction_type_id === 'INT15') {
            if (this.selectedValidation.id === 7) {
              this.$parent.newOffer.action = {
                urlAction: newVal.action,
                urlValidation: newVal.validation,
              };
            } else {
              this.$parent.newOffer.action = { urlAction: newVal.action };
            }
          }
        },
        deep: true,
      },
    },
    computed: {
      ...mapState(['openSideModal', 'newOfferType']),
      offerType() {
        return SetOfferType(this.$parent.newOffer.type_label_id);
      },
      interactionTypeIcon() {
        return SetInteractionTypeIcon(SetInteractionType(this.$parent.newOffer.interaction_type_id));
      },
      selectedValidation() {
        return this.validations.find((v) => v.isSelected);
      },
      disableInputs() {
        if (this.type === 'video' && this.videoURL === null) {
          return true;
        } else if (
          (this.type === 'online' || this.type === 'social-media' || this.type == 'share-post') &&
          this.isValidUrl === false
        ) {
          return true;
        } else if (this.type === 'website') {
          if (!this.isValidUrl) {
            return true;
          }
        } else if (this.type === 'follow-page') {
          if (this.hasValidFacebookUrl === false) {
            return true;
          }
        } else if (this.type === 'url-action') {
          if (!this.isValidUrl) {
            return true;
          }
        } else if (this.type === 'poll' || this.type === 'survey') {
          if (this.canDisableNexStep) {
            return true;
          }
        }
        if (this.selectedValidation) {
          if (this.selectedValidation.id === 2) {
            // Sem o IF: this.$parent.newOffer.validations_codes.length > 0,
            // Dá erro porque o [0], ainda não existe e só existirá quando input
            // do campo universal for preenchido.
            if (this.$parent.newOffer.validations_codes.length > 0) {
              if (
                this.$parent.newOffer.validations_codes[0].code === '' ||
                this.$parent.newOffer.validations_codes[0].code === null
              ) {
                return true;
              }
            } else {
              return true;
            }
          }

          if (this.selectedValidation.id === 3) {
            if (
              !this.$parent.newOffer.validations_codes.length > 0 ||
              this.$parent.newOffer.validations_codes[0].code === '' ||
              this.$parent.newOffer.validations_codes[0].code === null
            ) {
              return true;
            }
          }

          // ITV12 Deeplink
          if (this.selectedValidation.id === 7) {
            if (!this.deeplink.isValid || !this.isValidUrl || !this.$parent.newOffer.action.urlValidation) {
              return true;
            }
          }

          // Loja Integrada
          if (this.selectedValidation.id === 8) {
            if (
              !this.$parent.newOffer.action ||
              /^\s*$/.test(this.$parent.newOffer.action) ||
              isNaN(parseFloat(this.coupon.min)) ||
              !(this.coupon.min >= 0) ||
              isNaN(parseFloat(this.coupon.value)) ||
              !(this.coupon.value >= 0)
            ) {
              return true;
            }
          }
        }

        if (this.$parent.newOffer.interaction_type_id === 'INT4') {
          return (
            !this.$parent.isInteractionActionFilled || !this.$parent.isInteractionTypeFilled || this.$parent.isLoading
          );
        }

        return !this.$parent.isInteractionStepCompleted || this.$parent.isLoading;
      },
      isCouponFilled() {
        return this.coupon.min && this.coupon.value;
      },
      isFacebookURL() {
        let action = this.$parent.newOffer.action;
        const facebookPattern = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/;
        try {
          // const url = new URL(action);
          if (facebookPattern.test(action)) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
        // return url.host == 'facebook.com';
      },
      isURL() {
        let action = this.$parent.newOffer.action;
        const urlPattern = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[\w.-]*)*\/?$/;
        try {
          if (urlPattern.test(action)) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      },
    },
    methods: {
      ...mapActions(['getCredentials']),
      ...mapMutations(['setOpenSideModal', 'setOpenToast']),
      updateQuestions(questions) {
        this.$parent.newOffer.questions = questions;
      },
      handleBackClick() {
        this.$parent.currentStep--;
      },
      handleNextClick() {
        if (this.type === 'share-post' || this.type === 'create-post') {
          this.$parent.newOffer.action = JSON.stringify({
            url: this.socialInteractionsUrl,
            message: this.socialInteractionsMessage,
          });
        }

        if (this.selectedValidation.code == 'ITV13') {
          if (!this.$store.state.user.user.credentials) {
            this.openLojaConfig({
              id: 0,
              integration_logo: '40x40',
              logo_url: 'https://placehold.co/40x40',
              title: 'Preencha a sua chave API da a Loja Integrada antes de continuar',
              name: 'loja-integrada',
              active: true,
              selected: false,
            });
            return;
          }
        }

        if (this.isValidationOption('ITV4') || this.isValidationOption('ITV6')) {
          if (this.type === 'url-action') {
            if (this.$parent.newOffer.action.urlAction.includes('modid={modid}') === false) {
              this.$parent.newOffer.action.urlAction = Utilities.addModid(this.$parent.newOffer.action.urlAction);
            }
          } else {
            if (this.$parent.newOffer.action.includes('modid={modid}') === false) {
              this.$parent.newOffer.action = Utilities.addModid(this.$parent.newOffer.action);
            }
          }
        }

        if (this.type === 'poll' || this.type === 'survey') {
          this.hasEmptyQuestions = this.$parent.newOffer.questions.some((q) => q.question.replace(/\s/g, '') === '');
          this.hasEmptyAnswers = this.$parent.newOffer.questions.some((q) =>
            q.offer_answers.some((a) => a.answer.replace(/\s/g, '') === ''),
          );
          if (this.hasEmptyAnswers || this.hasEmptyQuestions) {
            return;
          }
        }

        if (this.type === 'url-action') {
          this.$parent.newOffer.action = JSON.stringify(this.$parent.newOffer.action);
        }

        this.$parent.handleNextClick(4, {
          action: this.$parent.newOffer.action,
          interaction_validation_id: this.$parent.newOffer.interaction_validation_id,
          validations_codes: this.$parent.newOffer.validations_codes,
          questions: this.$parent.newOffer.questions,
        });
      },
      clearValidationCodeObj() {
        return [{ code: '', type: '' }];
      },
      handleVideoURL() {
        this.videoURL = URL.createObjectURL(this.videoFile);
        this.$parent.newOffer.action = this.videoFile;
      },
      handleVideoURLAction() {
        if (this.$parent.newOffer.action) {
          this.videoURL = this.$parent.newOffer.action;
        }
      },
      handleRemoveVideo() {
        this.videoURL = null;
        this.videoFile = null;
        this.$parent.newOffer.action = null;
      },
      handleUrlInput() {
        this.isValidUrl = this.validURL(this.$parent.newOffer.action);
      },
      setURLvalidation() {
        if (this.selectedValidation.code === 'ITV12') {
          this.$parent.newOffer.urlAction.validation = this.deeplink.url;
        }
      },
      setURLaction() {
        this.$parent.newOffer.urlAction.action = this.$parent.newOffer.action;
      },
      handleSocialInteractionsUrlInput(e, isRequired) {
        this.isValidUrl = this.validURL(this.socialInteractionsUrl);
        if (isRequired) {
          this.$parent.newOffer.action = e.target.value;
        }
      },
      handleSocialInteractionsMessageInput(e) {
        this.$parent.newOffer.action = e.target.value;
      },

      selectOption(name, title) {
        this.$parent.selectedOption = { name, title };
        this.setOpenSideModal(true);
      },
      openLojaConfig(option) {
        if (option.active) {
          this.$parent.selectedOption = option;
          this.setOpenSideModal(true);
        }
      },

      // setValidationCodes() aceita uma Array de códigos e um Type
      // Retorna um array de objetos.
      // Cada objeto na Array contém um ´Code´, proveniente da Array, e ´Type´ que é consistente para todos os objetos.
      // Se a Array de códigos estiver vazia, retorna Array vazia.
      // usage: ´setValidationCodes(['code1', 'code2', 'code3'], 'universal')´

      setValidationCodes(codes, type) {
        return codes.length > 0 ? codes.map((code) => ({ code, type })) : [];
      },

      getValidationTypeName(itv) {
        // Esta função é relevante, caso contrário, na seleção de ITV2, o ´Type´ será vazio
        // ITV5 é definido através do emit em: ModattaNewOfferStepInteractionQrCode.vue
        // ITV3 é definido através do emit em: ModattaNewOfferStepInteractionUniqueCode.vue
        switch (itv) {
          case 'ITV2':
            return 'universal';
          case 'ITV3':
            return 'unique';
          case 'ITV5':
            return 'qrcode';
        }
      },

      setSelectedValidation(validationObj) {
        /* É importante definir this.selectedValidation, o qual é determinado pelas this.validations.
          O valor é o elemento com isSelected = true. Pode ser definido através de setValidationOptions(),
          que constrói as this.validations, ou durante selectValidationOptionIfAlreadySelected(),
          que verifica se já existe uma validação previamente selecionada. Caso contrário,
          pode selecionar a validação padrão, por exemplo, a primeira.*/

        if (this.selectedValidation.id === 2) {
          this.universalCode = '';
        }

        if (this.selectedValidation.id === 7) {
          this.$parent.newOffer.action.urlValidation = '';
        }

        // Se a opção de validação previamente selecionada for diferente da atual
        if (this.selectedValidation) {
          if (this.selectedValidation.id !== validationObj.id) {
            // Redefine o validation_codes com um array vazio [''], deixando uma entrada com um ´Code´ vazio e o ´Type´ fornecido
            this.$parent.newOffer.validations_codes = this.setValidationCodes(
              [],
              this.getValidationTypeName(validationObj.code),
            );
          }

          // BUG fix: Na nova função setSelectedValidation, a alteração do tipo de validação estava
          // condicionada por um IF(), que verificava se o novo tipo era diferente do anterior.
          // No entanto, quando a missão era criada pela primeira vez e chegávamos à guia de Interação,
          // o tipo de validação selecionado por padrão, sem intervenção do usuário, não era considerado
          // diferente do anterior. Isso resultava em um registro incorreto do tipo de validação, apenas
          // quando fazíamos uma alteração manualmente.

          // Define o ID do tipo validação de acordo com o novo ITV selecionado
          this.$parent.newOffer.interaction_validation_id = validationObj.code;
          // Define todos os outros tipos de validação como não selecionados e o atual como selecionado
          this.validations.forEach((v) => (v.isSelected = false));
          validationObj.isSelected = true;
        }
      },
      getInteractionType() {
        return this.$parent.newOffer.interaction_type_id || 'none';
      },
      isInteractionType(id) {
        return this.getInteractionType() === id;
      },
      getSelectedValidation() {
        return this.selectedValidation;
      },
      isSelectedValidation(id, key) {
        return this.selectedValidation[key || 'code'] === id;
      },
      getOfferTypeId() {
        return this.$parent.newOffer.type_label_id;
      },
      getOfferType() {
        const offerType = this.$parent.newOffer.type_label_id;
        console.log("🚀 ~ getOfferType ~ offerType:", offerType)
        if (offerType == null) return;
        switch (offerType) {
          case 'OFT1':
            return 'message';
          case 'OFT2':
            return 'video';
          case 'OFT3':
            return 'website';
          case 'OFT4':
          case 'OFT5':
          case 'OFT6':
          case 'OFT7':
          case 'OFT8':
          case 'OFT9':
          case 'OFT10':
          case 'OFT25':
            return 'online';
          case 'OFT11':
          case 'OFT12':
          case 'OFT13':
          case 'OFT14':
          case 'OFT15':
          case 'OFT16':
          case 'OFT17':
            return 'offline';
          case 'OFT18':
            return 'poll';
          case 'OFT19':
            return 'survey';
          case 'OFT20':
            return 'data';
          case 'OFT22':
            return 'data-points';
          case 'OFT27':
            return 'follow-page';
          case 'OFT24':
            return 'share-post';
          case 'OFT32':
            return 'create-post';
          case 'OFT26':
          case 'OFT30':
            return 'social-media';
          case 'OFT31':
            return 'comment-instagram';
          case 'OFT28':
            return 'url-action';
          case 'OFT29':
            return 'loja';
        }
      },
      setValidationOptions() {
        const interactionType = this.$parent.newOffer.interaction_type_id;
        if (interactionType == null) return;

        switch (interactionType) {
          case 'INT1':
          case 'INT2':
          case 'INT3':
          case 'INT6':
          case 'INT7':
            this.$parent.newOffer.interaction_validation_id = 'ITV1';
            this.validations.push({
              id: 1,
              code: 'ITV1',
              icon: 'gear',
              text: this.$t('offers_step4.box_section_2_val_1'),
              isSelected: true,
              description: this.$t('offers_step4.box_section_3_val_1_description_1'),
            });
            break;
          case 'INT4':
            this.validations.push(
              {
                id: 1,
                code: 'ITV1',
                icon: 'gear',
                text: this.$t('offers_step4.box_section_2_val_1'),
                isSelected: true,
                description: this.$t('offers_step4.box_section_3_val_1_description_1'),
              },
              {
                id: 2,
                code: 'ITV2',
                icon: 'globe',
                text: this.$t('offers_step4.box_section_2_val_2'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_2_description_1'),
              },
              {
                id: 3,
                code: 'ITV3',
                icon: 'fingerprint',
                text: this.$t('offers_step4.box_section_2_val_3'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_3_description_1'),
              },
              {
                id: 5,
                code: 'ITV4',
                icon: 'post-back',
                text: this.$t('offers_step4.box_section_2_val_5'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_5_description_1'),
              },
              {
                id: 4,
                code: 'ITV6',
                icon: 'tag-manager',
                text: this.$t('offers_step4.box_section_2_val_4'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_4_description_1'),
              },
            );
            break;
          case 'INT5':
            this.validations.push(
              {
                id: 2,
                code: 'ITV2',
                icon: 'globe',
                text: this.$t('offers_step4.box_section_2_val_2'),
                isSelected: true,
                description: this.$t('offers_step4.box_section_3_val_2_description_1'),
              },
              {
                id: 3,
                code: 'ITV3',
                icon: 'fingerprint',
                text: this.$t('offers_step4.box_section_2_val_3'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_3_description_1'),
              },
              {
                id: 6,
                code: 'ITV5',
                icon: 'qr-code',
                text: this.$t('offers_step4.box_section_2_val_6'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_6_description_1'),
              },
            );
            break;
          case 'INT12':
          case 'INT13':
            this.$parent.newOffer.interaction_validation_id = 'ITV10';
            this.validations.push({
              id: 1,
              code: 'ITV10',
              icon: 'gear',
              text: this.$t('offers_step4.box_section_2_val_1'),
              isSelected: true,
              description: this.$t('offers_step4.box_section_3_val_1_description_1'),
            });
            break;
          case 'INT14':
            this.$parent.newOffer.interaction_validation_id = 'ITV11';
            this.validations.push({
              id: 1,
              code: 'ITV11',
              icon: 'gear',
              text: this.$t('offers_step4.box_section_2_val_1'),
              isSelected: true,
              description: this.$t('offers_step4.box_section_3_val_1_description_1'),
            });
            break;
          case 'INT15':
            this.validations.push(
              {
                id: 1,
                code: 'ITV1',
                icon: 'gear',
                text: this.$t('offers_step4.box_section_2_val_1'),
                isSelected: true,
                description: this.$t('offers_step4.box_section_3_val_1_description_1'),
              },
              {
                id: 2,
                code: 'ITV2',
                icon: 'globe',
                text: this.$t('offers_step4.box_section_2_val_2'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_2_description_1'),
              },
              {
                id: 3,
                code: 'ITV3',
                icon: 'fingerprint',
                text: this.$t('offers_step4.box_section_2_val_3'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_3_description_1'),
              },
              {
                id: 4,
                code: 'ITV6',
                icon: 'tag-manager',
                text: this.$t('offers_step4.box_section_2_val_4'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_4_description_1'),
              },
              {
                id: 5,
                code: 'ITV4',
                icon: 'post-back',
                text: this.$t('offers_step4.box_section_2_val_5'),
                isSelected: false,
                description: this.$t('offers_step4.box_section_3_val_5_description_1'),
              },
              {
                id: 7,
                code: 'ITV12',
                icon: 'signin',
                text: 'Deeplink',
                description:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus tempor mollis. Praesent in orci eget sem ornare placerat. Integer molestie tellus non lobortis euismod. Sed ut tempor',
                isSelected: true,
              },
            );
            break;
          case 'INT16':
            this.validations.push({
              id: 8,
              code: 'ITV13',
              icon: 'loja-integrada',
              text: 'Cupom Loja Integrada',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus tempor mollis. Praesent in orci eget sem ornare placerat. Integer molestie tellus non lobortis euismod. Sed ut tempor',
            });
            break;
          case 'INT10':
            this.$parent.newOffer.interaction_validation_id = 'ITV8';
            this.validations.push({
              id: 1,
              code: 'ITV8',
              icon: 'gear',
              text: this.$t('offers_step4.box_section_2_val_1'),
              isSelected: true,
              description: this.$t('offers_step4.box_section_3_val_1_description_1'),
            });
            break;
          case 'INT19':
            this.$parent.newOffer.interaction_validation_id = 'ITV15';
            this.validations.push({
              id: 1,
              code: 'ITV15',
              icon: 'gear',
              text: this.$t('offers_step4.box_section_2_val_1'),
              isSelected: true,
              description: this.$t('offers_step4.box_section_3_val_1_description_1'),
            });
            break;
        }
      },
      selectValidationOptionIfAlreadySelected() {
        const interactionCode = this.$parent.newOffer.interaction_validation_id;
        const val = this.validations.find((v) => v.code === interactionCode);
        this.validations.forEach((v) => (v.isSelected = false));
        if (val != null) {
          val.isSelected = true;
        } else {
          this.validations[0].isSelected = true;
          this.setSelectedValidation(this.validations[0]);
        }
      },
      objectHasProperty(object, property) {
        return lodash.has(object, property);
      },
      setSocialInteractionsValues() {
        if (this.$parent.newOffer.action == null) {
          this.socialInteractionsUrl = '';
          this.socialInteractionsMessage = '';
          return;
        }
        this.socialInteractionsUrl = this.isJsonString(this.$parent.newOffer.action)
          ? JSON.parse(this.$parent.newOffer.action).url
          : '';
        this.socialInteractionsMessage = this.isJsonString(this.$parent.newOffer.action)
          ? JSON.parse(this.$parent.newOffer.action).message
          : '';
      },

      validURL(str, isDeeplink) {
        isDeeplink = isDeeplink || false; // defining default for isDeeplink
        return isDeeplink === false
          ? /^(http){1}(s)?(\:\/\/){1}(.*?)\/?\.+\w+.*$/gm.test(str)
          : /^(\w)+(\:\/\/){1}\w*.*$/gm.test(str);
      },
      isVimeoURL(urlInput) {
        const url = new URL(urlInput);
        return url.host == 'vimeo.com';
      },
      hasOtherQueries(url) {
        return url.includes('?');
      },
      isJsonString(str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      },

      isValidationOption(id) {
        return this.selectedValidation.code === id;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .field-container {
    width: 660px;
    max-width: 100%;
  }
</style>
