<template>
  <div class="modatta-side-modal-scroll-middle-area modatta-side-modal-create-audience">
    <div class="sidenav-container">
      <div v-if="questions.length > 0" class="basic-filters">
        <div class="filter">
          <b-field :label="$t('filters.details.question')">
            <div class="modatta-country-select">
              <multiselect
                placeholder="Choose a question"
                selectLabel="ENTER to select"
                v-model="selectedQuestion"
                openDirection="bottom"
                :options="questions"
                :allow-empty="false"
                :showLabels="false"
                track-by="id"
                label="question"
              >
                <span class="caret" slot="caret">
                  <modatta-icon icon="arrow-down" width="10"></modatta-icon>
                </span>
              </multiselect>
            </div>
          </b-field>
        </div>
        <div class="filter">
          <b-field :label="$t('filters.details.operator')">
            <modatta-radio-input
              :options="operators"
              :has-other="false"
              @radio-value="(radio) => (this.selectedOperator = radio)"
              :suggested-value="selectedOperator"
            ></modatta-radio-input>
          </b-field>
        </div>
        <div v-if="selectedQuestion != null" class="answers-radio">
          <h3>{{ selectedQuestion.question }}</h3>
          <div class="filter">
            <b-field label="Answers">
              <modatta-object-radio-input
                :options="answers"
                prop="answer"
                :has-to-translate="false"
                @radio-value="(radio) => (selectedAnswer = radio)"
                :suggested-value="selectedAnswer"
              ></modatta-object-radio-input>
            </b-field>
          </div>
        </div>
        <button
          class="button is-info"
          @click="addFilter()"
          :disabled="!allFieldsCompleted"
          style="margin-top: 0; min-width: 100px; height: 42px"
        >
          {{ $t('offers_step2.create_filter') }}
        </button>
      </div>
      <div v-else class="filter">
        <b-field v-bind:label="$t('offers_step2.nothing_here')">
          <div class="empty-state">{{ $t('offers_step2_offer_filter.no_poll') }}</div>
        </b-field>
      </div>
      <div v-if="filters.length > 0" class="selected-filters">
        <div class="filters-wrapper">
          <div class="filter-item by-question" v-for="(filter, ind) in filters" :key="ind">
            <div class="concatenation">
              <strong>
                <span>
                  <b>{{ findQuestion(filter.profile_id).question }} </b>
                </span>
              </strong>
              <span class="operator-label">{{ $t(`labels.${filter.operator_id}`).toLowerCase() }} </span>
              <u>{{ filter.value }}</u>
            </div>
            <button class="button" @click="deleteFilter(ind)">
              <modatta-icon icon="times" width="12"></modatta-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <!-- filters.length < 1 ||  -->
      <button class="button is-primary" type="Submit" :disabled="isLoading" @click="createFilters()">
        <template v-if="!isLoading">{{ $t('offers_step2.create_filter') }}</template>
        <modatta-icon v-else icon="loading" class="rotating"></modatta-icon>
      </button>
      <button class="button is-text" @click="$parent.closeSideModal()" :disabled="isLoading">
        <template v-if="!isLoading">{{ $t('offers_step2.cancel_filter') }}</template>
        <template v-else>{{ $t('offers_step2.loading_filter') }}</template>
      </button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import Multiselect from 'vue-multiselect';
  export default {
    props: ['options'],
    components: { Multiselect },
    data() {
      return {
        isLoading: false,

        newOptions: [...this.options.filters],

        selectedQuestion: null,

        operators: [this.$t('labels.OPE32'), this.$t('labels.OPE33')],
        // "Exactly Like", "Not Like"
        selectedOperator: null,

        answers: [],
        selectedAnswer: null,
      };
    },
    mounted() {
      this.getDetailedOffers();
    },
    watch: {
      selectedQuestion(newVal) {
        if (newVal == null) return;
        // this.answers = newVal.offer_answers.map(a => a.answer);
        this.answers = newVal.offer_answers;
      },
    },
    computed: {
      filters() {
        return this.newOptions;
      },
      allFieldsCompleted() {
        return (
          this.selectedQuestion != null &&
          this.selectedQuestion != '' &&
          this.selectedOperator != null &&
          this.selectedOperator != '' &&
          this.selectedAnswer != null &&
          this.selectedAnswer != ''
        );
      },
      questions() {
        const pollsAndSurveys = this.$store.state.offer.detailedOffers.filter(
          (o) => o.type_label_id === 'OFT18' || o.type_label_id === 'OFT19',
        );
        const questions = [];
        pollsAndSurveys.forEach((offer) => {
          if (offer.questions.length > 0) {
            offer.questions.forEach((question) => {
              if (question.offer_id !== this.$parent.$parent.newOffer.id) {
                questions.push(question);
              }
            });
          }
        });
        return questions;
      },
    },
    methods: {
      ...mapActions(['getOffer', 'getDetailedOffers']),
      ...mapMutations(['upsertProfileNewOfferFilters']),

      createFilters() {
        const payload = { filters: this.filters, id: 'question', targetId: this.options.targetGroupId };
        this.upsertProfileNewOfferFilters(payload);
        this.$parent.closeSideModal();
      },

      addFilter() {
        let filter = this.newOptions.find((f) => f.profile_id === this.selectedQuestion.dest_profile_id);
        const selected = this.selectedOperator.toLowerCase();
        const isExactlyLike = this.$t('labels.OPE32').toLowerCase();
        const isNotLike = this.$t('labels.OPE33').toLowerCase();

        if (filter) {
          filter.operator = this.selectedOperator;
          filter.operator_id = this.selectedOperator.toLowerCase() === isExactlyLike ? 'OPE32' : 'OPE33';
          filter.value = this.selectedAnswer.answer;
          return;
        }

        this.newOptions.push({
          profile: this.selectedQuestion.question,
          profile_id: this.selectedQuestion.dest_profile_id,
          operator: selected === isExactlyLike ? this.$t('labels.OPE32') : this.$t('labels.OPE33'),
          operator_id: selected === isExactlyLike ? 'OPE32' : 'OPE33',
          value: this.selectedAnswer.answer,
          value_id: this.selectedAnswer.value_id,
          category: 'question',
        });
        this.resetFilters();
      },
      findQuestion(id) {
        return this.questions.find((question) => question.dest_profile_id === id);
      },
      deleteFilter(i) {
        this.newOptions.splice(i, 1);
      },
      resetFilters() {
        this.selectedQuestion = null;
        this.selectedOperator = null;
        this.selectedAnswer = null;
      },
    },
  };
</script>
